"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceResultToJSON = exports.DeviceResultFromJSONTyped = exports.DeviceResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DeviceResultFromJSON(json) {
    return DeviceResultFromJSONTyped(json, false);
}
exports.DeviceResultFromJSON = DeviceResultFromJSON;
function DeviceResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'data': (0, _1.DeviceFromJSON)(json['data']),
        'success': json['success'],
        'resultCode': json['resultCode'],
        'resultMsg': !(0, runtime_1.exists)(json, 'resultMsg') ? undefined : json['resultMsg'],
    };
}
exports.DeviceResultFromJSONTyped = DeviceResultFromJSONTyped;
function DeviceResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'data': (0, _1.DeviceToJSON)(value.data),
        'success': value.success,
        'resultCode': value.resultCode,
        'resultMsg': value.resultMsg,
    };
}
exports.DeviceResultToJSON = DeviceResultToJSON;
