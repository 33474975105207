import { Semaphore } from '../data/Semaphore';
import { getLogger } from '../logger';

const logger = getLogger('hybrid/HybridFile');

// Interface to the cordova badge plugin
interface HybridFilePlugin {
  applicationDirectory?: string;
}

interface CordovaWindow extends Window {
  cordova: Cordova & {
    file?: HybridFilePlugin;
  };
}

export class HybridFile {
  private static _mounted = false;
  private static _instance: HybridFile | undefined;
  private static _semaphore = new Semaphore(1, 'HybridFile');
  private _plugin: HybridFilePlugin | undefined = undefined;
  constructor(plugin: HybridFilePlugin) {
    this._plugin = plugin;
  }
  static get instance() {
    return HybridFile._instance;
  }
  static async mount(): Promise<HybridFile | undefined> {
    await HybridFile._semaphore.acquire();
    try {
      if (!HybridFile._instance && !HybridFile._mounted) {
        const _plugin = (window as CordovaWindow).cordova.file;
        if (!_plugin) {
          logger.notice(`'file' plugin: not installed`);
        } else {
          HybridFile._instance = new HybridFile(_plugin);
          logger.info(`'file' plugin: mounted`);
        }
      }
      return HybridFile._instance;
    } finally {
      HybridFile._semaphore.release();
    }
  }
  applicationDirectory(): string | undefined {
    if (this._plugin) {
      return this._plugin.applicationDirectory;
    } else {
      return undefined;
    }
  }
}
