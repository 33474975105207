/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { DeviceActionTypes, DeviceState } from './types';

const initialState: DeviceState = {
  resizing: undefined,
  width: undefined,
};

const reducer: Reducer<DeviceState> = (state: DeviceState = initialState, action) => {
  switch (action.type) {
    case DeviceActionTypes.SET_RESIZING: {
      return { ...state, resizing: action.payload };
    }
    case DeviceActionTypes.CLEAR_RESIZING: {
      return { ...state, resizing: undefined };
    }
    case DeviceActionTypes.SET_WIDTH: {
      return { ...state, width: action.payload };
    }
    case DeviceActionTypes.CLEAR_WIDTH: {
      return { ...state, width: undefined };
    }
    default: {
      return state;
    }
  }
};

export { reducer as deviceReducer };
