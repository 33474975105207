"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.refsToIds = exports.refToId = exports.idsToRefs = exports.idToRef = exports.getIdFromRefPath = exports.getRefPathFromDocument = exports.getRefPathFromId = void 0;
const getRefPathFromId = (collection, id) => {
    const cleanId = id.replace('/' + collection + '/', '').replace(collection + '/', '');
    return collection + '/' + cleanId;
};
exports.getRefPathFromId = getRefPathFromId;
const getRefPathFromDocument = (document) => document.snapshot.ref.path;
exports.getRefPathFromDocument = getRefPathFromDocument;
const getIdFromRefPath = (refPath) => {
    // When queryihg from BigQuery, the refPath may not be defined
    // but it escapes our type checks. So we need to check for it
    // here.
    if (refPath) {
        const i = refPath.lastIndexOf('/');
        if (i >= 0) {
            return refPath.substring(i + 1);
        }
        return refPath;
    }
    else {
        return '';
    }
};
exports.getIdFromRefPath = getIdFromRefPath;
const idToRef = (id, collection) => collection + '/' + id;
exports.idToRef = idToRef;
const idsToRefs = (ids, collection) => ids.map((id) => (0, exports.idToRef)(id, collection));
exports.idsToRefs = idsToRefs;
const refToId = (ref, collection) => ref.replace(collection + '/', '');
exports.refToId = refToId;
const refsToIds = (refs, collection) => refs.map((ref) => (0, exports.refToId)(ref, collection));
exports.refsToIds = refsToIds;
