import { WrappedComponentProps, injectIntl } from 'react-intl';

import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';
import strings from '../../languages';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Props = WrappedComponentProps &
  ButtonProps & {
    label?: string;
  };

export const CancelButton = injectIntl((props: Props) => {
  const { intl, label, sx, ...rest } = props;
  const localized = strings(intl);

  return (
    <Button {...rest} variant="text" sx={{ color: 'inherit', mr: 1.5, mt: 1, ...sx }}>
      {label ? label : localized.action.cancel()}
    </Button>
  );
});
