"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class DeviceApi extends runtime.BaseAPI {
    /**
     * Chage device settings and information.
     */
    changeDeviceSettingsRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.deviceSettingsWithId === null || requestParameters.deviceSettingsWithId === undefined) {
                throw new runtime.RequiredError('deviceSettingsWithId', 'Required parameter requestParameters.deviceSettingsWithId was null or undefined when calling changeDeviceSettings.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/device/settingDeviceInfo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.DeviceSettingsWithIdToJSON)(requestParameters.deviceSettingsWithId),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ResponseMessageFromJSON)(jsonValue));
        });
    }
    /**
     * Chage device settings and information.
     */
    changeDeviceSettings(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.changeDeviceSettingsRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Delete a device
     */
    deleteDeviceRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteDevice.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/deviceinfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ResponseMessageFromJSON)(jsonValue));
        });
    }
    /**
     * Delete a device
     */
    deleteDevice(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteDeviceRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Get details about a device
     */
    getDeviceRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                throw new runtime.RequiredError('deviceId', 'Required parameter requestParameters.deviceId was null or undefined when calling getDevice.');
            }
            const queryParameters = {};
            if (requestParameters.deviceId !== undefined) {
                queryParameters['deviceId'] = requestParameters.deviceId;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/api/device/getCardInfo`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.DeviceResultFromJSON)(jsonValue));
        });
    }
    /**
     * Get details about a device
     */
    getDevice(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDeviceRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Get device information
     */
    getDeviceInfoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getDeviceInfo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/deviceinfo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.DeviceInfoWithIdFromJSON)(jsonValue));
        });
    }
    /**
     * Get device information
     */
    getDeviceInfo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDeviceInfoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Get list of devices.
     */
    getDeviceInfoPageRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.homeid === null || requestParameters.homeid === undefined) {
                throw new runtime.RequiredError('homeid', 'Required parameter requestParameters.homeid was null or undefined when calling getDeviceInfoPage.');
            }
            const queryParameters = {};
            if (requestParameters.homeid !== undefined) {
                queryParameters['homeid'] = requestParameters.homeid;
            }
            if (requestParameters.page !== undefined) {
                queryParameters['page'] = requestParameters.page;
            }
            if (requestParameters.limit !== undefined) {
                queryParameters['limit'] = requestParameters.limit;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/deviceinfo/deviceinfoPage`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.DevicePageFromJSON)(jsonValue));
        });
    }
    /**
     * Get list of devices.
     */
    getDeviceInfoPage(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getDeviceInfoPageRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Register a new device
     */
    registerDeviceInfoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.registerDeviceInfo === null || requestParameters.registerDeviceInfo === undefined) {
                throw new runtime.RequiredError('registerDeviceInfo', 'Required parameter requestParameters.registerDeviceInfo was null or undefined when calling registerDeviceInfo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/deviceinfo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.RegisterDeviceInfoToJSON)(requestParameters.registerDeviceInfo),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ResponseMessageFromJSON)(jsonValue));
        });
    }
    /**
     * Register a new device
     */
    registerDeviceInfo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.registerDeviceInfoRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * This function allows you to temporarily control alarm reporting by a device.
     * Pause, disable, and re-enable alarms on a device.
     */
    stopAlarmRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.alarmStop === null || requestParameters.alarmStop === undefined) {
                throw new runtime.RequiredError('alarmStop', 'Required parameter requestParameters.alarmStop was null or undefined when calling stopAlarm.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/api/device/alarmStop`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.AlarmStopToJSON)(requestParameters.alarmStop),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ResponseMessageFromJSON)(jsonValue));
        });
    }
    /**
     * This function allows you to temporarily control alarm reporting by a device.
     * Pause, disable, and re-enable alarms on a device.
     */
    stopAlarm(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.stopAlarmRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Update a device
     */
    updateDeviceInfoRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.deviceInfoWithId === null || requestParameters.deviceInfoWithId === undefined) {
                throw new runtime.RequiredError('deviceInfoWithId', 'Required parameter requestParameters.deviceInfoWithId was null or undefined when calling updateDeviceInfo.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/deviceinfo`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.DeviceInfoWithIdToJSON)(requestParameters.deviceInfoWithId),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ResponseMessageFromJSON)(jsonValue));
        });
    }
    /**
     * Update a device
     */
    updateDeviceInfo(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateDeviceInfoRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.DeviceApi = DeviceApi;
