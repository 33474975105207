import * as React from 'react';

import { DialogProps, Dialog as MuiDialog } from '@mui/material';
import { useWidth } from '../../hooks';
import { isExtraSmall } from '../../device';

const Dialog: React.FC<DialogProps> = (props) => {
  const { children, ...other } = props;
  const width = useWidth();

  return (
    <MuiDialog fullScreen={isExtraSmall(width)} {...other}>
      {children}
    </MuiDialog>
  );
};

export default Dialog;
