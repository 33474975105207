import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Icons } from '../';
import strings from '../../languages';
import * as redux from '../../redux';
import Dialog from './Dialog';
import { CancelButton } from '../buttons';
import {
  DashboardAwakeSettings,
  DashboardLayoutSettings,
  DashboardSoundSettings,
} from '../settings';

interface ComponentProps {
  className?: string;
  sx?: SxProps<Theme>;
  open: boolean;
  onClose: () => void;
}

interface StateProps {
  showVitals: boolean;
  showBleVitals: boolean;
  showAlertsList: boolean;
  sortByRoom: boolean;
  playVerbalNotifications: boolean;

  muteAlerts: boolean;
  muteWarnings: boolean;
  disableWarnings: boolean;

  setShowVitals: (show: boolean) => void;
  setShowBleVitals: (show: boolean) => void;
  setShowAlertsList: (show: boolean) => void;
  setSortByRoom: (sort: boolean) => void;
  setPlayVerbalNotifications: (play: boolean) => void;

  setMuteAlerts: (mute: boolean) => void;
  setMuteWarnings: (mute: boolean) => void;
  setDisableWarnings: (disabled: boolean) => void;
}

const mapStateToProps = (state: redux.ApplicationState) => ({
  hideWarnings: state.dashboard.hideWarnings,
  showVitals: state.dashboard.showVitals,
  showBleVitals: state.dashboard.showBleVitals,
  showAlertsList: state.dashboard.showAlertsList,
  sortByRoom: state.dashboard.sortByRoom,
  playVerbalNotifications: state.dashboard.playVerbalNotifications,
  bedOccupancy: state.dashboard.bedOccupancy,

  muteAlerts: state.dashboard.muteAlerts,
  muteWarnings: state.dashboard.muteWarnings,
  disableWarnings: state.dashboard.disableWarnings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setShowVitals: (show: boolean) => dispatch(redux.dashboard.showVitals(show)),
  setShowBleVitals: (show: boolean) => dispatch(redux.dashboard.showBleVitals(show)),
  setShowAlertsList: (show: boolean) => dispatch(redux.dashboard.showAlertsList(show)),
  setSortByRoom: (sort: boolean) => dispatch(redux.dashboard.sortByRoom(sort)),
  setPlayVerbalNotifications: (play: boolean) =>
    dispatch(redux.dashboard.playVerbalNotifications(play)),

  setMuteAlerts: (mute: boolean) => dispatch(redux.dashboard.muteAlerts(mute)),
  setMuteWarnings: (mute: boolean) => dispatch(redux.dashboard.muteWarnings(mute)),
  setDisableWarnings: (disabled: boolean) => dispatch(redux.dashboard.disableWarnings(disabled)),
});

type Props = ComponentProps & WrappedComponentProps & StateProps;

export const DashboardSettingsDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl((props: Props) => {
    const l = strings(props.intl);

    return (
      <Dialog
        // sx={{ ...props.sx }}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="system-settings-dialog-title"
      >
        <DialogTitle id="system-settings-dialog-title">
          <Icons.Settings />
          <span>{l.dashboard.settingsTitle()}</span>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Stack className="AC-infoSpacing">
            <Stack>
              <Typography>{l.dashboard.alertSoundNotifications()}</Typography>
              <DashboardSoundSettings muted={props.muteAlerts} onChange={props.setMuteAlerts} />
            </Stack>
            <Stack>
              <Typography>{l.dashboard.layoutSettings()}</Typography>
              <DashboardLayoutSettings
                sortByRoom={props.sortByRoom}
                onChange={props.setSortByRoom}
              />
            </Stack>
            <Stack direction="row" spacing={4}>
              <Stack>
                <Typography>{l.dashboard.awakeSettings()}</Typography>
                <DashboardAwakeSettings
                  enabled={!props.disableWarnings}
                  onChange={(enabled: boolean) => props.setDisableWarnings(!enabled)}
                />
              </Stack>
              {!props.disableWarnings && !props.muteAlerts ? (
                <Stack>
                  <Typography>{l.dashboard.awakeSoundNotifications()}</Typography>
                  <DashboardSoundSettings
                    muted={props.muteWarnings}
                    onChange={props.setMuteWarnings}
                    disabled={props.disableWarnings || props.muteAlerts}
                  />
                </Stack>
              ) : null}
            </Stack>
            {/* OneSignal */}
            <div className="onesignal-customlink-container"></div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={props.onClose} label={l.action.close()} />
        </DialogActions>
      </Dialog>
    );
  })
);
