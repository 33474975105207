"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeRecordToJSON = exports.HomeRecordFromJSONTyped = exports.HomeRecordFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function HomeRecordFromJSON(json) {
    return HomeRecordFromJSONTyped(json, false);
}
exports.HomeRecordFromJSON = HomeRecordFromJSON;
function HomeRecordFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'homename': json['homename'],
        'address': json['address'],
        'equipment': !(0, runtime_1.exists)(json, 'equipment') ? undefined : json['equipment'],
        'createTime': !(0, runtime_1.exists)(json, 'createTime') ? undefined : json['createTime'],
        'updateTime': !(0, runtime_1.exists)(json, 'updateTime') ? undefined : json['updateTime'],
        'callbackurl': !(0, runtime_1.exists)(json, 'callbackurl') ? undefined : json['callbackurl'],
        'cronpush': !(0, runtime_1.exists)(json, 'cronpush') ? undefined : json['cronpush'],
        'pushtype': json['pushtype'],
        'pushTypes': !(0, runtime_1.exists)(json, 'pushTypes') ? undefined : (json['pushTypes'].map(_1.PushTypeFromJSON)),
        'delFlag': !(0, runtime_1.exists)(json, 'delFlag') ? undefined : json['delFlag'],
    };
}
exports.HomeRecordFromJSONTyped = HomeRecordFromJSONTyped;
function HomeRecordToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'homename': value.homename,
        'address': value.address,
        'equipment': value.equipment,
        'createTime': value.createTime,
        'updateTime': value.updateTime,
        'callbackurl': value.callbackurl,
        'cronpush': value.cronpush,
        'pushtype': value.pushtype,
        'pushTypes': value.pushTypes === undefined ? undefined : (value.pushTypes.map(_1.PushTypeToJSON)),
        'delFlag': value.delFlag,
    };
}
exports.HomeRecordToJSON = HomeRecordToJSON;
