"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentUserResultToJSON = exports.CurrentUserResultFromJSONTyped = exports.CurrentUserResultFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CurrentUserResultFromJSON(json) {
    return CurrentUserResultFromJSONTyped(json, false);
}
exports.CurrentUserResultFromJSON = CurrentUserResultFromJSON;
function CurrentUserResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sysUser': !(0, runtime_1.exists)(json, 'sysUser') ? undefined : (0, _1.LoginAccountFromJSON)(json['sysUser']),
        'permissions': !(0, runtime_1.exists)(json, 'permissions') ? undefined : (json['permissions'].map(_1.PermissionCodeFromJSON)),
        'roles': !(0, runtime_1.exists)(json, 'roles') ? undefined : (json['roles'].map(_1.RoleCodeFromJSON)),
        'homeids': !(0, runtime_1.exists)(json, 'homeids') ? undefined : json['homeids'],
    };
}
exports.CurrentUserResultFromJSONTyped = CurrentUserResultFromJSONTyped;
function CurrentUserResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sysUser': (0, _1.LoginAccountToJSON)(value.sysUser),
        'permissions': value.permissions === undefined ? undefined : (value.permissions.map(_1.PermissionCodeToJSON)),
        'roles': value.roles === undefined ? undefined : (value.roles.map(_1.RoleCodeToJSON)),
        'homeids': value.homeids,
    };
}
exports.CurrentUserResultToJSON = CurrentUserResultToJSON;
