import { CareCenter } from 'attentive-connect-store/dist/models';
import { getLogger } from '../logger';
import moment from 'moment-timezone';

const logger = getLogger('data/Dates', 'info');

/**
 * TimeZoneDate is a wrapper around the Date object that manages a date in a specific time zone.
 */
export class TimeZoneDate {
  private _tz: string;
  private _offset: number;
  private _date: Date;

  private constructor(time: number, tz: string) {
    this._tz = tz;
    this._offset = TimeZoneDate.utcOffset(tz);
    this._date = new Date(time);
  }

  static create(tz: string | CareCenter = 'UTC', src?: Date | number | string): TimeZoneDate {
    const tzName = TimeZoneDate.tzName(tz);
    const offset = TimeZoneDate.utcOffset(tz);
    const date = src !== undefined ? new Date(src) : new Date();
    const tzDate = new TimeZoneDate(date.getTime() + offset * 60 * 1000, tzName);
    logger.debug(`create(${tzName}, ${date.toISOString()})`, tzDate.toISOString());
    return tzDate;
  }

  static parseCalendarDay(dateString: string, tz: string | CareCenter = 'UTC'): TimeZoneDate {
    const _tz = TimeZoneDate.tzName(tz);
    const date = new Date(dateString);
    const parsed = TimeZoneDate.create(tz, date);
    logger.debug(`parseCalendarDay(${dateString}, ${_tz}):`, {
      dateString,
      parsed: parsed.snapshot(),
    });
    return parsed;
  }

  static tzName(tz: string | CareCenter | undefined): string {
    const localTz = moment().zoneName();
    if (!tz) {
      return localTz;
    }
    const _tz = typeof tz === 'object' ? (tz.data.tz ? tz.data.tz : localTz) : tz;
    return _tz;
  }

  /**
   * Returns UTC offset in minutes for the specified time zone.
   * @param tz the time zone name or CareCenter object
   * @returns the offset in minutes
   */
  static utcOffset(tz: string | CareCenter | undefined) {
    const _tz = TimeZoneDate.tzName(tz);
    let offset = moment().utcOffset();
    if (_tz) {
      const date = new Date();
      offset =
        (TimeZoneDate.tzDate(_tz, date).getTime() - TimeZoneDate.utcDate(date).getTime()) /
        (1000 * 60);
    }
    logger.debug(`utcOffset: ${_tz} ${offset} minutes (${Math.round(offset / 60)} hours)`);
    return offset;
  }

  static utcDate(date?: Date) {
    return TimeZoneDate.tzDate('UTC', date);
  }

  static tzDate(tz: string, date?: Date) {
    const d = date ? date : new Date();
    const tzDate = new Date(d.toLocaleString('en-US', { timeZone: tz }));
    return tzDate;
  }

  get offset() {
    return this._offset;
  }

  toDate() {
    return new Date(this.getTime());
  }

  getTime() {
    return this._date.getTime() - this._offset * 60 * 1000;
  }

  private changeTime(time: Date | number | string) {
    // const date = TimeZoneDate.create(this._tz, time);
    // const date = new TimeZoneDate(new Date(time).getTime(), this._tz);
    const date = new Date(time);
    return new TimeZoneDate(date.getTime(), this._tz);
  }

  get year() {
    return this._date.getUTCFullYear();
  }

  setYear(year: number) {
    const d = new Date(this._date);
    d.setUTCFullYear(year);
    return this.changeTime(d);
  }

  get month() {
    return this._date.getUTCMonth() + 1;
  }

  setMonth(month: number) {
    const d = new Date(this._date);
    d.setUTCMonth(month - 1);
    return this.changeTime(d);
  }

  get day() {
    return this._date.getUTCDate();
  }

  setDay(day: number) {
    const d = new Date(this._date);
    d.setUTCDate(day);
    return this.changeTime(d);
  }

  get hours() {
    return this._date.getUTCHours();
  }

  setHours(hours: number, min?: number, sec?: number, ms?: number) {
    const d = new Date(this._date);
    d.setUTCHours(hours, min, sec, ms);
    return this.changeTime(d);
  }

  get minutes() {
    return this._date.getUTCMinutes();
  }

  setMinutes(minutes: number) {
    const d = new Date(this._date);
    d.setUTCMinutes(minutes);
    return this.changeTime(d);
  }

  get seconds() {
    return this._date.getUTCSeconds();
  }

  setSeconds(seconds: number) {
    const d = new Date(this._date);
    d.setUTCSeconds(seconds);
    return this.changeTime(d);
  }

  get milliseconds() {
    return this._date.getUTCMilliseconds();
  }

  setMilliseconds(milliseconds = 0) {
    const d = new Date(this._date);
    d.setUTCMilliseconds(milliseconds);
    return this.changeTime(d);
  }

  // setTimeZone(tz: string) {
  //   const d = TimeZoneDate.create(tz, new Date())
  //     .setYear(this.year)
  //     .setMonth(this.month)
  //     .setHours(this.hours, this.minutes, this.seconds, this.milliseconds);
  //   return d;
  // }

  timeZone() {
    return this._tz;
  }

  snapshot() {
    return {
      tz: this._tz,
      tzOffset: this._offset,
      year: this.year,
      month: this.month,
      day: this.day,
      hour: this.hours,
      minute: this.minutes,
      second: this.seconds,
      isoDate: this.toISOString(),
    };
  }

  daysUntil(date: TimeZoneDate) {
    const t1 = this.getTime();
    const t2 = date.getTime();
    return Math.round((t2 - t1) / (24 * 60 * 60 * 1000));
  }

  isBefore(date: TimeZoneDate) {
    return this.getTime() < date.getTime();
  }

  subtractDays(days: number) {
    return this.subtractTime(days * 24 * 60 * 60 * 1000);
  }

  subtractTime(time: number) {
    const t = this._date.getTime();
    return this.changeTime(t - time);
  }

  addDays(days: number) {
    return this.addTime(days * 24 * 60 * 60 * 1000);
  }

  addTime(time: number) {
    const t = this._date.getTime();
    return this.changeTime(t + time);
  }

  toCalendarDate() {
    return (
      this.year +
      '-' +
      (this.month < 10 ? '0' + this.month : this.month) +
      '-' +
      (this.day < 10 ? '0' + this.day : this.day)
    );
  }

  toString() {
    return this.toISOString();
  }

  toDateString(lang: string) {
    // const date = new Date(this.toISOString());
    const date = this._date;
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      // hour: '2-digit',
      // minute: '2-digit',
      // second: "2-digit",
      // timeZone: this._tz,
      timeZone: 'UTC',
    };
    const str = date.toLocaleDateString(lang, options);
    logger.debug(`toDateString(${lang}) [${this.toCalendarDate()}]:`, str);
    return str;
  }

  toDateTimeString(lang: string) {
    // const date = new Date(this.toISOString());
    const date = this._date;
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      // timeZone: this._tz,
      timeZone: 'UTC',
    };
    const str = date.toLocaleDateString(lang, options);
    logger.debug(`toDateTimeString(${lang}) [${this.toCalendarDate()}]:`, str);
    return str;
  }

  toISOString() {
    let iso = this.toCalendarDate();
    iso += 'T';
    iso += this.hours < 10 ? '0' + this.hours : this.hours;
    iso += ':';
    iso += this.minutes < 10 ? '0' + this.minutes : this.minutes;
    iso += ':';
    iso += this.seconds < 10 ? '0' + this.seconds : this.seconds;
    iso += '.';
    iso +=
      this.milliseconds < 10
        ? '00' + this.milliseconds
        : this.milliseconds < 100
        ? '0' + this.milliseconds
        : this.milliseconds;
    iso += this._offset < 0 ? '-' : '+';
    const offset = Math.abs(this._offset);
    const offsetHours = Math.floor(offset / 60);
    const offsetMinutes = offset % 60;
    iso += offsetHours < 10 ? '0' + offsetHours : offsetHours;
    iso += ':';
    iso += offsetMinutes < 10 ? '0' + offsetMinutes : offsetMinutes;
    return iso;
  }
}

export const createTimeZoneDate = TimeZoneDate.create;
export const tzName = TimeZoneDate.tzName;
export const parseCalendarDay = TimeZoneDate.parseCalendarDay;
export const utcOffset = TimeZoneDate.utcOffset;
export default TimeZoneDate;
