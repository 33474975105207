import { Reducer } from 'redux';

import { getLogger } from '../../logger';
import { SensingWaveActionTypes, SensingWaveState } from './types';

const logger = getLogger('redux/sensingWave/reducer');

const initialState: SensingWaveState = {
  token: undefined,
  vitals: [],
  vitalsPollingIsEnabled: false,
  vitalsPollingSensors: [],
};

const reducer: Reducer<SensingWaveState> = (state: SensingWaveState = initialState, action) => {
  switch (action.type) {
    case SensingWaveActionTypes.SET_SENSING_WAVE_VITALS: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      logger.debug('set sensingWave vitals: ' + action.payload.length);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { ...state, vitals: action.payload ? action.payload : [] };
    }
    case SensingWaveActionTypes.CLEAR_SENSING_WAVE_VITALS: {
      logger.debug('clear sensingWave vitals');
      return { ...state, vitals: [] };
    }
    case SensingWaveActionTypes.ENABLE_SENSING_WAVE_VITALS_POLLING: {
      logger.debug('enable sensingWave vitals polling');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { ...state, vitalsPollingIsEnabled: true, vitalsPollingSensors: action.payload };
    }
    case SensingWaveActionTypes.DISABLE_SENSING_WAVE_VITALS_POLLING: {
      logger.debug('disable sensingWave vitals polling');
      return { ...state, vitalsPollingIsEnabled: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as SensingWaveReducer };
