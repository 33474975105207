import * as React from 'react';
import { Stack, Typography } from '@mui/material';
import DisabledReading from './DisabledReading';
import { DataBoxVariants } from './DataBoxConfig';
import classNames from 'classnames';

type Reading = [value: number | string | undefined, unit: string];
export type DataBoxReadingData = Reading | Reading[];

type Props = {
  className?: string;
  variant?: DataBoxVariants;
  values?: DataBoxReadingData;
};

const DataBoxReading = (props: Props): React.JSX.Element => {
  const readings = getReadings(props.values);
  const singleReading = readings.length === 1 ? true : false;
  const compact = props.variant === 'dashboard' ? true : false;

  if (readings.length > 0) {
    return (
      <Stack
        justifyContent={singleReading ? 'space-between' : 'start'}
        className={classNames('AC-dataReading', props.className)}
      >
        {readings.map((reading, index) => (
          <Stack
            key={index}
            sx={{ width: singleReading ? '100%' : 'auto' }}
            spacing={0.25}
            direction="row"
            alignItems="baseline"
            justifyContent={singleReading || compact ? 'space-between' : undefined}
          >
            {reading[0] === undefined ? null : (
              <Typography
                className={classNames(
                  'AC-dataValue',
                  typeof reading[0] === 'string' ? 'AC-textVariant' : null
                )}
              >
                {reading[0]}
              </Typography>
            )}
            <Typography className="AC-dataUnits">{reading[1]}</Typography>
          </Stack>
        ))}
      </Stack>
    );
  } else {
    return <DisabledReading variant={props.variant} />;
  }
};

const isReadingArray = (values?: DataBoxReadingData): values is Reading[] => {
  return (
    Array.isArray(values) && values.length > 0 && Array.isArray(values[0]) && isReading(values[0])
  );
};
const isReading = (values?: DataBoxReadingData): values is Reading => {
  return (
    Array.isArray(values) &&
    values.length === 2 &&
    (typeof values[0] === 'number' || typeof values[0] === 'string' || values[0] === undefined) &&
    typeof values[1] === 'string'
  );
};
const getReadings = (values?: DataBoxReadingData): Reading[] => {
  if (isReadingArray(values)) {
    return values;
  } else if (isReading(values)) {
    return [values];
  } else {
    return [];
  }
};

export default DataBoxReading;
