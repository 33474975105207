"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class AuthenticationApi extends runtime.BaseAPI {
    /**
     * Get details of current user.
     */
    getCurrentUserRaw() {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/user/current`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.CurrentUserFromJSON)(jsonValue));
        });
    }
    /**
     * Get details of current user.
     */
    getCurrentUser() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCurrentUserRaw();
            return yield response.value();
        });
    }
    /**
     * Get login account details.
     */
    getUserByIdRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getUserById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.SystemUserFromJSON)(jsonValue));
        });
    }
    /**
     * Get login account details.
     */
    getUserById(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getUserByIdRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Login to AiSleep server and obtain a bearer token
     */
    loginRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.username !== undefined) {
                queryParameters['username'] = requestParameters.username;
            }
            if (requestParameters.password !== undefined) {
                queryParameters['password'] = requestParameters.password;
            }
            const headerParameters = {};
            const response = yield this.request({
                path: `/login`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.LoginStatusFromJSON)(jsonValue));
        });
    }
    /**
     * Login to AiSleep server and obtain a bearer token
     */
    login(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.loginRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.AuthenticationApi = AuthenticationApi;
