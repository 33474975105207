import { createSvgIcon } from '@mui/material';

const AttentiveConnectSymbol = createSvgIcon(
  <g transform="matrix(0.0410783,0,0,0.0403264,1.4795,1.67549)">
    <path d="M450.5,325.2C418,290.9 400.3,269.4 403.5,208.5C407.6,129.5 366.6,72.1 299.6,59.8C300.3,46.5 301.1,31.5 301.4,24.7C301.9,15.7 278,7.3 261.5,8.1C252.9,8.5 246.3,11.3 246,17.8C245.5,27.7 244.5,45.7 243.8,60.6C184,74.8 149.2,133.7 146.3,190C142.5,263.3 101.2,291 65.1,325.3C47.4,343.3 98,370 102.5,364.8C149.7,320 176.5,279 180.8,197C183.9,138.4 216.9,106.3 269.3,106.3C321.7,106.3 351.1,143.9 347.7,208.7C344.4,272.1 366.6,320.1 409,364.9C420.8,376.7 454.7,329.8 450.5,325.2Z" />
    <path d="M290.6,229.4C291.2,218.2 292.5,193.7 293.3,178.2C294.5,155.8 238.7,155.3 237.5,178.2C236.7,194.2 235.6,214.6 235.1,224.7C233.9,245.6 290.1,240.6 290.6,229.4Z" />
    <path d="M308.7,403.2C301.6,442.9 283.2,471.4 254.7,471.4C217.7,471.4 196.4,429.3 198.3,392.3C200.9,342.9 225.2,313.2 256,313.2C278,313.2 298.5,332.8 306.2,358.4C307.9,363.1 367.3,362.5 356.7,342.3C340.2,305.7 303.7,280.6 260.1,280.6C198.5,280.6 145.8,330.7 142.6,392.3C139.3,453.9 186.7,504 248.3,504C308.2,504 359.8,456.5 365.5,397.2C365.9,388.9 311.2,386.5 308.7,403.2Z" />
  </g>,
  'AttentiveConnectSymbol'
);

export { AttentiveConnectSymbol };
export default AttentiveConnectSymbol;
