import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import App from './App';
import { messages } from './languages';
import { ApplicationState } from './redux';
import * as localeStore from './redux/locale';

interface PropsFromState {
  locale: localeStore.LocaleState;
}

interface PropsFromDispatch {
  setLanguageFromBrowser: typeof localeStore.setLanguageFromBrowser;
}

type AppProps = PropsFromState & PropsFromDispatch;

class Localized extends React.Component<AppProps> {
  componentDidMount() {
    if (!this.props.locale.initialized) {
      this.props.setLanguageFromBrowser();
    }
  }

  render() {
    const { language } = this.props.locale;
    return (
      <IntlProvider locale={language} messages={messages[language]}>
        <App />
      </IntlProvider>
    );
  }
}

// It's usually good practice to only include one context at a time in a connected component.
// Although if necessary, you can always include multiple contexts. Just make sure to
// separate them from each other to prevent prop conflicts.
const mapStateToProps = ({ locale }: ApplicationState) => ({
  locale,
});

// mapDispatchToProps is especially useful for constraining our actions to the connected component.
// You can access these via `this.props`.
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLanguageFromBrowser: () => dispatch(localeStore.setLanguageFromBrowser()),
});

// Now let's connect our component!
// With redux v4's improved typings, we can finally omit generics here.
export default connect(mapStateToProps, mapDispatchToProps)(Localized);
