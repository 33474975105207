"use strict";
/* TS enum provides for type-safe enum property and a code/value
 * that is used for internal representation in the database.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RRAlertVitalMeterResolutionDetail = exports.RRAlertVisualResolutionDetail = exports.RRAlertResolution = exports.HRAlertVitalMeterResolutionDetail = exports.HRAlertVisualResolutionDetail = exports.HRAlertResolution = exports.PressureAlertResolutionChangedPostureDetail = exports.PressureAlertResolution = exports.FallAlertResolutionOtherDetail = exports.FallAlertResolutionCareProvidedDetail = exports.FallAlertResolution = exports.isFallAlertResolution = exports.FallAlertSituationOtherDetail = exports.FallAlertSituationLeftBedDetail = exports.FallAlertSituationAboutToLeaveBedDetail = exports.FallAlertSituationCategory = exports.isFallAlertSituation = exports.isAlertResolutionSituation = exports.AlertResolutionVerification = exports.SystemResolutionDetail = exports.SystemResolution = exports.OtherAlertResolution = void 0;
var OtherAlertResolution;
(function (OtherAlertResolution) {
    OtherAlertResolution["noActionRequired"] = "alert/resolution/no-action-required";
    OtherAlertResolution["other"] = "alert/resolution/other";
})(OtherAlertResolution || (exports.OtherAlertResolution = OtherAlertResolution = {}));
var SystemResolution;
(function (SystemResolution) {
    SystemResolution["other"] = "alert/resolution/other";
    SystemResolution["expired"] = "alert/resolution/system/expired";
    SystemResolution["canceled"] = "alert/resolution/system/canceled";
    SystemResolution["replaced"] = "alert/resolution/system/replaced";
    SystemResolution["returnedExpired"] = "alert/resolution/system/returned-expired";
})(SystemResolution || (exports.SystemResolution = SystemResolution = {}));
var SystemResolutionDetail;
(function (SystemResolutionDetail) {
    SystemResolutionDetail["returnedToBed"] = "alert/resolution/system/detail/returned-to-bed";
    SystemResolutionDetail["bedExit"] = "alert/resolution/system/detail/bed-exit";
    SystemResolutionDetail["falseBedExit"] = "alert/resolution/system/detail/false-bed-exit";
})(SystemResolutionDetail || (exports.SystemResolutionDetail = SystemResolutionDetail = {}));
var AlertResolutionVerification;
(function (AlertResolutionVerification) {
    AlertResolutionVerification["turnVerifiedBySensor"] = "alert/resolution/pressure/turn-verified-by-sensor";
    AlertResolutionVerification["turnNotVerifiedBySensor"] = "alert/resolution/pressure/turn-not-verified-by-sensor";
})(AlertResolutionVerification || (exports.AlertResolutionVerification = AlertResolutionVerification = {}));
// TODO - add remaining situation tests
const isAlertResolutionSituation = (val) => (0, exports.isFallAlertSituation)(val.resolution);
exports.isAlertResolutionSituation = isAlertResolutionSituation;
//
// -------------------------------------------
// Fall Alert Situation and Resolution Options
// -------------------------------------------
//
// FALL SITUATIONS
const isFallAlertSituation = (val) => val.indexOf('alert/situation/fall/') === 0;
exports.isFallAlertSituation = isFallAlertSituation;
var FallAlertSituationCategory;
(function (FallAlertSituationCategory) {
    FallAlertSituationCategory["aboutToLeaveBed"] = "alert/situation/fall/about-to-leave-bed";
    FallAlertSituationCategory["leftBed"] = "alert/situation/fall/already-left-bed";
    FallAlertSituationCategory["other"] = "alert/situation/fall/other";
})(FallAlertSituationCategory || (exports.FallAlertSituationCategory = FallAlertSituationCategory = {}));
var FallAlertSituationAboutToLeaveBedDetail;
(function (FallAlertSituationAboutToLeaveBedDetail) {
    FallAlertSituationAboutToLeaveBedDetail["patientTryingToGetUp"] = "alert/situation/fall/about-to-leave-bed/trying-to-get-up";
    FallAlertSituationAboutToLeaveBedDetail["patientSittingInBed"] = "alert/situation/fall/about-to-leave-bed/sitting-in-bed";
})(FallAlertSituationAboutToLeaveBedDetail || (exports.FallAlertSituationAboutToLeaveBedDetail = FallAlertSituationAboutToLeaveBedDetail = {}));
var FallAlertSituationLeftBedDetail;
(function (FallAlertSituationLeftBedDetail) {
    FallAlertSituationLeftBedDetail["alreadyLeftBed"] = "alert/situation/fall/left-bed/already-left-bed";
    FallAlertSituationLeftBedDetail["inWheelChair"] = "alert/situation/fall/left-bed/in-wheel-chair";
    FallAlertSituationLeftBedDetail["movingAround"] = "alert/situation/fall/left-bed/moving-around";
    FallAlertSituationLeftBedDetail["alreadyFell"] = "alert/situation/fall/left-bed/already-fell";
})(FallAlertSituationLeftBedDetail || (exports.FallAlertSituationLeftBedDetail = FallAlertSituationLeftBedDetail = {}));
var FallAlertSituationOtherDetail;
(function (FallAlertSituationOtherDetail) {
    FallAlertSituationOtherDetail["rollingOver"] = "alert/situation/fall/other/rolling-over";
    FallAlertSituationOtherDetail["providingCare"] = "alert/situation/fall/other/providing-care";
    FallAlertSituationOtherDetail["falseAlert"] = "alert/situation/fall/other/false-alert";
})(FallAlertSituationOtherDetail || (exports.FallAlertSituationOtherDetail = FallAlertSituationOtherDetail = {}));
const isFallAlertResolution = (val) => val.indexOf('alert/resolution/fall/') === 0;
exports.isFallAlertResolution = isFallAlertResolution;
var FallAlertResolution;
(function (FallAlertResolution) {
    // aboutToLeaveBed = 'alert/resolution/fall/about-to-leave-bed',
    // notLeavingBed = 'alert/resolution/fall/not-leaving-bed',
    // returnedToBed = 'alert/resolution/fall/returned-to-bed',
    // leftBed = 'alert/resolution/fall/already-left-bed',
    // notInRoom = 'alert/resolution/fall/not-in-room',
    // fellDown = 'alert/resolution/fall/fell-down',
    FallAlertResolution["careProvided"] = "alert/resolution/fall/care-provided";
    FallAlertResolution["other"] = "alert/resolution/fall/other";
})(FallAlertResolution || (exports.FallAlertResolution = FallAlertResolution = {}));
// export enum FallAlertResolutionReturnToBedDetail {
//   returnedToBed = 'alert/resolution/fall/returned-to-bed/returned-to-bed',
// }
// export enum FallAlertResolutionAboutToLeaveBedDetail {
//   helpedLayInBed = 'alert/resolution/fall/about-to-leave-bed/helped-lay-in-bed',
//   helpedLeaveToilet = 'alert/resolution/fall/about-to-leave-bed/helped-leave-toilet',
//   calledOut = 'alert/resolution/fall/about-to-leave-bed/called-out',
// }
// export enum FallAlertResolutionNotLeavingBedDetail {
//   justTurnedOver = 'alert/resolution/fall/not-leaving-bed/just-turned-over',
//   padWasBeingReplaced = 'alert/resolution/fall/not-leaving-bed/pad-was-being-replaced',
//   helpedChangeClothes = 'alert/resolution/fall/not-leaving-bed/helped-change-clothes',
// }
var FallAlertResolutionCareProvidedDetail;
(function (FallAlertResolutionCareProvidedDetail) {
    FallAlertResolutionCareProvidedDetail["helpedLayBackInBed"] = "alert/resolution/fall/care-provided/helped-lay-back-in-bed";
    FallAlertResolutionCareProvidedDetail["helpedLeaveBed"] = "alert/resolution/fall/care-provided/helped-leave-bed";
    FallAlertResolutionCareProvidedDetail["helpedToilet"] = "alert/resolution/fall/care-provided/helped-toilet";
    FallAlertResolutionCareProvidedDetail["calledOut"] = "alert/resolution/fall/care-provided/called-out";
})(FallAlertResolutionCareProvidedDetail || (exports.FallAlertResolutionCareProvidedDetail = FallAlertResolutionCareProvidedDetail = {}));
var FallAlertResolutionOtherDetail;
(function (FallAlertResolutionOtherDetail) {
    FallAlertResolutionOtherDetail["noAction"] = "alert/resolution/fall/other/no-action";
    FallAlertResolutionOtherDetail["other"] = "alert/resolution/fall/other/other";
})(FallAlertResolutionOtherDetail || (exports.FallAlertResolutionOtherDetail = FallAlertResolutionOtherDetail = {}));
var PressureAlertResolution;
(function (PressureAlertResolution) {
    PressureAlertResolution["changedPosture"] = "alert/resolution/pressure/changed-posture";
    PressureAlertResolution["didNotChangePosture"] = "alert/resolution/pressure/did-not-change-posture";
    PressureAlertResolution["noActionRequired"] = "alert/resolution/no-action-required";
    PressureAlertResolution["other"] = "alert/resolution/pressure/other";
})(PressureAlertResolution || (exports.PressureAlertResolution = PressureAlertResolution = {}));
var PressureAlertResolutionChangedPostureDetail;
(function (PressureAlertResolutionChangedPostureDetail) {
    PressureAlertResolutionChangedPostureDetail["alreadyTurned"] = "alert/resolution/pressure/changed-posture/patient-already-turned";
    PressureAlertResolutionChangedPostureDetail["changedPosture"] = "alert/resolution/pressure/changed-posture/changed-posture";
    PressureAlertResolutionChangedPostureDetail["noActionRequired"] = "alert/resolution/no-action-required";
    PressureAlertResolutionChangedPostureDetail["other"] = "alert/resolution/pressure/changed-posture/other";
})(PressureAlertResolutionChangedPostureDetail || (exports.PressureAlertResolutionChangedPostureDetail = PressureAlertResolutionChangedPostureDetail = {}));
var HRAlertResolution;
(function (HRAlertResolution) {
    HRAlertResolution["confirmedVisually"] = "alert/resolution/hr/visually-confirmed";
    HRAlertResolution["confirmedByVitalMeter"] = "alert/resolution/hr/confirmed-by-vital-meter";
    HRAlertResolution["couldNotConfirm"] = "alert/resolution/hr/could-not-confirm";
    HRAlertResolution["noActionRequired"] = "alert/resolution/no-action-required";
    HRAlertResolution["other"] = "alert/resolution/hr/other";
})(HRAlertResolution || (exports.HRAlertResolution = HRAlertResolution = {}));
var HRAlertVisualResolutionDetail;
(function (HRAlertVisualResolutionDetail) {
    HRAlertVisualResolutionDetail["verifiedByVitalMeter"] = "alert/resolution/hr/visually-confirmed/verified-by-vital-meter";
    HRAlertVisualResolutionDetail["reportedAndGaveCare"] = "alert/resolution/hr/visually-confirmed/reported-and-gave-care";
    HRAlertVisualResolutionDetail["takenToHospital"] = "alert/resolution/hr/visually-confirmed/taken-to-hospital";
    HRAlertVisualResolutionDetail["informedFamily"] = "alert/resolution/hr/visually-confirmed/informed-family";
    HRAlertVisualResolutionDetail["reported"] = "alert/resolution/hr/visually-confirmed/reported";
})(HRAlertVisualResolutionDetail || (exports.HRAlertVisualResolutionDetail = HRAlertVisualResolutionDetail = {}));
var HRAlertVitalMeterResolutionDetail;
(function (HRAlertVitalMeterResolutionDetail) {
    HRAlertVitalMeterResolutionDetail["verifiedByVitalMeter"] = "alert/resolution/hr/confirmed-by-vital-meter/verified-by-vital-meter";
    HRAlertVitalMeterResolutionDetail["reportedAndGaveCare"] = "alert/resolution/hr/confirmed-by-vital-meter/reported-and-gave-care";
    HRAlertVitalMeterResolutionDetail["takenToHospital"] = "alert/resolution/hr/confirmed-by-vital-meter/taken-to-hospital";
    HRAlertVitalMeterResolutionDetail["informedFamily"] = "alert/resolution/hr/confirmed-by-vital-meter/informed-family";
    HRAlertVitalMeterResolutionDetail["reported"] = "alert/resolution/hr/confirmed-by-vital-meter/reported";
})(HRAlertVitalMeterResolutionDetail || (exports.HRAlertVitalMeterResolutionDetail = HRAlertVitalMeterResolutionDetail = {}));
var RRAlertResolution;
(function (RRAlertResolution) {
    RRAlertResolution["confirmedVisually"] = "alert/resolution/rr/visually-confirmed";
    RRAlertResolution["confirmedByVitalMeter"] = "alert/resolution/rr/confirmed-by-vital-meter";
    RRAlertResolution["couldNotConfirm"] = "alert/resolution/rr/could-not-confirm";
    RRAlertResolution["noActionRequired"] = "alert/resolution/no-action-required";
    RRAlertResolution["other"] = "alert/resolution/rr/other";
})(RRAlertResolution || (exports.RRAlertResolution = RRAlertResolution = {}));
var RRAlertVisualResolutionDetail;
(function (RRAlertVisualResolutionDetail) {
    RRAlertVisualResolutionDetail["verifiedByVitalMeter"] = "alert/resolution/rr/visually-confirmed/verified-by-vital-meter";
    RRAlertVisualResolutionDetail["reportedAndGaveCare"] = "alert/resolution/rr/visually-confirmed/reported-and-gave-care";
    RRAlertVisualResolutionDetail["takenToHospital"] = "alert/resolution/rr/visually-confirmed/taken-to-hospital";
    RRAlertVisualResolutionDetail["informedFamily"] = "alert/resolution/rr/visually-confirmed/informed-family";
    RRAlertVisualResolutionDetail["reported"] = "alert/resolution/rr/visually-confirmed/reported";
})(RRAlertVisualResolutionDetail || (exports.RRAlertVisualResolutionDetail = RRAlertVisualResolutionDetail = {}));
var RRAlertVitalMeterResolutionDetail;
(function (RRAlertVitalMeterResolutionDetail) {
    RRAlertVitalMeterResolutionDetail["verifiedByVitalMeter"] = "alert/resolution/rr/confirmed-by-vital-meter/verified-by-vital-meter";
    RRAlertVitalMeterResolutionDetail["reportedAndGaveCare"] = "alert/resolution/rr/confirmed-by-vital-meter/reported-and-gave-care";
    RRAlertVitalMeterResolutionDetail["takenToHospital"] = "alert/resolution/rr/confirmed-by-vital-meter/taken-to-hospital";
    RRAlertVitalMeterResolutionDetail["informedFamily"] = "alert/resolution/rr/confirmed-by-vital-meter/informed-family";
    RRAlertVitalMeterResolutionDetail["reported"] = "alert/resolution/rr/confirmed-by-vital-meter/reported";
})(RRAlertVitalMeterResolutionDetail || (exports.RRAlertVitalMeterResolutionDetail = RRAlertVitalMeterResolutionDetail = {}));
