import { createSvgIcon } from '@mui/material';

const SVR = createSvgIcon(
  <g>
    <path
      d="M17,7H7c-2.8,0-5,2.2-5,5s2.2,5,5,5h10c2.8,0,5-2.2,5-5S19.8,7,17,7z M7,15c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3
		S8.7,15,7,15z"
    />
    <polygon points="16,3 13,3 13,1 11,1 11,3 8,3 12,7 	" />
    <polygon points="8,21 11,21 11,23 13,23 13,21 16,21 12,17 	" />{' '}
  </g>,
  'SVR'
);

export { SVR };
export default SVR;
