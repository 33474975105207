import { Reducer } from 'redux';
import { LocaleState, LocaleActionTypes } from './types';
import { browserLanguage } from '../../languages';

// Type-safe initialState!
const initialState: LocaleState = {
  initialized: false,
  language: browserLanguage(),
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<LocaleState> = (state: LocaleState = initialState, action) => {
  switch (action.type) {
    case LocaleActionTypes.SET_LANGUAGE: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { ...state, language: action.payload, initialized: true };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as localeReducer };
