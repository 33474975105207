import { action } from 'typesafe-actions';

import * as data from '../../data';
import { AisleepActionTypes } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

// vitals
export const enableAisleepVitalsPolling = (sensors: data.vitals.AisleepSensor[]) =>
  action(AisleepActionTypes.ENABLE_AISLEEP_VITALS_POLLING, sensors);
export const disableAisleepVitalsPolling = () =>
  action(AisleepActionTypes.DISABLE_AISLEEP_VITALS_POLLING);
export const clearAisleepVitals = () => action(AisleepActionTypes.CLEAR_AISLEEP_VITALS);

// by slicing the data redux recognizes the array as a new set of data
export const setAisleepVitals = (vitals: data.vitals.AisleepVitals[]) =>
  action(AisleepActionTypes.SET_AISLEEP_VITALS, vitals.slice());
