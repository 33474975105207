import * as data from '../../data';

export enum AisleepActionTypes {
  SET_AISLEEP_VITALS = '@@context/SET_AISLEEP_VITALS',
  CLEAR_AISLEEP_VITALS = '@@context/CLEAR_AISLEEP_VITALS',
  ENABLE_AISLEEP_VITALS_POLLING = '@@context/ENABLE_AISLEEP_VITALS_POLLING',
  DISABLE_AISLEEP_VITALS_POLLING = '@@context/DISABLE_AISLEEP_VITALS_POLLING',
}

export interface AisleepState {
  readonly token: string | undefined;
  readonly vitalsPollingIsEnabled: boolean;
  readonly vitals: data.vitals.AisleepVitals[];
  readonly vitalsPollingSensors: data.vitals.AisleepSensor[];
}
