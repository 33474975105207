"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep Client API
 * This document is a specification for the API that must be implemented by AiSleep client applications (i.e. AttentiveConnect) so that AiSleep servers can push data to clients. This is a specifications for the callbacks.  This specification is used to generate the code that is used by AC to integrate AiSleep data.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes). The below status codes are used in this interface:    - 200 OK - Standard response for successful HTTP requests.   - 201 Created - The request has been fulfilled, resulting in the creation of a new resource   - 202 Accepted - The request has been accepted for processing, but the processing has not been completed.   - 400 Bad Request - The server cannot or will not process the request due to an apparent client         error (e.g., malformed request syntax, size too large, invalid request message framing,         or deceptive request routing)   - 404 Not Found - The requested resource could not be found   - 500 Internal Server Error
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DailyLogStatusToJSON = exports.DailyLogStatusFromJSONTyped = exports.DailyLogStatusFromJSON = exports.DailyLogStatus = void 0;
/**
 * A patient's status as reported in the daily log.
 *
 * * 1 - Out of bed (bed exit) (`TODO` - need to confirm this, docs have conflicting info)
 * * 2 - Moving
 * * 3 - Sitting
 * * 4 - Sleeping
 * * 5 - Awake
 * * 6 - Unknown
 * * 11 - Deep sleep
 * * 12 - Light sleep
 * * 13 - REM
 * * 14 - Awake
 * * 15 - Leaving (`TODO` - what does 'leaving' mean?)
 * * 16 - Undetectable
 * @export
 * @enum {string}
 */
var DailyLogStatus;
(function (DailyLogStatus) {
    DailyLogStatus[DailyLogStatus["NUMBER_1"] = 1] = "NUMBER_1";
    DailyLogStatus[DailyLogStatus["NUMBER_2"] = 2] = "NUMBER_2";
    DailyLogStatus[DailyLogStatus["NUMBER_3"] = 3] = "NUMBER_3";
    DailyLogStatus[DailyLogStatus["NUMBER_4"] = 4] = "NUMBER_4";
    DailyLogStatus[DailyLogStatus["NUMBER_5"] = 5] = "NUMBER_5";
    DailyLogStatus[DailyLogStatus["NUMBER_6"] = 6] = "NUMBER_6";
    DailyLogStatus[DailyLogStatus["NUMBER_11"] = 11] = "NUMBER_11";
    DailyLogStatus[DailyLogStatus["NUMBER_12"] = 12] = "NUMBER_12";
    DailyLogStatus[DailyLogStatus["NUMBER_13"] = 13] = "NUMBER_13";
    DailyLogStatus[DailyLogStatus["NUMBER_14"] = 14] = "NUMBER_14";
    DailyLogStatus[DailyLogStatus["NUMBER_15"] = 15] = "NUMBER_15";
    DailyLogStatus[DailyLogStatus["NUMBER_16"] = 16] = "NUMBER_16";
})(DailyLogStatus || (exports.DailyLogStatus = DailyLogStatus = {}));
function DailyLogStatusFromJSON(json) {
    return DailyLogStatusFromJSONTyped(json, false);
}
exports.DailyLogStatusFromJSON = DailyLogStatusFromJSON;
function DailyLogStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.DailyLogStatusFromJSONTyped = DailyLogStatusFromJSONTyped;
function DailyLogStatusToJSON(value) {
    return value;
}
exports.DailyLogStatusToJSON = DailyLogStatusToJSON;
