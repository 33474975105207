import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import strings from '../languages';
import { ApplicationState } from '../redux';
import { Language, setLanguage } from '../redux/locale';
import * as Icons from '../icons';
import Dialog from './dialogs/Dialog';
import { CancelButton } from './buttons';

interface LanguageSelectorProps {
  open: boolean;
  onClose: () => void;
  className?: string;

  // props from dispatch
  setLanguage: typeof setLanguage;

  // props from state
  language: Language;
}

type Props = WrappedComponentProps & LanguageSelectorProps;

class LanguageDialog extends React.Component<Props> {
  getLanguageName = (language: Language) => {
    const { intl } = this.props;
    const localized = strings(intl);

    switch (language) {
      case 'zh-HANT':
        return localized.language.zh_hant();
      case 'zh-HANS':
        return localized.language.zh_hans();
      case 'en-US':
        return localized.language.english();
      case 'he':
        return localized.language.hebrew();
      case 'pt':
        return localized.language.portuguese();
      case 'ja-JP':
      default:
        return localized.language.japanese();
    }
  };

  languages = (): Language[] => {
    return ['ja-JP', 'zh-HANT', 'zh-HANS', 'en-US', 'pt'];
  };

  languageSelection = (): Language[] => {
    const { language } = this.props;
    const selection: Language[] = [];
    this.languages()
      .sort()
      .forEach((l) => {
        if (language !== l) {
          selection.push(l);
        }
      });
    return selection;
  };

  setLanguage = (lang: Language) => {
    this.props.setLanguage(lang);
    this.props.onClose();
  };

  render = () => {
    const { open, onClose } = this.props;
    const l10n = strings(this.props.intl);

    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle id="simple-dialog-title">
          <Icons.Language />
          <span>{l10n.language.language()}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <List>
            {this.languageSelection()
              .sort((a, b) => a.localeCompare(b))
              .map((lang) => (
                <ListItem button onClick={() => this.setLanguage(lang)} key={lang}>
                  <ListItemIcon>
                    <Icons.Locale fontSize="large" color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary={this.getLanguageName(lang)} />
                </ListItem>
              ))}
          </List>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose} />
        </DialogActions>
      </Dialog>
    );
  };
}

const mapStateToProps = ({ locale }: ApplicationState) => ({
  language: locale.language,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLanguage: (language: Language) => dispatch(setLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LanguageDialog));
