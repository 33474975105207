"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleCodeToJSON = exports.RoleCodeFromJSONTyped = exports.RoleCodeFromJSON = exports.RoleCode = void 0;
/**
 * Role types
 * @export
 * @enum {string}
 */
var RoleCode;
(function (RoleCode) {
    RoleCode["RoleAdmin"] = "ROLE_ADMIN";
    RoleCode["SubAdmin"] = "sub_admin";
    RoleCode["MonitorUser"] = "MonitorUser";
    RoleCode["SuperUser"] = "SuperUser";
    RoleCode["Cards"] = "cards";
})(RoleCode || (exports.RoleCode = RoleCode = {}));
function RoleCodeFromJSON(json) {
    return RoleCodeFromJSONTyped(json, false);
}
exports.RoleCodeFromJSON = RoleCodeFromJSON;
function RoleCodeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.RoleCodeFromJSONTyped = RoleCodeFromJSONTyped;
function RoleCodeToJSON(value) {
    return value;
}
exports.RoleCodeToJSON = RoleCodeToJSON;
