import { Theme } from '@mui/material/styles';
import {
  isAisleepBedExitAlarm,
  isAisleepBreathingHighAlarm,
  isAisleepBreathingLowAlarm,
  isAisleepBreathingShallowAlarm,
  isAisleepHeartRateHighAlarm,
  isAisleepHeartRateLowAlarm,
  isAisleepMovementAlarm,
  isAisleepSittingAlarm,
} from 'attentive-connect-store/dist/mappers';
import * as models from 'attentive-connect-store/dist/models';
import * as alertResolution from 'attentive-connect-store/dist/models/AlertResolveType';
import AlertStatusType from 'attentive-connect-store/dist/models/AlertStatusType';
import AlertType from 'attentive-connect-store/dist/models/AlertType';
import RiskLevelType from 'attentive-connect-store/dist/models/RiskLevelType';
import { Alarm } from 'attentive-connect-store/dist/types/aisleepClient';
import { ClinicalAlert } from 'noomi-client-api/dist/models/ClinicalAlert';
import { ClinicalAlertType } from 'noomi-client-api/dist/models/ClinicalAlertType';
import {
  UserConfigGet,
  UserConfigGetBexFeatureEnum,
} from 'noomi-client-api/dist/models/UserConfigGet';
import { StringsIntl } from '../languages';
import { getLogger } from '../logger';
import session from '../session';
import * as ResidentData from './Residents';

const logger = getLogger('ui/data/Alerts');

export interface AlertViewData {
  alertWithSensorDetail: models.MaybeStoredAlertWithSensorDetail;
  sensors: models.Sensor[];
  resident: models.Resident;
  alertResolvedUserProfile?: models.UserProfile;
  alertResolvedUserPerson?: models.UserPerson;
  // statusColor: () => AlertIconColor;
  // riskColor: () => string;
  status: () => AlertStatusType;
  isOneClickResolving: () => boolean;
  isOneClickHidden: () => boolean;
  isFallPrevention: () => boolean;
  isOpen: () => boolean;
  isResolutionInProgress: () => boolean;
  isResolved: () => boolean;
  isExpired: () => boolean;
  isReturnedExpired: () => boolean;
  isReplaced: () => boolean;
  isCancelled: () => boolean;
  isSnoozed: () => boolean;
  isDisabled: () => boolean;
  getDate: () => Date;
  getDateResolved: () => Date | undefined;
  getDuration: () => number;
  getDescription: (lang: StringsIntl) => string;
}

export const alertStatus = (status: AlertStatusType) => ({
  isOpen: () =>
    status === AlertStatusType.OPEN ||
    status === AlertStatusType.OPEN_DISABLED ||
    status === AlertStatusType.OPEN_SNOOZED ||
    status === AlertStatusType.RESOLUTION_IN_PROGRESS,
  isResolutionInProgress: () => status === AlertStatusType.RESOLUTION_IN_PROGRESS,
  isResolved: () => status === AlertStatusType.RESOLVED,
  isExpired: () => status === AlertStatusType.EXPIRED,
  isReturnedExpired: () => status === AlertStatusType.RETURNED_EXPIRED,
  isReplaced: () => status === AlertStatusType.REPLACED,
  isCancelled: () => status === AlertStatusType.CANCELED,
  isSnoozed: () => status === AlertStatusType.OPEN_SNOOZED,
  isDisabled: () => status === AlertStatusType.OPEN_DISABLED,
});

export const alertType = (alertType: AlertType) => ({
  isFall: () => alertType === AlertType.FALL,
  isSitting: () => alertType === AlertType.SITTING,
  isFallPrevention: () => alertType === AlertType.FALL || alertType === AlertType.SITTING,
});

export const toAlertViewData = (
  theme: Theme,
  alert: models.MaybeStoredAlertWithSensorDetail,
  sensors: models.Sensor[],
  residents: models.Resident[],
  userProfiles: models.UserProfile[],
  userPersons: models.UserPerson[],
  sensorAlert?: models.MaybeStoredSensorAlert
) => {
  // const riskColor = () => getRiskColor(theme, alert.alert.data.riskLevel);
  // const statusColor = () => getStatusColor(theme, alert.alert.data.alertStatusType);
  const status = () => alert.alert.data.alertStatusType;
  const isOneClickResolving = (): boolean =>
    alert.alert.id ? session.alerts.oneClickResolvingTimeMilliSec(alert.alert.id) >= 0 : false;
  const isOneClickHidden = (): boolean =>
    alert.alert.id ? session.alerts.oneClickResolvingTimeMilliSec(alert.alert.id) >= 2000 : false;
  const isFallPrevention = () => alertType(alert.alert.data.alertType).isFallPrevention();
  const isOpen = () => alertStatus(alert.alert.data.alertStatusType).isOpen();
  const isResolutionInProgress = () =>
    alertStatus(alert.alert.data.alertStatusType).isResolutionInProgress();
  const isResolved = () => alertStatus(alert.alert.data.alertStatusType).isResolved();
  const isExpired = () => alertStatus(alert.alert.data.alertStatusType).isExpired();
  const isReturnedExpired = () => alertStatus(alert.alert.data.alertStatusType).isReturnedExpired();
  const isReplaced = () => alertStatus(alert.alert.data.alertStatusType).isReplaced();
  const isCancelled = () => alertStatus(alert.alert.data.alertStatusType).isCancelled();
  const isSnoozed = () => alertStatus(alert.alert.data.alertStatusType).isSnoozed();
  const isDisabled = () => alertStatus(alert.alert.data.alertStatusType).isDisabled();
  const getDate = () => alert.alert.data.alertTime.toDate();
  const getDateResolved = () => {
    let d;
    // if (isResolved() || isResolutionInProgress() || isExpired() || isReplaced() || isReturnedExpired()) {
    if (alert.alert.data.resolvedDate) {
      d = alert.alert.data.resolvedDate.toDate();
    }
    // }
    return d;
  };
  const getDuration = () => {
    let endDate = getDateResolved();
    if (!endDate || isResolutionInProgress()) {
      endDate = new Date();
    }
    return endDate.getTime() - getDate().getTime();
  };

  const getDescription = (lang: StringsIntl) => {
    let desc = '';
    if (sensorAlert?.data.aisleepAlert) {
      desc = getAisleepAlertDescription(sensorAlert.data.aisleepAlert, lang);
    } else {
      desc = getAlertLabel2(alert.alert.data.alertType, lang);
    }
    logger.debug('getDescription()', {
      alertType: alert.alert.data.alertType,
      sensorAlert: sensorAlert || null,
      desc,
    });
    return desc;
  };

  const resident = residents.find((r) => r.snapshot.ref.path === alert.alert.data.residentRef);

  if (!resident) {
    return undefined;
  }

  const data: AlertViewData = {
    alertWithSensorDetail: alert,
    resident,
    sensors: sensors.filter((s) => s.data.residentRef === resident.snapshot.ref.path),
    // riskColor,
    // statusColor,
    status,
    isOneClickResolving,
    isOneClickHidden,
    isFallPrevention,
    isOpen,
    isResolutionInProgress,
    isResolved,
    isExpired,
    isReturnedExpired,
    isReplaced,
    isCancelled,
    isSnoozed,
    isDisabled,
    getDate,
    getDateResolved,
    getDuration,
    getDescription,
    alertResolvedUserProfile: userProfiles.find(
      (u) => u.data.uid === alert.alert.data.resolvedUserUid
    ),
    alertResolvedUserPerson: userPersons.find(
      (u) => u.snapshot.ref.path === alert.alert.data.resolvedUserPersonRef
    ),
  };

  return data;
};

export const getNoomiClinicalAlertDescription = (
  clinicalAlert: ClinicalAlert,
  noomiConfig: UserConfigGet | null,
  lang: StringsIntl
) => {
  let desc = '';
  const val = clinicalAlert.value !== undefined ? clinicalAlert.value : '';
  switch (clinicalAlert.alertType) {
    case ClinicalAlertType.HeartRateAbove:
      desc = lang.alerts.noomi.hrAbove(val);
      break;
    case ClinicalAlertType.HeartRateBelow:
      desc = lang.alerts.noomi.hrBelow(val);
      break;
    case ClinicalAlertType.BedExit:
      if (
        noomiConfig &&
        noomiConfig.bexFeature &&
        noomiConfig.bexFeature === UserConfigGetBexFeatureEnum.MaxOutOfBedTime
      ) {
        desc = lang.alerts.noomi.longBedExit(
          noomiConfig.maxOutOfBedtime ? noomiConfig.maxOutOfBedtime : 0
        );
      } else {
        desc = lang.alerts.noomi.bedExit();
      }
      break;
    case ClinicalAlertType.LowMotionAtNight:
      desc = lang.alerts.noomi.lowMotionAtNight();
      break;
    case ClinicalAlertType.MultiParameter:
      desc = lang.alerts.noomi.multiParameter();
      break;
    case ClinicalAlertType.NoMotion:
      desc = lang.alerts.noomi.noMotion();
      break;
    case ClinicalAlertType.ReassessBraden:
      desc = lang.alerts.noomi.reassessBraden();
      break;
    case ClinicalAlertType.RespiratoryDepression:
      desc = lang.alerts.noomi.respiratoryDepression();
      break;
    case ClinicalAlertType.RespiratoryRateAbove:
      desc = lang.alerts.noomi.rrAbove(val);
      break;
    case ClinicalAlertType.RespiratoryRateBelow:
      desc = lang.alerts.noomi.rrBelow(val);
      break;
    case ClinicalAlertType.TrendIndication:
      desc = lang.alerts.noomi.trendIndication();
      break;
    case ClinicalAlertType.TurnNotVerified:
      desc = lang.alerts.noomi.turnNotVerified();
      break;
    case ClinicalAlertType.TurnTimerExpired:
      desc = lang.alerts.noomi.turnTimerExpired();
      break;
    case ClinicalAlertType.UnstableSignalLevel:
      desc = lang.alerts.noomi.unstableSignalLevel();
      break;
    default:
      desc = lang.alerts.noomi.unsupported(clinicalAlert.alertType);
      break;
  }

  return desc;
};

export const getAisleepAlertDescription = (alarm: Alarm, lang: StringsIntl) => {
  const type = alarm.alarm.type;
  let desc = '';

  if (isAisleepBedExitAlarm(type)) {
    desc = lang.alerts.aisleep.bedExit();
  } else if (isAisleepSittingAlarm(type)) {
    desc = lang.alerts.aisleep.sitting();
  } else if (isAisleepHeartRateHighAlarm(type)) {
    desc = lang.alerts.aisleep.hrAbove(0);
  } else if (isAisleepHeartRateLowAlarm(type)) {
    desc = lang.alerts.aisleep.hrBelow(0);
  } else if (isAisleepBreathingHighAlarm(type)) {
    desc = lang.alerts.aisleep.rrAbove(0);
  } else if (isAisleepBreathingLowAlarm(type)) {
    desc = lang.alerts.aisleep.rrBelow(0);
  } else if (isAisleepBreathingShallowAlarm(type)) {
    desc = lang.alerts.aisleep.respiratoryDepression();
  } else if (isAisleepMovementAlarm(type)) {
    desc = lang.alerts.aisleep.moving();
  } else {
    desc = lang.alerts.aisleep.unsupported(type);
  }

  return desc;
};

export const toAlertViewDataList = (
  theme: Theme,
  alerts: models.MaybeStoredAlertWithSensorDetail[],
  sensors: models.Sensor[],
  residents: models.Resident[],
  userProfiles: models.UserProfile[],
  userPersons: models.UserPerson[]
) => {
  const alertData: AlertViewData[] = [];
  alerts.forEach((alert) => {
    // filter the sensor linked to the alert
    const s = sensors.filter((s) => s.data.sensorType === alert.alert.data.sensorType);
    const data = toAlertViewData(theme, alert, s, residents, userProfiles, userPersons);
    if (data) {
      alertData.push(data);
    }
  });
  return alertData;
};

export const toAlertViewDataListWithSensorDetail = (
  theme: Theme,
  alerts: Array<models.MaybeStoredAlertWithSensorDetail>,
  sensors: models.Sensor[],
  residents: models.Resident[],
  users: models.UserProfile[],
  userPersons: models.UserPerson[]
) => {
  const alertViewData: AlertViewData[] = [];
  alerts.forEach((alert) => {
    const data = toAlertViewData(
      theme,
      alert,
      sensors,
      residents,
      users,
      userPersons,
      alert.sensorAlert
    );
    if (data) {
      alertViewData.push(data);
    }
  });
  return alertViewData;
};

export interface AlertResolutionAction {
  resolution: alertResolution.AlertResolved;
  display: {
    resolution: string;
    detail: string | null;
  };
}

export const makeAlertResolutionAction = (
  resolved: alertResolution.AlertResolved,
  l10n: StringsIntl
): AlertResolutionAction => {
  return {
    resolution: resolved,
    display: {
      resolution: l10n.translate(resolved.resolution),
      detail: resolved.detail ? l10n.translate(resolved.detail) : null,
    },
  };
};

export const alertCompare = (a: AlertViewData, b: AlertViewData, localized: StringsIntl) => {
  let cmp = riskLevelCompare(a, b);
  if (cmp === 0) {
    cmp = durationCompare(a, b);
  }
  if (cmp === 0) {
    cmp = riskTypeCompare(a, b);
  }
  if (cmp === 0) {
    cmp = residentCompare(a, b, localized);
  }
  return cmp;
};

export const riskTypeCompare = (a: AlertViewData, b: AlertViewData) => {
  const values = [AlertType.HEART, AlertType.BREATHING, AlertType.FALL, AlertType.TURNOVER];
  let aVal: number | undefined;
  let bVal: number | undefined;
  values.forEach((v, idx) => {
    if (a.alertWithSensorDetail.alert.data.alertType === v) {
      aVal = idx;
    }
    if (b.alertWithSensorDetail.alert.data.alertType === v) {
      bVal = idx;
    }
  });
  if (aVal === undefined) {
    return 1;
  }
  if (bVal === undefined) {
    return -1;
  }
  if (aVal < bVal) {
    return 1;
  } else if (aVal > bVal) {
    return -1;
  } else {
    return 0;
  }
};

export const durationCompare = (a: AlertViewData, b: AlertViewData) => {
  const aVal = a.alertWithSensorDetail.alert.data.alertTime.seconds;
  const bVal = b.alertWithSensorDetail.alert.data.alertTime.seconds;
  if (aVal < bVal) {
    return -1;
  } else if (aVal > bVal) {
    return 1;
  } else {
    return 0;
  }
};

export const riskLevelCompare = (a: AlertViewData, b: AlertViewData) => {
  const values = [
    RiskLevelType.VERY_HIGH,
    RiskLevelType.HIGH,
    RiskLevelType.CUSTOM,
    RiskLevelType.MEDIUM,
    RiskLevelType.LOW,
    RiskLevelType.VERY_LOW,
    RiskLevelType.NONE,
  ];
  let aVal: number | undefined;
  let bVal: number | undefined;
  values.forEach((v, idx) => {
    if (a.alertWithSensorDetail.alert.data.riskLevel === v) {
      aVal = idx;
    }
    if (b.alertWithSensorDetail.alert.data.riskLevel === v) {
      bVal = idx;
    }
  });
  if (aVal === undefined) {
    return 1;
  }
  if (bVal === undefined) {
    return -1;
  }
  if (aVal < bVal) {
    return 1;
  } else if (aVal > bVal) {
    return -1;
  } else {
    return 0;
  }
};

export const residentCompare = (a: AlertViewData, b: AlertViewData, localized: StringsIntl) => {
  if (a.resident === undefined) {
    return 1;
  }
  if (b.resident === undefined) {
    return -1;
  }
  return ResidentData.residentCompare(a.resident, b.resident, localized);
};

export const getAlertLabel = (alertType: AlertType, localized: StringsIntl) => {
  // const sensorAlert = alert && alert.sensorAlert ? alert.sensorAlert : null;
  const customAlertType = '';
  return getAlertLabel2Types(alertType, localized, customAlertType);
};

export const getAlertLabel2Types = (
  alertType: AlertType,
  localized: StringsIntl,
  customAlertType: string
) => {
  let label = '';
  switch (alertType) {
    case AlertType.FALL:
      if (customAlertType) {
        switch (customAlertType) {
          case 'FallDetected':
            label = localized.alertTypes.fallDetected();
            break;
          case 'Fall':
          default:
            label = localized.alertTypes.fall();
        }
      } else {
        label = localized.alertTypes.fall();
      }
      break;
    case AlertType.SITTING:
      label = localized.alertTypes.sitting();
      break;
    case AlertType.HEART:
      label = localized.alertTypes.heart();
      break;
    case AlertType.BREATHING:
      label = localized.alertTypes.breathing();
      break;
    case AlertType.TURNOVER:
      label = localized.alertTypes.turnover();
      break;
    case AlertType.MOVEMENT:
      label = localized.alertTypes.movement();
      break;
    case AlertType.ANY:
      label = localized.alertTypes.health();
      break;
    default:
      label = localized.alertTypes.health();
  }
  return label;
};

export const sortAlertTypes = (a: AlertType, b: AlertType, l10n: StringsIntl) => {
  if (a === b) {
    return 0;
  }

  if (a === AlertType.FALL) {
    return -1;
  }
  if (b === AlertType.FALL) {
    return 1;
  }

  if (a === AlertType.MOVEMENT) {
    return -1;
  }
  if (b === AlertType.MOVEMENT) {
    return 1;
  }

  if (a === AlertType.HEART) {
    return -1;
  }
  if (b === AlertType.HEART) {
    return 1;
  }

  if (a === AlertType.BREATHING) {
    return -1;
  }
  if (b === AlertType.BREATHING) {
    return 1;
  }

  if (a === AlertType.TURNOVER) {
    return -1;
  }
  if (b === AlertType.TURNOVER) {
    return 1;
  }

  if (a === AlertType.ANY) {
    return -1;
  }
  if (b === AlertType.ANY) {
    return 1;
  }

  return getAlertLabel(a, l10n).localeCompare(getAlertLabel(b, l10n));
};

/**
 * Returns the lablel to be displayed for an alert type.
 *
 * @param alertType
 * @param localized
 */
export const getAlertTypeLabel = (alertType: AlertType, localized: StringsIntl) => {
  let label = '';
  switch (alertType) {
    case AlertType.FALL:
      label = localized.alertSettings.fall();
      break;
    case AlertType.HEART:
      label = localized.alertSettings.heart();
      break;
    case AlertType.BREATHING:
      label = localized.alertSettings.breathing();
      break;
    case AlertType.TURNOVER:
      label = localized.alertSettings.turnover();
      break;
    case AlertType.ANY:
      label = localized.alertSettings.health();
      break;
    default:
      label = localized.alertSettings.health();
  }
  return label;
};

export const getAlertDate = (alert: models.AlertData) => {
  const time = alert.alertTime.toDate();
  return time;
};

export const getAlertResolvedDate = (alert: models.AlertData) => {
  // TODO - need to discuss with Vitalerter
  let time;
  if (
    AlertStatusType.RESOLVED === alert.alertStatusType ||
    AlertStatusType.RESOLUTION_IN_PROGRESS ||
    AlertStatusType.EXPIRED === alert.alertStatusType ||
    AlertStatusType.RETURNED_EXPIRED === alert.alertStatusType ||
    AlertStatusType.REPLACED === alert.alertStatusType
  ) {
    if (alert.resolvedDate) {
      time = alert.resolvedDate.toDate();
    }
  }
  return time;
};

export const getAlertLabel2 = (alert: models.AlertType, localized: StringsIntl) => {
  return getAlertLabel(alert, localized);
};

export const getRiskLabel = (
  riskLevel: RiskLevelType,
  alertType: AlertType,
  localized: StringsIntl
) => {
  let risk = '';
  if (riskLevel === RiskLevelType.HIGH) {
    risk = localized.riskLevels.high();
  } else if (riskLevel === RiskLevelType.MEDIUM) {
    risk = localized.riskLevels.medium();
  } else if (riskLevel === RiskLevelType.LOW) {
    risk = localized.riskLevels.low();
  } else if (riskLevel === RiskLevelType.NONE) {
    risk = localized.riskLevels.off();
  } else if (riskLevel === RiskLevelType.VERY_HIGH) {
    risk = localized.riskLevels.veryHigh();
  } else if (riskLevel === RiskLevelType.VERY_LOW) {
    risk = localized.riskLevels.veryLow();
  } else {
    risk = '';
  }

  if (alertType === AlertType.TURNOVER) {
    // if (riskLevel === RiskLevelType.LOW) {
    //   risk = localized.riskLevels.turnoverLow();
    // } else if (riskLevel === RiskLevelType.MEDIUM) {
    //   risk = localized.riskLevels.turnoverMedium();
    // } else
    if (riskLevel === RiskLevelType.CUSTOM) {
      risk = localized.riskLevels.turnoverCustom();
    }
  } else if (alertType === AlertType.FALL) {
    if (riskLevel === RiskLevelType.VERY_HIGH) {
      risk = localized.riskLevels.fallVeryHigh();
    } else if (riskLevel === RiskLevelType.VERY_LOW) {
      risk = localized.riskLevels.fallVeryLow();
    } else if (riskLevel === RiskLevelType.CUSTOM) {
      risk = localized.riskLevels.fallCustom();
    }
  } else if (alertType === AlertType.BREATHING) {
    if (riskLevel === RiskLevelType.CUSTOM) {
      risk = localized.riskLevels.breathingCustom();
    }
  } else if (alertType === AlertType.HEART) {
    if (riskLevel === RiskLevelType.CUSTOM) {
      risk = localized.riskLevels.heartCustom();
    }
  }

  return risk.toLocaleUpperCase();
};

// export const getStatusColor = (theme: Theme, status: AlertStatusType): AlertIconColor => {
//   // const colors = alertColors(theme);
//   let color: AlertIconColor = 'inherit';
//   const s = alertStatus(status);

//   if (s.isOpen() && !s.isResolutionInProgress()) {
//     color = 'error';
//   } else if (s.isResolutionInProgress()) {
//     color = 'warning';
//   }

//   return color;
// };

export const formatDate = (date: Date, localized: StringsIntl) => {
  const today = new Date();
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    return localized.alerts.today();
  } else {
    return localized.time.date(date);
  }
};

export const formatTime = (date: Date, localized: StringsIntl) => {
  const formatted = localized.time.time(date);
  return formatted;
};

export const formatTimestamp = (date: Date, localized: StringsIntl) => {
  const today = new Date();
  const time = localized.time.time(date);
  let dateTimestamp = '';
  if (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  ) {
    dateTimestamp = localized.alerts.today();
  } else {
    dateTimestamp = localized.time.dateTimestamp(date);
  }
  return dateTimestamp + ' ' + time;
};

export const formatTimeDiff = (date: Date, endDate: Date, localized: StringsIntl) => {
  const elapsed = getTimeElapsed(date, endDate);
  return formatElapsed(elapsed, localized);
};

export const formatElapsed = (elapsed: models.TimeElapsed, localized: StringsIntl) => {
  return localized.time.duration(
    elapsed.days ? elapsed.days : 0,
    elapsed.hours ? elapsed.hours % 24 : 0,
    elapsed.minutes ? elapsed.minutes % 60 : 0,
    elapsed.seconds ? elapsed.seconds % 60 : 0
  );
};

export const getTimeElapsed = (date: Date, endDate: Date) => {
  const milliseconds = endDate.getTime() - date.getTime();
  return getTimeElapsedMilliseconds(milliseconds);
};

export const getTimeElapsedMilliseconds = (milliseconds: number): Elapsed => {
  let seconds = 0;
  let minutes = 0;
  let hours = 0;
  let days = 0;
  if (milliseconds >= 0) {
    seconds = Math.floor(milliseconds / 1000);
    minutes = Math.floor(milliseconds / (1000 * 60));
    hours = Math.floor(milliseconds / (1000 * 60 * 60));
    days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
  }
  return { days, hours, minutes, seconds, milliseconds };
};

interface Elapsed {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

export const getAlertTypeResolveActions = (
  alertType: AlertType,
  localized: StringsIntl
): AlertResolutionAction[] => {
  const actions: AlertResolutionAction[] = [];

  switch (alertType) {
    case AlertType.HEART:
      // confirmed visually
      actions.push({
        resolution: {
          resolution: alertResolution.HRAlertResolution.confirmedVisually,
          detail: alertResolution.HRAlertVisualResolutionDetail.verifiedByVitalMeter,
        },
        display: {
          resolution: localized.translate(alertResolution.HRAlertResolution.confirmedVisually),
          detail: localized.translate(
            alertResolution.HRAlertVisualResolutionDetail.verifiedByVitalMeter
          ),
        },
      });
      actions.push({
        resolution: {
          resolution: alertResolution.HRAlertResolution.confirmedVisually,
          detail: alertResolution.HRAlertVisualResolutionDetail.reportedAndGaveCare,
        },
        display: {
          resolution: localized.translate(alertResolution.HRAlertResolution.confirmedVisually),
          detail: localized.translate(
            alertResolution.HRAlertVisualResolutionDetail.reportedAndGaveCare
          ),
        },
      });
      actions.push({
        resolution: {
          resolution: alertResolution.HRAlertResolution.confirmedVisually,
          detail: alertResolution.HRAlertVisualResolutionDetail.takenToHospital,
        },
        display: {
          resolution: localized.translate(alertResolution.HRAlertResolution.confirmedVisually),
          detail: localized.translate(
            alertResolution.HRAlertVisualResolutionDetail.takenToHospital
          ),
        },
      });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.HRAlertResolution.confirmedVisually,
      //     detail: alertResolution.HRAlertVisualResolutionDetail.informedFamily,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.HRAlertResolution.confirmedVisually),
      //     detail: localized.translate(alertResolution.HRAlertVisualResolutionDetail.informedFamily),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.HRAlertResolution.confirmedVisually,
      //     detail: alertResolution.HRAlertVisualResolutionDetail.reported,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.HRAlertResolution.confirmedVisually),
      //     detail: localized.translate(alertResolution.HRAlertVisualResolutionDetail.reported),
      //   },
      // });

      // confirmed by vital meter
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.HRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.HRAlertVitalMeterResolutionDetail.verifiedByVitalMeter,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.HRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.HRAlertVitalMeterResolutionDetail.verifiedByVitalMeter),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.HRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.HRAlertVitalMeterResolutionDetail.reportedAndGaveCare,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.HRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.HRAlertVitalMeterResolutionDetail.reportedAndGaveCare),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.HRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.HRAlertVitalMeterResolutionDetail.takenToHospital,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.HRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.HRAlertVitalMeterResolutionDetail.takenToHospital),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.HRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.HRAlertVitalMeterResolutionDetail.informedFamily,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.HRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.HRAlertVitalMeterResolutionDetail.informedFamily),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.HRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.HRAlertVitalMeterResolutionDetail.reported,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.HRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.HRAlertVitalMeterResolutionDetail.reported),
      //   },
      // });

      // could not confirm
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.HRAlertResolution.couldNotConfirm,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.HRAlertResolution.couldNotConfirm),
      //     detail: null,
      //   },
      // });
      // // no action required
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.OtherAlertResolution.noActionRequired,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.OtherAlertResolution.noActionRequired),
      //     detail: null,
      //   },
      // });

      // other
      actions.push({
        resolution: {
          resolution: alertResolution.HRAlertResolution.other,
          detail: null,
        },
        display: {
          resolution: localized.translate(alertResolution.HRAlertResolution.other),
          detail: null,
        },
      });
      break;

    case AlertType.BREATHING:
      // confirmed visually
      actions.push({
        resolution: {
          resolution: alertResolution.RRAlertResolution.confirmedVisually,
          detail: alertResolution.RRAlertVisualResolutionDetail.verifiedByVitalMeter,
        },
        display: {
          resolution: localized.translate(alertResolution.RRAlertResolution.confirmedVisually),
          detail: localized.translate(
            alertResolution.RRAlertVisualResolutionDetail.verifiedByVitalMeter
          ),
        },
      });
      actions.push({
        resolution: {
          resolution: alertResolution.RRAlertResolution.confirmedVisually,
          detail: alertResolution.RRAlertVisualResolutionDetail.reportedAndGaveCare,
        },
        display: {
          resolution: localized.translate(alertResolution.RRAlertResolution.confirmedVisually),
          detail: localized.translate(
            alertResolution.RRAlertVisualResolutionDetail.reportedAndGaveCare
          ),
        },
      });
      actions.push({
        resolution: {
          resolution: alertResolution.RRAlertResolution.confirmedVisually,
          detail: alertResolution.RRAlertVisualResolutionDetail.takenToHospital,
        },
        display: {
          resolution: localized.translate(alertResolution.RRAlertResolution.confirmedVisually),
          detail: localized.translate(
            alertResolution.RRAlertVisualResolutionDetail.takenToHospital
          ),
        },
      });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.RRAlertResolution.confirmedVisually,
      //     detail: alertResolution.RRAlertVisualResolutionDetail.informedFamily,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.RRAlertResolution.confirmedVisually),
      //     detail: localized.translate(alertResolution.RRAlertVisualResolutionDetail.informedFamily),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.RRAlertResolution.confirmedVisually,
      //     detail: alertResolution.RRAlertVisualResolutionDetail.reported,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.RRAlertResolution.confirmedVisually),
      //     detail: localized.translate(alertResolution.RRAlertVisualResolutionDetail.reported),
      //   },
      // });

      // confirmed by vital meter
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.RRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.RRAlertVitalMeterResolutionDetail.verifiedByVitalMeter,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.RRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.RRAlertVitalMeterResolutionDetail.verifiedByVitalMeter),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.RRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.RRAlertVitalMeterResolutionDetail.reportedAndGaveCare,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.RRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.RRAlertVitalMeterResolutionDetail.reportedAndGaveCare),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.RRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.RRAlertVitalMeterResolutionDetail.takenToHospital,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.RRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.RRAlertVitalMeterResolutionDetail.takenToHospital),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.RRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.RRAlertVitalMeterResolutionDetail.informedFamily,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.RRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.RRAlertVitalMeterResolutionDetail.informedFamily),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.RRAlertResolution.confirmedByVitalMeter,
      //     detail: alertResolution.RRAlertVitalMeterResolutionDetail.reported,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.RRAlertResolution.confirmedByVitalMeter),
      //     detail: localized.translate(alertResolution.RRAlertVitalMeterResolutionDetail.reported),
      //   },
      // });

      // could not confirm
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.RRAlertResolution.couldNotConfirm,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.RRAlertResolution.couldNotConfirm),
      //     detail: null,
      //   },
      // });
      // no action required
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.OtherAlertResolution.noActionRequired,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.OtherAlertResolution.noActionRequired),
      //     detail: null,
      //   },
      // });

      // other
      actions.push({
        resolution: {
          resolution: alertResolution.RRAlertResolution.other,
          detail: null,
        },
        display: {
          resolution: localized.translate(alertResolution.RRAlertResolution.other),
          detail: null,
        },
      });
      break;

    case AlertType.FALL:
    case AlertType.SITTING:
      // about to leave bed
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.aboutToLeaveBed,
      //     detail: alertResolution.FallAlertResolutionAboutToLeaveBedDetail.helpedLayInBed,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.aboutToLeaveBed),
      //     detail: localized.translate(alertResolution.FallAlertResolutionAboutToLeaveBedDetail.helpedLayInBed),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.aboutToLeaveBed,
      //     detail: alertResolution.FallAlertResolutionAboutToLeaveBedDetail.helpedLeaveToilet,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.aboutToLeaveBed),
      //     detail: localized.translate(alertResolution.FallAlertResolutionAboutToLeaveBedDetail.helpedLeaveToilet),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.aboutToLeaveBed,
      //     detail: alertResolution.FallAlertResolutionAboutToLeaveBedDetail.calledOut,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.aboutToLeaveBed),
      //     detail: localized.translate(alertResolution.FallAlertResolutionAboutToLeaveBedDetail.calledOut),
      //   },
      // });
      // not leaving bed
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.notLeavingBed,
      //     detail: alertResolution.FallAlertResolutionNotLeavingBedDetail.helpedChangeClothes,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.notLeavingBed),
      //     detail: localized.translate(alertResolution.FallAlertResolutionNotLeavingBedDetail.helpedChangeClothes),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.notLeavingBed,
      //     detail: alertResolution.FallAlertResolutionNotLeavingBedDetail.justTurnedOver,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.notLeavingBed),
      //     detail: localized.translate(alertResolution.FallAlertResolutionNotLeavingBedDetail.justTurnedOver),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.returnedToBed,
      //     detail: alertResolution.FallAlertResolutionReturnToBedDetail.returnedToBed,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.returnedToBed),
      //     detail: localized.translate(alertResolution.FallAlertResolutionReturnToBedDetail.returnedToBed),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.notLeavingBed,
      //     detail: alertResolution.FallAlertResolutionNotLeavingBedDetail.padWasBeingReplaced,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.notLeavingBed),
      //     detail: localized.translate(alertResolution.FallAlertResolutionNotLeavingBedDetail.padWasBeingReplaced),
      //   },
      // });
      // left bed
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.leftBed,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.leftBed),
      //     detail: null,
      //   },
      // });
      // not in room
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.notInRoom,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.notInRoom),
      //     detail: null,
      //   },
      // });
      // fell
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.fellDown,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.fellDown),
      //     detail: null,
      //   },
      // });
      // no action required
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.OtherAlertResolution.noActionRequired,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.OtherAlertResolution.noActionRequired),
      //     detail: null,
      //   },
      // });
      // other
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.other,
      //     detail: alertResolution.FallAlertResolutionOtherDetail.turningOver,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.other),
      //     detail: localized.translate(alertResolution.FallAlertResolutionOtherDetail.turningOver),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.other,
      //     detail: alertResolution.FallAlertResolutionOtherDetail.rollingOver,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.other),
      //     detail: localized.translate(alertResolution.FallAlertResolutionOtherDetail.rollingOver),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.other,
      //     detail: alertResolution.FallAlertResolutionOtherDetail.measuringVitals,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.other),
      //     detail: localized.translate(alertResolution.FallAlertResolutionOtherDetail.measuringVitals),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.other,
      //     detail: alertResolution.FallAlertResolutionOtherDetail.providingCare,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.other),
      //     detail: localized.translate(alertResolution.FallAlertResolutionOtherDetail.providingCare),
      //   },
      // });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.other,
      //     detail: alertResolution.FallAlertResolutionOtherDetail.changedDiaper,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.other),
      //     detail: localized.translate(alertResolution.FallAlertResolutionOtherDetail.changedDiaper),
      //   },
      // });
      // resolution -> care provided -> helped lay back in bed
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertResolution.careProvided,
          detail: alertResolution.FallAlertResolutionCareProvidedDetail.helpedLayBackInBed,
        },
        display: {
          resolution: localized.translate(alertResolution.FallAlertResolution.careProvided),
          detail: localized.translate(
            alertResolution.FallAlertResolutionCareProvidedDetail.helpedLayBackInBed
          ),
        },
      });
      // fall -> resolution -> care provided -> helped leave bed
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertResolution.careProvided,
          detail: alertResolution.FallAlertResolutionCareProvidedDetail.helpedLeaveBed,
        },
        display: {
          resolution: localized.translate(alertResolution.FallAlertResolution.careProvided),
          detail: localized.translate(
            alertResolution.FallAlertResolutionCareProvidedDetail.helpedLeaveBed
          ),
        },
      });
      // fall -> resolution -> care provided -> helped to toilet
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertResolution.careProvided,
          detail: alertResolution.FallAlertResolutionCareProvidedDetail.helpedToilet,
        },
        display: {
          resolution: localized.translate(alertResolution.FallAlertResolution.careProvided),
          detail: localized.translate(
            alertResolution.FallAlertResolutionCareProvidedDetail.helpedToilet
          ),
        },
      });
      // fall -> resolution -> care provided -> called out
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertResolution.careProvided,
          detail: alertResolution.FallAlertResolutionCareProvidedDetail.calledOut,
        },
        display: {
          resolution: localized.translate(alertResolution.FallAlertResolution.careProvided),
          detail: localized.translate(
            alertResolution.FallAlertResolutionCareProvidedDetail.calledOut
          ),
        },
      });
      // fall -> resolution -> other -> no action required
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertResolution.other,
      //     detail: alertResolution.FallAlertResolutionOtherDetail.noAction,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertResolution.other),
      //     detail: localized.translate(alertResolution.FallAlertResolutionOtherDetail.noAction),
      //   },
      // });
      // fall -> resolution -> other -> other
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertResolution.other,
          detail: alertResolution.FallAlertResolutionOtherDetail.other,
        },
        display: {
          resolution: localized.translate(alertResolution.FallAlertResolution.other),
          detail: localized.translate(alertResolution.FallAlertResolutionOtherDetail.other),
        },
      });
      break;

    case AlertType.TURNOVER:
      // changed posture - changed posture
      actions.push({
        resolution: {
          resolution: alertResolution.PressureAlertResolution.changedPosture,
          detail: alertResolution.PressureAlertResolutionChangedPostureDetail.changedPosture,
        },
        display: {
          resolution: localized.translate(alertResolution.PressureAlertResolution.changedPosture),
          detail: localized.translate(
            alertResolution.PressureAlertResolutionChangedPostureDetail.changedPosture
          ),
        },
      });
      // changed posture - already turned
      actions.push({
        resolution: {
          resolution: alertResolution.PressureAlertResolution.changedPosture,
          detail: alertResolution.PressureAlertResolutionChangedPostureDetail.alreadyTurned,
        },
        display: {
          resolution: localized.translate(alertResolution.PressureAlertResolution.changedPosture),
          detail: localized.translate(
            alertResolution.PressureAlertResolutionChangedPostureDetail.alreadyTurned
          ),
        },
      });
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.PressureAlertResolution.didNotChangePosture,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.PressureAlertResolution.didNotChangePosture),
      //     detail: null,
      //   },
      // });
      // no action required
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.OtherAlertResolution.noActionRequired,
      //     detail: null,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.OtherAlertResolution.noActionRequired),
      //     detail: null,
      //   },
      // });
      // other
      actions.push({
        resolution: {
          resolution: alertResolution.PressureAlertResolution.other,
          detail: null,
        },
        display: {
          resolution: localized.translate(alertResolution.PressureAlertResolution.other),
          detail: null,
        },
      });
      break;

    default:
      // no action required
      actions.push({
        resolution: {
          resolution: alertResolution.OtherAlertResolution.noActionRequired,
          detail: null,
        },
        display: {
          resolution: localized.translate(alertResolution.OtherAlertResolution.noActionRequired),
          detail: null,
        },
      });
      actions.push({
        resolution: {
          resolution: alertResolution.OtherAlertResolution.other,
          detail: null,
        },
        display: {
          resolution: localized.translate(alertResolution.OtherAlertResolution.other),
          detail: null,
        },
      });
  }

  return actions;
};

export const getAlertTypeSituationActions = (
  alertType: AlertType,
  localized: StringsIntl
): AlertResolutionAction[] => {
  const actions: AlertResolutionAction[] = [];

  switch (alertType) {
    case AlertType.HEART:
      break;

    case AlertType.BREATHING:
      break;

    case AlertType.FALL:
    case AlertType.SITTING:
      // SITUATION: other -> rolling over
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertSituationCategory.other,
          detail: alertResolution.FallAlertSituationOtherDetail.rollingOver,
        },
        display: {
          resolution: localized.translate(alertResolution.FallAlertSituationCategory.other),
          detail: localized.translate(alertResolution.FallAlertSituationOtherDetail.rollingOver),
        },
      });

      // SITUATION: other -> providing care
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertSituationCategory.other,
      //     detail: alertResolution.FallAlertSituationOtherDetail.providingCare,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertSituationCategory.other),
      //     detail: localized.translate(alertResolution.FallAlertSituationOtherDetail.providingCare),
      //   },
      // });

      // SITUATION: other -> false alert
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertSituationCategory.other,
          detail: alertResolution.FallAlertSituationOtherDetail.falseAlert,
        },
        display: {
          resolution: localized.translate(alertResolution.FallAlertSituationCategory.other),
          detail: localized.translate(alertResolution.FallAlertSituationOtherDetail.falseAlert),
        },
      });

      // SITUATION: about to leave bed -> sitting in bed
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertSituationCategory.aboutToLeaveBed,
          detail: alertResolution.FallAlertSituationAboutToLeaveBedDetail.patientSittingInBed,
        },
        display: {
          resolution: localized.translate(
            alertResolution.FallAlertSituationCategory.aboutToLeaveBed
          ),
          detail: localized.translate(
            alertResolution.FallAlertSituationAboutToLeaveBedDetail.patientSittingInBed
          ),
        },
      });

      // SITUATION: about to leave bed -> trying to get up
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertSituationCategory.aboutToLeaveBed,
          detail: alertResolution.FallAlertSituationAboutToLeaveBedDetail.patientTryingToGetUp,
        },
        display: {
          resolution: localized.translate(
            alertResolution.FallAlertSituationCategory.aboutToLeaveBed
          ),
          detail: localized.translate(
            alertResolution.FallAlertSituationAboutToLeaveBedDetail.patientTryingToGetUp
          ),
        },
      });

      // SITUATION: already left bed -> already left bed
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertSituationCategory.leftBed,
          detail: alertResolution.FallAlertSituationLeftBedDetail.alreadyLeftBed,
        },
        display: {
          resolution: localized.translate(alertResolution.FallAlertSituationCategory.leftBed),
          detail: localized.translate(
            alertResolution.FallAlertSituationLeftBedDetail.alreadyLeftBed
          ),
        },
      });

      // SITUATION: already left bed -> in wheel chair
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertSituationCategory.leftBed,
      //     detail: alertResolution.FallAlertSituationLeftBedDetail.inWheelChair,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertSituationCategory.leftBed),
      //     detail: localized.translate(alertResolution.FallAlertSituationLeftBedDetail.inWheelChair),
      //   },
      // });

      // SITUATION: already left bed -> moving around
      // actions.push({
      //   resolution: {
      //     resolution: alertResolution.FallAlertSituationCategory.leftBed,
      //     detail: alertResolution.FallAlertSituationLeftBedDetail.movingAround,
      //   },
      //   display: {
      //     resolution: localized.translate(alertResolution.FallAlertSituationCategory.leftBed),
      //     detail: localized.translate(alertResolution.FallAlertSituationLeftBedDetail.movingAround),
      //   },
      // });

      // SITUATION: already left bed -> already fell
      actions.push({
        resolution: {
          resolution: alertResolution.FallAlertSituationCategory.leftBed,
          detail: alertResolution.FallAlertSituationLeftBedDetail.alreadyFell,
        },
        display: {
          resolution: localized.translate(alertResolution.FallAlertSituationCategory.leftBed),
          detail: localized.translate(alertResolution.FallAlertSituationLeftBedDetail.alreadyFell),
        },
      });

      break;

    case AlertType.TURNOVER:
      break;

    default:
  }

  return actions;
};
