import * as React from 'react';
import * as Icons from '../../icons';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  SxProps,
  Tab,
  TextField,
  Theme,
  styled,
} from '@mui/material';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { TimeZoneDate, parseCalendarDay } from '../../data/TimeZoneDate';
import strings from '../../languages';
import { getLogger } from '../../logger';
import Dialog from './Dialog';
import { CancelButton, SubmitButton } from '../buttons';

const logger = getLogger('components/dialogs/CalendarDialog', 'info');

const dialogStyles = (theme: Theme) => ({
  tabsBox: {
    // borderBottom: '1px solid',
    // borderBottomColor: theme.palette.divider,
    // backgroundColor: theme.palette.background.default,
    backgroundColor: 'transparent',
  },
  tabPanel: {
    minHeight: 128,
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
    // marginBottom: theme.spacing(4),
  },
});

const Styled = {
  DayButton: styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  })),
  TabsBox: styled(Box)(({ theme }) => dialogStyles(theme).tabsBox),
  TabPanel: styled(TabPanel)(({ theme }) => dialogStyles(theme).tabPanel),
};

interface DialogProps {
  className?: string;
  sx?: SxProps;
  fullWidth?: boolean;
  open: boolean;
  onClose: () => void;
  onSelectDates: (startDate: TimeZoneDate, endDate: TimeZoneDate) => void;
  onSelectDays: (days: number) => void;
  startDate: TimeZoneDate;
  endDate: TimeZoneDate;
  minDate: TimeZoneDate;
  maxDate: TimeZoneDate;
  tz: string;
  maxDays: number;
  daysOptions: number[];
}

interface DialogState {
  startDate: TimeZoneDate;
  endDate: TimeZoneDate;
  tabIndex: string;
  startDateError: boolean;
  endDateError: boolean;
  errorMessage: string[];
}

type AllDialogProps = DialogProps & WrappedComponentProps;
const TAB_LATEST = '1';
const TAB_DURATION = '2';
class CalendarDialog extends React.Component<AllDialogProps, DialogState> {
  readonly state: DialogState;

  constructor(props: AllDialogProps) {
    super(props);
    this.state = {
      startDate: props.startDate,
      endDate: props.endDate,
      tabIndex: TAB_LATEST,
      startDateError: false,
      endDateError: false,
      errorMessage: [],
    };
    logger.debug('constructor', {
      minDate: props.minDate.toCalendarDate(),
      maxDate: props.maxDate.toCalendarDate(),
      startDate: props.startDate.toCalendarDate(),
      endDate: props.endDate.toCalendarDate(),
    });
  }

  componentDidUpdate(prevProps: Readonly<AllDialogProps>): void {
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    if (prevProps.startDate.getTime() !== this.props.startDate.getTime()) {
      startDate = this.props.startDate;
      this.setState({ startDate });
    }
    if (prevProps.endDate.getTime() !== this.props.endDate.getTime()) {
      endDate = this.props.endDate;
      this.setState({ endDate });
    }
    logger.debug('componentDidUpdate', {
      minDate: this.props.minDate.toCalendarDate(),
      maxDate: this.props.maxDate.toCalendarDate(),
      startDate: startDate.toCalendarDate(),
      endDate: endDate.toCalendarDate(),
    });
  }

  checkDateIntervalValidation = () => {
    const { startDate, endDate } = this.state;
    const { intl, maxDays } = this.props;
    const localized = strings(intl);
    const errorMessage: string[] = [];

    const addErrorMessage = (error: string) => {
      errorMessage.unshift(error);
    };

    const setErrorState = (errorType: 'startDateError' | 'endDateError', value: boolean) => {
      this.setState((prevState) => ({
        ...prevState,
        [errorType]: value,
      }));
    };

    const checkDateFormat = (
      date: Date,
      errorType: 'startDateError' | 'endDateError',
      label: string
    ) => {
      if (!date || isNaN(date.getTime())) {
        setErrorState(errorType, true);
        addErrorMessage(localized.validation.invalidDateFormat(label));
      } else if (this.state[errorType]) {
        setErrorState(errorType, false);
      }
    };

    checkDateFormat(startDate.toDate(), 'startDateError', localized.careReport.startDate());
    checkDateFormat(endDate.toDate(), 'endDateError', localized.careReport.endDate());

    if (errorMessage.length > 0) {
      this.setState({ errorMessage });
      return;
    }

    if (startDate.getTime() > endDate.getTime()) {
      setErrorState('startDateError', true);
      addErrorMessage(
        localized.validation.invalidDateOnConditionOrBeforeIt(
          localized.careReport.startDate(),
          localized.careReport.endDate()
        )
      );
    }

    const days = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1;
    if (days > maxDays) {
      setErrorState('startDateError', true);
      addErrorMessage(localized.validation.invalidDate(maxDays));
    }

    if (errorMessage.length === 0) {
      this.setState({ startDateError: false, endDateError: false, errorMessage: [] });
    } else {
      this.setState({ errorMessage });
    }
  };

  handleChange = (event: React.SyntheticEvent, tabIndex: string) => {
    this.setState({ tabIndex: tabIndex });
  };

  onSelectDays = (days: number) => {
    const { onSelectDays, endDate } = this.props;
    const startDate = endDate.subtractDays(days - 1).setHours(0, 0, 0);

    logger.debug('onSelectDays', {
      days,
      start: startDate.toCalendarDate(),
      end: endDate.toCalendarDate(),
    });

    this.setState({
      startDate,
      endDate,
      startDateError: false,
      endDateError: false,
      errorMessage: [],
    });

    onSelectDays(days);
  };

  onSelectDates = (startDate: TimeZoneDate, endDate: TimeZoneDate) => {
    logger.debug('onSelectDates', {
      start: startDate.toCalendarDate(),
      end: endDate.toCalendarDate(),
    });
    this.props.onSelectDates(startDate, endDate);
  };

  onChangeStartDate = (value: string) => {
    try {
      let endDate = this.state.endDate;
      const startDate = parseCalendarDay(value, this.props.tz);
      // if (startDate.getTime() < this.props.minDate.getTime()) {
      //   startDate = parseCalendarDay(this.props.minDate.toCalendarDate(), this.props.tz);
      // }
      // if (startDate.getTime() > this.props.maxDate.getTime()) {
      //   startDate = parseCalendarDay(this.props.maxDate.toCalendarDate(), this.props.tz);
      // }
      // const days = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1;
      // if (days >= this.props.maxDays) {
      //   endDate = startDate.addDays(this.props.maxDays - 1);
      // }
      if (startDate.getTime() > endDate.getTime()) {
        endDate = startDate;
      }
      this.setState({ startDate, endDate }, () => {
        this.checkDateIntervalValidation();
      });
    } catch (e) {
      // ignore - invalid date
      logger.debug('onChangeStartDate', e);
    }
  };

  onChangeEndDate = (value: string) => {
    try {
      let startDate = this.state.startDate;
      const endDate = parseCalendarDay(value, this.props.tz);
      // if (endDate.getTime() > this.props.maxDate.getTime()) {
      //   endDate = parseCalendarDay(this.props.maxDate.toCalendarDate(), this.props.tz);
      // }
      // if (endDate.getTime() < this.props.minDate.getTime()) {
      //   endDate = parseCalendarDay(this.props.minDate.toCalendarDate(), this.props.tz);
      // }
      // const days = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1;
      // if (days >= this.props.maxDays) {
      //   startDate = endDate.subtractDays(this.props.maxDays - 1);
      // }
      if (startDate.getTime() > endDate.getTime()) {
        startDate = endDate;
      }
      this.setState({ startDate, endDate }, () => {
        this.checkDateIntervalValidation();
      });
    } catch (e) {
      // ignore - invalid date
      logger.debug('onChangeEndDate', e);
    }
  };

  renderDaysButtom() {
    const localized = strings(this.props.intl);
    const { daysOptions } = this.props;
    if (daysOptions) {
      return (
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          {daysOptions.map((option, index) => (
            <Styled.DayButton
              key={index}
              variant="outlined"
              onClick={() => this.onSelectDays(option)}
            >
              {localized.report.numberOfDays(option)}
            </Styled.DayButton>
          ))}
        </Stack>
      );
    } else {
      return null;
    }
  }

  render() {
    const { onClose, open, minDate, maxDate } = this.props;
    const localized = strings(this.props.intl);
    const {
      startDate: calendarStartDate,
      endDate: calendarEndDate,
      tabIndex,
      startDateError,
      endDateError,
      errorMessage,
    } = this.state;

    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>
          <Icons.DateRange />
          <span>{localized.alerts.duration()}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <form noValidate>
            <TabContext value={tabIndex}>
              <Styled.TabsBox>
                <TabList onChange={this.handleChange} aria-label="lab API tabs example">
                  <Tab label={localized.label.dialogLatest()} value={TAB_LATEST} />
                  <Tab label={localized.label.dialogDuration()} value={TAB_DURATION} />
                </TabList>
              </Styled.TabsBox>
              <Styled.TabPanel value={TAB_LATEST}>{this.renderDaysButtom()}</Styled.TabPanel>
              <Styled.TabPanel value={TAB_DURATION}>
                <Stack direction="row" justifyContent="space-between">
                  <TextField
                    id="startDate"
                    label={localized.careReport.startDate()}
                    InputProps={{
                      inputProps: {
                        min: minDate.toCalendarDate(),
                        max: maxDate.toCalendarDate(),
                      },
                    }}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => this.onChangeStartDate(e.target.value)}
                    value={calendarStartDate.toCalendarDate()}
                    error={startDateError}
                  />
                  <TextField
                    id="endDate"
                    label={localized.careReport.endDate()}
                    InputProps={{
                      inputProps: {
                        min: minDate.toCalendarDate(),
                        max: maxDate.toCalendarDate(),
                      },
                    }}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => this.onChangeEndDate(e.target.value)}
                    value={calendarEndDate.toCalendarDate()}
                    error={endDateError}
                  />
                </Stack>
                {errorMessage.length > 0 ? (
                  <Alert severity="info" sx={{ whiteSpace: 'pre-line', mt: 1 }}>
                    {errorMessage.join('\n')}
                  </Alert>
                ) : null}
                {/* <Typography className={classes.errorMessage}>{errorMessage}</Typography> */}
              </Styled.TabPanel>
            </TabContext>
          </form>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose} />
          {tabIndex == TAB_DURATION ? (
            <SubmitButton
              onClick={() => this.onSelectDates(calendarStartDate, calendarEndDate)}
              disabled={startDateError === true || endDateError === true ? true : false}
            />
          ) : null}
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(CalendarDialog);
