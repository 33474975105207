import { Width } from './types';

export const isExtraLarge = (width?: Width) => width === 'lg';
export const isExtraLargeOrBigger = (width?: Width) => isExtraLarge(width);
export const isExtraLargeOrSmaller = (width?: Width) => isLargeOrSmaller(width) || isExtraLarge(width);
export const isExtraSmall = (width?: Width) => width === 'xs';
export const isExtraSmallOrBigger = (width?: Width) => isExtraSmall(width) || true;
export const isExtraSmallOrSmaller = (width?: Width) => isExtraSmall(width);
export const isLarge = (width?: Width) => width === 'lg';
export const isLargeOrBigger = (width?: Width) => !isExtraSmall(width) && !isSmall(width) && !isMedium(width);
export const isLargeOrSmaller = (width?: Width) => isMediumOrSmaller(width) || isLarge(width);
export const isMedium = (width?: Width) => width === 'md';
export const isMediumOrBigger = (width?: Width) => !isExtraSmall(width) && !isSmall(width);
export const isMediumOrSmaller = (width?: Width) => isSmallOrSmaller(width) || isMedium(width);
export const isSmall = (width?: Width) => width === 'sm';
export const isSmallOrBigger = (width?: Width) => !isExtraSmall(width);
export const isSmallOrSmaller = (width?: Width) => isExtraSmall(width) || isSmall(width);
