"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRole = void 0;
var UserRole;
(function (UserRole) {
    UserRole["superuser"] = "superuser";
    UserRole["admin"] = "admin";
    // admin_care_centers = "admin_care_centers",
    // admin_accounts = "admin_accounts",
    // admin_regions = "admin_regions",
    UserRole["read_only"] = "read_only";
    UserRole["care_giver"] = "care_giver";
    UserRole["manager"] = "manager";
    // regional_manager = "regional_manager",
    // director = "director",
    UserRole["hq"] = "hq";
    UserRole["dev"] = "dev";
})(UserRole || (exports.UserRole = UserRole = {}));
