"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep Client API
 * This document is a specification for the API that must be implemented by AiSleep client applications (i.e. AttentiveConnect) so that AiSleep servers can push data to clients. This is a specifications for the callbacks.  This specification is used to generate the code that is used by AC to integrate AiSleep data.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes). The below status codes are used in this interface:    - 200 OK - Standard response for successful HTTP requests.   - 201 Created - The request has been fulfilled, resulting in the creation of a new resource   - 202 Accepted - The request has been accepted for processing, but the processing has not been completed.   - 400 Bad Request - The server cannot or will not process the request due to an apparent client         error (e.g., malformed request syntax, size too large, invalid request message framing,         or deceptive request routing)   - 404 Not Found - The requested resource could not be found   - 500 Internal Server Error
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VitalsApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class VitalsApi extends runtime.BaseAPI {
    /**
     * Sent when an alarm condition occurs.
     */
    alarmPostRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.alarm === null || requestParameters.alarm === undefined) {
                throw new runtime.RequiredError('alarm', 'Required parameter requestParameters.alarm was null or undefined when calling alarmPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/alarm`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.AlarmToJSON)(requestParameters.alarm),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.AlarmResultFromJSON)(jsonValue));
        });
    }
    /**
     * Sent when an alarm condition occurs.
     */
    alarmPost(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.alarmPostRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * A daily log for an AiSleep sensor that contains a sleep report. The log is sent at 10:30 AM daily and contains data for the previous 24 hrs.
     * Register daily log for a sensor that includes a sleep summary.
     */
    dailyLogPostRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.dailyLog === null || requestParameters.dailyLog === undefined) {
                throw new runtime.RequiredError('dailyLog', 'Required parameter requestParameters.dailyLog was null or undefined when calling dailyLogPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/dailyLog`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.DailyLogToJSON)(requestParameters.dailyLog),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.DailyLogResultFromJSON)(jsonValue));
        });
    }
    /**
     * A daily log for an AiSleep sensor that contains a sleep report. The log is sent at 10:30 AM daily and contains data for the previous 24 hrs.
     * Register daily log for a sensor that includes a sleep summary.
     */
    dailyLogPost(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.dailyLogPostRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * AiSleep sensors send data once per second.
     * Register vitals data from a sensor.
     */
    vitalsPostRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.vitals === null || requestParameters.vitals === undefined) {
                throw new runtime.RequiredError('vitals', 'Required parameter requestParameters.vitals was null or undefined when calling vitalsPost.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            const response = yield this.request({
                path: `/vitals`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.VitalsToJSON)(requestParameters.vitals),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.VitalsResultFromJSON)(jsonValue));
        });
    }
    /**
     * AiSleep sensors send data once per second.
     * Register vitals data from a sensor.
     */
    vitalsPost(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.vitalsPostRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.VitalsApi = VitalsApi;
