import { getLogger } from '../logger';

const logger = getLogger('data/Semaphore', 'info');

export class Semaphore {
  private name: string;
  private acquired = 0;
  private waiting: { resolve: (value: unknown) => void; err: (reason?: unknown) => void }[] = [];
  private max: number;

  // constructor(max = Number.MAX_SAFE_INTEGER) {
  constructor(max: number, name: string) {
    logger.debug(`Semaphore ${name} created with max=${max}`);
    this.name = name;
    this.max = max;
  }

  public take(): void {
    if (this.waiting.length > 0 && this.acquired < this.max) {
      this.acquired += 1;
      const promise = this.waiting.shift();
      if (promise) {
        logger.debug(
          `[take] Semaphore ${this.name}, acquire is now ${this.acquired}, waiting is ${this.waiting.length}`
        );
        promise.resolve(undefined);
      }
    }
  }

  public acquire(): Promise<unknown> {
    logger.debug(`[acquire] Semaphore ${this.name} acquiring...`);
    if (this.acquired < this.max) {
      this.acquired += 1;
      logger.debug(`[acquire] Semaphore ${this.name}, acquired is now ${this.acquired}`);
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }
    logger.debug(
      `[acquire] Semaphore ${this.name} waiting to acquire, acquire is now ${this.acquired}, waiting is ${this.waiting.length}`
    );
    return new Promise((resolve, err) => {
      this.waiting.push({ resolve, err });
    });
  }

  public release(): void {
    if (this.acquired > 0) this.acquired -= 1;
    logger.debug(`[release] Semaphore ${this.name}`);
    this.take();
  }

  public purge(): number {
    const unresolved = this.waiting.length;

    for (let i = 0; i < unresolved; i += 1) {
      this.waiting[i].err(`Task has been purged.`);
    }

    this.acquired = 0;
    this.waiting = [];

    return unresolved;
  }
}
