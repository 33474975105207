import {
  isAisleepPatientStatusAwake,
  isAisleepPatientStatusMoving,
  isAisleepPatientStatusOutOfBed,
  isAisleepPatientStatusSitting,
  isAisleepPatientStatusSleeping,
  isAisleepPatientStatusUnknown,
} from 'attentive-connect-store/dist/mappers';
import {
  AlertType,
  BedOccupancy,
  Resident,
  Sensor,
  SensorStatus,
  SensorType,
} from 'attentive-connect-store/dist/models';
import {
  FallAlertSettings,
  RangeAlertSettings,
} from 'attentive-connect-store/dist/models/AlertSettings';
import RiskLevelType from 'attentive-connect-store/dist/models/RiskLevelType';
import { AisleepAlertSettings } from 'attentive-connect-store/dist/models/SensorAlertSettings';
import * as timeRange from 'attentive-connect-store/dist/models/TimeRange';
import { Database } from 'attentive-connect-store/dist/services';
import { StringsIntl } from '../languages';
import { getLogger } from '../logger';
import { AisleepVitals } from './Vitals';

const logger = getLogger('data/Aisleep');
logger.debug();

const DOZE_DURATION = 5 * 60 * 1000; // 5 minutes
// const DOZE_DURATION = 30 * 1000; // 30 sec

export type SittingStatusType = 'sitting' | 'not-sitting' | 'na';
export type InBedStatusType = 'in' | 'out' | 'na';
export type SleepStatusType = 'asleep' | 'awake' | 'na' | 'dozing';

export const aisleepFallAlertSettings = (
  risk: RiskLevelType,
  current?: FallAlertSettings | null
): FallAlertSettings => {
  const fall: FallAlertSettings = {
    riskLevel: risk,
    timeOutOfBedSeconds: current ? current.timeOutOfBedSeconds : -1,
    timeRange: current && current.timeRange ? { ...current.timeRange } : null,
  };

  switch (risk) {
    case RiskLevelType.VERY_LOW:
    case RiskLevelType.LOW:
      // alert when resident leaves the bed during the night
      fall.timeRange = { ...timeRange.DEFAULT_TIMERANGE_NIGHT };
      if (fall.timeOutOfBedSeconds < 0) {
        fall.timeOutOfBedSeconds = 30 * 60; // 30 minutes
      }
      break;

    case RiskLevelType.HIGH:
    case RiskLevelType.VERY_HIGH:
      // early alert after 1 minute in bed
      fall.timeRange = { ...timeRange.DEFAULT_TIMERANGE_ALWAYS };
      if (fall.timeOutOfBedSeconds < 0) {
        fall.timeOutOfBedSeconds = 60; // 1 minute
      }
      break;

    case RiskLevelType.CUSTOM:
      // custom time range
      if (!fall.timeRange) {
        fall.timeRange = { ...timeRange.DEFAULT_TIMERANGE_NIGHT };
      }
      if (fall.timeOutOfBedSeconds < 0) {
        fall.timeOutOfBedSeconds = 10 * 60; // 10 minutes
      }
      break;

    case RiskLevelType.NONE:
    case RiskLevelType.MEDIUM:
    default:
      fall.timeRange = null;
      fall.timeOutOfBedSeconds = -1;
      break;
  }

  return fall;
};

/**
 * Ensure we have minimal BR alert settings for a risk level.
 *
 * @param risk the risk level for which we want settings.
 * @param current the current BR settings.
 */
export const aisleepRespirationAlertSettings = (
  risk: RiskLevelType,
  current?: RangeAlertSettings | null
): RangeAlertSettings => {
  const settings: RangeAlertSettings = current
    ? { ...current }
    : {
        riskLevel: risk,
        duration: -1,
        max: null,
        min: null,
        percent: -1,
        cooloff: -1,
      };

  switch (risk) {
    case RiskLevelType.CUSTOM:
      settings.riskLevel = risk;
      if (settings.duration < 0) {
        settings.duration = 60; // 1 minute
      }
      if (settings.max === null || settings.max < 0) {
        settings.max = 60;
      }
      if (settings.min === null || settings.min < 0) {
        settings.min = 10;
      }
      if (settings.percent < 0 || settings.percent > 1) {
        settings.percent = 0.8;
      }
      if (settings.cooloff < 0) {
        settings.cooloff = 30; // 30 seconds
      }
      break;

    case RiskLevelType.NONE:
    default:
      settings.riskLevel = RiskLevelType.NONE;
      settings.max = null;
      settings.min = null;
      settings.duration = -1;
      settings.cooloff = -1;
      settings.percent = -1;
      break;
  }

  return settings;
};

/**
 * Ensure we have minimal HR alert settings for a risk level.
 *
 * @param risk the risk level for which we want settings.
 * @param current the current BR settings.
 */
export const aisleepHeartRateAlertSettings = (
  risk: RiskLevelType,
  current?: RangeAlertSettings | null
): RangeAlertSettings => {
  const settings: RangeAlertSettings = current
    ? { ...current }
    : {
        riskLevel: risk,
        duration: -1,
        max: null,
        min: null,
        percent: -1,
        cooloff: -1,
      };

  switch (risk) {
    case RiskLevelType.CUSTOM:
      settings.riskLevel = risk;
      if (settings.duration < 0) {
        settings.duration = 60; // 1 minute
      }
      if (settings.max === null || settings.max < 0) {
        settings.max = 130;
      }
      if (settings.min === null || settings.min < 0) {
        settings.min = 50;
      }
      if (settings.percent < 0 || settings.percent > 1) {
        settings.percent = 0.8;
      }
      if (settings.cooloff < 0) {
        settings.cooloff = 30; // 30 seconds
      }
      break;

    case RiskLevelType.NONE:
    default:
      settings.riskLevel = RiskLevelType.NONE;
      settings.max = null;
      settings.min = null;
      settings.duration = -1;
      settings.cooloff = -1;
      settings.percent = -1;
      break;
  }

  return settings;
};

export const aisleepGetAlertSettingsDescription = (
  alertType: AlertType,
  aisleep: AisleepAlertSettings,
  localized: StringsIntl
) => {
  let description = '';

  switch (alertType) {
    case AlertType.BREATHING:
      // TODO
      break;
    case AlertType.HEART:
      // TODO
      break;
    case AlertType.FALL:
      if (aisleep.processFallAlerts) {
        // TODO
      } else {
        description = localized.alerts.noomi.offDescription();
      }
      break;
    default:
      description = '';
  }

  return description;
};

export const aisleepSensorStatus = (vitals?: AisleepVitals): SensorStatus => {
  let status: SensorStatus = 'other';

  if (vitals) {
    status = vitals.sensorStatus();
  }
  return status;
};

export const aisleepMovementLevel = (
  db: Database | undefined,
  vitals: AisleepVitals | undefined
): number | undefined => {
  const _status = aisleepSensorStatus(vitals);
  const sensorOk = _status === 'ok' || _status === 'limited-data';

  if (sensorOk && aisleepIsInBed(vitals)) {
    if (vitals && vitals.vitals && vitals.vitals.data.aisleep) {
      if (isAisleepPatientStatusMoving(vitals.vitals.data.aisleep.sdata.status)) {
        return 1;
      } else {
        return 0;
      }
    }
  }
  return undefined;
};

export const aisleepIsStatusUnknown = (
  db: Database | undefined,
  vitals: AisleepVitals | undefined
): boolean | undefined => {
  const _status = aisleepSensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';

  if (ok && db && vitals && vitals.vitals && vitals.vitals.data.aisleep) {
    return isAisleepPatientStatusUnknown(vitals.vitals.data.aisleep.sdata.status);
  }
  return undefined;
};

export const aisleepInBedStatus = (vitals: AisleepVitals | undefined): InBedStatusType => {
  const _status = aisleepSensorStatus(vitals);
  const sensorOk = _status === 'ok' || _status === 'limited-data';
  let status: InBedStatusType = 'na';

  if (sensorOk) {
    if (aisleepIsOutOfBed(vitals)) {
      status = 'out';
    } else if (aisleepIsInBed(vitals)) {
      status = 'in';
    } else {
      status = 'na';
    }
  }

  logger.debug(`inBedStatus -> :`, { sensorOk, status });
  return status;
};

export const aisleepIsInBed = (vitals: AisleepVitals | undefined): boolean | undefined => {
  if (
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.aisleep
    // vitals.vitals.data.aisleepUserDetection
  ) {
    // const { inBedTime, outOfBedTime } = vitals.vitals.data.aisleepUserDetection;
    if (
      !isAisleepPatientStatusOutOfBed(vitals.vitals.data.aisleep.sdata.status) &&
      !isAisleepPatientStatusUnknown(vitals.vitals.data.aisleep.sdata.status)
      // inBedTime > outOfBedTime
    ) {
      logger.debug('isInBed -> true');
      return true;
    } else {
      logger.debug('isInBed -> false');
      return false;
    }
  }

  return undefined;
};

export const aisleepIsOutOfBed = (vitals: AisleepVitals | undefined): boolean | undefined => {
  if (
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.aisleep
    // vitals.vitals.data.aisleepUserDetection
  ) {
    // const { inBedTime, outOfBedTime } = vitals.vitals.data.aisleepUserDetection;
    if (
      isAisleepPatientStatusOutOfBed(vitals.vitals.data.aisleep.sdata.status)
      // ||
      // (!isAisleepPatientStatusUnknown(vitals.vitals.data.aisleep.sdata.status) &&
      //   inBedTime < outOfBedTime)
    ) {
      logger.debug('isOutOfBed -> true', {
        status: vitals.vitals.data.aisleep.sdata.status,
        // inBedTime,
        // outOfBedTime,
      });
      return true;
    } else {
      logger.debug('isOutOfBed -> false', {
        status: vitals.vitals.data.aisleep.sdata.status,
        // inBedTime,
        // outOfBedTime,
      });
      return false;
    }
  }

  logger.debug('isOutOfBed -> undefined');
  return undefined;
};

export const aisleepDurationOutOfBed = (vitals: AisleepVitals | undefined): number | undefined => {
  const time = aisleepTimeOutOfBed(vitals);
  if (time !== undefined) {
    return Date.now() - time;
  }

  return undefined;
};

export const aisleepDurationOutOfBedMinutes = (
  vitals: AisleepVitals | undefined
): number | undefined => {
  const time = aisleepDurationOutOfBed(vitals);
  if (time !== undefined) {
    return time / 1000 / 60;
  }

  return undefined;
};

export const aisleepTimeOutOfBed = (vitals: AisleepVitals | undefined): number | undefined => {
  if (aisleepIsOutOfBed(vitals) && vitals?.vitals.data.aisleepUserDetection?.outOfBedTime) {
    return vitals.vitals.data.aisleepUserDetection.outOfBedTime;
  }

  return undefined;
};

export const aisleepTimeAwake = (vitals: AisleepVitals | undefined): number | undefined => {
  if (
    aisleepIsAwake(vitals) &&
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.aisleep &&
    vitals.vitals.data.aisleepUserDetection
  ) {
    const { awakeTime, outOfBedTime, inBedTime } = vitals.vitals.data.aisleepUserDetection;
    if (awakeTime >= inBedTime && inBedTime > outOfBedTime) {
      return awakeTime;
    }
  }
  return undefined;
};

export const aisleepTimeAsleep = (vitals: AisleepVitals | undefined): number | undefined => {
  if (
    aisleepIsAsleep(vitals) &&
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.aisleep &&
    vitals.vitals.data.aisleepUserDetection
  ) {
    const { asleepTime, outOfBedTime, inBedTime } = vitals.vitals.data.aisleepUserDetection;
    if (asleepTime >= inBedTime && inBedTime > outOfBedTime) {
      return asleepTime;
    }
  }
  return undefined;
};

export const aisleepIsDozing = (vitals: AisleepVitals | undefined): boolean | undefined => {
  if (
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.aisleep &&
    vitals.vitals.data.aisleepUserDetection
  ) {
    const { asleepTime, awakeTime, inBedTime } = vitals.vitals.data.aisleepUserDetection;
    const inBedDuration = Date.now() - inBedTime;
    const asleepDuration = Date.now() - asleepTime;
    const awakeDuration = Date.now() - awakeTime;
    if (aisleepIsSensorStatusAsleep(vitals)) {
      if (asleepDuration <= DOZE_DURATION) {
        return true;
      }
    }
    if (aisleepIsSensorStatusAwake(vitals)) {
      if (awakeDuration <= DOZE_DURATION && inBedDuration > DOZE_DURATION) {
        return true;
      }
    }
    return false;
  }

  return undefined;
};

const aisleepIsSensorStatusAsleep = (vitals: AisleepVitals | undefined): boolean | undefined => {
  if (
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.aisleep &&
    vitals.vitals.data.aisleepUserDetection
  ) {
    const { asleepTime, awakeTime } = vitals.vitals.data.aisleepUserDetection;
    if (
      isAisleepPatientStatusSleeping(vitals.vitals.data.aisleep.sdata.status) ||
      (aisleepIsInBed(vitals) && asleepTime > awakeTime)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return undefined;
};

export const aisleepIsAsleep = (vitals: AisleepVitals | undefined): boolean | undefined =>
  aisleepIsSensorStatusAsleep(vitals) && !aisleepIsDozing(vitals);

// current status reported by the sensor
export const aisleepIsSensorStatusAwake = (
  vitals: AisleepVitals | undefined
): boolean | undefined => {
  if (
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.aisleep &&
    vitals.vitals.data.aisleepUserDetection
  ) {
    const { asleepTime, awakeTime } = vitals.vitals.data.aisleepUserDetection;
    if (
      isAisleepPatientStatusAwake(vitals.vitals.data.aisleep.sdata.status) ||
      (aisleepIsInBed(vitals) && asleepTime < awakeTime)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return undefined;
};

// AC also supports a 'dozing' status, if patient is 'dozing' then they are
// not considered awake even if the sensor is reporting 'awake' status.
export const aisleepIsAwake = (vitals: AisleepVitals | undefined): boolean | undefined =>
  aisleepIsSensorStatusAwake(vitals) && !aisleepIsDozing(vitals);

export const aisleepSleepStatus = (
  db: Database | undefined,
  vitals: AisleepVitals | undefined
): SleepStatusType => {
  const _status = aisleepSensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';
  let status: SleepStatusType = 'na';

  if (ok && aisleepIsInBed(vitals)) {
    if (aisleepIsDozing(vitals)) {
      status = 'dozing';
    } else if (aisleepIsAsleep(vitals)) {
      status = 'asleep';
    } else if (aisleepIsAwake(vitals)) {
      status = 'awake';
    } else {
      status = 'na';
    }
  }
  return status;
};

export const aisleepSittingStatus = (
  db: Database | undefined,
  vitals: AisleepVitals | undefined
): SittingStatusType => {
  const _status = aisleepSensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';
  let status: SittingStatusType = 'na';

  if (ok && aisleepIsInBed(vitals) && vitals && vitals.vitals && vitals.vitals.data.aisleep) {
    status = isAisleepPatientStatusSitting(vitals.vitals.data.aisleep.sdata.status)
      ? 'sitting'
      : 'not-sitting';
  }

  return status;
};

export const aisleepHeartRate = (vitals: AisleepVitals | undefined) => {
  const _status = aisleepSensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';

  if (
    ok &&
    vitals &&
    vitals.vitals.data.aisleep &&
    vitals.vitals.data.aisleep.sdata.heartRate >= 0 &&
    aisleepIsInBed(vitals)
  ) {
    return vitals.vitals.data.aisleep.sdata.heartRate;
  }
  return undefined;
};

export const aisleepRespiratoryRate = (vitals: AisleepVitals | undefined) => {
  const _status = aisleepSensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';

  if (
    ok &&
    vitals &&
    vitals.vitals.data.aisleep &&
    vitals.vitals.data.aisleep.sdata.respiratoryRate >= 0 &&
    aisleepIsInBed(vitals)
  ) {
    return Math.round(vitals.vitals.data.aisleep.sdata.respiratoryRate);
  }
  return undefined;
};

/**
 * Returns the last time a patient got in bed.
 *
 * @param tile
 * @param bedOccupancy
 */
export const aisleepGetOccupancyTime = (
  resident: Resident,
  sensors: Sensor[],
  vitals: AisleepVitals | undefined,
  bedOccupancy: BedOccupancy[]
) => {
  const occupancy = bedOccupancy.find((x) => x.resident_ref === resident.snapshot.ref.path);

  if (occupancy) {
    // logger.debug("bed occupancy time", { resident: tile.resident.snapshot.ref.path, occupancy });
    return occupancy.in_bed_timestamp;
  }

  // for aisleep use combination vitals
  const isAisleep = sensors.find((s) => s.data.sensorType === SensorType.AISLEEP) !== undefined;
  if (isAisleep && vitals && vitals.vitals.data.aisleepUserDetection && aisleepIsInBed(vitals)) {
    const { inBedTime } = vitals.vitals.data.aisleepUserDetection;

    return new Date(inBedTime).toISOString();
  }

  return undefined;
};
