import Highcharts from 'highcharts';

const en: Highcharts.LangObject = {
  loading: 'Loading...',
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  resetZoom: 'Reset Zoom',
  thousandsSep: '.',
  decimalPoint: ',',
};

const ja: Highcharts.LangObject = {
  loading: '読込中...',
  months: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  shortMonths: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  resetZoom: 'Reset Zoom',
  thousandsSep: '.',
  decimalPoint: ',',
};

const zh: Highcharts.LangObject = {
  loading: '读取中...',
  shortMonths: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  months: [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月',
  ],
  weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  resetZoom: 'Reset Zoom',
  thousandsSep: '.',
  decimalPoint: ',',
};

const he: Highcharts.LangObject = {
  loading: 'טוען...',
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  resetZoom: 'Reset Zoom',
  thousandsSep: '.',
  decimalPoint: ',',
};

const pt: Highcharts.LangObject = {
  loading: 'Aguarde...',
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Abr',
    'Maio',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
  resetZoom: 'Reset',
  thousandsSep: '.',
  decimalPoint: ',',
};

export const setLanguage = (locale: string) => {
  const l = locale.toLowerCase();

  if (l.startsWith('pt')) {
    Highcharts.setOptions({ lang: pt });
  } else if (l.startsWith('ja')) {
    Highcharts.setOptions({ lang: ja });
  } else if (l.startsWith('zh')) {
    Highcharts.setOptions({ lang: zh });
  } else if (l.startsWith('he')) {
    Highcharts.setOptions({ lang: he });
  } else {
    Highcharts.setOptions({ lang: en });
  }
};
