import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';

import { MultipleSelectItem } from './types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(item: MultipleSelectItem, selected: readonly MultipleSelectItem[], theme: Theme) {
  return {
    fontWeight: selected.indexOf(item) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

interface Properties {
  label: string;
  items: MultipleSelectItem[];
  onChange: (selected: MultipleSelectItem[]) => void;
}

export default function MultipleSelectChip(props: Properties) {
  const theme = useTheme();
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    const values = typeof value === 'string' ? [value] : value;
    const items: MultipleSelectItem[] = [];

    values.forEach((v) => {
      const i = props.items.find((i: MultipleSelectItem) => i.id === v);
      if (i) {
        items.push(i);
      }
    });
    props.onChange(items);
  };
  const selected = props.items.filter((item: MultipleSelectItem) => item.selected);

  const getLabel = (id: string) => {
    const i = props.items.find((item: MultipleSelectItem) => item.id === id);
    if (i) {
      return i.label;
    }
    return id;
  };

  return (
    <FormControl sx={{ m: 0, width: '100%' }}>
      <InputLabel id="multiple-chip-label">{props.label}</InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={selected.map((s) => s.id)}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label={props.label} />}
        renderValue={(selectedIds) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selectedIds.map((id) => (
              <Chip key={id} label={getLabel(id)} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {props.items.map((item) => (
          <MenuItem key={item.id} value={item.id} style={getStyles(item, selected, theme)}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
