"use strict";
/* TS enum provides for type-safe enum property and a code/value
 * that is used for internal representation in the database.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRiskLevelPriority = exports.RiskLevelType = void 0;
var RiskLevelType;
(function (RiskLevelType) {
    RiskLevelType["HIGH"] = "HIGH";
    RiskLevelType["MEDIUM"] = "MEDIUM";
    RiskLevelType["LOW"] = "LOW";
    RiskLevelType["NONE"] = "NONE";
    RiskLevelType["CUSTOM"] = "CUSTOM";
    RiskLevelType["VERY_HIGH"] = "VERY_HIGH";
    RiskLevelType["VERY_LOW"] = "VERY_LOW";
})(RiskLevelType || (exports.RiskLevelType = RiskLevelType = {}));
const getRiskLevelPriority = (level) => {
    switch (level) {
        case RiskLevelType.NONE:
            return 0;
        case RiskLevelType.VERY_LOW:
            return 10;
        case RiskLevelType.LOW:
            return 20;
        case RiskLevelType.MEDIUM:
            return 30;
        case RiskLevelType.HIGH:
            return 40;
        case RiskLevelType.VERY_HIGH:
            return 50;
        case RiskLevelType.CUSTOM:
            return 60;
    }
};
exports.getRiskLevelPriority = getRiskLevelPriority;
exports.default = RiskLevelType;
