"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_TIMERANGE_ALWAYS = exports.DEFAULT_TIMERANGE_NIGHT = exports.DEFAULT_TIMERANGE_DAY = void 0;
// daytime default time range
exports.DEFAULT_TIMERANGE_DAY = {
    startHour: 6,
    startMinute: 0,
    endHour: 19,
    endMinute: 0,
};
// night time default time range
exports.DEFAULT_TIMERANGE_NIGHT = {
    startHour: 19,
    startMinute: 0,
    endHour: 6,
    endMinute: 0,
};
// 24hrs time range
exports.DEFAULT_TIMERANGE_ALWAYS = {
    startHour: 12,
    startMinute: 0,
    endHour: 12,
    endMinute: 0,
};
