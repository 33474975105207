import { createSvgIcon } from '@mui/material';

const TurnoverAlert = createSvgIcon(
  <path d="M9,11L19.016,11C21.226,11 23.016,12.79 23.016,15L23,22L21,22L21,19L3,19L3,22L1,22L1,7L3,7L3,16L9,16L9,11ZM8,13C8,11.89 7.11,11 6,11C4.89,11 4,11.89 4,13C4,14.11 4.89,15 6,15C7.11,15 8,14.11 8,13ZM10.125,2L10.125,6.193L7.5,6.193L10.875,9.567L14.25,6.193L11.625,6.193L11.625,2L10.125,2ZM18.125,2L18.125,6.193L15.5,6.193L18.875,9.567L22.25,6.193L19.625,6.193L19.625,2L18.125,2Z" />,
  'TurnoverAlert'
);

export { TurnoverAlert };

export default TurnoverAlert;
