import { createSvgIcon } from '@mui/material';

const NullValue = createSvgIcon(
  <g transform="matrix(1,0,0,1,0,-6.25624)">
    <path d="M24,17.255L24,19.258C24,19.814 23.548,20.266 22.991,20.266L1.009,20.266C0.452,20.266 0,19.814 0,19.258L0,17.255C0,16.698 0.452,16.246 1.009,16.246L22.991,16.246C23.548,16.246 24,16.698 24,17.255Z" />
  </g>,
  'NullValue'
);

export { NullValue };
export default NullValue;
