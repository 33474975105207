import attentiveConnectLogoImage from './attentiveconnect-logo.svg';
import attentiveConnectSymbolImage from './attentiveconnect-symbol.svg';
import sensingWaveLogoImage from './sensingwave2-logo.svg';
import sensingWaveSymbolImage from './sensingwave2-symbol.svg';
import biobeatLogoImage from './biobeat-logo.png';
import biobeatSymbolImage from './biobeat-symbol.png';
import bonxSymbolImage from './bonx-symbol.png';
import buddycomSymbolImage from './buddycom-symbol.png';
import domoSymbolImage from './domo-symbol.png';
import earlysenseLogoImage from './earlysense-logo.png';
import earlysenseSymbolImage from './earlysense-symbol.png';
import noomiLogoImage from './noomi-logo.png';
import noomiSymbolImage from './noomi-symbol.png';
import secomLogoImage from './secom-logo.png';
import unimatLogoImage from './unimat-logo.png';
import vitalerterLogoImage from './vitalerter-logo.png';
import vitalerterSymbolImage from './vitalerter-symbol.png';

const DEFAULT_WIDTH = 24;

interface CustomStyle {
  width?: number;
  height?: number;
  fill?: string;
}

// interface IconProps extends WithStyles<typeof iconStyles, true> {
interface IconProps {
  className?: string;
  badge?: React.ReactElement;
  icon?: React.ReactElement;
  width?: number;
  height?: number;
  fill?: string;
  color?: string;
}

////////////////////////////////////////////////
// AttentiveConnect
////////////////////////////////////////////////
export const AttentiveConnectLogo = (props: IconProps) => {
  const ASPECT_RATIO = 2372 / 856;
  const src = attentiveConnectLogoImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

export const AttentiveConnectSymbol = (props: IconProps) => {
  const ASPECT_RATIO = 1 / 1;
  // const ASPECT_RATIO = 856 / 672;
  const src = attentiveConnectSymbolImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// SensingWaveII
////////////////////////////////////////////////
export const SensingWaveLogo = (props: IconProps) => {
  const ASPECT_RATIO = 2400 / 621;
  const src = sensingWaveLogoImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

export const SensingWaveSymbol = (props: IconProps) => {
  const ASPECT_RATIO = 1 / 1;
  const src = sensingWaveSymbolImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// EarlySense
////////////////////////////////////////////////
export const EarlySenseLogo = (props: IconProps) => {
  const ASPECT_RATIO = 450 / 112;
  const src = earlysenseLogoImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

export const EarlySenseSymbol = (props: IconProps) => {
  const ASPECT_RATIO = 144 / 144;
  const src = earlysenseSymbolImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// Vitalerter
////////////////////////////////////////////////
export const VitalerterLogo = (props: IconProps) => {
  const ASPECT_RATIO = 199 / 150;
  const src = vitalerterLogoImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

export const VitalerterSymbol = (props: IconProps) => {
  const ASPECT_RATIO = 185 / 126;
  const src = vitalerterSymbolImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// Unimat
////////////////////////////////////////////////
export const UnimatLogo = (props: IconProps) => {
  const ASPECT_RATIO = 200 / 138;
  const src = unimatLogoImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// Secom
////////////////////////////////////////////////
export const SecomLogo = (props: IconProps) => {
  const ASPECT_RATIO = 420 / 166;
  const src = secomLogoImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// BioBeat
////////////////////////////////////////////////
export const BioBeatLogo = (props: IconProps) => {
  const ASPECT_RATIO = 240 / 153;
  const src = biobeatLogoImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

export const BioBeatSymbol = (props: IconProps) => {
  const ASPECT_RATIO = 524 / 423;
  const src = biobeatSymbolImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// Buddycom
////////////////////////////////////////////////
export const BuddycomSymbol = (props: IconProps) => {
  const ASPECT_RATIO = 1;
  const src = buddycomSymbolImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// Bonx
////////////////////////////////////////////////
export const BonxSymbol = (props: IconProps) => {
  const ASPECT_RATIO = 1;
  const src = bonxSymbolImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// Noomi
////////////////////////////////////////////////
export const NoomiLogo = (props: IconProps) => {
  const ASPECT_RATIO = 450 / 112;
  // TODO-NOOMI - need Noomi logo with imaga
  const src = noomiLogoImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

export const NoomiSymbol = (props: IconProps) => {
  const ASPECT_RATIO = 144 / 144;
  // TODO-NOOMI - need Noomi logo
  const src = noomiSymbolImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

////////////////////////////////////////////////
// DOMO
////////////////////////////////////////////////
export const DomoSymbol = (props: IconProps) => {
  const ASPECT_RATIO = 1;
  const src = domoSymbolImage;
  const { className } = props;
  const style: CustomStyle = getDimensions(props, ASPECT_RATIO);

  return <img alt={''} className={className} src={src} width={style.width} height={style.height} />;
};

const getDimensions = (props: IconProps, aspectRatio = 1) => {
  let width: number | undefined;
  let height: number | undefined;
  if (aspectRatio <= 0) {
    aspectRatio = 1;
  }

  if (props.width) {
    width = props.width;
    if (!props.height) {
      height = width / aspectRatio;
    }
  }

  if (props.height) {
    height = props.height;
    if (!props.width) {
      width = height * aspectRatio;
    }
  }

  if (!width) {
    width = DEFAULT_WIDTH;
  }

  if (!height) {
    height = width * aspectRatio;
  }

  return {
    width,
    height,
  };
};
