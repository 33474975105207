"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainService = void 0;
const attentive_connect_logger_1 = require("attentive-connect-logger");
const models_1 = require("../models");
const logger = (0, attentive_connect_logger_1.getLogger)('/services/DomainService');
class DomainService {
    constructor(db) {
        this.upgradeDomainData = (domain) => {
            // place holder for future upgrades
            return domain;
        };
        this.findDomainById = (id) => this.db
            .findById(models_1.Collection.domains, id)
            .then((domain) => (domain ? this.upgradeDomainData(domain) : domain));
        this.findDomainByRef = (ref) => this.db
            .findByRef(ref)
            .then((domain) => (domain ? this.upgradeDomainData(domain) : domain));
        this.loadDomains = (...conditions) => this.db
            .load(models_1.Collection.domains, null, conditions)
            .then((snapshots) => snapshots.map((s) => this.domain(s)));
        this.domain = (snapshot) => this.upgradeDomainData({
            snapshot,
            id: snapshot.id,
            data: this.db.data(snapshot),
        });
        this.refreshDomain = (domain) => __awaiter(this, void 0, void 0, function* () {
            const r = yield this.findDomainById(domain.snapshot.id);
            if (r) {
                return this.domain(r.snapshot);
            }
        });
        this.getAllDomains = () => __awaiter(this, void 0, void 0, function* () { return this.loadDomains(); });
        this.getDomainById = (id) => __awaiter(this, void 0, void 0, function* () { return id.length > 0 ? undefined : undefined; });
        this.createDomain = (domain, id) => __awaiter(this, void 0, void 0, function* () {
            const exists = yield this.findDomainById(id);
            if (exists) {
                throw new Error('domain already exists - cannot create');
            }
            logger.debug('create new domain', Object.assign({ id }, domain));
            const added = yield this.db.add(models_1.Collection.domains, domain, id);
            return added;
        });
        this.deleteDomain = (domain) => __awaiter(this, void 0, void 0, function* () {
            logger.debug('delete domain', domain);
            const centers = yield this.db.careCenters.getDomainCareCenters(domain);
            if (centers.length > 0) {
                throw new Error('domain is in use - cannot delete');
            }
            yield this.db.delete(domain);
        });
        this.hardDeleteDomain = (domain) => __awaiter(this, void 0, void 0, function* () {
            logger.debug('hard delete domain', domain);
            const centers = yield this.db.careCenters.getDomainCareCenters(domain);
            if (centers.length > 0) {
                throw new Error('domain is in use - cannot hard delete');
            }
            yield this.db.hardDelete(domain);
        });
        this.updateDomain = (domain) => __awaiter(this, void 0, void 0, function* () {
            logger.debug('update domain', domain);
            const updated = yield this.db.update(domain);
            return this.upgradeDomainData(updated);
        });
        this.db = db;
    }
}
exports.DomainService = DomainService;
/**
 * Don't change this. The value when domain is null or undefined is '0' and that
 * value is used in the database to represent the default domain.
 * @param domain the domain id if it exists
 * @returns
 */
// export const domainId = (domain: string | null | undefined) => domain || '0';
exports.default = DomainService;
