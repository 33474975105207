import * as React from 'react';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { UserPerson } from 'attentive-connect-store/dist/models';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import strings from '../languages';
import * as Icons from '../icons';
import UserLabel from './UserLabel';
import { CancelButton } from './buttons';
import { Dialog } from './dialogs';

interface Props {
  label?: string;
  allLabel?: string;
  className?: string;
  users: UserPerson[];
  open: boolean;
  onSelectionChanged: (user: UserPerson) => void;
  onCancel?: () => void;
}

type AllProps = Props & WrappedComponentProps;

interface State {
  user: UserPerson | null;
}

class UserSelectorDialog extends React.Component<AllProps, State> {
  readonly state: State = {
    user: null,
  };

  denull = (str: string | null) => {
    return str || '';
  };

  userSorter = (user1: UserPerson, user2: UserPerson) => {
    const localized = strings(this.props.intl);
    return (
      (user1.data.sortName ? user1.data.sortName : '') +
      localized.person.fullName(user1.data.firstName, user1.data.lastName)
    ).localeCompare(
      (user2.data.sortName ? user2.data.sortName : '') +
        localized.person.fullName(user2.data.firstName, user2.data.lastName)
    );
  };

  render() {
    const { users, open, onCancel } = this.props;
    const localized = strings(this.props.intl);
    let key = 1;

    const userList = users.sort(this.userSorter).map((user: UserPerson) => {
      const name = localized.person.fullName(user.data.firstName, user.data.lastName);
      const url = user.data.imageUrl ? user.data.imageUrl : null;

      return (
        <ListItem button onClick={() => this.onSelection(user)} key={key++}>
          <ListItemText>
            <UserLabel user={name} avatarUrl={url} />
          </ListItemText>
        </ListItem>
      );
    });

    return (
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle id="simple-dialog-title">
          <Icons.SwitchUser />
          <span>
            {onCancel === undefined ? localized.login.selectUser() : localized.user.changeUser()}
          </span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <List>{userList}</List>
        </DialogContent>
        {onCancel !== undefined ? (
          <DialogActions>
            <CancelButton onClick={onCancel} />
          </DialogActions>
        ) : null}
      </Dialog>
    );
  }

  onSelection = (user: UserPerson) => {
    this.props.onSelectionChanged(user);
  };
}

export default injectIntl(UserSelectorDialog);
