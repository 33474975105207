//
// Displays a dialog that AC client is offline.
//
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { Dialog, DialogContent, Typography } from '@mui/material';

import { Width } from '../device';
import strings from '../languages';
import { getLogger } from '../logger';
import * as app from '../redux';

const logger = getLogger('components/OfflineDialog');
logger.debug();

// global application state properties that should be passed to the component
type ApplicationStateProps = {
  // add your properties here...
  width?: Width;
};

const mapApplicationStateToProps = (state: app.ApplicationState): ApplicationStateProps => ({
  width: state.device.width,
});

// the component's properties
type Props = {
  className?: string;
  offline: boolean;
} & WrappedComponentProps &
  ApplicationStateProps;

const makeComponent = (component: (props: Props) => JSX.Element | null) =>
  connect(mapApplicationStateToProps)(injectIntl(component));

export default makeComponent((props: Props) => {
  const localized = strings(props.intl);

  logger.debug('log a message');

  return (
    <Dialog open={props.offline}>
      <DialogContent>
        <Typography>{localized.label.offline()}</Typography>
      </DialogContent>
    </Dialog>
  );
});
