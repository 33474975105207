import { WrappedComponentProps, injectIntl } from 'react-intl';

import { ButtonProps } from '@mui/material';
import Button from '@mui/material/Button';

import strings from '../../languages';

type Props = WrappedComponentProps &
  ButtonProps & {
    label?: string;
  };

export const SubmitButton = injectIntl((props: Props) => {
  const { intl, label, sx, ...rest } = props;
  const localized = strings(intl);

  return (
    <Button
      disabled={props.disabled}
      autoCapitalize={props.autoCapitalize}
      {...rest}
      color="success"
      variant="contained"
      sx={{ mr: 1.5, mt: 1, ...sx }}
    >
      {label ? label : localized.action.submit()}
    </Button>
  );
});
