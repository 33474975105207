import * as models from 'attentive-connect-store/dist/models';
import * as types from 'attentive-connect-store/dist/types';
import {
  aisleepIsAsleep,
  aisleepIsAwake,
  aisleepIsDozing,
  aisleepIsInBed,
  aisleepIsOutOfBed,
  aisleepTimeAsleep,
  aisleepTimeAwake,
  aisleepTimeOutOfBed,
} from './Aisleep';
import { getLogger } from '../logger';

const logger = getLogger('data/Vitals', 'info');

export enum InBedStatus {
  IN = 0,
  OUT,
  NA,
}

export enum VitalsReadingType {
  ANY = 'ANY',
  FALL = 'FALL',
  SITTING = 'SITTING',
  HEART_RATE = 'HEART',
  BREATHING_RATE = 'BREATHING',
  TURNOVER = 'TURNOVER',
  SPO2 = 'SPO2',
  BODY_TEMPERATURE = 'BODY_TEMPERATURE',
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
  CALORIES = 'CALORIES',
  CARDIAC_INDEX = 'CARDIAC_INDEX',
  CARDIAC_OUTPUT = 'CARDIAC_OUTPUT',
  HEART_RATE_VARIABILITY = 'HEART_RATE_VARIABILITY',
  MOVEMENT = 'MOVEMENT',
  OCCUPANCY = 'OCCUPANCY',
  PULSE_PRESSURE = 'PULSE_PRESSURE',
  SLEEP_STAGE = 'SLEEP_STAGE',
  STROKE_VOLUME = 'STROKE_VOLUME',
  SVR = 'SVR',
  SWEAT = 'SWEAT',
  WEIGHT = 'WEIGHT',
}

// export type VitalReading = (string | number)[];
// VitalReading[0] = timestamp
// VitalReading[1] = value
export type HeartRateReading = [number, number];
export type BreathingRateReading = [number, number];
export type WeightReading = [number, number];
export type BodyTempReading = [number, number];
export type BloodPressureReading = [number, number, number, string | undefined];
export type SleepStageReading = [number, string];
export type VitalsReading =
  | HeartRateReading
  | BreathingRateReading
  | BloodPressureReading
  | SleepStageReading
  | WeightReading
  | BodyTempReading;
export type VitalsReadingMap = Map<VitalsReadingType, VitalsReading[]>;

export const getPulseData = (vitals: VitalsReadingMap): HeartRateReading[] | undefined => {
  const data = vitals.get(VitalsReadingType.HEART_RATE);
  return data ? data.map((d) => d as HeartRateReading) : undefined;
};

export const getBreathingData = (vitals: VitalsReadingMap): BreathingRateReading[] | undefined => {
  const data = vitals.get(VitalsReadingType.BREATHING_RATE);
  return data ? data.map((d) => d as BreathingRateReading) : undefined;
};

export const getBloodPressureData = (
  vitals: VitalsReadingMap
): BloodPressureReading[] | undefined => {
  const data = vitals.get(VitalsReadingType.BLOOD_PRESSURE);
  return data ? data.map((d) => d as BloodPressureReading) : undefined;
};

export const getWeightData = (vitals: VitalsReadingMap): WeightReading[] | undefined => {
  const data = vitals.get(VitalsReadingType.WEIGHT);
  return data ? data.map((d) => d as WeightReading) : undefined;
};

export const getBodyTempData = (vitals: VitalsReadingMap): BodyTempReading[] | undefined => {
  const data = vitals.get(VitalsReadingType.BODY_TEMPERATURE);
  return data ? data.map((d) => d as BodyTempReading) : undefined;
};

/////////////////////////////////////////////////////////////////
// BioBeat
/////////////////////////////////////////////////////////////////
export interface BioBeatSensor {
  // TimeZoneName: string;
  Patch_ID: string;
}

export interface BioBeatVitals {
  sensorIsActive: boolean;
  sensorId: string;
  vitals: types.biobeat.Vitals | undefined;
  sensorStatus: models.SensorStatus;
}

export const biobeat = (
  sensorId: string,
  vitals: types.biobeat.Vitals | undefined,
  sensorStatus: models.SensorStatus
): BioBeatVitals => {
  return {
    sensorIsActive: isBioBeatSensorActive(vitals),
    sensorId,
    vitals,
    sensorStatus,
  };
};

export const isBioBeatSensorActive = (vitals: types.biobeat.Vitals | undefined) => {
  if (vitals) {
    return true;
  }
  return false;
};

/////////////////////////////////////////////////////////
// Aisleep
/////////////////////////////////////////////////////////
export type AisleepSensor = models.Sensor;

export interface AisleepVitals {
  sensor: AisleepSensor;
  sensorIsActive: boolean;
  // current sensor status - time dependent
  sensorStatus: () => models.SensorStatus;
  vitals: models.SensorVitals;
}

// type guard
export function isAisleepVitals(vitals: unknown): vitals is AisleepVitals {
  const _vitals = vitals as AisleepVitals;
  return (
    _vitals &&
    _vitals.sensor.data.sensorType === models.SensorType.AISLEEP &&
    _vitals.vitals.data.aisleep !== undefined &&
    _vitals.vitals.data.aisleep !== null
  );
}

export const aisleep = (
  sensor: AisleepSensor,
  vitals: models.SensorVitals,
  sensorStatus: () => models.SensorStatus
): AisleepVitals => {
  return {
    sensor,
    sensorIsActive: isAisleepSensorActive(sensor, vitals),
    vitals,
    sensorStatus,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isAisleepSensorActive = (sensor: AisleepSensor, vitals: models.SensorVitals) => {
  if (sensor.data.aisleep && sensor.data.aisleep.serialNumber) {
    return true;
  }
  return false;
};

/////////////////////////////////////////////////////////
// SensingWave
/////////////////////////////////////////////////////////
export type SensingWaveSensor = models.Sensor;

export interface SensingWaveVitals {
  sensor: SensingWaveSensor;
  sensorIsActive: boolean;
  // current sensor status - time dependent
  sensorStatus: () => models.SensorStatus;
  vitals: models.SensorVitals;
}

// type guard
export function isSensingWaveVitals(vitals: unknown): vitals is SensingWaveVitals {
  const _vitals = vitals as SensingWaveVitals;
  return (
    _vitals &&
    _vitals.sensor.data.sensorType === models.SensorType.SENSING_WAVE &&
    _vitals.vitals.data.sensingWave !== undefined &&
    _vitals.vitals.data.sensingWave !== null
  );
}

export const sensingWave = (
  sensor: SensingWaveSensor,
  vitals: models.SensorVitals,
  sensorStatus: () => models.SensorStatus
): SensingWaveVitals => {
  return {
    sensor,
    sensorIsActive: isSensingWaveSensorActive(sensor, vitals),
    vitals,
    sensorStatus,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isSensingWaveSensorActive = (
  sensor: SensingWaveSensor,
  vitals: models.SensorVitals
) => {
  logger.debug('isSensingWaveSensorActive', { sensor: sensor.id, vitals: vitals.data });
  if (sensor.data.sensingWave && sensor.data.sensingWave.serialNumber) {
    return true;
  }
  return false;
};

export const isInBed = (
  vitals: AisleepVitals | SensingWaveVitals | undefined
): boolean | undefined => {
  if (isAisleepVitals(vitals)) {
    return aisleepIsInBed(vitals);
  }
  return undefined;
};

export const isOutOfBed = (
  vitals: AisleepVitals | SensingWaveVitals | undefined
): boolean | undefined => {
  if (isAisleepVitals(vitals)) {
    return aisleepIsOutOfBed(vitals);
  }
  return undefined;
};

export const isAwake = (
  vitals: AisleepVitals | SensingWaveVitals | undefined
): boolean | undefined => {
  if (isAisleepVitals(vitals)) {
    return aisleepIsAwake(vitals);
  }
  return false;
};

export const isAsleep = (
  vitals: AisleepVitals | SensingWaveVitals | undefined
): boolean | undefined => {
  if (isAisleepVitals(vitals)) {
    return aisleepIsAsleep(vitals);
  }
  return false;
};

export const isDozing = (
  vitals: AisleepVitals | SensingWaveVitals | undefined
): boolean | undefined => {
  if (isAisleepVitals(vitals)) {
    return aisleepIsDozing(vitals);
  }
  return false;
};

export const timeAsleep = (
  vitals: AisleepVitals | SensingWaveVitals | undefined
): number | undefined => {
  if (isAisleepVitals(vitals)) {
    return aisleepTimeAsleep(vitals);
  }
  return undefined;
};

export const timeAwake = (
  vitals: AisleepVitals | SensingWaveVitals | undefined
): number | undefined => {
  if (isAisleepVitals(vitals)) {
    return aisleepTimeAwake(vitals);
  }
  return undefined;
};

export const timeOutOfBed = (
  vitals: AisleepVitals | SensingWaveVitals | undefined
): number | undefined => {
  if (isAisleepVitals(vitals)) {
    return aisleepTimeOutOfBed(vitals);
  }
  return undefined;
};

export interface ResidentVitals {
  time: Date;
  manual: boolean;
  sensorModel?: string;
  sensorManufacturer?: string;
  sensorRef?: string;
  deviceSerialNumber?: string;
  bodyTemp?: number;
  systolic?: number;
  diastolic?: number;
  meanArterialPressure?: number;
  heartRate?: number;
  respirationRate?: number;
  movementLevel?: number;
  heartRateVariability?: number;
  weight?: number;
}
