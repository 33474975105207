import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import * as Icons from '../icons';
import { Avatar, Button, Stack, SxProps, Theme, Typography } from '@mui/material';

interface Props {
  sx?: SxProps<Theme>;
  className?: string;
  user: string;
  subtitleAbove?: string;
  subtitleBelow?: string;
  avatarUrl?: string | null;
  avatarHide?: boolean;
  avatarLast?: boolean;
  key?: string | number;
  clickable?: boolean;
  color?:
    | 'inherit'
    | 'action'
    | 'disabled'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | null;
}

type AllProps = Props & WrappedComponentProps;

/**
 * Displays a lable with brief description of a caretaker.
 */
class UserLabel extends React.Component<AllProps> {
  render() {
    const { clickable, user, subtitleAbove, subtitleBelow, avatarLast, key, avatarHide, sx } =
      this.props;
    const label = (
      <Stack className="AC-infoLabel">
        {subtitleAbove ? <Typography variant="labelsubtitle">{subtitleAbove}</Typography> : null}
        <Typography variant="labeltitle">{user}</Typography>
        {subtitleBelow ? <Typography variant="labelsubtitle">{subtitleBelow}</Typography> : null}
      </Stack>
    );
    const layout = (
      <Stack className="AC-infoGroup" sx={{ ...sx }} direction="row" alignItems="center" key={key}>
        {!avatarHide && !avatarLast ? this.renderAvatar() : null}
        {label}
        {!avatarHide && avatarLast ? this.renderAvatar() : null}
      </Stack>
    );

    if (clickable) {
      return (
        <Button
          variant="outlined"
          onClick={this.onClick}
          sx={{ textAlign: 'left' }}
          endIcon={<Icons.Forward />}
        >
          {layout}
        </Button>
      );
    } else {
      return layout;
    }
  }

  renderAvatar = () => {
    const { avatarLast, avatarUrl, color } = this.props;
    const avatar: JSX.Element | null = avatarUrl ? (
      <Avatar src={avatarUrl} variant="rounded" className="AC-infoIcon" />
    ) : avatarLast ? null : (
      <Icons.User color={color ? color : 'disabled'} className="AC-infoIcon" />
    );

    if (avatar) {
      return avatar;
    } else {
      return null;
    }
  };

  onClick = () => {
    return null;
  };
}

export default injectIntl(UserLabel);
