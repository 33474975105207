import { Store as Database } from 'attentive-connect-store/dist';
import * as models from 'attentive-connect-store/dist/models';
import { AlertResolved } from 'attentive-connect-store/dist/models/AlertResolveType';
import AlertSettings from 'attentive-connect-store/dist/models/AlertSettings';
import AlertType from 'attentive-connect-store/dist/models/AlertType';
import Resident from 'attentive-connect-store/dist/models/Resident';
import RiskLevelType from 'attentive-connect-store/dist/models/RiskLevelType';
import SensorProperties from 'attentive-connect-store/dist/models/SensorProperties';
import * as types from 'attentive-connect-store/dist/types';
import { StringsIntl } from '../languages';
import { getLogger } from '../logger';
import * as Aisleep from './Aisleep';
import * as SensingWave from './SensingWave';
import * as Settings from './Settings';
import * as Vitals from './Vitals';
import { isVendorToppan } from '../Vendor';
const logger = getLogger('ui/data/Sensors');

export type InBedStatusType = 'in' | 'out' | 'out-warning' | 'na';
export type SittingStatusType = 'sitting' | 'not-sitting' | 'na';
export type SleepStatusType = 'asleep' | 'awake' | 'awake-warning' | 'na' | 'dozing';

export type AlertTypeToSensorPropsMap = Map<AlertType, SensorProperties>;

/**
 * Supported sensor types.
 */
export const AllSensorTypes: models.SensorType[] = [models.SensorType.BIOBEAT];

export const getSupportedSensors = (db: Database, center: models.CareCenter) => {
  const supported: models.SensorType[] = [];

  if (db.careCenters.supportsBioBeat(center)) {
    supported.push(models.SensorType.BIOBEAT);
  }
  if (db.careCenters.supportsAisleep(center.data)) {
    supported.push(models.SensorType.AISLEEP);
  }
  if (db.careCenters.supportsSensingWave(center.data)) {
    supported.push(models.SensorType.SENSING_WAVE);
  }

  return supported;
};

export const getSensorLabel = (type: models.SensorType, l10n: StringsIntl) => {
  switch (type) {
    case models.SensorType.AISLEEP:
      return isVendorToppan() ? l10n.sensingWave.sensingWave2() : l10n.aisleep.aisleep();
    case models.SensorType.SENSING_WAVE:
      return l10n.sensingWave.sensingWave();
    case models.SensorType.BIOBEAT:
      return l10n.biobeat.biobeat();
    default:
      return '';
  }
};

export const sortSensors = (a: models.SensorType, b: models.SensorType, l10n: StringsIntl) =>
  getSensorLabel(a, l10n).localeCompare(getSensorLabel(b, l10n));

export const isAssigned = (sensor: models.Sensor) => (sensor.data.residentRef ? true : false);

export interface AlertSettingsViewData {
  resident: models.Resident;
  settings: AlertTypeToSensorPropsMap;
  sensors: models.Sensor[];
  alertSettings: models.SensorAlertSettings;
}

export interface ResidentViewData {
  resident: models.Resident;
  sensors: models.Sensor[];
}

export interface SensorDeviceData {
  sensor: models.Sensor;
  resident?: models.Resident;
  version?: models.AiSleepVersion;
}

export interface SensorStateData {
  sensor: models.Sensor;
  sensorState: models.SensorState;
}

export const toSensorDeviceData = (
  sensor: models.Sensor,
  resident?: models.Resident
): SensorDeviceData => {
  return { sensor, resident };
};

export const toSensorDeviceDataList = (sensors: models.Sensor[], residents: models.Resident[]) => {
  const data: SensorDeviceData[] = [];

  sensors.forEach((sensor) => {
    const resident = residents.find((r) => r.snapshot.ref.path === sensor.data.residentRef);
    data.push({ sensor, resident });
  });

  return data;
};

export const toResidentViewDataList = (sensors: models.Sensor[], residents: models.Resident[]) => {
  const residentData: ResidentViewData[] = [];

  residents.forEach((resident) => {
    const sensorView = toResidentViewData(resident, sensors);
    // only include rows with sensors
    residentData.push(sensorView);
  });

  return residentData;
};

export const toResidentViewData = (resident: models.Resident, sensors: models.Sensor[]) => {
  const data: ResidentViewData = {
    resident,
    sensors: sensors.filter((s) => s.data.residentRef === resident.snapshot.ref.path),
  };

  return data;
};

/**
 * Constructs alert settings view data for multiple residents.
 * @param db DB connection
 * @param sensors the sensors to include.
 * @param residents the residents.
 */
export const getAlertSettingsViewDataList = (
  db: Database,
  sensors: models.Sensor[],
  residents: models.Resident[]
) => {
  const log = getLogger('ui/data/Sensors->getAlertSettingsViewDataList()');
  return Promise.all(
    residents.map((resident) => {
      const residentsSensors = sensors.filter(
        (s) => s.data.residentRef === resident.snapshot.ref.path
      );
      // query for each resident
      return getAlertSettingsViewData(db, resident, residentsSensors);
    })
  ).then((viewData) => {
    const alertSettingsViewData: AlertSettingsViewData[] = [];

    viewData.forEach((s) => {
      if (log.isDebugEnabled()) {
        s.forEach((x) =>
          log.debug({
            resident: x.resident.data,
          })
        );
      }
      alertSettingsViewData.push(...s);
    });

    return alertSettingsViewData;
  });
};

/**
 * Returns alert settings that are controlled in the sensor vendor's cloud.
 * These settings are managed in the 'sensor_alert_settings' collection.
 *
 * Vendors that manage their own alert settings:
 *  - NOOMI
 */
export const getAlertSettingsViewData = (
  db: Database,
  resident: models.Resident,
  sensors: models.Sensor[]
): Promise<AlertSettingsViewData[]> => {
  const log = getLogger('ui/data/Sensors.getAlertSettingsViewData');

  // RETURN
  return loadAlertSettings(db, resident, sensors).then((alertSettings) => {
    // window.console.log("sensorSettings", sensorSettings);
    const viewData: AlertSettingsViewData[] = [];
    const { sensorAlertSettings } = alertSettings;

    if (isAisleep(sensors)) {
      // AISLEEP
      if (log.isDebugEnabled()) {
        log.debug({
          aisleepAlertSettings: sensorAlertSettings.data.aisleep,
        });
      }
      const data: AlertSettingsViewData = {
        resident,
        settings: new Map<AlertType, SensorProperties>(),
        // same settings for all aisleep sensors...
        sensors: sensors.filter((s) => s.data.sensorType === models.SensorType.AISLEEP),
        alertSettings: sensorAlertSettings,
      };
      viewData.push(data);
    }

    if (isSensingWave(sensors)) {
      // SensingWave
      if (log.isDebugEnabled()) {
        log.debug({
          sensingWaveAlertSettings: sensorAlertSettings.data.sensingWave,
        });
      }
      const data: AlertSettingsViewData = {
        resident,
        settings: new Map<AlertType, SensorProperties>(),
        // same settings for all sensingWave sensors...
        sensors: sensors.filter((s) => s.data.sensorType === models.SensorType.SENSING_WAVE),
        alertSettings: sensorAlertSettings,
      };
      viewData.push(data);
    }

    // log.debug(viewData);
    return viewData;
  });
};

/**
 * gets all sensor settings, both those managed by AC and those managed in vendor systems.
 *
 * @param db
 * @param resident
 */
const loadAlertSettings = (db: Database, resident: Resident, sensors: models.Sensor[]) => {
  const sensorPropMaps: AlertTypeToSensorPropsMap[] = [];
  const sensorAlertSettings: models.SensorAlertSettings[] = [];
  return db.sensorAlertSettings
    .getByResident(resident)
    .then((settings) => sensorAlertSettings.push(settings))
    .then(() => getAttentiveAlertTypeToSensorPropsMap(db, resident, sensors))
    .then((settings) => sensorPropMaps.push(...settings))
    .then(() => {
      if (logger.isDebugEnabled()) {
        sensorPropMaps.forEach((s) => {
          s.forEach((x) => {
            logger.debug('getSensorAlertSettings().sensorSettings', {
              alertType: x.alert,
              attentive: x.attentive,
            });
          });
        });
        logger.debug('getSensorAlertSettings().alertSettings', {
          alertSettings: sensorAlertSettings[0].data,
        });
      }
      return { sensorPropMaps, sensorAlertSettings: sensorAlertSettings[0] };
    });
};

/**
 * Gets alert settings that are used by AC to trigger alerts.
 *
 * @param db
 * @param resident the resident
 * @param sensors the set of sensors for which to retrieve settings.
 */
const getAttentiveAlertTypeToSensorPropsMap = (
  db: Database,
  resident: Resident,
  sensors: models.Sensor[]
): Promise<AlertTypeToSensorPropsMap[]> => {
  const propsMappings: AlertTypeToSensorPropsMap[] = [];
  const log = getLogger('ui/data/Sensors->getAttantiveAlertTypeToSensorPropsMap()');

  if (Settings.disableAlerts()) {
    log.debug('AC generated alerts are disabled - skipping');
    // RETURN
    return Promise.resolve(propsMappings);
  }
  // RETURN
  return ensureAttentiveSensorAlertSettings(db, resident, sensors).then((settings) => {
    if (log.isDebugEnabled()) {
      log.debug({
        resident: (({ firstName, lastName }) => ({ firstName, lastName }))(resident.data),
        sensors: sensors.map((s) => {
          return {
            ref: s.snapshot.ref.path,
            biobeat: s.data.biobeat,
          };
        }),
        settings: settings.map((s) =>
          (({ sensorRef, fall, hr, rr }) => ({ sensorRef, fall, hr, rr }))(s.data)
        ),
      });
    }
    settings.forEach((setting) => {
      const sas: AlertTypeToSensorPropsMap = new Map<AlertType, SensorProperties>();
      const sensor = sensors.find((s) => s.snapshot.ref.path === setting.data.sensorRef);

      if (sensor) {
        if (setting.data.hr) {
          const props: SensorProperties = {
            sensors: [sensor],
            risk: setting.data.hr.riskLevel,
            alert: AlertType.HEART,
            attentive: setting.data,
          };
          sas.set(props.alert, props);
        } else {
          const props: SensorProperties = {
            sensors: [sensor],
            risk: RiskLevelType.NONE, // default
            alert: AlertType.HEART,
          };
          sas.set(props.alert, props);
        }

        if (setting.data.rr) {
          const props: SensorProperties = {
            sensors: [sensor],
            risk: setting.data.rr.riskLevel,
            attentive: setting.data,
            alert: AlertType.BREATHING,
          };
          sas.set(props.alert, props);
        } else {
          const props: SensorProperties = {
            sensors: [sensor],
            risk: RiskLevelType.NONE,
            alert: AlertType.BREATHING,
          };
          sas.set(props.alert, props);
        }

        if (setting.data.fall) {
          const props: SensorProperties = {
            sensors: [sensor],
            risk: setting.data.fall.riskLevel,
            attentive: setting.data,
            alert: AlertType.FALL,
          };
          sas.set(props.alert, props);
        } else {
          const props: SensorProperties = {
            sensors: [sensor],
            risk: RiskLevelType.NONE,
            alert: AlertType.FALL,
          };
          sas.set(props.alert, props);
        }

        // Add future alert types...

        propsMappings.push(sas);
      }
    });
    // window.console.log("attentive sensor alert settings", sensorSettings);
    if (logger.isDebugEnabled()) {
      propsMappings.forEach((s) => {
        s.forEach((x) => {
          log.debug('AC generated alert settings', {
            alertType: x.alert,
            attentive: x.attentive,
          });
        });
      });
    }
    return propsMappings;
  });
};

/**
 * Retruns the AC sensor settings for the listed sensors. If a sensor doesn't
 * have settings, then they are created in the DB.
 *
 * @param db
 * @param resident
 * @param sensors the sensors to ensure
 */
const ensureAttentiveSensorAlertSettings = (
  db: Database,
  resident: Resident,
  sensors: models.Sensor[]
): Promise<AlertSettings[]> => {
  // Ensure settings exists for sensors that are being tracked for alerts by AC.
  return Promise.all(
    sensors
      .filter((s) => {
        switch (s.data.sensorType) {
          // currently only support ES
          default:
            return false;
        }
      })
      .map((s) => {
        logger.debug('ensureAttentiveSensorAlertSettings', {
          type: s.data.sensorType,
          ref: s.snapshot.ref.path,
        });
        return db.alertSettings.getBySensor(s).then((existing) => {
          if (existing.length > 0) {
            logger.debug('exists AC alert_settings');
            return Promise.resolve(existing[0]);
          } else {
            logger.debug('create new AC alert_settings');
            return db.alertSettings.add({
              sensorRef: s.snapshot.ref.path,
              hr: null,
              rr: null,
              fall: null,
            });
          }
        });
      })
  );
};

export interface AlertResolutionAction {
  resolution: AlertResolved;
  display: {
    resolution: string;
    detail: string | null;
  };
}

/**
 * returns all the alert types that are supported for the listed sensors.
 *
 * @param sensors the sensors to check
 */
export const getSupportedAlertTypes = (sensors: models.Sensor[]): AlertType[] => {
  const types: AlertType[] = [];
  sensors.forEach((s) => {
    switch (s.data.sensorType) {
      case models.SensorType.BIOBEAT:
        break;
      case models.SensorType.AISLEEP:
        if (!types.find((x) => x === AlertType.HEART)) {
          types.push(AlertType.HEART);
        }
        if (!types.find((x) => x === AlertType.BREATHING)) {
          types.push(AlertType.BREATHING);
        }
        if (!types.find((x) => x === AlertType.FALL)) {
          types.push(AlertType.FALL);
        }
        if (!types.find((x) => x === AlertType.MOVEMENT)) {
          types.push(AlertType.MOVEMENT);
        }
        break;
      case models.SensorType.SENSING_WAVE:
        if (!types.find((x) => x === AlertType.HEART)) {
          types.push(AlertType.HEART);
        }
        if (!types.find((x) => x === AlertType.BREATHING)) {
          types.push(AlertType.BREATHING);
        }
        if (!types.find((x) => x === AlertType.FALL)) {
          types.push(AlertType.FALL);
        }
        if (!types.find((x) => x === AlertType.MOVEMENT)) {
          types.push(AlertType.MOVEMENT);
        }
        break;
    }
  });

  return types;
};

export const getRiskLevelFromAlertTypeToSensorPropsMap = (
  settings: AlertTypeToSensorPropsMap,
  alertType: AlertType
) => {
  const sensorProperties = settings.get(alertType);

  let riskLevel = RiskLevelType.NONE;
  if (sensorProperties) {
    riskLevel = sensorProperties.risk;
  }

  return riskLevel;
};

export const getRiskLevel = (
  viewData: AlertSettingsViewData,
  alertType: AlertType
): RiskLevelType => {
  const riskLevel = getRiskLevelFromAlertTypeToSensorPropsMap(viewData.settings, alertType);

  if (isAisleep(viewData.sensors) && viewData.alertSettings.data.aisleep) {
    if (
      (alertType === AlertType.FALL && viewData.alertSettings.data.aisleep.processFallAlerts) ||
      (alertType === AlertType.HEART && viewData.alertSettings.data.aisleep.processHeartAlerts) ||
      (alertType === AlertType.BREATHING &&
        viewData.alertSettings.data.aisleep.processRespirationAlerts)
    ) {
      return RiskLevelType.CUSTOM;
    }
  } else if (isSensingWave(viewData.sensors) && viewData.alertSettings.data.sensingWave) {
    if (
      (alertType === AlertType.FALL && viewData.alertSettings.data.sensingWave.processFallAlerts) ||
      (alertType === AlertType.HEART &&
        viewData.alertSettings.data.sensingWave.processHeartAlerts) ||
      (alertType === AlertType.BREATHING &&
        viewData.alertSettings.data.sensingWave.processRespirationAlerts)
    ) {
      return RiskLevelType.CUSTOM;
    }
  }

  return riskLevel;
};

/**
 * TRUE if list of sensor contains BIOBEAT sensors.
 *
 * @param sensor
 */
export const isBioBeat = (sensors: models.Sensor[]) => {
  let is = false;
  sensors.forEach((s) => {
    if (s.data.sensorType === models.SensorType.BIOBEAT) {
      is = true;
    }
  });

  return is;
};

/**
 * TRUE if list of sensor contains AISLEEP sensors.
 *
 * @param sensor
 */
export const isAisleep = (sensors: models.Sensor[]) => {
  let is = false;
  sensors.forEach((s) => {
    if (s.data.sensorType === models.SensorType.AISLEEP) {
      is = true;
    }
  });

  return is;
};

/**
 * TRUE if list of sensor contains SENSING_WAVE sensors.
 *
 * @param sensor
 */
export const isSensingWave = (sensors: models.Sensor[]) => {
  let is = false;
  sensors.forEach((s) => {
    if (s.data.sensorType === models.SensorType.SENSING_WAVE) {
      is = true;
    }
  });

  return is;
};

export const bioBeatSensorsAreSame = (
  sensorListA: models.Sensor[],
  sensorListB: models.Sensor[]
) => {
  const bioBeatListA = sensorListA
    .filter((s) => s.data.biobeat !== null && s.data.biobeat !== undefined)
    .sort((a, b) => {
      if (a.data.biobeat && b.data.biobeat) {
        return a.data.biobeat.Patch_ID.localeCompare(b.data.biobeat.Patch_ID);
      }
      return 0;
    });
  const bioBeatListB = sensorListB
    .filter((s) => s.data.biobeat !== null && s.data.biobeat !== undefined)
    .sort((a, b) => {
      if (a.data.biobeat && b.data.biobeat) {
        return a.data.biobeat.Patch_ID.localeCompare(b.data.biobeat.Patch_ID);
      }
      return 0;
    });

  if (bioBeatListA.length === bioBeatListB.length) {
    let same = true;
    bioBeatListA.forEach((a, idx) => {
      const b = bioBeatListB[idx];
      same =
        same &&
        a.data.biobeat !== null &&
        b.data.biobeat !== null &&
        a.data.biobeat.Patch_ID === b.data.biobeat.Patch_ID;
    });
    return same;
  }
  return false;
};

export const aisleepSensorsAreSame = (
  sensorListA: models.Sensor[],
  sensorListB: models.Sensor[]
) => {
  const listA = sensorListA
    .filter(
      (s) =>
        s.data.aisleep !== null &&
        s.data.aisleep !== undefined &&
        s.data.sensorType === models.SensorType.AISLEEP
    )
    .sort((a, b) => {
      if (
        a.data.aisleep &&
        b.data.aisleep &&
        a.data.aisleep.serialNumber &&
        b.data.aisleep.serialNumber
      ) {
        return a.data.aisleep.serialNumber.localeCompare(b.data.aisleep.serialNumber);
      }
      return 0;
    });
  const listB = sensorListB
    .filter(
      (s) =>
        s.data.aisleep !== null &&
        s.data.aisleep !== undefined &&
        s.data.sensorType === models.SensorType.AISLEEP
    )
    .sort((a, b) => {
      if (
        a.data.aisleep &&
        b.data.aisleep &&
        a.data.aisleep.serialNumber &&
        b.data.aisleep.serialNumber
      ) {
        return a.data.aisleep.serialNumber.localeCompare(b.data.aisleep.serialNumber);
      }
      return 0;
    });

  if (listA.length === listB.length) {
    let same = true;
    listA.forEach((a, idx) => {
      const b = listB[idx];
      same =
        same &&
        a.data.aisleep !== null &&
        a.data.aisleep.serialNumber !== undefined &&
        b.data.aisleep !== null &&
        b.data.aisleep.serialNumber !== undefined &&
        a.data.aisleep.serialNumber.localeCompare(b.data.aisleep.serialNumber) === 0;
    });
    return same;
  }
  return false;
};

export const sensingWaveSensorsAreSame = (
  sensorListA: models.Sensor[],
  sensorListB: models.Sensor[]
) => {
  const listA = sensorListA
    .filter(
      (s) =>
        s.data.sensingWave !== null &&
        s.data.sensingWave !== undefined &&
        s.data.sensorType === models.SensorType.SENSING_WAVE
    )
    .sort((a, b) => {
      if (
        a.data.sensingWave &&
        b.data.sensingWave &&
        a.data.sensingWave.serialNumber &&
        b.data.sensingWave.serialNumber
      ) {
        return a.data.sensingWave.serialNumber.localeCompare(b.data.sensingWave.serialNumber);
      }
      return 0;
    });
  const listB = sensorListB
    .filter(
      (s) =>
        s.data.sensingWave !== null &&
        s.data.sensingWave !== undefined &&
        s.data.sensorType === models.SensorType.SENSING_WAVE
    )
    .sort((a, b) => {
      if (
        a.data.sensingWave &&
        b.data.sensingWave &&
        a.data.sensingWave.serialNumber &&
        b.data.sensingWave.serialNumber
      ) {
        return a.data.sensingWave.serialNumber.localeCompare(b.data.sensingWave.serialNumber);
      }
      return 0;
    });

  if (listA.length === listB.length) {
    let same = true;
    listA.forEach((a, idx) => {
      const b = listB[idx];
      same =
        same &&
        a.data.sensingWave !== null &&
        a.data.sensingWave.serialNumber !== undefined &&
        b.data.sensingWave !== null &&
        b.data.sensingWave.serialNumber !== undefined &&
        a.data.sensingWave.serialNumber.localeCompare(b.data.sensingWave.serialNumber) === 0;
    });
    return same;
  }
  return false;
};

// Resets sensor state polling in redux.
export const loadSensorsForPolling = (db?: Database, center?: models.CareCenter) => {
  if (db && center) {
    return db.sensors
      .getCareCenterSensors(center)
      .then((sensors) =>
        Promise.all(
          sensors.map((s: models.Sensor) =>
            db.sensorState.findBySensorRef(s.snapshot.ref.path).then((sensorState) => {
              if (sensorState) {
                return { sensor: s, sensorState } as SensorStateData;
              } else {
                return null;
              }
            })
          )
        )
      )
      .then((sensors) => {
        const poll: SensorStateData[] = [];
        sensors.forEach((s) => {
          if (s !== null) {
            poll.push(s);
          }
        });
        return poll;
      });
  } else {
    return Promise.resolve([]);
  }
};

export const aisleepSleepStatus = (
  db: Database,
  center: models.CareCenter,
  resident: models.Resident,
  sensors: models.Sensor[],
  vitals: Vitals.AisleepVitals | undefined,
  bedOccupancy: models.BedOccupancy[]
): SleepStatusType => {
  let status: SleepStatusType = 'na';

  // aisleep
  if (vitals && vitals.vitals) {
    const sensorStatus = vitals.sensorStatus();
    const inBed = aisleepInBedStatus(db, center, resident, sensors, vitals, bedOccupancy);
    if (inBed === 'out' || inBed === 'out-warning' || sensorStatus !== 'nok') {
      switch (Aisleep.aisleepSleepStatus(db, vitals)) {
        case 'awake':
          if (
            db.careCenters.showWarnings(
              center,
              sensors,
              Aisleep.aisleepGetOccupancyTime(resident, sensors, vitals, bedOccupancy)
            )
          ) {
            status = 'awake-warning';
          } else {
            status = 'awake';
          }
          break;
        case 'asleep':
          status = 'asleep';
          break;
        case 'dozing':
          status = 'dozing';
          break;
        default:
          status = 'na';
      }
    } else {
      status = 'na';
    }
  }

  if (logger.isDebugEnabled()) {
    logger.debug('sleepStatus: ', {
      resident: resident.id,
      occupancyTime: Aisleep.aisleepGetOccupancyTime(resident, sensors, vitals, bedOccupancy),
      status,
    });
  }

  return status;
};

export const sensingWaveSleepStatus = (
  db: Database,
  center: models.CareCenter,
  resident: models.Resident,
  sensors: models.Sensor[],
  vitals: Vitals.SensingWaveVitals | undefined,
  bedOccupancy: models.BedOccupancy[]
): SleepStatusType => {
  let status: SleepStatusType = 'na';

  // sensingWave
  if (vitals && vitals.vitals) {
    const sensorStatus = vitals.sensorStatus();
    const inBed = sensingWaveInBedStatus(db, center, resident, sensors, vitals, bedOccupancy);
    if (inBed === 'out' || inBed === 'out-warning' || sensorStatus !== 'nok') {
      switch (SensingWave.sleepStatus(db, vitals)) {
        case 'awake':
          if (
            db.careCenters.showWarnings(
              center,
              sensors,
              getSensingWaveOccupancyTime(resident, sensors, vitals, bedOccupancy)
            )
          ) {
            status = 'awake-warning';
          } else {
            status = 'awake';
          }
          break;
        case 'asleep':
          status = 'asleep';
          break;
        default:
          status = 'na';
      }
    } else {
      status = 'na';
    }
  }

  if (logger.isDebugEnabled()) {
    logger.debug('sleepStatus: ', {
      resident: resident.id,
      occupancyTime: getSensingWaveOccupancyTime(resident, sensors, vitals, bedOccupancy),
      status,
    });
  }

  return status;
};

export const aisleepInBedStatus = (
  db: Database,
  center: models.CareCenter,
  resident: models.Resident,
  sensors: models.Sensor[],
  vitals: Vitals.AisleepVitals | undefined,
  bedOccupancy: models.BedOccupancy[]
): InBedStatusType => {
  let status: InBedStatusType = 'na';

  if (vitals && vitals.vitals) {
    const inBed = Aisleep.aisleepInBedStatus(vitals);
    if (inBed === 'in') {
      status = 'in';
    } else if (inBed === 'out') {
      const showWarnings = db.careCenters.showWarnings(
        center,
        sensors,
        Aisleep.aisleepGetOccupancyTime(resident, sensors, vitals, bedOccupancy)
      );
      if (showWarnings) {
        status = 'out-warning';
      } else {
        status = 'out';
      }
    } else {
      status = 'na';
    }
  }

  logger.debug('aisleepInBedStatus', {
    vitals: vitals && vitals.vitals ? vitals.vitals.data.aisleep : undefined,
    status,
  });
  return status;
};

export const sensingWaveInBedStatus = (
  db: Database,
  center: models.CareCenter,
  resident: models.Resident,
  sensors: models.Sensor[],
  vitals: Vitals.SensingWaveVitals | undefined,
  bedOccupancy: models.BedOccupancy[]
): InBedStatusType => {
  let status: InBedStatusType = 'na';

  if (vitals && vitals.vitals) {
    const inBed = SensingWave.inBedStatus(vitals);
    if (inBed === 'in') {
      status = 'in';
    } else if (inBed === 'out') {
      const showWarnings = db.careCenters.showWarnings(
        center,
        sensors,
        getSensingWaveOccupancyTime(resident, sensors, vitals, bedOccupancy)
      );
      if (showWarnings) {
        status = 'out-warning';
      } else {
        status = 'out';
      }
    } else {
      status = 'na';
    }
  }

  logger.debug('sensingWaveInBedStatus', {
    vitals: vitals && vitals.vitals ? vitals.vitals.data.sensingWave : undefined,
    status,
  });
  return status;
};

/**
 * Finds the first BioBeat watch that contains vitals data, and returns the vitals.
 * This is the data that should be displayed in the dashboard.
 *
 * @param resident
 * @param sensors
 * @param bioBeatVitals
 * @param localized
 */
export const getBioBeatVitals = (
  resident: models.Resident,
  sensors: models.Sensor[],
  bioBeatVitals: Vitals.BioBeatVitals[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  localized: StringsIntl
) => {
  const bioBeatSensors = sensors.filter(
    (s) =>
      s.data.biobeat &&
      s.data.residentRef &&
      s.data.residentRef.indexOf(resident.snapshot.ref.path) >= 0
  );
  const vitals = bioBeatVitals.find((v) => {
    if (
      bioBeatSensors.find(
        (s) =>
          v.vitals !== undefined &&
          v.sensorIsActive &&
          s.data.biobeat !== null &&
          v.sensorId === s.data.biobeat.Patch_ID
      )
    ) {
      return true;
    }
    return false;
  });

  return vitals;
};

/**
 * Finds the aisleep vitals linked to a resident.
 * This is the data that should be displayed in the dashboard.
 *
 * @param resident
 * @param sensors
 * @param aisleepVitals
 * @param localized
 */
export const getAisleepVitals = (
  resident: models.Resident,
  sensors: models.Sensor[],
  aisleepVitals: Vitals.AisleepVitals[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  localized: StringsIntl
) => {
  const residentRef = resident.snapshot.ref.path;
  const aisleepSensors = sensors.filter((s) => s.data.residentRef === residentRef);
  const sensorVitals = aisleepVitals.filter(
    (v) => aisleepSensors.find((s) => s.id === v.sensor.id) !== undefined
  );
  const vitals = sensorVitals.find((v) => {
    if (
      aisleepSensors.find((s) => {
        const inBed = Aisleep.aisleepInBedStatus(v);
        if (logger.isDebugEnabled()) {
          logger.debug('getAisleepVitals', { inBed, sensorStatus: v.sensorStatus() });
        }
        return (
          v.sensor.data.aisleep !== null &&
          s.data.aisleep !== null &&
          // if out of bed sensor will stop sending data
          (v.sensorStatus() === 'ok' || v.sensorStatus() === 'limited-data' || inBed === 'out')
        );
      })
    ) {
      return true;
    }
    return false;
  });

  logger.debug('getAisleepVitals', {
    resident: resident.snapshot.ref.path,
    vitals: vitals ? vitals.vitals.data : undefined,
  });

  return vitals;
};

/**
 * Finds the sensingWave vitals linked to a resident.
 * This is the data that should be displayed in the dashboard.
 *
 * @param resident
 * @param sensors
 * @param sensingWaveVitals
 * @param localized
 */
export const getSensingWaveVitals = (
  resident: models.Resident,
  sensors: models.Sensor[],
  sensingWaveVitals: Vitals.SensingWaveVitals[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  localized: StringsIntl
) => {
  const residentRef = resident.snapshot.ref.path;
  const sensingWaveSensors = sensors.filter((s) => s.data.residentRef === residentRef);
  const sensorVitals = sensingWaveVitals.filter(
    (v) => sensingWaveSensors.find((s) => s.id === v.sensor.id) !== undefined
  );
  const vitals = sensorVitals.find((v) => {
    if (
      sensingWaveSensors.find((s) => {
        const inBed = SensingWave.inBedStatus(v);
        if (logger.isDebugEnabled()) {
          logger.debug('getSensingWaveVitals', { inBed, sensorStatus: v.sensorStatus() });
        }
        return (
          v.sensor.data.sensingWave !== null &&
          s.data.sensingWave !== null &&
          // if out of bed sensor will stop sending data
          (v.sensorStatus() === 'ok' || v.sensorStatus() === 'limited-data' || inBed === 'out')
        );
      })
    ) {
      return true;
    }
    return false;
  });

  logger.debug('getSensingWaveVitals', {
    resident: resident.snapshot.ref.path,
    vitals: vitals ? vitals.vitals.data : undefined,
  });

  return vitals;
};

/**
 * Finds the BLE vitals linked to a resident.
 * This is the data that should be displayed in the dashboard.
 *
 * @param resident
 * @param bleVitals
 * @param localized
 */
export const getBleVitals = (
  resident: models.Resident,
  bleVitals: Record<string, types.acApi.Vitals[]>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  localized: StringsIntl
) => {
  return bleVitals[resident.snapshot.ref.path];
};

/**
 * Returns the last time a patient got in bed.
 *
 * @param tile
 * @param bedOccupancy
 */
const getSensingWaveOccupancyTime = (
  resident: models.Resident,
  sensors: models.Sensor[],
  vitals: Vitals.SensingWaveVitals | undefined,
  bedOccupancy: models.BedOccupancy[]
) => {
  const occupancy = bedOccupancy.find((x) => x.resident_ref === resident.snapshot.ref.path);

  if (occupancy) {
    // logger.debug("bed occupancy time", { resident: tile.resident.snapshot.ref.path, occupancy });
    return occupancy.in_bed_timestamp;
  }

  // for sensingWave use combination vitals
  const isSensingWave =
    sensors.find((s) => s.data.sensorType === models.SensorType.SENSING_WAVE) !== undefined;
  if (
    isSensingWave &&
    vitals &&
    vitals.vitals.data.sensingWaveUserDetection &&
    SensingWave.isInBed(vitals)
  ) {
    const { inBedTime } = vitals.vitals.data.sensingWaveUserDetection;

    return new Date(inBedTime).toISOString();
  }

  return undefined;
};
