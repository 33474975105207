/* eslint-disable @typescript-eslint/no-explicit-any */
import { GlobalConfig } from 'attentive-connect-store/dist/config/Environment';
import { isHybrid } from './hybrid';

import { getLogger } from './logger';

const logger = getLogger('Deployment', 'info');

interface GlobalConfigWindow extends Window {
  ac?: GlobalConfig;
}

// Global config settings that are stored in 'window.ac'.
// window.ac is initialized in index.html
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const ac = (window as GlobalConfigWindow).ac
  ? ((window as GlobalConfigWindow).ac as GlobalConfig)
  : ({ pathname: '/' } as GlobalConfig);
logger.debug('window.ac', ac);

if (!ac.pathname.endsWith('/')) {
  ac.pathname = ac.pathname + '/';
}
if (ac.pathname.startsWith('./')) {
  ac.pathname = ac.pathname.slice(2);
}

export const publicUrl = new URL(ac.pathname, window.location.href);
export const serviceWorkerUrl = new URL(publicUrl.pathname + 'service-worker.js', publicUrl.href);
export const fullPath = (path: string) => {
  if (path.startsWith('/')) {
    return path;
  }
  return ac.pathname + path;
};

interface Deployment {
  pathname: string;
  publicUrl: URL;
  serviceWorkerUrl: URL;
  hybrid?: boolean;
}

const deployment: Deployment = {
  pathname: isHybrid() ? '' : ac.pathname,
  publicUrl,
  serviceWorkerUrl,
  hybrid: isHybrid(),
};

logger.info(deployment);

export default deployment;
