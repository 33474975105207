"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceInfoToJSON = exports.DeviceInfoFromJSONTyped = exports.DeviceInfoFromJSON = void 0;
const runtime_1 = require("../runtime");
function DeviceInfoFromJSON(json) {
    return DeviceInfoFromJSONTyped(json, false);
}
exports.DeviceInfoFromJSON = DeviceInfoFromJSON;
function DeviceInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'deviceid': json['deviceid'],
        'createTime': json['createTime'],
        'delFlag': !(0, runtime_1.exists)(json, 'delFlag') ? undefined : json['delFlag'],
        'updateTime': !(0, runtime_1.exists)(json, 'updateTime') ? undefined : json['updateTime'],
        'guardian': !(0, runtime_1.exists)(json, 'guardian') ? undefined : json['guardian'],
        'haseamato': !(0, runtime_1.exists)(json, 'haseamato') ? undefined : json['haseamato'],
        'hasmov': !(0, runtime_1.exists)(json, 'hasmov') ? undefined : json['hasmov'],
        'homeid': json['homeid'],
        'homename': !(0, runtime_1.exists)(json, 'homename') ? undefined : json['homename'],
        'address': !(0, runtime_1.exists)(json, 'address') ? undefined : json['address'],
        'mail': !(0, runtime_1.exists)(json, 'mail') ? undefined : json['mail'],
        'roomname': !(0, runtime_1.exists)(json, 'roomname') ? undefined : json['roomname'],
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : json['type'],
        'username': !(0, runtime_1.exists)(json, 'username') ? undefined : json['username'],
    };
}
exports.DeviceInfoFromJSONTyped = DeviceInfoFromJSONTyped;
function DeviceInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'deviceid': value.deviceid,
        'createTime': value.createTime,
        'delFlag': value.delFlag,
        'updateTime': value.updateTime,
        'guardian': value.guardian,
        'haseamato': value.haseamato,
        'hasmov': value.hasmov,
        'homeid': value.homeid,
        'homename': value.homename,
        'address': value.address,
        'mail': value.mail,
        'roomname': value.roomname,
        'type': value.type,
        'username': value.username,
    };
}
exports.DeviceInfoToJSON = DeviceInfoToJSON;
