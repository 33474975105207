import { Semaphore } from '../data/Semaphore';
import { getLogger } from '../logger';
import { Push } from '../push/Push';
import { isHybrid } from './isHybrid';
import { HybridCallKit } from './HybridCallKit';
import { HybridFile } from './HybridFile';
import { HybridOneSignal } from './HybridOneSignal';
import { HybridPush } from './HybridPush';
import { HybridVibration } from './HybridVibration';
import { HybridVoIP } from './HybridVoIP';
import { HybridFCM } from './HybridFCM';

const logger = getLogger('hybrid/Hybrid', 'info');

/**
 * Hybrid is a singleton class that provides access to all hybrid
 * application features. It is a wrapper around the Cordova global object.
 */
export class Hybrid {
  private static _instance: Hybrid | undefined;
  private static _vibrationPlugin: HybridVibration | undefined;
  private static _oneSignalPlugin: HybridOneSignal | undefined;
  private static _filePlugin: HybridFile | undefined;
  private static _voIPPlugin: HybridVoIP | undefined;
  private static _pushPlugin: HybridPush | undefined;
  private static _callKitPlugin: HybridCallKit | undefined;
  private static _fcmPlugin: HybridFCM | undefined;
  private static _mounted = false;
  private static _semaphore = new Semaphore(1, 'Hybrid');
  private static _push: Push;
  private constructor() {
    // do nothing
  }
  // This should only be called if we are running in a hybrid environment.
  static async mount(push: Push, onSuspend: () => Promise<void>, onResume: () => Promise<void>) {
    await Hybrid._semaphore.acquire();
    try {
      if (!isHybrid()) {
        throw new Error('Hybrid.init() should only be called in a hybrid environment');
      }
      if (!Hybrid._instance && !Hybrid._mounted) {
        Hybrid._push = push;
        Hybrid._instance = new Hybrid();
        Hybrid._oneSignalPlugin = await HybridOneSignal.mount();
        Hybrid._vibrationPlugin = await HybridVibration.mount();
        Hybrid._filePlugin = await HybridFile.mount();
        // Hybrid._voIP = await HybridVoIP.mount();
        // Hybrid._push = await HybridPush.mount();
        // Hybrid._callKit = await HybridCallKit.mount();
        Hybrid._fcmPlugin = await HybridFCM.mount();
        Hybrid._mounted = true;

        if (window.cordova && window.cordova.plugins) {
          logger.debug('cordova plugins:', window.cordova.plugins);
        } else {
          logger.debug('cordova plugins are not defined in window.cordova.plugins');
        }
        // when we first start up we are in the foreground
        // notify the push plugin that we are in the foreground
        if (Hybrid.push && Hybrid.push.isHybridSuspended()) {
          await Hybrid.push.onHybridResume();
        }
        // cordova - only send push notifications when when app is in background
        // When AC is in the foreground play notifications through AC.
        document.addEventListener('resume', async () => {
          logger.debug('[resume event handler] app is now in foreground');
          await onResume();
        });
        document.addEventListener('pause', async () => {
          logger.debug('[pause event handler] app is now in background');
          await onSuspend();
        });
        document.addEventListener('visibilitychange', async () => {
          if (document.hidden) {
            // tab is changed
            logger.debug('[visibilitychange event handler] app is now hidden');
            await onSuspend();
          } else {
            // tab is active
            logger.debug('[visibilitychange event handler] app is now visible');
            await onResume();
          }
        });
        logger.info('[mount] hybrid environment is now mounted');
      }
    } finally {
      Hybrid._semaphore.release();
    }
  }
  static instance() {
    return Hybrid._instance;
  }
  static shouldMount(): boolean {
    return isHybrid() && Hybrid._instance === undefined;
  }
  static isMounted(): boolean {
    return isHybrid() && Hybrid._instance !== undefined;
  }
  static get push(): Push | undefined {
    return Hybrid._push;
  }
  get vibrationPlugin(): HybridVibration | undefined {
    return Hybrid._vibrationPlugin;
  }
  get filePlugin(): HybridFile | undefined {
    return Hybrid._filePlugin;
  }
  get oneSignalPlugin(): HybridOneSignal | undefined {
    return Hybrid._oneSignalPlugin;
  }
  get pushPlugin(): HybridPush | undefined {
    return Hybrid._pushPlugin;
  }
  get voIPPlugin(): HybridVoIP | undefined {
    return Hybrid._voIPPlugin;
  }
  get callKitPlugin(): HybridCallKit | undefined {
    return Hybrid._callKitPlugin;
  }
  get fcmPlugin(): HybridFCM | undefined {
    return Hybrid._fcmPlugin;
  }
}
