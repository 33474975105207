"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuddycomStatus = exports.BuddycomLanguage = void 0;
// Languages supported by Buddycom.
// @see Buddycom API
var BuddycomLanguage;
(function (BuddycomLanguage) {
    BuddycomLanguage[BuddycomLanguage["JA"] = 1] = "JA";
    BuddycomLanguage[BuddycomLanguage["EN"] = 2] = "EN";
})(BuddycomLanguage || (exports.BuddycomLanguage = BuddycomLanguage = {}));
// @see Buddycom API
var BuddycomStatus;
(function (BuddycomStatus) {
    BuddycomStatus[BuddycomStatus["STATUS_SUCCESS"] = 0] = "STATUS_SUCCESS";
    BuddycomStatus[BuddycomStatus["STATUS_ERROR_UNKNOWN"] = -1] = "STATUS_ERROR_UNKNOWN";
    BuddycomStatus[BuddycomStatus["STATUS_ERROR_INVALID_PARAMETER"] = -2] = "STATUS_ERROR_INVALID_PARAMETER";
    BuddycomStatus[BuddycomStatus["STATUS_ERROR_DENIED"] = -3] = "STATUS_ERROR_DENIED";
    BuddycomStatus[BuddycomStatus["STATUS_ERROR_USER_NOT_FOUND"] = -18] = "STATUS_ERROR_USER_NOT_FOUND";
    BuddycomStatus[BuddycomStatus["STATUS_ERROR_TALKGROUP_NOT_FOUND"] = -19] = "STATUS_ERROR_TALKGROUP_NOT_FOUND";
    BuddycomStatus[BuddycomStatus["STATUS_ERROR_AUTH_FAILED"] = -33] = "STATUS_ERROR_AUTH_FAILED";
})(BuddycomStatus || (exports.BuddycomStatus = BuddycomStatus = {}));
