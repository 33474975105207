import * as React from 'react';

import { ClickAwayListenerProps } from '@mui/material/ClickAwayListener';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';

// import classNames from 'classnames';
import NotificationContent, { ContentVariant } from './NotificationContent';

interface Props {
  className?: string;
  message: string | JSX.Element;
  variant: ContentVariant;
  visible: boolean;
  onClose: (
    event: Event | React.SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason
  ) => void;
  duration?: number;
  closeButton?: boolean;
  action?: JSX.Element[];
  ClickAwayListenerProps?: ClickAwayListenerProps;
  vertical?: 'top' | 'bottom';
  horizontal?: 'left' | 'center' | 'right';
}

type AllProps = Props;

class Notification extends React.Component<AllProps> {
  handleClose = (
    event: Event | React.SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason
  ) => {
    this.setState({ open: false });
    this.props.onClose(event, reason);
  };

  render() {
    const { vertical, horizontal } = this.props;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: vertical ? vertical : 'bottom',
          horizontal: horizontal ? horizontal : 'left',
        }}
        open={this.props.visible}
        autoHideDuration={this.props.duration}
        onClose={this.handleClose}
        ClickAwayListenerProps={this.props.ClickAwayListenerProps}
      >
        <div>
          <NotificationContent
            onClose={this.handleClose}
            variant={this.props.variant}
            message={this.props.message}
            closeButton={this.props.closeButton}
            action={this.props.action}
          />
        </div>
      </Snackbar>
    );
  }
}

export default Notification;
