"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// export interface SensorRecordVitals {
//   manual: boolean;
//   sensorRef: string | undefined;
//   residentRef: string;
//   deviceSerialNumber: string;
//   sensorManufacturer: string | undefined;
//   sensorModelNumber: string | undefined;
//   t: string;
//   sys: number | undefined;
//   dia: number | undefined;
//   map: number | undefined;
//   bodyTemp: number | undefined;
//   hr: number | undefined;
//   rr: number | undefined;
//   mv: number | undefined;
//   hrv: number | undefined;
//   weight: number | undefined;
// }
