"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadBytes = exports.updateMetadata = exports.storageRef = exports.signInWithCustomToken = exports.getDownloadURL = exports.getApps = exports.enableIndexedDbPersistence = exports.deleteObject = exports.isFailing = exports.isHealthy = exports.setHealthy = exports.goOnline = exports.goOffline = exports.FirebaseRuntime = void 0;
const app_1 = require("firebase/app");
Object.defineProperty(exports, "getApps", { enumerable: true, get: function () { return app_1.getApps; } });
const auth_1 = require("firebase/auth");
Object.defineProperty(exports, "signInWithCustomToken", { enumerable: true, get: function () { return auth_1.signInWithCustomToken; } });
const database_1 = require("firebase/database");
const firestore_1 = require("firebase/firestore");
Object.defineProperty(exports, "enableIndexedDbPersistence", { enumerable: true, get: function () { return firestore_1.enableIndexedDbPersistence; } });
const storage_1 = require("firebase/storage");
Object.defineProperty(exports, "deleteObject", { enumerable: true, get: function () { return storage_1.deleteObject; } });
Object.defineProperty(exports, "getDownloadURL", { enumerable: true, get: function () { return storage_1.getDownloadURL; } });
Object.defineProperty(exports, "storageRef", { enumerable: true, get: function () { return storage_1.ref; } });
Object.defineProperty(exports, "updateMetadata", { enumerable: true, get: function () { return storage_1.updateMetadata; } });
Object.defineProperty(exports, "uploadBytes", { enumerable: true, get: function () { return storage_1.uploadBytes; } });
// import { createConnection } from 'net';
const firestore_2 = require("firebase/firestore");
const Environment_1 = require("../config/Environment");
const logger_1 = require("../logger");
const APP_NAME = 'AC';
const createConnection = 'not used';
// use Middleware to access admin functions.
class FirebaseRuntime {
    constructor() {
        this._env = new Environment_1.Environment();
        this.checkFirestoreConnection = (useApi = true) => __awaiter(this, void 0, void 0, function* () {
            const stats = {
                healthy: false,
                status: 'skipped',
            };
            if (this.env) {
                const firestoreUrl = this.env.firestoreURL();
                const firestoreHostname = this.env.firestoreHostname();
                const firestorePort = this.env.firestorePort();
                const servicesUrl = this.env.servicesUrl() ? this.env.servicesUrl() : 'api/v1';
                return new Promise((resolve, reject) => {
                    if (typeof fetch === 'function' && servicesUrl && useApi) {
                        this.logger.debug(`checkFirestoreConnection[fetch]: ${servicesUrl}`);
                        fetch(servicesUrl, { method: 'GET', mode: 'no-cors', redirect: 'follow' })
                            .then(() => {
                            stats.healthy = true;
                            stats.status = 'ok';
                            resolve(stats);
                        })
                            .catch((e) => {
                            this.logger.console.error(e);
                            stats.healthy = false;
                            stats.status = 'error';
                            reject(stats);
                        });
                    }
                    else if (typeof createConnection === 'function' &&
                        firestoreUrl !== undefined &&
                        firestoreHostname &&
                        firestorePort) {
                        // stats.hostname = firestoreHostname;
                        // stats.port = firestorePort;
                        // try {
                        //   const client = createConnection({
                        //     host: firestoreHostname,
                        //     port: firestorePort,
                        //     timeout: 5000,
                        //   });
                        //   client.on('connect', () => {
                        //     client.end();
                        //     stats.status = 'ok';
                        //     stats.healthy = true;
                        //     resolve(stats);
                        //   });
                        //   client.on('timeout', () => {
                        //     this.logger.notice(
                        //       `TCP connection to host ${firestoreHostname} on port ${firestorePort} timed out`
                        //     );
                        //     client.destroy();
                        //     stats.status = 'timeout';
                        //     reject(stats);
                        //   });
                        //   client.on('error', (err) => {
                        //     this.logger.warn(
                        //       `Error trying to connect via TCP to host ${firestoreHostname} on port ${firestorePort}`,
                        //       err
                        //     );
                        //     stats.status = 'error';
                        //     stats.error = err.message;
                        //     reject(stats);
                        //   });
                        // } catch (e) {
                        //   // TODO - need to handle this better...
                        //   console.error('checkFirestoreConnection[socket]: ', e);
                        //   resolve(stats);
                        // }
                    }
                    else {
                        // TODO - how to check from browser?
                        stats.healthy = true;
                        resolve(stats);
                    }
                });
            }
            else {
                return stats;
            }
        });
        // singleton - initialize with defaults
        this._id = Date.now();
        this._logger = this.initLogger(this._id);
    }
    initLogger(id) {
        return (0, logger_1.getLogger)(`services/Firebase${id}`, 'info');
    }
    static get instance() {
        if (!FirebaseRuntime._instance) {
            FirebaseRuntime._instance = new FirebaseRuntime();
        }
        return FirebaseRuntime._instance;
    }
    get id() {
        return this._id;
    }
    get domain() {
        return this._domain;
    }
    get initialized() {
        const _ready = this._auth !== undefined && this._firestore !== undefined;
        this._logger.trace(`initialized: ${_ready}`);
        return _ready;
    }
    get firestore() {
        if (this._firestore) {
            return this._firestore;
        }
        else {
            throw new Error('cannot get firestore: firebase application has not been initialized (is not ready())');
        }
    }
    get storage() {
        if (this._storage) {
            return this._storage;
        }
        else {
            throw new Error('cannot get storage: firebase application has not been initialized (is not ready())');
        }
    }
    get database() {
        if (this._database) {
            return this._database;
        }
        else {
            throw new Error('cannot get database: firebase application has not been initialized (is not ready())');
        }
    }
    get auth() {
        if (this._auth) {
            return this._auth;
        }
        else {
            throw new Error('cannot get auth: firebase application has not been initialized (is not ready())');
        }
    }
    get env() {
        return this._env;
    }
    get projectId() {
        var _a;
        if (this.initialized && this.env) {
            return (_a = this.env.firebase) === null || _a === void 0 ? void 0 : _a.projectId;
        }
        return undefined;
    }
    get logger() {
        return this._logger;
    }
    init(domain, env) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.initialized) {
                yield this.close();
                // create a new instance
                FirebaseRuntime._instance = new FirebaseRuntime();
                yield FirebaseRuntime._instance.init(domain, env);
                return;
            }
            this._id = Date.now(); // change the id
            this._logger = this.initLogger(this._id);
            this.logger.debug(`init(${domain})`, env.getEnvironmentConfig(true));
            this._domain = domain;
            this._env = env;
            if (env.firebase) {
                const app = env.isEmulator()
                    ? (0, app_1.initializeApp)({
                        projectId: env.firebase.projectId,
                        apiKey: env.firebase.apiKey,
                        storageBucket: env.firebase.storageBucket,
                    }, APP_NAME)
                    : (0, app_1.initializeApp)(env.firebase, APP_NAME);
                this._auth = (0, auth_1.getAuth)(app);
                this._storage = (0, storage_1.getStorage)(app);
                this._firestore = (0, firestore_1.getFirestore)(app);
                this._database = (0, database_1.getDatabase)(app);
                if (!env.isNode && !env.useFirestoreEmulator) {
                    (0, firestore_1.enableIndexedDbPersistence)(this._firestore).catch((err) => {
                        this.logger.notice('cannot enable DB persistence', err);
                    });
                }
                if (env.useAuthEmulator() && env.emulator && env.emulator.authUrl) {
                    this.logger.notice('using auth emulator', env.emulator.authUrl);
                    (0, auth_1.connectAuthEmulator)(this._auth, env.emulator.authUrl);
                }
                if (env.useFirestoreEmulator() &&
                    env.emulator &&
                    env.emulator.firestoreHost &&
                    env.emulator.firestorePort) {
                    const port = parseInt(env.emulator.firestorePort);
                    this.logger.notice('using Firestore emulator:', env.emulator);
                    (0, firestore_1.connectFirestoreEmulator)(this._firestore, env.emulator.firestoreHost, port);
                }
                if (env.isEmulator()) {
                    (0, storage_1.connectStorageEmulator)(this._storage, 'localhost', 9199);
                }
                // workaround - force the app to connect to the firestore otherwise
                // we won't be able to close the apps
                // @see https://github.com/firebase/firebase-js-sdk/issues/7816
                (0, firestore_2.getDoc)((0, firestore_2.doc)(this._firestore, 'test/test'))
                    .then((d) => {
                    // ignore
                    this.logger.debug('forced app to connect to firestore', d);
                })
                    .catch((e) => {
                    // ignore
                    this.logger.debug('forced app to connect to firestore', e);
                });
                this.logger.debug(`init(${domain}): initialized ${this.id}`);
            }
            else {
                this.logger.notice(`not initialized, firebase config is missing`);
            }
        });
    }
    close() {
        return __awaiter(this, void 0, void 0, function* () {
            this.logger.debug(`close(${this.id}, ${this.domain})`);
            // if (this.initialized && this.env) {
            // following hangs when using emulator
            const apps = (0, app_1.getApps)();
            yield Promise.all(apps.map((app) => __awaiter(this, void 0, void 0, function* () {
                this.logger.trace(`close(${this.id}): deleting app ${app.name}...`);
                yield (0, app_1.deleteApp)(app);
                this.logger.trace(`close(${this.id}): deleted app ${app.name}`);
            }))).catch((e) => {
                // ignore
                this.logger.notice(`close(${this.id}): error deleting apps`, e);
            });
            if (this._auth) {
                this._auth.onAuthStateChanged(() => {
                    // ignore;
                });
            }
            // }
            this._auth = undefined;
            this._firestore = undefined;
            this._database = undefined;
            this._storage = undefined;
        });
    }
}
exports.FirebaseRuntime = FirebaseRuntime;
const goOffline = () => {
    const database = FirebaseRuntime.instance.database;
    if (database) {
        (0, database_1.goOffline)(database);
    }
};
exports.goOffline = goOffline;
const goOnline = () => {
    const database = FirebaseRuntime.instance.database;
    if (database) {
        (0, database_1.goOnline)(database);
    }
};
exports.goOnline = goOnline;
//
// The following is used to check the health of the running
// system. The global _healthy should be set to false if
// the system is failing and should be rebooted.
//
let _healthy = true;
const setHealthy = (healthy) => {
    _healthy = healthy;
};
exports.setHealthy = setHealthy;
const isHealthy = () => Promise.resolve(_healthy);
exports.isHealthy = isHealthy;
const isFailing = () => Promise.resolve(!_healthy);
exports.isFailing = isFailing;
__exportStar(require("firebase/firestore"), exports);
