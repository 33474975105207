import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';

function withRoot<P extends JSX.IntrinsicAttributes & { children?: React.ReactNode }>(
  Component: React.ComponentType<P>
) {
  function WithRoot(props: P) {
    return (
      <React.Fragment>
        <CssBaseline />
        <Component {...props} />
      </React.Fragment>
    );
  }

  return WithRoot;
}

export default withRoot;
