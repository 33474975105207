"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLogger = exports.Logger = exports.isLoggerEnabled = exports.enableLogger = exports.disableLogger = void 0;
/**
 * Logger implementation based on 'winston'.
 * Can run in browser and server.
 * Uses SYSLOG logging levels.
 */
// import * as winston from 'winston';
const levels_1 = require("./levels");
/* eslint-disable no-restricted-globals */
// check if running as web worker
const isWebWorker = () => typeof self === 'object' &&
    self.constructor &&
    self.constructor.name === 'DedicatedWorkerGlobalScope';
/* eslint-enable no-restricted-globals */
// check if running in node env
const isNode = () => typeof process !== 'undefined' && process.versions != null && process.versions.node != null;
// check if running in browser
// const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const isBrowser = () => typeof window !== 'undefined' && typeof window.document !== 'undefined';
const isHybrid = () => {
    if (!isNode() && window) {
        const w = window;
        return w.cordova !== undefined;
    }
    return false;
};
let enabled = true;
// Use if you want to disable/enable the logger
const disableLogger = () => (enabled = false);
exports.disableLogger = disableLogger;
const enableLogger = () => (enabled = true);
exports.enableLogger = enableLogger;
const isLoggerEnabled = () => enabled;
exports.isLoggerEnabled = isLoggerEnabled;
class ConsoleLogger {
    constructor(name, logger) {
        this._isNode = isNode();
        this.isLevelEnabled = (level) => {
            return this.logger.isLevelEnabled(level);
        };
        this.isAlertEnabled = () => this.isLevelEnabled('alert');
        this.isConsoleEnabled = () => !this._isNode && this.isLevelEnabled('debug');
        this.isCriticalEnabled = () => this.isLevelEnabled('crit');
        this.isDebugEnabled = () => this.isLevelEnabled('debug');
        this.isEmergencyEnabled = () => this.isLevelEnabled('emerg');
        this.isErrorEnabled = () => this.isLevelEnabled('error');
        this.isInfoEnabled = () => this.isLevelEnabled('info');
        this.isNoticeEnabled = () => this.isLevelEnabled('notice');
        this.isTraceEnabled = () => this.isLevelEnabled('trace');
        this.isWarningEnabled = () => this.isLevelEnabled('warning');
        this.prefix = (level) => {
            const time = new Date().toISOString();
            const l = level ? level.toUpperCase() : '';
            if (level) {
                if (this._isNode) {
                    return `[${time}] [${l}] "${this._name}"`;
                }
                return `[${l}] "${this._name}"`;
            }
            return `[${time}] "${this._name}"`;
        };
        this.logPrefix = (prefix) => {
            if (prefix.length > 0) {
                console.log(prefix);
            }
        };
        this.assert = (value, message, ...params) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                if (params.length > 0) {
                    console.assert(value, message, ...params);
                }
                else {
                    console.assert(value, message);
                }
            }
            return this.logger;
        };
        this.clear = () => {
            if (this.isConsoleEnabled()) {
                console.clear();
            }
            return this.logger;
        };
        this.count = (label) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.count(label);
            }
            return this.logger;
        };
        this.countReset = (label) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.countReset(label);
            }
            return this.logger;
        };
        this.debug = (params) => {
            if (this.isDebugEnabled()) {
                console.log(this.prefix('debug'), ...params);
            }
            return this.logger;
        };
        this.dir = (obj, options) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.dir(obj, options);
            }
            return this.logger;
        };
        this.dirxml = (data) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.dirxml(...data);
            }
            return this.logger;
        };
        this.error = (params) => {
            if (this.isErrorEnabled()) {
                console.error(this.prefix('error'), ...params);
            }
            return this.logger;
        };
        this.group = (label) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.group(...label);
            }
            return this.logger;
        };
        this.groupCollapsed = (label) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.groupCollapsed(...label);
            }
            return this.logger;
        };
        this.groupEnd = () => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.groupEnd();
            }
            return this.logger;
        };
        this.info = (params) => {
            if (this.isInfoEnabled()) {
                console.info(this.prefix('info'), ...params);
            }
            return this.logger;
        };
        this.log = (params) => {
            if (this.isConsoleEnabled()) {
                console.log(this.prefix('info'), ...params);
            }
            return this.logger;
        };
        this.notice = (params) => {
            if (this.isNoticeEnabled()) {
                console.info(this.prefix('notice'), ...params);
            }
            return this.logger;
        };
        this.table = (tabularData, properties) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.table(tabularData, properties);
            }
            return this.logger;
        };
        this.time = (label) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.time(label);
            }
            return this.logger;
        };
        this.timeEnd = (label) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.timeEnd(label);
            }
            return this.logger;
        };
        this.timeLog = (label, ...data) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.timeLog(label, ...data);
            }
            return this.logger;
        };
        this.trace = (params) => {
            if (this.isTraceEnabled()) {
                console.log(this.prefix('trace'), ...params);
            }
            return this.logger;
        };
        this.warning = (params) => {
            if (this.isWarningEnabled()) {
                console.warn(this.prefix('warning'), ...params);
            }
            return this.logger;
        };
        this.warn = (params) => this.warning(params);
        this.emerg = (params) => {
            if (this.isEmergencyEnabled()) {
                console.warn(this.prefix('emerg'), ...params);
            }
            return this.logger;
        };
        this.alert = (params) => {
            if (this.isAlertEnabled()) {
                console.warn(this.prefix('alert'), ...params);
            }
            return this.logger;
        };
        this.crit = (params) => {
            if (this.isCriticalEnabled()) {
                console.warn(this.prefix('crit'), ...params);
            }
            return this.logger;
        };
        this.profile = (label) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.profile(label);
            }
            return this.logger;
        };
        this.profileEnd = (label) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.profileEnd(label);
            }
            return this.logger;
        };
        this.timeStamp = (label) => {
            if (this.isConsoleEnabled()) {
                this.logPrefix(this.prefix('console'));
                console.timeStamp(label);
            }
            return this.logger;
        };
        this._name = name;
        this._logger = logger;
    }
    get logger() {
        return this._logger;
    }
}
/**
 * Logger - implements logging in both browser and on server.
 * Supports SYSLOG logging levels.
 */
class Logger {
    constructor(name, level) {
        // env
        this.isNode = () => isNode();
        this.isBrowser = () => isBrowser();
        this.isWebWorker = () => isWebWorker();
        this.isEnabled = () => (0, exports.isLoggerEnabled)();
        // enabled levels
        this.isEmergencyEnabled = () => this.isLevelEnabled('emerg');
        this.isAlertEnabled = () => this.isLevelEnabled('alert');
        this.isCriticalEnabled = () => this.isLevelEnabled('crit');
        this.isErrorEnabled = () => this.isLevelEnabled('error');
        this.isWarningEnabled = () => this.isLevelEnabled('warning');
        this.isNoticeEnabled = () => this.isLevelEnabled('notice');
        this.isInfoEnabled = () => this.isLevelEnabled('info');
        this.isDebugEnabled = () => this.isLevelEnabled('debug');
        this.isTraceEnabled = () => this.isLevelEnabled('trace');
        // logging
        this.emergency = (...args) => {
            this.log.emerg(this.format(args));
            return this;
        };
        this.alert = (...args) => {
            this.log.alert(this.format(args));
            return this;
        };
        this.critical = (...args) => {
            this.log.crit(this.format(args));
            return this;
        };
        this.error = (...args) => {
            this.log.error(this.format(args));
            return this;
        };
        this.warning = (...args) => this.warn(...args);
        this.warn = (...args) => {
            this.log.warning(this.format(args));
            return this;
        };
        this.notice = (...args) => {
            this.log.notice(this.format(args));
            return this;
        };
        this.info = (...args) => {
            this.log.info(this.format(args));
            return this;
        };
        this.debug = (...args) => {
            this.log.debug(this.format(args));
            return this;
        };
        this.trace = (...args) => {
            this.consoleLogger.trace(this.format(args));
            return this;
        };
        // include console functions
        this.assert = (value, message, ...params) => this.consoleLogger.assert(value, message, ...params);
        this.clear = () => this.consoleLogger.clear();
        this.count = (label) => this.consoleLogger.count(label);
        this.countReset = (label) => this.consoleLogger.countReset(label);
        this.dir = (obj, options) => this.consoleLogger.dir(obj, options);
        this.dirxml = (data) => this.consoleLogger.dirxml(data);
        this.group = (label) => this.consoleLogger.group(label);
        this.groupCollapsed = (label) => this.consoleLogger.groupCollapsed(label);
        this.groupEnd = () => this.consoleLogger.groupEnd();
        this.table = (tabularData, properties) => this.consoleLogger.table(tabularData, properties);
        this.time = (label) => this.consoleLogger.time(label);
        this.timeEnd = (label) => this.consoleLogger.timeEnd(label);
        this.timeLog = (label, ...data) => this.consoleLogger.timeLog(label, ...data);
        this.profile = (label) => this.consoleLogger.profile(label);
        this.profileEnd = (label) => this.consoleLogger.profileEnd(label);
        this.timeStamp = (label) => this.consoleLogger.timeStamp(label);
        this.isLevelEnabled = (level) => {
            if (!this.isEnabled()) {
                return false;
            }
            const l = levels_1.sysLogLevelValues.get(level) || 10;
            const current = levels_1.sysLogLevelValues.get(this.level) || 10;
            return l <= current;
        };
        this.format = (args) => {
            // use cache to handle circular references...
            const cache = [];
            const stringifier = (key, value) => {
                if (typeof value === 'object' && value !== null) {
                    if (cache.indexOf(value) !== -1) {
                        // Duplicate reference found, discard key
                        return '(circular ref...)';
                    }
                    // Store value in our cache
                    cache.push(value);
                    const obj = {};
                    Object.getOwnPropertyNames(value).forEach((k) => {
                        obj[k] = value[k];
                    });
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    return obj;
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return value;
            };
            if (isNode()) {
                const formatted = [];
                args.forEach((arg) => {
                    if (typeof arg === 'object') {
                        formatted.push(JSON.stringify(arg, stringifier));
                    }
                    else {
                        formatted.push(`${arg}`);
                    }
                });
                // return formatted.join(' ');
                return formatted;
            }
            else if (isHybrid()) {
                const formatted = [];
                args.forEach((arg) => {
                    if (typeof arg === 'object') {
                        formatted.push(JSON.stringify(arg, stringifier, 2));
                    }
                    else {
                        formatted.push(`${arg}`);
                    }
                });
                return formatted;
                // return formatted.join('\n');
            }
            else {
                return args;
            }
        };
        // this.winstonLogger = winstonLogger;
        // console.debug('Winston levels:', winstonLogger.levels);
        this.level = level;
        // level !== 'trace' ? winstonLogger.levels[level] : winstonLogger.levels['debug'] + 1;
        // console.debug(`Logger level: ${level} is ${this.level}`);
        this.consoleLogger = new ConsoleLogger(name, this);
    }
    // getWinstonLevel = (level: SysLogLevel) =>
    //   level !== 'trace' ? this.winstonLogger.levels[level] : this.winstonLogger.levels['debug'] + 1;
    get log() {
        return this.consoleLogger;
    }
    get console() {
        return this.consoleLogger;
    }
}
exports.Logger = Logger;
/**
 * Create a logger.
 * @param name the logger name - prefixes all log messages
 * @param sysLogLevel the level os logging to support
 */
const createLogger = (name, sysLogLevel) => {
    // const winstonFormat = winston.format.printf(({ level, message, label }) => {
    //   // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    //   return `[${level.toUpperCase()}] ${label} - ${message}`;
    // });
    // const winstonFormat = winston.format.printf(
    //   ({ level, message, label, timestamp }) =>
    //     // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    //     `[${timestamp}] [${level.toUpperCase()}] "${label}" ${message}`
    // );
    // const winstonLogger = winston.createLogger({
    //   levels: winston.config.syslog.levels,
    //   // we create a single winston logger that can log at any level.
    //   // our logger will enforce the correct level.
    //   level: sysLogLevel,
    //   format: winston.format.combine(
    //     winston.format.label({ label: name }),
    //     winston.format.timestamp(),
    //     winstonFormat
    //   ),
    //   // format: winston.format.prettyPrint(),
    //   // TODO - what does defaultMeta do?
    //   // defaultMeta: { service: name },
    //   // Always log to console
    //   transports: [new winston.transports.Console({ level: sysLogLevel })],
    // });
    return new Logger(name, sysLogLevel);
};
exports.createLogger = createLogger;
