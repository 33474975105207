import { createSvgIcon } from '@mui/material';

const InBedSitting = createSvgIcon(
  <g>
    <g transform="matrix(1,0,0,1,0,3)">
      <path d="M19,10L13,10L9,14L3,14L3,5L1,5L1,20L3,20L3,17L21,17L21,20L23,20L23,14C23,11.79 21.21,10 19,10Z" />
    </g>
    <g transform="matrix(1,0,0,1,-1,1)">
      <path d="M6.639,16.861L9.632,16.861L14.09,12.41C14.4,12.46 14.7,12.48 15,12.48L15,10.32C13.34,10.35 11.39,9.45 10.33,8.28L8.93,6.73C8.74,6.52 8.5,6.35 8.24,6.23C7.95,6.09 7.62,6 7.28,6L7.25,6C6.01,6.01 5,7.02 5,8.26L5,14.01C5,14.85 5.35,15.62 5.92,16.17L9.5,12.59L9.5,10.32C10.13,10.84 10.93,11.34 11.79,11.71L6.639,16.861ZM7,1C8.1,1 9,1.9 9,3C9,4.1 8.1,5 7,5C5.9,5 5,4.1 5,3C5,1.9 5.9,1 7,1Z" />
    </g>
  </g>,
  'InBedSitting'
);

export { InBedSitting };
export default InBedSitting;
