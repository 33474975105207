"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = void 0;
// Provides global default log settings
const config = new Map();
exports.config = config;
// default if setting is not provided for a name
config.set('default', 'info');
// store
config.set('store/logger', 'info');
config.set('store/services/EarlySenseService', 'info');
config.set('store/services/ReportsService', 'info');
