"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Registry for push notifications. Uses a database to store
 * push tokens and other information. This is used to send
 * push notifications to users.
 *
 * At this time this is just a place holder. It is not used. In the future
 * we may backup push tokens in the database.
 *
 * @param db the database
 */
class PushRegistry {
    constructor(db) {
        this.db = db;
    }
}
exports.default = PushRegistry;
