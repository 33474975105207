/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';

interface Properties {
  message?: string;
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error?: any;
  errorInfo?: any;
}

export class ErrorBoundary extends React.Component<Properties, State> {
  private error: any;
  private errorInfo: any;

  constructor(props: Properties) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.error(JSON.stringify(error, undefined, 2), JSON.stringify(errorInfo, undefined, 2));
    this.error = error;
    this.errorInfo = errorInfo;
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ padding: 8 }}>
          <h2>{this.props.message || 'Oops, something went wrong.'}</h2>
          <hr />
          <div>{this.error}</div>
          <div>{this.errorInfo}</div>
        </div>
      );
    }

    return this.props.children;
  }
}
