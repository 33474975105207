import { createSvgIcon } from '@mui/material';

const BodyTemperature = createSvgIcon(
  <g>
    <path
      d="M15,13.5v-9c0-1.7-1.3-3-3-3s-3,1.3-3,3v9c-1.2,0.9-2,2.3-2,4c0,2.8,2.2,5,5,5s5-2.2,5-5C17,15.9,16.2,14.4,15,13.5z
		 M12,20.5c-1.7,0-3-1.3-3-3c0-1.3,0.8-2.4,2-2.8V10h2v4.7c1.2,0.4,2,1.5,2,2.8C15,19.2,13.7,20.5,12,20.5z"
    />
    <rect x="16" y="8" width="3" height="1" />
    <rect x="16" y="12" width="3" height="1" />
    <rect x="16" y="6" width="2" height="1" />
    <rect x="16" y="10" width="2" height="1" />
    <rect x="16" y="4" width="3" height="1" />
  </g>,
  'BodyTemperature'
);

export { BodyTemperature };
export default BodyTemperature;
