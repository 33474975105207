import * as React from 'react';

import {
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import strings from '../../languages';
import { CancelButton, SubmitButton } from '../buttons';
import Dialog from './Dialog';

interface DialogProps {
  className?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (date: Date) => void;
  date: Date;
  dateOptions: Date[];
}

interface DialogState {
  date?: Date;
}

type AllDialogProps = DialogProps & WrappedComponentProps;

class DateSelectionDialog extends React.Component<AllDialogProps, DialogState> {
  readonly state: DialogState;

  constructor(props: AllDialogProps) {
    super(props);
    this.state = { date: props.date };
  }

  saveAndClose = () => {
    const { date } = this.state;
    if (date) {
      this.props.onSubmit(date);
    } else {
      this.props.onClose();
    }
  };

  onDateSelectionChanged = (time: number | string) => {
    const date = this.props.dateOptions.find(
      (x) => x.getTime() === time || this.dateValue(x) === time
    );
    if (date) {
      this.setState({ date });
    }
  };

  dateValue = (d: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return d.toLocaleDateString('en-us', options);
  };

  renderDateSelection = () => {
    const { dateOptions } = this.props;
    const { date } = this.state;
    const localized = strings(this.props.intl);

    const selectOptions = dateOptions.map((d: Date) => {
      const val = this.dateValue(d);
      return (
        <MenuItem value={val} key={val}>
          {localized.time.date(d)}
        </MenuItem>
      );
    });

    return (
      <FormControl fullWidth sx={{ mt: 1 }}>
        <InputLabel id="days-select-label">{localized.label.date()}</InputLabel>
        <Select
          sx={{ minWidth: 300 }}
          labelId="days-select-label"
          label={localized.label.date()}
          fullWidth
          value={date ? this.dateValue(date) : undefined}
          onChange={(event) => this.onDateSelectionChanged(event.target.value)}
          inputProps={{
            name: 'dates',
            id: 'report-date',
          }}
        >
          {selectOptions}
        </Select>
      </FormControl>
    );
  };

  render() {
    const { onClose, open } = this.props;
    const dateSelection = this.renderDateSelection();

    return (
      <Dialog
        // sx={{ minWidth: 300 }}
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="form-dialog-title">{localized.report.settings()}</DialogTitle> */}
        <DialogContent>
          <DialogContentText />
          {dateSelection}
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={this.props.onClose} />
          <SubmitButton onClick={this.saveAndClose} />
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(DateSelectionDialog);
