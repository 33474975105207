"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInfoToJSON = exports.UserInfoFromJSONTyped = exports.UserInfoFromJSON = void 0;
const runtime_1 = require("../runtime");
function UserInfoFromJSON(json) {
    return UserInfoFromJSONTyped(json, false);
}
exports.UserInfoFromJSON = UserInfoFromJSON;
function UserInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': !(0, runtime_1.exists)(json, 'username') ? undefined : json['username'],
        'roomname': !(0, runtime_1.exists)(json, 'roomname') ? undefined : json['roomname'],
        'address': !(0, runtime_1.exists)(json, 'address') ? undefined : json['address'],
    };
}
exports.UserInfoFromJSONTyped = UserInfoFromJSONTyped;
function UserInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'username': value.username,
        'roomname': value.roomname,
        'address': value.address,
    };
}
exports.UserInfoToJSON = UserInfoToJSON;
