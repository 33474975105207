import * as EmailValidator from 'email-validator';
import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  // FormControl,
  // Input,
  // InputLabel,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from './dialogs/Dialog';
import * as Icons from '../icons';
// import InputCaption from './InputCaption';
import strings from '../languages';

// WithStyles provides the 'classes' property that contains the classnames
// used by the theme...
interface Props {
  className?: string;
}

interface State {
  focusUsername: boolean;
  username?: string;
  usernameError?: string;
}

interface TransitionProps {
  children?: React.ReactElement;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface DialogProps {
  onSubmit: (email: string) => void;
  onCancel: () => void;
  open: boolean;
}

type AllProps = Props & DialogProps & WrappedComponentProps;

// interface PropsWithStyles extends WithStyles<typeof styles>;

class PasswordResetDialog extends React.Component<AllProps, State> {
  readonly state: State = {
    focusUsername: false,
  };

  onSubmit = () => {
    const { intl, onSubmit } = this.props;
    const { username } = this.state;
    const localized = strings(intl);

    if (!username) {
      this.setState({
        usernameError: localized.validation.requiredField(),
      });
    } else if (!EmailValidator.validate(username)) {
      this.setState({
        usernameError: localized.validation.invalidEmail(),
      });
    } else {
      onSubmit(username);
    }
  };

  onCancel = () => {
    this.props.onCancel();
  };

  onFocusUsername = () => {
    this.setState({
      focusUsername: true,
      usernameError: undefined,
    });
  };

  onBlurUsername = () => {
    this.setState({ focusUsername: false });
  };

  onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      username: event.target.value,
      usernameError: undefined,
    });
  };

  onClose = () => this.props.onCancel();

  render() {
    const { intl, open } = this.props;
    const { usernameError } = this.state;
    const localized = strings(intl);

    return (
      <React.Fragment>
        <Dialog
          maxWidth={false}
          onClose={this.onClose}
          open={open}
          aria-labelledby="buddycom-message-title"
          aria-describedby="buddycom-message-content"
          TransitionComponent={Transition}
        >
          <DialogTitle id="buddycom-message-title">
            <Icons.LockReset />
            <span>{localized.login.passwordReset()}</span>
          </DialogTitle>

          <DialogContent id="buddycom-message-content" dividers>
            <Stack className="AC-infoSpacing">
              <Typography>{localized.login.sendPasswordResetEmail()}</Typography>
              <TextField
                // inputRef={this.usernameRef}
                label={localized.login.email()}
                id="email"
                name="email"
                autoComplete="email"
                autoFocus={true}
                onFocus={this.onFocusUsername}
                onBlur={this.onBlurUsername}
                onChange={this.onChangeUsername}
                helperText={usernameError}
                value={this.state.username}
                fullWidth
                required
              />
              {/* <FormControl required={true} fullWidth={true} variant="standard" margin="normal">
                <InputLabel htmlFor="email">{localized.login.email()}</InputLabel>
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus={true}
                  onFocus={this.onFocusUsername}
                  onBlur={this.onBlurUsername}
                  onChange={this.onChangeUsername}
                />
                <InputCaption variant="error">{usernameError}</InputCaption>
              </FormControl> */}
            </Stack>
          </DialogContent>

          <DialogActions sx={{ px: 3 }}>
            <Button size="large" onClick={this.onClose}>
              {localized.action.cancel()}
            </Button>
            {/* <Button onClick={this.onCancel}>{localized.action.cancel()}</Button> */}
            <Button
              size="large"
              // color="secondary"
              variant="contained"
              autoCapitalize="false"
              onClick={this.onSubmit}
            >
              {localized.action.submit()}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default injectIntl(PasswordResetDialog);
