import { VendorId } from 'attentive-connect-store/dist/config/Environment';
import * as firebase from 'attentive-connect-store/dist/services/Firebase';
import session from './session/session';
import { getLogger } from './logger';

const logger = getLogger('Vendor', 'info');
const storageKey = 'toggleVendor';

// only set this to true for development
export const canToggleVendor = () => false;

const isVendorId = (vendor: unknown): vendor is VendorId => {
  return typeof vendor === 'string' && (vendor === 'macnica' || vendor === 'toppan');
};
export const getVendor = (): VendorId => {
  let vendor = firebase.FirebaseRuntime.instance.env
    ? firebase.FirebaseRuntime.instance.env.vendor.id
    : 'macnica';
  const sessionVendor = session.getTransientItem(storageKey);
  logger.trace(`getVendor(): configuration is`, { runtimeVendor: vendor, sessionVendor });
  if (isVendorId(sessionVendor)) {
    vendor = sessionVendor;
    logger.debug(`getVendor(): ${vendor} (loaded from session)`);
  } else {
    logger.debug(`getVendor(): ${vendor} (loaded from runtime)`);
  }

  return vendor;
};
export const setVendor = (vendor: VendorId) => {
  logger.debug(`setVendor(${vendor})`);
  if (firebase.FirebaseRuntime.instance.env) {
    const v = firebase.FirebaseRuntime.instance.env.vendor;
    v.id = vendor;
    firebase.FirebaseRuntime.instance.env.setVendor(v);
  }
  session.setTransientItem(storageKey, vendor);
  if (window.ac) {
    window.ac.vendor = { id: vendor };
  }
};
export const isVendorMacnica = () => getVendor() === 'macnica';
export const isVendorToppan = () => getVendor() === 'toppan';
export const toggleVendor = () => {
  if (canToggleVendor()) {
    if (isVendorToppan()) {
      setVendor('macnica');
    } else {
      setVendor('toppan');
    }
  }
};
