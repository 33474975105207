"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionCodeToJSON = exports.PermissionCodeFromJSONTyped = exports.PermissionCodeFromJSON = exports.PermissionCode = void 0;
/**
 * Permission types
 * @export
 * @enum {string}
 */
var PermissionCode;
(function (PermissionCode) {
    PermissionCode["HomeAdd"] = "sys_home_add";
    PermissionCode["HomePage"] = "sys_home_page";
    PermissionCode["UserDel"] = "sys_user_del";
    PermissionCode["HomeEdit"] = "sys_home_edit";
    PermissionCode["UserAdd"] = "sys_user_add";
    PermissionCode["UserPage"] = "sys_user_page";
    PermissionCode["UserUpd"] = "sys_user_upd";
    PermissionCode["HomeDel"] = "sys_home_del";
})(PermissionCode || (exports.PermissionCode = PermissionCode = {}));
function PermissionCodeFromJSON(json) {
    return PermissionCodeFromJSONTyped(json, false);
}
exports.PermissionCodeFromJSON = PermissionCodeFromJSON;
function PermissionCodeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PermissionCodeFromJSONTyped = PermissionCodeFromJSONTyped;
function PermissionCodeToJSON(value) {
    return value;
}
exports.PermissionCodeToJSON = PermissionCodeToJSON;
