"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginAccountToJSON = exports.LoginAccountFromJSONTyped = exports.LoginAccountFromJSON = void 0;
const runtime_1 = require("../runtime");
function LoginAccountFromJSON(json) {
    return LoginAccountFromJSONTyped(json, false);
}
exports.LoginAccountFromJSON = LoginAccountFromJSON;
function LoginAccountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'username': json['username'],
        'password': !(0, runtime_1.exists)(json, 'password') ? undefined : json['password'],
        'salt': !(0, runtime_1.exists)(json, 'salt') ? undefined : json['salt'],
        'phone': !(0, runtime_1.exists)(json, 'phone') ? undefined : json['phone'],
        'avatar': !(0, runtime_1.exists)(json, 'avatar') ? undefined : json['avatar'],
        'homeId': !(0, runtime_1.exists)(json, 'homeId') ? undefined : json['homeId'],
        'createTime': json['createTime'],
        'updateTime': !(0, runtime_1.exists)(json, 'updateTime') ? undefined : json['updateTime'],
        'ip': !(0, runtime_1.exists)(json, 'ip') ? undefined : json['ip'],
        'delFlag': !(0, runtime_1.exists)(json, 'delFlag') ? undefined : json['delFlag'],
    };
}
exports.LoginAccountFromJSONTyped = LoginAccountFromJSONTyped;
function LoginAccountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'username': value.username,
        'password': value.password,
        'salt': value.salt,
        'phone': value.phone,
        'avatar': value.avatar,
        'homeId': value.homeId,
        'createTime': value.createTime,
        'updateTime': value.updateTime,
        'ip': value.ip,
        'delFlag': value.delFlag,
    };
}
exports.LoginAccountToJSON = LoginAccountToJSON;
