import { createSvgIcon } from '@mui/material';

const CardiacIndex = createSvgIcon(
  <path d="M16.5,3c-1.7,0-3.4,0.8-4.5,2.1C10.9,3.8,9.2,3,7.5,3C4.4,3,2,5.4,2,8.5c0,3.8,3.4,6.9,8.5,11.5l1.5,1.3l1.5-1.3
	c5.1-4.7,8.5-7.8,8.5-11.5C22,5.4,19.6,3,16.5,3z M12.9,17.8V7.4h0C13.5,6,15,5,16.5,5c2,0,3.5,1.5,3.5,3.5
	C20,11.2,17.2,13.9,12.9,17.8z" />,
  'CardiacIndex'
);

export { CardiacIndex };
export default CardiacIndex;
