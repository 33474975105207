"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleToJSON = exports.RoleFromJSONTyped = exports.RoleFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function RoleFromJSON(json) {
    return RoleFromJSONTyped(json, false);
}
exports.RoleFromJSON = RoleFromJSON;
function RoleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'roleName': !(0, runtime_1.exists)(json, 'roleName') ? undefined : json['roleName'],
        'roleCode': (0, _1.RoleCodeFromJSON)(json['roleCode']),
        'roleDesc': !(0, runtime_1.exists)(json, 'roleDesc') ? undefined : json['roleDesc'],
        'createTime': json['createTime'],
        'updateTime': !(0, runtime_1.exists)(json, 'updateTime') ? undefined : json['updateTime'],
        'delFlag': !(0, runtime_1.exists)(json, 'delFlag') ? undefined : json['delFlag'],
    };
}
exports.RoleFromJSONTyped = RoleFromJSONTyped;
function RoleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'roleName': value.roleName,
        'roleCode': (0, _1.RoleCodeToJSON)(value.roleCode),
        'roleDesc': value.roleDesc,
        'createTime': value.createTime,
        'updateTime': value.updateTime,
        'delFlag': value.delFlag,
    };
}
exports.RoleToJSON = RoleToJSON;
