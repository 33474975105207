import { useState, useEffect } from 'react';

export const useResizing = (): boolean => {
  const [resizeTimeout, setResizeTimeout] = useState<NodeJS.Timeout | null>(null);
  const [resizing, setResizing] = useState(false);

  useEffect(() => {
    const resizeHandler = () => {
      if (!resizing) {
        setResizing(true);
        if (resizeTimeout) {
          window.clearTimeout(resizeTimeout);
          setResizeTimeout(null);
        }
        setResizeTimeout(
          setTimeout(() => {
            setResizing(false);
          }, 600)
        );
      }
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      if (resizeTimeout) {
        window.clearTimeout(resizeTimeout);
      }
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return resizing;
};
