import './index.css';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Localized from './Localized';
import { getLogger } from './logger';
import { configureStore } from './redux';
import * as actions from './redux/actions';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { isHybrid } from './hybrid';

const logger = getLogger('ac', 'info');
const store = configureStore();

const startApp = () => {
  const container = document.getElementById('root');
  if (!container) {
    throw new Error('root element not found');
  }
  const root = createRoot(container);
  root.render(
    <Provider store={store}>
      <Localized />
    </Provider>
  );
};

if (isHybrid()) {
  document.addEventListener('deviceready', startApp, false);
} else {
  serviceWorkerRegistration.register({
    onSuccess: () => logger.info('service worker registered'),
    onUpdate: () => {
      logger.debug('service worker: onUpdate()');
      store.dispatch(actions.serviceWorker.setNewVersionAvailable(true));
    },
  });
  startApp();
}
