import type {} from '@mui/lab/themeAugmentation'; // When using TypeScript 4.x and above
// import '@mui/lab/themeAugmentation'; // When using TypeScript 3.x and below
import { UserPersonTheme } from 'attentive-connect-store/dist/models/UserPerson';
import { alpha, CSSInterpolation, darken, lighten, styled, Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import * as constants from './constants';
import { isVendorToppan } from '../Vendor';
// import NotoSansJP from '../../public/fonts/NotoSansJP-VariableFont_wght.ttf'

export const includeStyles = (theme: Theme, ...styles: ((theme: Theme) => CSSInterpolation)[]) => {
  return styles.reduce((acc, style) => {
    return { ...(acc as object), ...(style(theme) as object) };
  }, {});
};

export const styledComponent = (
  component: React.ComponentClass | React.FC,
  ...styles: ((theme: Theme) => CSSInterpolation)[]
) => styled(component)(({ theme }) => includeStyles(theme, ...styles));

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary'];
    neutral: Palette['primary'];
    hilite: Palette['primary'];
    awake: Palette['primary'];
    rem: Palette['primary'];
    lightSleep: Palette['primary'];
    deepSleep: Palette['primary'];
    noStatus: Palette['primary'];
  }
  interface TypographyVariants {
    menutitle: React.CSSProperties;
    menusubtitle: React.CSSProperties;
    labeltitle: React.CSSProperties;
    labelsubtitle: React.CSSProperties;
    datatitle: React.CSSProperties;
    datavalue: React.CSSProperties;
    dataunits: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    tertiary?: PaletteOptions['primary'];
    neutral?: PaletteOptions['primary'];
    hilite?: PaletteOptions['primary'];
    awake?: PaletteOptions['primary'];
    rem?: PaletteOptions['primary'];
    lightSleep?: PaletteOptions['primary'];
    deepSleep?: PaletteOptions['primary'];
    noStatus?: PaletteOptions['primary'];
  }
  interface TypographyVariantsOptions {
    menutitle?: React.CSSProperties;
    menusubtitle?: React.CSSProperties;
    labeltitle?: React.CSSProperties;
    labelsubtitle?: React.CSSProperties;
    datatitle?: React.CSSProperties;
    datavalue?: React.CSSProperties;
    dataunits?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    menutitle: true;
    menusubtitle: true;
    labeltitle: true;
    labelsubtitle: true;
    datatitle: true;
    datavalue: true;
    dataunits: true;
  }
}

export const loadTheme = (
  mode = UserPersonTheme.light,
  fontSize = constants.FONT_SIZE_DEFAULT,
  toolbar = false
) => {
  // color calculations
  let bghue = '';
  let color1 = '';
  let color2 = '';
  let color3 = '';
  if (isVendorToppan()) {
    bghue = '215deg';
    color1 = darken('rgb(79,134,198)', 0.05);
    color2 = darken('#35A070', 0.05);
    color3 = '#B5B52E';
  } else {
    bghue = '201deg';
    color1 = '#1071b9';
    color2 = '#239E46';
    color3 = '#E75225';
  }
  // create theme once for custom theme vars
  let theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontSize: fontSize,
          },
        },
      },
    },
    breakpoints: {
      values: {
        // Default
        // xs: 0,
        // sm: 600,
        // md: 900,
        // lg: 1200,
        // xl: 1536,

        // Custom
        xs: 0,
        // xs: 372,
        sm: 728,
        md: 1084,
        lg: 1440,
        xl: 1796,
      },
    },
    palette: {
      mode: toolbar || mode === UserPersonTheme.dark ? 'dark' : 'light',
      background: {
        default:
          mode === UserPersonTheme.dark
            ? 'hsl(' + bghue + ' 12% 21%)'
            : 'hsl(' + bghue + ' 66% 95%)',
        paper: mode === UserPersonTheme.dark ? darken('#121212', 0.333) : '#ffffff',
      },
      primary: {
        main: color1,
      },
      secondary: {
        main: color2,
      },
      success: {
        main: '#29B751',
        light: lighten('#29B751', 0.2),
        dark: darken('#29B751', 0.2),
      },
      error: {
        main: '#D52408',
        light: lighten('#D52408', 0.2),
        dark: darken('#D52408', 0.2),
      },
      warning: {
        main: '#E88B00',
        light: lighten('#E88B00', 0.25),
        dark: darken('#E88B00', 0.25),
      },
      info: {
        main: '#0288D1',
        light: lighten('#0288D1', 0.2),
        dark: darken('#0288D1', 0.2),
      },
      // CUSTOM COLORS
      tertiary: {
        main: color3,
        light: lighten(color3, 0.125),
        dark: darken(color3, 0.125),
      },
      neutral: {
        // main: '#314143',
        main: 'hsl(187deg 20% 25%)',
        contrastText: '#fff',
      },
      hilite: {
        main: '#ffff00',
        contrastText: '#000',
      },
      awake: {
        main: alpha('#FFD303', 1),
        light: lighten('#FFD303', 0.125),
        dark: darken('#FFD303', 0.125),
      },
      rem: {
        main: alpha('#5DFFFF', 1),
        light: lighten('#5DFFFF', 0.125),
        dark: darken('#5DFFFF', 0.125),
      },
      lightSleep: {
        main: alpha('#3CC8FD', 1),
        light: lighten('#3CC8FD', 0.125),
        dark: darken('#3CC8FD', 0.125),
      },
      deepSleep: {
        main: alpha('#0083E8', 1),
        light: lighten('#0083E8', 0.125),
        dark: darken('#0083E8', 0.125),
      },
      noStatus: {
        main: alpha('#515254', 1),
        light: lighten('#515254', 0.125),
        dark: darken('#515254', 0.125),
      },
    },
    typography: {
      fontFamily: [
        // 'Roboto',
        '"Noto Sans JP"',
        '-apple-system',
        'BlinkMacSystemFont',
        'sans-serif',
      ].join(','),
      htmlFontSize: fontSize,
      fontSize: 14,
      button: {
        textTransform: 'none',
      },
    },
  });
  // reload theme with custom vars for style overrides
  const usingLightMode = theme.palette.mode === UserPersonTheme.light ? true : false;
  theme = createTheme(theme, {
    components: {
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: '4rem',
            lineHeight: '1.2em',
            fontWeight: '300',
          },
          h2: {
            fontSize: '3.25rem',
            lineHeight: '1.2em',
            fontWeight: '300',
          },
          h3: {
            fontSize: '2.5rem',
            lineHeight: '1.2em',
            fontWeight: '400',
          },
          h4: {
            fontSize: '2rem',
            lineHeight: '1.25em',
            fontWeight: '400',
          },
          h5: {
            fontSize: '1.5rem',
            lineHeight: '1.25em',
            fontWeight: '500',
          },
          h6: {
            fontSize: '1.25rem',
            lineHeight: '1.25em',
            fontWeight: '500',
          },
          subtitle1: {
            fontSize: '1rem',
            lineHeight: '1.5em',
          },
          subtitle2: {
            fontSize: '.875rem',
            lineHeight: '1.25em',
          },
          body1: {
            fontSize: '1rem',
            lineHeight: '1.5em',
          },
          body2: {
            fontSize: '.875rem',
            lineHeight: '1.25em',
          },
          button: {
            fontSize: '1rem',
            lineHeight: '1.2em',
          },
          caption: {
            fontSize: '.75rem',
            lineHeight: '1.2em',
          },
          overline: {
            fontSize: '.75rem',
            lineHeight: '1.2em',
          },
          // CUSTOM VARIANTS
          menutitle: {
            fontSize: '1.25rem',
            lineHeight: 'inherit',
            fontWeight: '500',
          },
          menusubtitle: {
            fontSize: '1rem',
            lineHeight: 'inherit',
          },
          labeltitle: {
            fontSize: '1.125rem',
            lineHeight: '1.2em',
            fontWeight: '500',
            '.highlight &, .AC-expandTiles &': {
              fontSize: '1.25rem',
            },
          },
          labelsubtitle: {
            fontSize: '.875rem',
            lineHeight: '1.2em',
            color: theme.palette.text.secondary,

            '&.limitedWidth': {
              maxWidth: 420,

              [theme.breakpoints.down('sm')]: {
                maxWidth: 'none',
              },
            },

            '.highlight &, .AC-expandTiles &': {
              fontSize: '1rem',
              fontWeight: '500',
            },
          },
        },
      },
      MuiStack: {
        styleOverrides: {
          root: {
            '&.AC-limitWidth': {
              maxWidth: '100%',
              overflow: 'hidden',
            },

            '&.AC-boxSpacing': {
              gap: theme.spacing(2),
            },

            '&.AC-infoSpacing': {
              gap: theme.spacing(2),
            },

            '&.AC-infoGroup': {
              flexDirection: 'row',
              alignItems: 'center',
              gap: theme.spacing(2),

              [theme.breakpoints.down('sm')]: {
                gap: theme.spacing(1),
              },

              '& .AC-infoIcon': {
                justifySelf: 'center',
                width: theme.spacing(5),
                height: theme.spacing(5),

                '.highlight &': {
                  width: theme.spacing(6),
                  height: theme.spacing(6),
                  // color: theme.palette.action.active,
                },
              },

              '& .AC-infoIconSmall': {
                width: theme.spacing(2.5),
                height: theme.spacing(2.5),
                color: theme.palette.primary.main,
              },
            },

            '&.AC-infoLabel': {
              gap: theme.spacing(0.5),
              maxWidth: '100%',
              minWidth: 0,

              '.AC-compactTiles &': {
                gap: theme.spacing(0.25),
              },

              '& .MuiLink-root': {
                textDecoration: 'none',
                color: usingLightMode ? theme.palette.info.dark : theme.palette.info.light,

                '&:hover': {
                  textDecoration: 'underline',
                },
              },
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            '&.AC-boxSpacing': {
              marginTop: theme.spacing(-2),
              marginLeft: theme.spacing(-2),
              width: 'calc(100% + ' + theme.spacing(2) + ')',

              '& > .MuiGrid-item': {
                paddingLeft: theme.spacing(2),
                paddingTop: theme.spacing(2),
              },
            },
            '&.AC-infoSpacing': {
              marginTop: theme.spacing(-2),
              marginLeft: theme.spacing(-2),
              width: 'calc(100% + ' + theme.spacing(2) + ')',

              '& > .MuiGrid-item': {
                paddingLeft: theme.spacing(2),
                paddingTop: theme.spacing(2),
              },
            },
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            margin: 0,

            '.MuiChip-root': {
              borderRadius: theme.spacing(0.25),
              fontSize: '0.875rem',
              lineHeight: '1.25em',
              height: 26,
              userSelect: 'none',

              '&:not(.MuiChip-clickable)': {
                // color: theme.palette.primary.main,
                backgroundColor: alpha(theme.palette.info.main, 0.24),
              },
            },

            '.MuiChip-icon': {
              color: 'inherit',
              marginLeft: theme.spacing(1),
            },

            '.MuiChip-label': {
              fontWeight: 500,
              letterSpacing: '0.39998px',
            },
          },
          separator: {
            marginLeft: 0,
            marginRight: 0,
          },
        },
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            alignSelf: 'center',
          },
          ul: {
            gap: 2,
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            margin: 0,
            border: 0,
            borderRadius: theme.spacing(0.25),
            backgroundColor: theme.palette.action.selected,
            minHeight: 26,
            fontWeight: 500,
            userSelect: 'none',

            '&:hover': {
              backgroundColor: theme.palette.action.focus,
            },

            '&.Mui-selected, &.Mui-selected:hover': {
              // color: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.info.main, 0.24),
            },
          },
        },
      },

      MuiToolbar: {
        styleOverrides: {
          root: {
            paddingRight: '0 !important',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            // fontSize: '0.875rem',
            // lineHeight: '1.2em',
          },
          label: {
            fontSize: '0.875rem',
            lineHeight: '1.2em',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
            textTransform: 'none',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            // '&.Mui-selected': {
            //   color: usingLightMode
            //     ? theme.palette.info.main
            //     : lighten(theme.palette.info.main, 0.36),
            // },
            '&.MuiToggleButton-warning.Mui-selected': {
              color: usingLightMode
                ? theme.palette.warning.main
                : lighten(theme.palette.warning.main, 0.36),
            },
            '&.MuiToggleButton-success.Mui-selected': {
              color: usingLightMode
                ? theme.palette.success.main
                : lighten(theme.palette.success.main, 0.36),
            },
            '&.MuiToggleButton-error.Mui-selected': {
              color: usingLightMode
                ? theme.palette.error.main
                : lighten(theme.palette.error.main, 0.36),
            },
            '&.MuiToggleButton-info.Mui-selected': {
              color: usingLightMode
                ? theme.palette.info.main
                : lighten(theme.palette.info.main, 0.36),
            },
            '&.MuiToggleButton-primary.Mui-selected': {
              color: usingLightMode
                ? theme.palette.primary.main
                : lighten(theme.palette.primary.main, 0.36),
            },
            '&.MuiToggleButton-secondary.Mui-selected': {
              color: usingLightMode
                ? theme.palette.secondary.main
                : lighten(theme.palette.secondary.main, 0.36),
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            '&.AC-wideButtons button': {
              minWidth: theme.spacing(8),
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            '&.AC-detailsTable': {
              '& td, & th': {
                verticalAlign: 'baseline',
                paddingRight: theme.spacing(2),
                border: 'none',
              },
              '& td:first-of-type': {
                width: '30%',
                minWidth: 120,
              },
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            border: 'none',

            '&:before, &:after': {
              // divider lines
              display: 'none',
            },

            '&.Mui-expanded': {
              margin: 0,
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            borderRadius: theme.spacing(0.5),
            border: 'none',
            color: theme.palette.text.secondary,
            backgroundColor: alpha(theme.palette.info.main, 0.25),
            marginTop: 2,
            minHeight: theme.spacing(5),

            [theme.breakpoints.down('sm')]: {
              borderRadius: theme.spacing(0),
            },

            '&.Mui-expanded': {
              minHeight: theme.spacing(5),
            },

            '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
              marginTop: 0,
              marginBottom: 0,
            },

            '& .AC-accordianTitle': {
              fontWeight: '500',
            },

            '& .AC-accordionChip': {
              fontWeight: '700',
              height: 'auto',

              '& .MuiChip-label': {
                padding: '0 6px',
              },
            },
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: 0,
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(4),
            backgroundColor: 'transparent',
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(1.5),
              paddingBottom: theme.spacing(3),
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            // color: alpha(theme.palette.background.paper, .75),
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.action.disabledBackground,
          },
          circular: {},
          rounded: {
            borderRadius: theme.spacing(0.5),
          },
          img: {
            // padding: 4,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            gap: theme.spacing(2),
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            minHeight: 64,
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            gap: theme.spacing(2),
            alightItems: 'center',

            '.AC-pageContent &': {
              [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                gap: theme.spacing(3),
              },

              [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
              },
            },

            '.AC-largeMenu &': {
              minHeight: '4.5rem',
            },

            '&.AC-optionButton': {
              border: 'none',
              color: 'inherit',
              margin: 0,
              borderRadius: 0,

              '&:hover': {
                backgroundColor: alpha(theme.palette.success.main, 0.09) + '!important',
              },
              '&.AC-selected': {
                backgroundColor: alpha(theme.palette.success.main, 0.18),
              },
            },
          },
          listItemIcon: {
            minWidth: 'auto',
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'auto !important',

            '&:first-of-type': {
              color: theme.palette.primary.main,
            },

            '.AC-largeMenu &': {
              fontSize: theme.spacing(4),
            },
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            '.AC-largeMenu .MuiListItemIcon-root &': {
              fontSize: '2rem',
            },
            '&.AC-rotate90': {
              transform: 'rotate(90deg)',
            },
            '&.AC-rotate180': {
              transform: 'rotate(180deg)',
            },
            '&.AC-rotate270': {
              transform: 'rotate(270deg)',
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {},
          primary: {
            fontSize: '1.125rem',
            lineHeight: '1.125em',
            fontWeight: '500',

            '& p': {
              marginTop: 0,
            },

            '.AC-largeMenu &': {
              fontSize: '1.25rem',

              display: 'flex',
              direction: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          },
          secondary: {
            lineHeight: '1.125em',
          },
        },
      },
      MuiListItemSecondaryAction: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.action.active,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            minHeight: theme.spacing(6) + '!important',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            gap: theme.spacing(2),
            alightItems: 'center',
          },
          listItemIcon: {
            minWidth: 'auto',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            [theme.breakpoints.up('sm')]: {
              paddingTop: theme.spacing(2),
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            },
            [theme.breakpoints.up('md')]: {
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            },
            '.AC-compactCard &': {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            },
          },
          content: {
            // gap: 16,
            // alignItems: 'baseline',
          },
          action: {
            alignSelf: 'end',
            paddingBottom: theme.spacing(1),
          },
          title: {
            fontWeight: 400,
            fontSize: '2.25rem',
            lineHeight: '1.125em',
            // color: theme.palette.tertiary.main,

            [theme.breakpoints.down('sm')]: {
              fontSize: '1.75rem',
            },
          },
          subheader: {
            color: theme.palette.text.secondary,
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: '1.25em',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            },
            [theme.breakpoints.up('md')]: {
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            },
            '&:last-child': {
              paddingBottom: theme.spacing(4),
            },
            '.AC-compactCard &': {
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {},
          paper: {
            minWidth: 500,
            [theme.breakpoints.down('sm')]: {
              minWidth: 'auto',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            display: 'flex',
            gap: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(2),

            '& .MuiSvgIcon-root:first-of-type': {
              fontSize: '1.25em',
              color: theme.palette.primary.main,
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            // [theme.breakpoints.down('sm')]: {
            //   // backgroundColor: theme.palette.background.default,
            //   padding: theme.spacing(1),
            // },
            // [theme.breakpoints.up('md')]: {
            //   padding: theme.spacing(3),
            //   paddingLeft: theme.spacing(3),
            //   paddingRight: theme.spacing(3),
            // },
          },
        },
      },
    },
  });
  return theme;
};
export { constants };
