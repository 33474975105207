"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VitalsFilter = void 0;
var VitalsFilter;
(function (VitalsFilter) {
    VitalsFilter["MANUAL"] = "MANUAL";
    VitalsFilter["AISLEEP"] = "AISLEEP";
    VitalsFilter["SENSING_WAVE"] = "SENSING_WAVE";
    VitalsFilter["OTHER"] = "OTHER";
})(VitalsFilter || (exports.VitalsFilter = VitalsFilter = {}));
