import { createSvgIcon } from '@mui/material';

const CardiacOutput = createSvgIcon(
  <g>
    <path d="M12.1,18.5L12,18.7l-0.1-0.1C7.1,14.2,4,11.4,4,8.5C4,6.5,5.5,5,7.5,5c1.5,0,3,1,3.6,2.4h1.9C13.5,6,15,5,16.5,5
		c2,0,3.5,1.5,3.5,3.5h2C22,5.4,19.6,3,16.5,3c-1.7,0-3.4,0.8-4.5,2.1C10.9,3.8,9.2,3,7.5,3C4.4,3,2,5.4,2,8.5
		c0,3.8,3.4,6.9,8.5,11.5l1.5,1.3l1.5-1.3c1.6-1.5,3.1-2.8,4.3-4h-2.9C14,16.8,13.1,17.6,12.1,18.5z" />
    <polygon points="23,13 19,9 19,12 11,12 11,14 19,14 19,17 	" />
  </g>,
  'CardiacOutput'
);

export { CardiacOutput };
export default CardiacOutput;
