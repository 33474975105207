"use strict";
/* TS enum provides for type-safe enum property and a code/value
 * that is used for internal representation in the database.
 */
Object.defineProperty(exports, "__esModule", { value: true });
var AlertStatusType;
(function (AlertStatusType) {
    // active alert
    AlertStatusType["OPEN"] = "OPEN";
    // snoozed is open, but is not displayed in list of open alerts
    AlertStatusType["OPEN_SNOOZED"] = "OPEN_SNOOZED";
    // muted is open, but is not displayed (should be ignored)
    AlertStatusType["OPEN_DISABLED"] = "OPEN_DISABLED";
    // alert has been marked resolved but is still considered open (still should be watched)
    // e.g. sensor may continue to report an alert even when it's resolved in AC.
    AlertStatusType["RESOLUTION_IN_PROGRESS"] = "RESOLUTION_IN_PROGRESS";
    // resolved by care giver
    AlertStatusType["RESOLVED"] = "RESOLVED";
    // canceled when not resolved withing max duration
    AlertStatusType["CANCELED"] = "CANCELED";
    // expired alert
    AlertStatusType["EXPIRED"] = "EXPIRED";
    // replaced alert (replaced by another one)
    AlertStatusType["REPLACED"] = "REPLACED";
    // expired due to return to bed alert
    AlertStatusType["RETURNED_EXPIRED"] = "RETURNED_EXPIRED";
})(AlertStatusType || (AlertStatusType = {}));
exports.default = AlertStatusType;
