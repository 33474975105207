import { createSvgIcon } from '@mui/material';

const FallAlert = createSvgIcon(
  <g transform="matrix(0.707107,-0.707107,0.707107,0.707107,-5.44366,11.3178)">
    <path d="M13.49,5.48C14.59,5.48 15.49,4.58 15.49,3.48C15.49,2.38 14.59,1.48 13.49,1.48C12.39,1.48 11.49,2.38 11.49,3.48C11.49,4.58 12.39,5.48 13.49,5.48ZM9.89,19.38L10.89,14.98L12.99,16.98L12.99,22.98L14.99,22.98L14.99,15.48L12.89,13.48L13.49,10.48C14.79,11.98 16.79,12.98 18.99,12.98L18.99,10.98C17.09,10.98 15.49,9.98 14.69,8.58L13.69,6.98C13.29,6.38 12.69,5.98 11.99,5.98C11.69,5.98 11.49,6.08 11.19,6.08L5.99,8.28L5.99,12.98L7.99,12.98L7.99,9.58L9.79,8.88L8.19,16.98L3.29,15.98L2.89,17.98L9.89,19.38Z" />
  </g>,
  'FallAlert'
);

export { FallAlert };
export default FallAlert;
