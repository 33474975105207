// Interface to the cordova vibration plugin
// Nothing special is need since it modifies the navigator object

import { Semaphore } from '../data/Semaphore';
import { getLogger } from '../logger';
import { isHybrid } from './isHybrid';

const logger = getLogger('hybrid/HybridVibration');

interface HybridVibrationPlugin {
  vibrate: (val: VibratePattern) => void;
}

export class HybridVibration implements HybridVibrationPlugin {
  private static _instance: HybridVibration | undefined;
  private static _mounted = false;
  private static _semaphore = new Semaphore(1, 'HybridVibration');
  private constructor() {
    // this vibration plugin works through navigator object
  }
  static async mount() {
    await HybridVibration._semaphore.acquire();
    try {
      if (isHybrid() && !HybridVibration._mounted) {
        if (typeof navigator.vibrate === 'function') {
          HybridVibration._instance = new HybridVibration();
          logger.info('vibration plugin: mounted');
        } else {
          logger.info('vibration plugin: not installed');
        }
      }
      HybridVibration._mounted = true;
      return HybridVibration._instance;
    } finally {
      HybridVibration._semaphore.release();
    }
  }
  static get instance() {
    return HybridVibration._instance;
  }
  vibrate(val: VibratePattern) {
    if (typeof navigator.vibrate === 'function') {
      navigator.vibrate(val);
    }
  }
}
