import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { UserPerson, UserPersonTheme, UserProfile } from 'attentive-connect-store/dist/models';
import { Database } from 'attentive-connect-store/dist/services';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Icons } from '../';
import strings from '../../languages';
import * as redux from '../../redux';
import { FONT_SIZE_DEFAULT, FONT_SIZE_LARGE, FONT_SIZE_SMALL } from '../../themes/constants';
import Dialog from './Dialog';
import { CancelButton } from '../buttons';
import { FontSize, FontSizeSettings, ThemeSettings } from '../settings';

interface ComponentProps {
  className?: string;
  sx?: SxProps<Theme>;
  open: boolean;
  onClose: () => void;
}

interface StateProps {
  db?: Database;
  userPerson?: UserPerson;
  userProfile?: UserProfile;
  themeName?: UserPersonTheme;
  fontSize?: number;
  setThemeName: (name: UserPersonTheme) => void;
  setFontSize: (size: number) => void;
  selectUser: (user: UserPerson) => void;
}

const mapStateToProps = ({ auth, layout }: redux.ApplicationState) => ({
  db: auth.database,
  userProfile: auth.userProfile,
  userPerson: auth.userPerson,
  themeName: layout.themeName,
  fontSize: layout.fontSize,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setThemeName: (name: UserPersonTheme) => dispatch(redux.layout.setThemeName(name)),
  setFontSize: (size: number) => dispatch(redux.layout.setFontSize(size)),
  selectUser: (userPerson: UserPerson) => dispatch(redux.auth.selectPerson(userPerson)),
});

type Props = ComponentProps & WrappedComponentProps & StateProps;

export const UserSettingsDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl((props: Props) => {
    const localized = strings(props.intl);

    const getFontSize = (): FontSize => {
      const { fontSize } = props;
      if (fontSize !== undefined && fontSize <= FONT_SIZE_SMALL) {
        return 'small';
      } else if (fontSize !== undefined && fontSize >= FONT_SIZE_LARGE) {
        return 'large';
      } else {
        return 'medium';
      }
    };

    const setFontSize = (newFontSize: FontSize) => {
      const { db, userPerson } = props;
      if (db && userPerson) {
        let fontSize = FONT_SIZE_DEFAULT;
        switch (newFontSize) {
          case 'small':
            fontSize = FONT_SIZE_SMALL;
            break;
          case 'large':
            fontSize = FONT_SIZE_LARGE;
            break;
          default:
            fontSize = FONT_SIZE_DEFAULT;
        }
        userPerson.data.fontSize = fontSize;
        void db.userPersons.update(userPerson).then(() => {
          props.setFontSize(fontSize);
          props.selectUser(userPerson);
        });
      }
    };

    const setTheme = (theme: UserPersonTheme) => {
      const { db, userPerson } = props;
      if (db && userPerson) {
        userPerson.data.theme = theme;
        void db.userPersons.update(userPerson).then(() => {
          props.setThemeName(theme);
          props.selectUser(userPerson);
        });
      }
    };

    return (
      <Dialog
        // sx={{ ...props.sx }}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="system-settings-dialog-title"
      >
        <DialogTitle id="system-settings-dialog-title">
          <Icons.Settings />
          <span>{localized.report.settings()}</span>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 3 }}>
          <Stack className="AC-infoSpacing">
            <Stack>
              <Typography variant="subtitle1">{localized.theme.color()}</Typography>
              <ThemeSettings
                theme={props.themeName ? props.themeName : UserPersonTheme.light}
                onChange={setTheme}
              />
            </Stack>
            <Stack>
              <Typography variant="subtitle1">{localized.theme.fontSize()}</Typography>
              <FontSizeSettings size={getFontSize()} onChange={setFontSize} />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={props.onClose} label={localized.action.close()} />
        </DialogActions>
      </Dialog>
    );
  })
);
