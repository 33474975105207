/**
 * This component is used to play audio notifications when patient's are awake
 * or there are alerts. The component renders sound :)
 */

import {
  AlertWithSensorDetail,
  BedOccupancy,
  CareCenter,
  Resident,
  Sensor,
  SensorType,
} from 'attentive-connect-store/dist/models';
import { Database } from 'attentive-connect-store/dist/services';
import { User } from 'attentive-connect-store/dist/services/Firebase';
import { Listener } from 'attentive-connect-store/dist/services/ListenerService';
import * as React from 'react';
import { connect } from 'react-redux';

import { AisleepVitals } from '../data/Vitals';
import { Language } from '../languages';
import { getLogger } from '../logger';
import * as app from '../redux';
import { SoundLanguage } from '../sounds/Sounds';
import sounds from '../sounds/SoundsStateEngine';
import { aisleepSleepStatus } from '../data/Sensors';

const logger = getLogger('services/AudioNotificationService', 'info');

type ApplicationStateProps = {
  db: Database | undefined;
  center: CareCenter | undefined;
  user: User | undefined;
  language: Language;
  openAlerts: AlertWithSensorDetail[] | undefined;
  playVerbalNotifications: boolean;
  muteAlerts: boolean;
  hideWarnings: boolean;
  muteWarnings: boolean;
  disableWarnings: boolean;
  bedOccupancy: BedOccupancy[];
  // noomiVitals: NoomiVitals[];
  aisleepVitals: AisleepVitals[];
  suspended: boolean;
};

const mapApplicationStateToProps = (state: app.ApplicationState): ApplicationStateProps => ({
  db: state.auth.database,
  center: state.context.center ? state.context.center : undefined,
  user: state.auth.user,
  language: state.locale.language,
  openAlerts: state.context.openAlerts,
  playVerbalNotifications: state.dashboard.playVerbalNotifications,
  muteAlerts: state.dashboard.muteAlerts,
  hideWarnings: state.dashboard.hideWarnings,
  muteWarnings: state.dashboard.muteWarnings,
  disableWarnings: state.dashboard.disableWarnings,
  bedOccupancy: state.dashboard.bedOccupancy,
  // noomiVitals: state.noomi.vitals,
  aisleepVitals: state.aisleep.vitals,
  suspended: state.hybrid.suspended,
});

// the component's properties
type Props = ApplicationStateProps;

type ResidentWithSensors = {
  resident: Resident;
  sensors: Sensor[];
};

interface State {
  initializing: boolean;
  residents: ResidentWithSensors[];
  lastAlertCount: number;
  lastAwakeCount: number;
}

class AudioNotificationService extends React.Component<Props, State> {
  readonly state: State = {
    initializing: false,
    residents: [],
    lastAlertCount: -1,
    lastAwakeCount: -1,
  };
  private willUnmount = false;
  private debugCounter = 0;
  private listeners = new Map<string, Listener>();

  get nameSpace() {
    return 'ui.services.AudioNotificationService';
  }

  async componentDidMount() {
    this.willUnmount = false;
    await this.resetState();
    this.updateSounds(this.props, this.props);
  }

  // Every time the component updates we check if sounds should be played.
  // The interval time forces calls to update every second.
  async componentDidUpdate(prevProps: Props) {
    this.logState();

    // anytime global state changes we need to check if a re-initialization of component state
    // is needed
    if (this.needToReinitialize(prevProps)) {
      await this.resetState();
    }
    this.updateSounds(this.props, prevProps);
  }

  getSoundLanguage = (): SoundLanguage => {
    switch (this.props.language) {
      case 'en-US':
        return 'en';
      default:
        return 'ja';
    }
  };

  logState = () => {
    if (logger.isDebugEnabled()) {
      // const { residents, ...rest } = this.state;
      // logger.debug({
      //   residents: residents.length,
      //   shouldPlay: this.canPlay(),
      //   ...rest,
      //   debugCounter: this.debugCounter,
      //   muteAlerts: this.props.muteAlerts,
      //   muteWarnings: this.props.muteWarnings,
      //   alertCount: this.props.openAlerts ? this.props.openAlerts.length : 0,
      //   awakeCount: this.awakeCount(this.props),
      //   willUnmount: this.willUnmount,
      //   playAlertCount: this.playAlertCount(this.props),
      //   playAwakeCount: this.playAwakeCount(this.props),
      //   muted: this.muted(this.props),
      //   hideWarnings: this.props.hideWarnings,
      //   disableWarning: this.props.disableWarnings,
      // });
      this.debugCounter = this.debugCounter + 1;
    }
  };

  componentWillUnmount() {
    this.willUnmount = true;
  }

  careCenterChanged(prevProps: Props) {
    return (
      !prevProps.center ||
      !this.props.center ||
      prevProps.center.snapshot.id !== this.props.center.snapshot.id
    );
  }

  // listener called when a resident's settings change
  residentModifiedListener = (resident: Resident) => {
    if (this.props.db) {
      let index = -1;
      this.state.residents.forEach((r, i) => {
        if (r.resident.snapshot.id === resident.snapshot.id) {
          index = i;
        }
      });

      if (index >= 0) {
        // if (logger.isDebugEnabled()) {
        //   logger.debug('resident modified', {
        //     lastName: resident.data.lastName,
        //     warningsDisabled: resident.data.warningsDisabled,
        //   });
        // }
        this.props.db.sensors
          .getResidentSensors(this.state.residents[index].resident)
          .then((sensors) => {
            this.state.residents[index] = { resident, sensors };
            this.setState({ residents: this.state.residents });
          });
      }
    }
  };

  // returns true when we need to re-initialize state
  needToReinitialize = (prevProps: Props) => {
    if (!this.state.initializing) {
      if ((!prevProps.db && this.props.db) || (prevProps.db && !this.props.db)) {
        logger.debug('db changed');
        return true;
      }
      if (prevProps.db && this.props.db && prevProps.db.uid !== this.props.db.uid) {
        logger.debug('uid changed');
        return true;
      }
      if (
        (!prevProps.center && this.props.center) ||
        (prevProps.center && !this.props.center) ||
        (prevProps.center &&
          this.props.center &&
          prevProps.center.snapshot.id !== this.props.center.snapshot.id)
      ) {
        logger.debug('center changed');
        return true;
      }
      if (prevProps.language !== this.props.language) {
        logger.debug('language changed');
        return true;
      }
    }

    return false;
  };

  // initializes state
  resetState = async () => {
    const { initializing } = this.state;
    if (!initializing) {
      this.setState({ initializing: true });
      if (!this.props.db) {
        logger.debug('clearing state: no db');
        this.setState({
          residents: [],
          initializing: false,
        });
      } else if (!this.props.center) {
        logger.debug('clearing state: no center');
        this.setState({
          residents: [],
          initializing: false,
        });
      } else {
        logger.debug('initializing state');
        // this.loadAudio();
        // cleanup
        this.state.residents.forEach((r) => {
          if (this.props.db) {
            // logger.debug('stop listening: ' + r.resident.data.lastName);
            this.stopListen(r.resident);
          }
        });
        // load new residents
        if (this.props.db && this.props.center) {
          const database = this.props.db;
          const center = this.props.center;
          await database.residents
            .getActiveResidents(center)
            .then(async (activeResidents) => {
              const residents: ResidentWithSensors[] = await Promise.all(
                activeResidents.map(async (resident): Promise<ResidentWithSensors> => {
                  const sensors = await database.sensors.getResidentSensors(resident);
                  return { resident, sensors };
                })
              );
              residents.forEach((r) => {
                // n.b. make sure to store the listener
                this.listen(r.resident);
              });
              this.setState({ residents, initializing: false });
            })
            .catch((e) => {
              logger.error('An error occurred loading residents.');
              logger.error(e);
              this.setState({ residents: [], initializing: false });
            });
        }
      }
    }
  };

  listen = (resident: Resident) => {
    const { db } = this.props;
    const l = this.listeners.get(resident.snapshot.id);

    if (db) {
      if (l) {
        logger.debug('stop listening: ' + resident.data.lastName);
        db.residents.stopListening(l);
      }
      logger.debug('start listening: ' + resident.data.lastName);
      this.listeners.set(
        resident.snapshot.id,
        db.residents.listen(this.nameSpace, resident, this.residentModifiedListener)
      );
    }
  };

  stopListen = (resident: Resident) => {
    const { db } = this.props;
    const l = this.listeners.get(resident.snapshot.id);

    if (db && l) {
      logger.debug('stop listening: ' + resident.data.lastName);
      db.residents.stopListening(l);
    }
  };

  render() {
    // we don't render anything, just play sounds...
    return <audio controls={false} id="audio-notification" style={{ display: 'none' }}></audio>;
  }

  // returns the number of active (open) alerts
  alertCount = (props: Props) => (props.openAlerts ? props.openAlerts.length : 0);

  // returns true if alerts are muted in AC
  muteAlerts = (props: Props) => props.muteAlerts;

  // returns true if warnings are muted in AC
  muteWarnings = (props: Props) =>
    props.muteWarnings || props.hideWarnings || this.disableWarnings(props);

  // returns true if all sound notifcations are muted in AC
  muted = (props: Props) => this.muteAlerts(props) && this.muteWarnings(props);

  // returns true if warnings are disabled in AC
  disableWarnings = (props: Props) => props.disableWarnings === true;

  // alert count for which audio notifications should be played
  playAlertCount = (props: Props) => (this.muteAlerts(props) ? 0 : this.alertCount(props));

  // awake count for which audio notifications should be played
  playAwakeCount = (props: Props) => (this.muteWarnings(props) ? 0 : this.awakeCount(props));

  isLoading = () => this.state.initializing;

  now = () => Date.now();

  // are there more alerts since the last time checked
  newAlerts = (props: Props, prevProps: Props) =>
    this.playAlertCount(props) > this.playAlertCount(prevProps);

  allAlertsResolved = (props: Props, prevProps: Props) =>
    this.playAlertCount(prevProps) > 0 && this.playAlertCount(props) === 0;

  // are there more patients awake since the last time checked
  newAwakes = (props: Props, prevProps: Props) =>
    this.playAwakeCount(props) > this.playAwakeCount(prevProps);

  allAwakesResolved = (props: Props, prevProps: Props) =>
    this.playAwakeCount(prevProps) > 0 && this.playAwakeCount(props) === 0;

  // returns the current aisleep vitals associated with a resident
  getAisleepVitals = (resident: ResidentWithSensors): AisleepVitals | undefined => {
    if (this.props.aisleepVitals) {
      const v = this.props.aisleepVitals.filter(
        (n) =>
          resident.sensors.find(
            (s) =>
              s.data.sensorType === SensorType.AISLEEP &&
              s.data.aisleep &&
              s.snapshot.id === n.sensor.snapshot.id
          ) !== undefined
      );
      return v.length > 0 ? v[0] : undefined;
    } else {
      return undefined;
    }
  };

  // returns the count of residents that are awake for whom notifictions should be played.
  awakeCount = (props: Props) => {
    const { db, center, bedOccupancy } = props;
    const { residents } = this.state;
    let count = 0;
    if (residents.length > 0 && db && center) {
      count = 0;
      residents.forEach((_resident) => {
        const awakeStatus = aisleepSleepStatus(
          db,
          center,
          _resident.resident,
          _resident.sensors,
          this.getAisleepVitals(_resident),
          bedOccupancy
        );
        if (
          (awakeStatus === 'awake-warning' || awakeStatus === 'awake') &&
          !_resident.resident.data.warningsDisabled
        ) {
          count++;
        }
      });
    }
    logger.debug('awake count: ' + count);
    return count;
  };

  // should notifications be played
  // note, if push is enabled, then stop playing notifications
  canPlay = () =>
    !this.willUnmount &&
    !this.isLoading() &&
    !this.props.suspended &&
    this.props.user !== undefined;

  // the sound player called periodically by the interval timer
  updateSounds = (props: Props, prevProps: Props) => {
    if (logger.isDebugEnabled()) {
      logger.debug('[update sounds] called', {
        canPlay: this.canPlay(),
        suspended: props.suspended,
        muteAlerts: props.muteAlerts,
        muteWarnings: props.muteWarnings,
        isLoading: this.isLoading(),
        willUnmount: this.willUnmount,
      });
    }
    if (prevProps && logger.isDebugEnabled()) {
      logger.debug('[update sounds] props changed');
      if (props.suspended !== prevProps.suspended) {
        logger.debug(
          `[update sounds] all sounds are now ${props.suspended ? 'suspended' : 'active'}`
        );
      }
      if (props.muteAlerts !== prevProps.muteAlerts) {
        logger.debug(`[update sounds] alerts are now ${props.muteAlerts ? 'muted' : 'playable'}`);
      }
      if (props.muteWarnings !== prevProps.muteWarnings) {
        logger.debug(
          `[update sounds] warnings (awake) are now ${props.muteWarnings ? 'muted' : 'playable'}`
        );
      }
    }
    if (!this.canPlay()) {
      // stop all sounds
      logger.debug('[update sounds] can play is false');
      if (this.state.lastAlertCount !== -1 || this.state.lastAwakeCount !== 0) {
        sounds.setAlertCount(this.getSoundLanguage(), -1);
        sounds.setAwakeCount(this.getSoundLanguage(), 0);
        this.setState({ lastAlertCount: -1, lastAwakeCount: 0 });
      }
    } else {
      const alertCount = this.props.muteAlerts ? -1 : this.alertCount(props);
      const awakeCount =
        this.muteAlerts(props) || this.muteWarnings(props) ? 0 : this.awakeCount(props);
      logger.debug(`[update sounds] ${alertCount} alerts, ${awakeCount} awake`);
      if (alertCount !== this.state.lastAlertCount || awakeCount !== this.state.lastAwakeCount) {
        logger.debug(`[update sounds] state changed - updating state enging`);
        sounds.setAlertCount(this.getSoundLanguage(), alertCount);
        sounds.setAwakeCount(this.getSoundLanguage(), awakeCount);
        this.setState({ lastAlertCount: alertCount, lastAwakeCount: awakeCount });
      }
    }
  };
}

export default connect(mapApplicationStateToProps)(AudioNotificationService);
