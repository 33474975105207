"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep Client API
 * This document is a specification for the API that must be implemented by AiSleep client applications (i.e. AttentiveConnect) so that AiSleep servers can push data to clients. This is a specifications for the callbacks.  This specification is used to generate the code that is used by AC to integrate AiSleep data.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes). The below status codes are used in this interface:    - 200 OK - Standard response for successful HTTP requests.   - 201 Created - The request has been fulfilled, resulting in the creation of a new resource   - 202 Accepted - The request has been accepted for processing, but the processing has not been completed.   - 400 Bad Request - The server cannot or will not process the request due to an apparent client         error (e.g., malformed request syntax, size too large, invalid request message framing,         or deceptive request routing)   - 404 Not Found - The requested resource could not be found   - 500 Internal Server Error
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VitalsToJSON = exports.VitalsFromJSONTyped = exports.VitalsFromJSON = void 0;
const _1 = require("./");
function VitalsFromJSON(json) {
    return VitalsFromJSONTyped(json, false);
}
exports.VitalsFromJSON = VitalsFromJSON;
function VitalsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sdata': (0, _1.VitalsDataFromJSON)(json['sdata']),
        'deviceId': json['deviceId'],
    };
}
exports.VitalsFromJSONTyped = VitalsFromJSONTyped;
function VitalsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sdata': (0, _1.VitalsDataToJSON)(value.sdata),
        'deviceId': value.deviceId,
    };
}
exports.VitalsToJSON = VitalsToJSON;
