import { Howl, HowlOptions } from 'howler';

import { fullPath } from '../Deployment';
import { Hybrid } from '../hybrid';
import { getLogger } from '../logger';

const logger = getLogger('sounds/Sounds');

// export type Language = 'en' | 'ja' | 'zh' | 'he' | 'pt' | 'pt_BR';
export type SoundLanguage = 'en' | 'ja';
export type Player = (sounds?: Sounds) => Promise<unknown>;

export interface Sounds {
  audio: Howl;
  sprite?: { [name: string]: [number, number] | [number, number, boolean] };
}

export const soundCache: { [name: string]: Howl } = {};

export const cacheSound = (name: string, sound: Howl): void => {
  soundCache[name] = sound;
};

interface SoundFiles {
  en: string;
  ja: string;
}
type SoundName =
  | 'newAlerts'
  | 'activeAlerts'
  | 'alertsResolved'
  | 'ding'
  | 'oneActiveAlert'
  | 'twoActiveAlerts'
  | 'severalActiveAlerts'
  | 'onePersonAwake'
  | 'twoPeopleAwake'
  | 'severalPeopleAwake';

interface SoundFileMap {
  newAlerts: SoundFiles;
  activeAlerts: SoundFiles;
  alertsResolved: SoundFiles;
  ding: SoundFiles;
  oneActiveAlert: SoundFiles;
  twoActiveAlerts: SoundFiles;
  severalActiveAlerts: SoundFiles;
  onePersonAwake: SoundFiles;
  twoPeopleAwake: SoundFiles;
  severalPeopleAwake: SoundFiles;
}

const soundFiles: SoundFileMap = {
  newAlerts: {
    en: 'audio/new_alert.mp3',
    ja: 'audio/new_alert.mp3',
  },
  activeAlerts: {
    en: 'audio/special_alerts_4s.mp3',
    ja: 'audio/special_alerts_4s.mp3',
  },
  alertsResolved: {
    en: 'audio/alerts_resolved.mp3',
    ja: 'audio/alerts_resolved.mp3',
  },
  ding: {
    en: 'audio/ding.mp3',
    ja: 'audio/ding.mp3',
  },
  oneActiveAlert: {
    en: 'audio/en_one_active_alert.mp3',
    ja: 'audio/ja_one_active_alert.mp3',
  },
  twoActiveAlerts: {
    en: 'audio/en_two_active_alerts.mp3',
    ja: 'audio/ja_two_active_alerts.mp3',
  },
  severalActiveAlerts: {
    en: 'audio/en_several_active_alerts.mp3',
    ja: 'audio/ja_several_active_alerts.mp3',
  },
  onePersonAwake: {
    en: 'audio/en_one_person_awake.mp3',
    ja: 'audio/ja_one_person_awake.mp3',
  },
  twoPeopleAwake: {
    en: 'audio/en_two_people_awake.mp3',
    ja: 'audio/ja_two_people_awake.mp3',
  },
  severalPeopleAwake: {
    en: 'audio/en_several_people_awake.mp3',
    ja: 'audio/ja_several_people_awake.mp3',
  },
};

export const mediaPath = (path: string): string => {
  const hybrid = Hybrid.instance();
  if (hybrid && hybrid.filePlugin && hybrid.filePlugin.applicationDirectory()) {
    return hybrid.filePlugin.applicationDirectory() + 'www/' + path;
  }
  return fullPath(path);
};

export const loadSound = (name: SoundName, language: SoundLanguage): Sounds => {
  const cacheKey = `${language}_${name}`;
  if (!soundCache[cacheKey]) {
    logger.debug(`loadSound: ${name}`);
    const file = soundFiles[name][language];
    const src = mediaPath(file);
    const sound = new Howl({
      src: [src],
      html5: true,
      onload: () => {
        logger.debug(`loaded sound file: ${src}`);
      },
      onloaderror: (id, error) => {
        logger.error(`error loading sound file '${src}': ${error}`);
      },
    });
    sound.load();
    soundCache[cacheKey] = sound;
  }
  return { audio: soundCache[cacheKey] };
};

export const loadAudioSprite = (lang: SoundLanguage): Promise<Sounds | undefined> => {
  const prefix = lang + '/';
  const metadata = mediaPath(prefix + 'audiosprite.json');
  return fetch(metadata)
    .then((response) => response.json() as unknown as HowlOptions)
    .then((options) => {
      options.src = mediaPath(prefix + 'audiosprite.mp3');
      logger.debug('loaded audio sprite:', {
        metadata,
        options,
      });
      return { audio: new Howl(options), sprite: options.sprite };
    })
    .catch((e: Error) => {
      logger.notice("Audio sprite couldn't be loaded:", {
        metadata,
        lang,
        e,
      });
      return undefined;
    });
};

const PAUSE = 2000;
const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const playNothing: Player = (sounds?: Sounds) => Promise.resolve();
export const playDing: Player = (sounds?: Sounds) => {
  if (sounds) {
    sounds.audio.play('ding');
  }
  if (sounds?.sprite) {
    return sleep(sounds.sprite.ding[1] + PAUSE);
  } else {
    return Promise.resolve();
  }
};
export const playOneActiveAlert: Player = (sounds?: Sounds) => {
  if (sounds) {
    sounds.audio.play('one_active_alert');
  }
  if (sounds?.sprite) {
    return sleep(sounds.sprite.one_active_alert[1] + PAUSE);
  } else {
    return Promise.resolve();
  }
};
export const playTwoActiveAlerts: Player = (sounds?: Sounds) => {
  if (sounds) {
    sounds.audio.play('two_active_alerts');
  }
  if (sounds?.sprite) {
    return sleep(sounds.sprite.two_active_alerts[1] + PAUSE);
  } else {
    return Promise.resolve();
  }
};
export const playSeveralActiveAlerts: Player = (sounds?: Sounds) => {
  if (sounds) {
    sounds.audio.play('several_active_alerts');
  }
  if (sounds?.sprite) {
    return sleep(sounds.sprite.several_active_alerts[1] + PAUSE);
  } else {
    return Promise.resolve();
  }
};
export const playOnePersonAwake: Player = (sounds?: Sounds) => {
  if (sounds) {
    sounds.audio.play('one_person_awake');
  }
  if (sounds?.sprite) {
    return sleep(sounds.sprite.one_person_awake[1] + PAUSE);
  } else {
    return Promise.resolve();
  }
};
export const playTwoPeopleAwake: Player = (sounds?: Sounds) => {
  if (sounds) {
    sounds.audio.play('two_people_awake');
  }
  if (sounds?.sprite) {
    return sleep(sounds.sprite.two_people_awake[1] + PAUSE);
  } else {
    return Promise.resolve();
  }
};
export const playSeveralPeopleAwake: Player = (sounds?: Sounds) => {
  if (sounds) {
    sounds.audio.play('several_people_awake');
  }
  if (sounds?.sprite) {
    return sleep(sounds.sprite.several_people_awake[1] + PAUSE);
  } else {
    return Promise.resolve();
  }
};
