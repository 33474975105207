import { WrappedComponentProps, injectIntl } from 'react-intl';
import strings from '../../languages';
import * as Icons from '../../icons';
import { Stack, SxProps, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

interface ComponentProps {
  className?: string;
  sx?: SxProps<Theme>;
  // callback when a selection is made
  onChange: (enabled: boolean) => void;
  // the current selection
  enabled: boolean;
}

type Props = ComponentProps & WrappedComponentProps;

export const DashboardAwakeSettings = injectIntl((props: Props) => {
  const l = strings(props.intl);

  return (
    <Stack spacing={0.5}>
      <ToggleButtonGroup
        sx={{ ...props.sx }}
        value={props.enabled}
        exclusive
        onChange={(event: unknown, muted: boolean) => props.onChange(muted)}
        aria-label="awake-settings"
        className="AC-wideButtons"
      >
        <ToggleButton value={true} aria-label="show-bedtime-warnings" color="info">
          <Icons.AwakeOn />
        </ToggleButton>
        <ToggleButton value={false} aria-label="hide-bedtime-warnings" color="warning">
          <Icons.AwakeOff />
        </ToggleButton>
      </ToggleButtonGroup>
      {props.enabled ? (
        <Typography sx={{ paddingRight: 1 }} fontSize="small">
          {l.label.show()}
        </Typography>
      ) : (
        <Typography sx={{ paddingRight: 1 }} fontSize="small">
          {l.label.hide()}
        </Typography>
      )}
    </Stack>
  );
});
