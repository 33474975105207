import { Reducer } from 'redux';

import { ServiceWorkerActionTypes, ServiceWorkerState } from './types';

// Type-safe initialState!
const initialState: ServiceWorkerState = {
  newVersionAvailable: false,
  newVersionOnServer: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<ServiceWorkerState> = (state: ServiceWorkerState = initialState, action) => {
  switch (action.type) {
    case ServiceWorkerActionTypes.NEW_VERSION_AVAILABLE: {
      return { ...state, newVersionAvailable: action.payload as boolean };
    }
    case ServiceWorkerActionTypes.NEW_VERSION_ON_SERVER: {
      return { ...state, newVersionOnServer: action.payload as boolean };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as serviceWorkerReducer };
