import { createSvgIcon } from '@mui/material';

const CareCenterBox = createSvgIcon(
  <path d="M19,3C20.1,3 21,3.9 21,5L21,19C21,20.1 20.1,21 19,21L5,21C3.9,21 3,20.1 3,19L3,5C3,3.9 3.9,3 5,3L19,3ZM15.889,11.222L15.889,5L8.111,5L8.111,8.111L5,8.111L5,19L11.222,19L11.222,15.889L12.778,15.889L12.778,19L19,19L19,11.222L15.889,11.222ZM11.222,8.111L9.667,8.111L9.667,6.556L11.222,6.556L11.222,8.111ZM11.222,14.333L9.667,14.333L9.667,12.778L11.222,12.778L11.222,14.333ZM8.111,14.333L6.556,14.333L6.556,12.778L8.111,12.778L8.111,14.333ZM8.111,11.222L6.556,11.222L6.556,9.667L8.111,9.667L8.111,11.222ZM17.444,14.333L15.889,14.333L15.889,12.778L17.444,12.778L17.444,14.333ZM14.333,8.111L12.778,8.111L12.778,6.556L14.333,6.556L14.333,8.111ZM14.333,14.333L12.778,14.333L12.778,12.778L14.333,12.778L14.333,14.333ZM17.444,17.444L15.889,17.444L15.889,15.889L17.444,15.889L17.444,17.444ZM11.222,11.222L9.667,11.222L9.667,9.667L11.222,9.667L11.222,11.222ZM14.333,11.222L12.778,11.222L12.778,9.667L14.333,9.667L14.333,11.222ZM8.111,17.444L6.556,17.444L6.556,15.889L8.111,15.889L8.111,17.444Z" />
  ,
  'CareCenterBox'
);

export { CareCenterBox };
export default CareCenterBox;
