import classNames from 'classnames';
import * as React from 'react';
import * as Icons from '../icons';
import {
  CSSInterpolation,
  IconButton,
  SnackbarCloseReason,
  SnackbarContent,
  Theme,
  styled,
} from '@mui/material';
import { includeStyles } from '../themes';

const styles = {
  success: (theme: Theme): CSSInterpolation => ({
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  }),
  error: (theme: Theme): CSSInterpolation => ({
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  }),
  info: (theme: Theme): CSSInterpolation => ({
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
  }),
  warning: (theme: Theme): CSSInterpolation => ({
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  }),
  icon: (): CSSInterpolation => ({
    fontSize: 20,
  }),
  iconVariant: (theme: Theme): CSSInterpolation => ({
    opacity: 0.9,
    marginRight: theme.spacing(1),
  }),
  message: (): CSSInterpolation => ({
    display: 'flex',
    alignItems: 'center',
  }),
};

const Styled = {
  CloseIcon: styled(Icons.Close)(({ theme }) => includeStyles(theme, styles.icon)),
  Message: styled('span')(({ theme }) => includeStyles(theme, styles.message)),
  SnackbarContent: {
    Warning: styled(SnackbarContent)(({ theme }) => includeStyles(theme, styles.warning)),
    Error: styled(SnackbarContent)(({ theme }) => includeStyles(theme, styles.error)),
    Info: styled(SnackbarContent)(({ theme }) => includeStyles(theme, styles.info)),
    Success: styled(SnackbarContent)(({ theme }) => includeStyles(theme, styles.success)),
  },
};

const variantIcon = {
  success: styled(Icons.StatusSuccess)(({ theme }) =>
    includeStyles(theme, styles.icon, styles.iconVariant)
  ),
  warning: styled(Icons.StatusWarning)(({ theme }) =>
    includeStyles(theme, styles.icon, styles.iconVariant)
  ),
  error: styled(Icons.StatusError)(({ theme }) =>
    includeStyles(theme, styles.icon, styles.iconVariant)
  ),
  info: styled(Icons.StatusInfo)(({ theme }) =>
    includeStyles(theme, styles.icon, styles.iconVariant)
  ),
};

export type ContentVariant = 'success' | 'warning' | 'error' | 'info';

interface ContentProps {
  className?: string;
  message: string | JSX.Element;
  variant: ContentVariant;
  onClose: (event: React.MouseEvent<HTMLElement>, reason: SnackbarCloseReason) => void;
  closeButton?: boolean;
  action?: JSX.Element[];
}

type AllContentProps = ContentProps;

function NotificationContent(props: AllContentProps) {
  const { closeButton, className, message, onClose, variant, action, ...other } = props;
  const Icon = variantIcon[variant];
  const notificationActions = new Array<JSX.Element>();

  if (action) {
    action.forEach((el) => notificationActions.push(el));
  }
  if (closeButton) {
    notificationActions.push(
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={(event: React.MouseEvent<HTMLElement>) => onClose(event, 'clickaway')}
      >
        <Styled.CloseIcon />
      </IconButton>
    );
  }

  const Content =
    variant === 'success'
      ? Styled.SnackbarContent.Success
      : variant === 'warning'
      ? Styled.SnackbarContent.Warning
      : variant === 'error'
      ? Styled.SnackbarContent.Error
      : Styled.SnackbarContent.Info;

  return (
    <Content
      className={classNames(className)}
      aria-describedby="client-snackbar"
      message={
        <Styled.Message id="client-snackbar">
          <Icon />
          {message}
        </Styled.Message>
      }
      action={notificationActions}
      {...other}
    />
  );
}

export default NotificationContent;
