import { createSvgIcon } from '@mui/material';

const Restlessness = createSvgIcon(
  <g>
    <path d="M7.1,16.1c1.2-1.2,1.1-3.1,0-4.2c-1.2-1.2-3.1-1.1-4.2,0c-1.2,1.2-1.1,3.1,0,4.2C4.1,17.3,6,17.3,7.1,16.1z" />
    <path d="M18,11H9v6h13v-2C22,12.8,20.2,11,18,11z" />
    <rect x="2" y="18" width="20" height="3" />
    <path d="M12,4c2.5,0,4.7,1,6.3,2.6l1.4-1.4C17.8,3.2,15,2,12,2S6.2,3.2,4.2,5.2l1.4,1.4C7.3,5,9.5,4,12,4z" />
    <path d="M7,8.1l1.4,1.4C9.3,8.6,10.6,8,12,8s2.7,0.6,3.6,1.5L17,8.1C15.7,6.8,14,6,12,6S8.3,6.8,7,8.1z" />
  </g>,
  'Restlessness'
);

export { Restlessness };
export default Restlessness;
