import { createSvgIcon } from '@mui/material';

// TODO - @jami: replace the contents of this file with the contents of the 'snoozing' icon you want to create.
const InBedDozing = createSvgIcon(
  <>
    <path d="M9,11L19.016,11C21.226,11 23.016,12.79 23.016,15L23,22L21,22L21,19L3,19L3,22L1,22L1,7L3,7L3,16L9,16L9,11ZM8,13C8,11.89 7.11,11 6,11C4.89,11 4,11.89 4,13C4,14.11 4.89,15 6,15C7.11,15 8,14.11 8,13Z" />
    <g transform="matrix(0.875,0,0,-0.875,1.5,13)">
      <path d="M15,11L20,7.45L20,5L15,8.55L10,5L4,8.66L4,11L9.92,7.39L15,11Z" />
    </g>
  </>,
  'InBedDozing'
);

export { InBedDozing };
export default InBedDozing;
