"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlarmStopToJSON = exports.AlarmStopFromJSONTyped = exports.AlarmStopFromJSON = exports.AlarmStopTypeEnum = void 0;
/**
* @export
* @enum {string}
*/
var AlarmStopTypeEnum;
(function (AlarmStopTypeEnum) {
    AlarmStopTypeEnum[AlarmStopTypeEnum["NUMBER_1"] = 1] = "NUMBER_1";
    AlarmStopTypeEnum[AlarmStopTypeEnum["NUMBER_2"] = 2] = "NUMBER_2";
    AlarmStopTypeEnum[AlarmStopTypeEnum["NUMBER_3"] = 3] = "NUMBER_3";
})(AlarmStopTypeEnum || (exports.AlarmStopTypeEnum = AlarmStopTypeEnum = {}));
function AlarmStopFromJSON(json) {
    return AlarmStopFromJSONTyped(json, false);
}
exports.AlarmStopFromJSON = AlarmStopFromJSON;
function AlarmStopFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'skey': json['skey'],
        'deviceid': json['deviceid'],
        'type': json['type'],
    };
}
exports.AlarmStopFromJSONTyped = AlarmStopFromJSONTyped;
function AlarmStopToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'skey': value.skey,
        'deviceid': value.deviceid,
        'type': value.type,
    };
}
exports.AlarmStopToJSON = AlarmStopToJSON;
