import { createSvgIcon } from '@mui/material';

const StrokeVolume = createSvgIcon(
  <path d="M16.5,3c-1.7,0-3.4,0.8-4.5,2.1C10.9,3.8,9.2,3,7.5,3C4.4,3,2,5.4,2,8.5c0,3.8,3.4,6.9,8.5,11.5l1.5,1.3l1.5-1.3
	c5.1-4.7,8.5-7.8,8.5-11.5C22,5.4,19.6,3,16.5,3z M16.5,5c2,0,3.5,1.5,3.5,3.5c0,1.5-2.9,3.2-7.1,3.5V7.4h0C13.5,6,15,5,16.5,5z
	 M7.5,5c1.5,0,3,1,3.6,2.4V12C6.9,11.7,4,10,4,8.5C4,6.5,5.5,5,7.5,5z M6.2,13c1.4,0.5,3.1,0.9,4.9,1v3.8C9.1,16,7.4,14.4,6.2,13z" />,
  'StrokeVolume'
);

export { StrokeVolume };
export default StrokeVolume;
