import {
  isSensingWavePatientStatusAwake,
  isSensingWavePatientStatusMoving,
  isSensingWavePatientStatusOutOfBed,
  isSensingWavePatientStatusSitting,
  isSensingWavePatientStatusSleeping,
  isSensingWavePatientStatusUnknown,
} from 'attentive-connect-store/dist/mappers';
import { AlertType, SensorStatus } from 'attentive-connect-store/dist/models';
import {
  FallAlertSettings,
  RangeAlertSettings,
} from 'attentive-connect-store/dist/models/AlertSettings';
import RiskLevelType from 'attentive-connect-store/dist/models/RiskLevelType';
import { SensingWaveAlertSettings } from 'attentive-connect-store/dist/models/SensorAlertSettings';
import * as timeRange from 'attentive-connect-store/dist/models/TimeRange';
import { Database } from 'attentive-connect-store/dist/services';

import { StringsIntl } from '../languages';
import { getLogger } from '../logger';
import { SensingWaveVitals } from './Vitals';

const logger = getLogger('data/SensingWave');
logger.debug();

export type SittingStatusType = 'sitting' | 'not-sitting' | 'na';
export type InBedStatusType = 'in' | 'out' | 'na';
export type SleepStatusType = 'asleep' | 'awake' | 'na';

export const fallAlertSettings = (
  risk: RiskLevelType,
  current?: FallAlertSettings | null
): FallAlertSettings => {
  const fall: FallAlertSettings = {
    riskLevel: risk,
    timeOutOfBedSeconds: current ? current.timeOutOfBedSeconds : -1,
    timeRange: current && current.timeRange ? { ...current.timeRange } : null,
  };

  switch (risk) {
    case RiskLevelType.VERY_LOW:
    case RiskLevelType.LOW:
      // alert when resident leaves the bed during the night
      fall.timeRange = { ...timeRange.DEFAULT_TIMERANGE_NIGHT };
      if (fall.timeOutOfBedSeconds < 0) {
        fall.timeOutOfBedSeconds = 30 * 60; // 30 minutes
      }
      break;

    case RiskLevelType.HIGH:
    case RiskLevelType.VERY_HIGH:
      // early alert after 1 minute in bed
      fall.timeRange = { ...timeRange.DEFAULT_TIMERANGE_ALWAYS };
      if (fall.timeOutOfBedSeconds < 0) {
        fall.timeOutOfBedSeconds = 60; // 1 minute
      }
      break;

    case RiskLevelType.CUSTOM:
      // custom time range
      if (!fall.timeRange) {
        fall.timeRange = { ...timeRange.DEFAULT_TIMERANGE_NIGHT };
      }
      if (fall.timeOutOfBedSeconds < 0) {
        fall.timeOutOfBedSeconds = 10 * 60; // 10 minutes
      }
      break;

    case RiskLevelType.NONE:
    case RiskLevelType.MEDIUM:
    default:
      fall.timeRange = null;
      fall.timeOutOfBedSeconds = -1;
      break;
  }

  return fall;
};

/**
 * Ensure we have minimal BR alert settings for a risk level.
 *
 * @param risk the risk level for which we want settings.
 * @param current the current BR settings.
 */
export const respirationAlertSettings = (
  risk: RiskLevelType,
  current?: RangeAlertSettings | null
): RangeAlertSettings => {
  const settings: RangeAlertSettings = current
    ? { ...current }
    : {
        riskLevel: risk,
        duration: -1,
        max: null,
        min: null,
        percent: -1,
        cooloff: -1,
      };

  switch (risk) {
    case RiskLevelType.CUSTOM:
      settings.riskLevel = risk;
      if (settings.duration < 0) {
        settings.duration = 60; // 1 minute
      }
      if (settings.max === null || settings.max < 0) {
        settings.max = 60;
      }
      if (settings.min === null || settings.min < 0) {
        settings.min = 10;
      }
      if (settings.percent < 0 || settings.percent > 1) {
        settings.percent = 0.8;
      }
      if (settings.cooloff < 0) {
        settings.cooloff = 30; // 30 seconds
      }
      break;

    case RiskLevelType.NONE:
    default:
      settings.riskLevel = RiskLevelType.NONE;
      settings.max = null;
      settings.min = null;
      settings.duration = -1;
      settings.cooloff = -1;
      settings.percent = -1;
      break;
  }

  return settings;
};

/**
 * Ensure we have minimal HR alert settings for a risk level.
 *
 * @param risk the risk level for which we want settings.
 * @param current the current BR settings.
 */
export const heartRateAlertSettings = (
  risk: RiskLevelType,
  current?: RangeAlertSettings | null
): RangeAlertSettings => {
  const settings: RangeAlertSettings = current
    ? { ...current }
    : {
        riskLevel: risk,
        duration: -1,
        max: null,
        min: null,
        percent: -1,
        cooloff: -1,
      };

  switch (risk) {
    case RiskLevelType.CUSTOM:
      settings.riskLevel = risk;
      if (settings.duration < 0) {
        settings.duration = 60; // 1 minute
      }
      if (settings.max === null || settings.max < 0) {
        settings.max = 130;
      }
      if (settings.min === null || settings.min < 0) {
        settings.min = 50;
      }
      if (settings.percent < 0 || settings.percent > 1) {
        settings.percent = 0.8;
      }
      if (settings.cooloff < 0) {
        settings.cooloff = 30; // 30 seconds
      }
      break;

    case RiskLevelType.NONE:
    default:
      settings.riskLevel = RiskLevelType.NONE;
      settings.max = null;
      settings.min = null;
      settings.duration = -1;
      settings.cooloff = -1;
      settings.percent = -1;
      break;
  }

  return settings;
};

export const getAlertSettingsDescription = (
  alertType: AlertType,
  sensingWave: SensingWaveAlertSettings,
  localized: StringsIntl
) => {
  let description = '';

  switch (alertType) {
    case AlertType.BREATHING:
      // TODO
      break;
    case AlertType.HEART:
      // TODO
      break;
    case AlertType.FALL:
      if (sensingWave.processFallAlerts) {
        // TODO
      } else {
        description = localized.alerts.noomi.offDescription();
      }
      break;
    default:
      description = '';
  }

  return description;
};

export const sensorStatus = (vitals?: SensingWaveVitals): SensorStatus => {
  let status: SensorStatus = 'other';

  if (vitals) {
    status = vitals.sensorStatus();
  }
  return status;
};

export const movementLevel = (
  db: Database | undefined,
  vitals: SensingWaveVitals | undefined
): number | undefined => {
  const _status = sensorStatus(vitals);
  const sensorOk = _status === 'ok' || _status === 'limited-data';

  if (sensorOk && isInBed(vitals)) {
    if (vitals && vitals.vitals && vitals.vitals.data.sensingWave) {
      if (isSensingWavePatientStatusMoving(vitals.vitals.data.sensingWave)) {
        return 1;
      } else {
        return 0;
      }
    }
  }
  return undefined;
};

export const isStatusUnknown = (
  db: Database | undefined,
  vitals: SensingWaveVitals | undefined
): boolean | undefined => {
  const _status = sensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';

  if (ok && db && vitals && vitals.vitals && vitals.vitals.data.sensingWave) {
    return isSensingWavePatientStatusUnknown(vitals.vitals.data.sensingWave);
  }
  return undefined;
};

export const inBedStatus = (vitals: SensingWaveVitals | undefined): InBedStatusType => {
  const _status = sensorStatus(vitals);
  const sensorOk = _status === 'ok' || _status === 'limited-data';
  let status: InBedStatusType = 'na';

  if (sensorOk) {
    if (isOutOfBed(vitals)) {
      status = 'out';
    } else if (isInBed(vitals)) {
      status = 'in';
    } else {
      status = 'na';
    }
  }

  logger.debug(`inBedStatus -> :`, { sensorOk, status });
  return status;
};

export const isInBed = (vitals: SensingWaveVitals | undefined): boolean | undefined => {
  if (
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.sensingWave
    // vitals.vitals.data.sensingWaveUserDetection
  ) {
    // const { inBedTime, outOfBedTime } = vitals.vitals.data.sensingWaveUserDetection;
    if (
      !isSensingWavePatientStatusOutOfBed(vitals.vitals.data.sensingWave) &&
      !isSensingWavePatientStatusUnknown(vitals.vitals.data.sensingWave)
      // inBedTime > outOfBedTime
    ) {
      logger.debug('isInBed -> true');
      return true;
    } else {
      logger.debug('isInBed -> false');
      return false;
    }
  }

  return undefined;
};

export const isOutOfBed = (vitals: SensingWaveVitals | undefined): boolean | undefined => {
  if (
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.sensingWave
    // vitals.vitals.data.sensingWaveUserDetection
  ) {
    // const { inBedTime, outOfBedTime } = vitals.vitals.data.sensingWaveUserDetection;
    if (
      isSensingWavePatientStatusOutOfBed(vitals.vitals.data.sensingWave)
      // ||
      // (!isSensingWavePatientStatusUnknown(vitals.vitals.data.sensingWave.sdata.status) &&
      //   inBedTime < outOfBedTime)
    ) {
      logger.debug('isOutOfBed -> true', {
        status: vitals.vitals.data.sensingWave,
        // inBedTime,
        // outOfBedTime,
      });
      return true;
    } else {
      logger.debug('isOutOfBed -> false', {
        status: vitals.vitals.data.sensingWave,
        // inBedTime,
        // outOfBedTime,
      });
      return false;
    }
  }

  logger.debug('isOutOfBed -> undefined');
  return undefined;
};

export const timeOutOfBed = (vitals: SensingWaveVitals | undefined): number | undefined => {
  if (isOutOfBed(vitals) && vitals?.vitals.data.sensingWaveUserDetection?.outOfBedTime) {
    const now = Date.now();
    return now - vitals.vitals.data.sensingWaveUserDetection.outOfBedTime;
  }

  return undefined;
};

export const timeOutOfBedMinutes = (vitals: SensingWaveVitals | undefined): number | undefined => {
  const time = timeOutOfBed(vitals);
  if (time !== undefined) {
    return time / 1000 / 60;
  }

  return undefined;
};

export const isAsleep = (vitals: SensingWaveVitals | undefined): boolean | undefined => {
  if (
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.sensingWave &&
    vitals.vitals.data.sensingWaveUserDetection
  ) {
    const { asleepTime, awakeTime } = vitals.vitals.data.sensingWaveUserDetection;
    if (
      isSensingWavePatientStatusSleeping(vitals.vitals.data.sensingWave) ||
      (isInBed(vitals) && asleepTime > awakeTime)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return undefined;
};

export const isAwake = (vitals: SensingWaveVitals | undefined): boolean | undefined => {
  if (
    vitals &&
    vitals.vitals &&
    vitals.vitals.data.sensingWave &&
    vitals.vitals.data.sensingWaveUserDetection
  ) {
    const { asleepTime, awakeTime } = vitals.vitals.data.sensingWaveUserDetection;
    if (
      isSensingWavePatientStatusAwake(vitals.vitals.data.sensingWave) ||
      (isInBed(vitals) && asleepTime < awakeTime)
    ) {
      return true;
    } else {
      return false;
    }
  }

  return undefined;
};

export const sleepStatus = (
  db: Database | undefined,
  vitals: SensingWaveVitals | undefined
): SleepStatusType => {
  const _status = sensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';
  let status: SleepStatusType = 'na';

  if (ok && isInBed(vitals)) {
    if (isAsleep(vitals)) {
      status = 'asleep';
    } else if (isAwake(vitals)) {
      status = 'awake';
    } else {
      status = 'na';
    }
  }
  return status;
};

export const sittingStatus = (
  db: Database | undefined,
  vitals: SensingWaveVitals | undefined
): SittingStatusType => {
  const _status = sensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';
  let status: SittingStatusType = 'na';

  if (ok && isInBed(vitals) && vitals && vitals.vitals && vitals.vitals.data.sensingWave) {
    status = isSensingWavePatientStatusSitting(vitals.vitals.data.sensingWave)
      ? 'sitting'
      : 'not-sitting';
  }

  return status;
};

export const heartRate = (vitals: SensingWaveVitals | undefined) => {
  const _status = sensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';

  if (
    ok &&
    vitals &&
    vitals.vitals.data.sensingWave &&
    vitals.vitals.data.sensingWave.hr >= 0 &&
    isInBed(vitals)
  ) {
    return vitals.vitals.data.sensingWave.hr;
  }
  return undefined;
};

export const respiratoryRate = (vitals: SensingWaveVitals | undefined) => {
  const _status = sensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';

  if (
    ok &&
    vitals &&
    vitals.vitals.data.sensingWave &&
    vitals.vitals.data.sensingWave.rr >= 0 &&
    isInBed(vitals)
  ) {
    return Math.round(vitals.vitals.data.sensingWave.rr);
  }
  return undefined;
};

export const sleepStage = (vitals: SensingWaveVitals | undefined) => {
  const _status = sensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';

  if (
    ok &&
    vitals &&
    vitals.vitals.data.sensingWave &&
    vitals.vitals.data.sensingWave.sleepstage >= 0 &&
    isInBed(vitals)
  ) {
    return vitals.vitals.data.sensingWave.sleepstage;
  }
  return undefined;
};

export const position = (vitals: SensingWaveVitals | undefined) => {
  const _status = sensorStatus(vitals);
  const ok = _status === 'ok' || _status === 'limited-data';

  if (
    ok &&
    vitals &&
    vitals.vitals.data.sensingWave &&
    vitals.vitals.data.sensingWave.position >= 0 &&
    isInBed(vitals)
  ) {
    return vitals.vitals.data.sensingWave.position;
  }
  return undefined;
};
