import { createSvgIcon } from '@mui/material';

const GettingOutOfBed = createSvgIcon(
  <g>
    <g transform="matrix(1,0,0,1,0,1)">
      <path d="M23,15L3,15L3,6L1,6L1,21L3,21L3,18L21,18L21,21L23,21L23,15Z" />
    </g>
    <g transform="matrix(1,0,0,1,-3,0)">
      <circle cx="17" cy="4.54" r="2" />
    </g>
    <g transform="matrix(1,0,0,1,-3,0)">
      <path d="M17,13.5L15.14,13.5L16.81,9.83C17.42,8.5 16.44,7 14.96,7L9.76,7C8.95,7 8.22,7.47 7.89,8.2L7.22,10L9.14,10.53L9.79,9L12,9L10.17,13.1C9.57,14.43 10.56,16 12.02,16L17,16L17,21L19,21L19,15.5C19,14.4 18.1,13.5 17,13.5Z" />
    </g>
    <g transform="matrix(1,0,0,1,1,-9)">
      <path d="M19.51,19.75L14,19.75L14,21.25L19.51,21.25L19.51,23L22,20.5L19.51,18L19.51,19.75Z" />
    </g>
  </g>,
  'GettingOutOfBed'
);

export { GettingOutOfBed };
export default GettingOutOfBed;
