"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.enableLogger = exports.disableLogger = exports.isSysLogLevel = exports.Logger = exports.getLogger = exports.config = void 0;
const config_1 = require("./config");
Object.defineProperty(exports, "config", { enumerable: true, get: function () { return config_1.config; } });
const levels_1 = require("./levels");
Object.defineProperty(exports, "isSysLogLevel", { enumerable: true, get: function () { return levels_1.isSysLogLevel; } });
const logger = __importStar(require("./logger"));
// logger identifier
const loggerId = (name, level) => `${level}:${name}`;
// registry - map of logger ID ==> logger
const loggerRegistry = new Map();
/**
 * Returns the name of the environment variable that can be used to set the
 * log level for the given logger.
 * @param name
 * @returns
 */
const envLoggerName = (name) => {
    let envVariable = 'LOGGER_';
    const fields = name.replace('-', '_').split('/');
    if (fields.length > 0) {
        envVariable = envVariable + fields[0].toUpperCase();
        fields.shift();
    }
    if (fields.length > 0) {
        envVariable = envVariable + '_' + fields.join('_');
    }
    return envVariable;
};
/**
 * Looks up or instantiates a logger.
 *
 * @param name - the name of the logger
 * @param level - set this to override global defaults
 */
const getLogger = (name, level) => {
    let loggerLevel;
    // if configured in env, use that
    const configName = envLoggerName(name);
    const localStorageConfigLevel = typeof localStorage !== 'undefined' ? localStorage.getItem(configName) : undefined;
    const configLevel = localStorageConfigLevel ? localStorageConfigLevel : process.env[configName];
    // console.log(`envLogLevel: ${configName} => ${configLevel}`);
    if ((0, levels_1.isSysLogLevel)(configLevel)) {
        loggerLevel = configLevel;
    }
    else {
        loggerLevel = level;
    }
    if (!loggerLevel) {
        loggerLevel = config_1.config.get(name);
    }
    if (!loggerLevel) {
        loggerLevel = config_1.config.get('default');
    }
    if (!loggerLevel) {
        loggerLevel = 'info';
    }
    const id = loggerId(name, loggerLevel);
    const registered = loggerRegistry.get(id);
    if (registered !== undefined) {
        return registered;
    }
    const created = logger.createLogger(name, loggerLevel);
    loggerRegistry.set(id, created);
    return created;
};
exports.getLogger = getLogger;
const { disableLogger } = logger;
exports.disableLogger = disableLogger;
const { enableLogger } = logger;
exports.enableLogger = enableLogger;
class Logger extends logger.Logger {
}
exports.Logger = Logger;
