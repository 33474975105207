import React from 'react';
import { Stack, SxProps, Theme } from '@mui/material';
import * as Icons from '../../icons';
import { DataBoxVariants } from './DataBoxConfig';
import classNames from 'classnames';

type Props = {
  className?: string;
  variant?: DataBoxVariants;
  sx?: SxProps<Theme>;
  size?: 'small' | 'medium' | 'large';
};

export default (props: Props): React.JSX.Element => {
  const { className, size, sx, variant } = props;
  const iconSize = variant === 'dashboard' ? 'medium' : size ? size : 'large';

  return (
    <Stack className={classNames('AC-dataReading', className)} sx={sx}>
      <Icons.NullValue fontSize={iconSize} color="disabled" />
    </Stack>
  );
};
