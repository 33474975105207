import { Width } from '../../device';

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum DeviceActionTypes {
  SET_WIDTH = '@@device/SET_WIDTH',
  CLEAR_WIDTH = '@@device/CLEAR_WIDTH',

  SET_RESIZING = '@@device/SET_RESIZING',
  CLEAR_RESIZING = '@@device/CLEAR_RESIZING',
}

export interface DeviceState {
  readonly resizing?: boolean;
  readonly width?: Width;
}
