import * as EmailValidator from 'email-validator';
import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { Button, LinearProgress, TextField, styled, CssBaseline, Paper } from '@mui/material';

import strings from '../languages';
import { getLogger } from '../logger';
import { PrimaryLogo as Logo } from './Logos';
import { isHybrid } from '../hybrid';

const logger = getLogger('components/LoginForm');
logger.debug();

const Styled = {
  Main: styled('main')(() => ({
    width: '500px',
    maxWidth: '95%',
  })),
  Paper: styled(Paper)(({ theme }) => ({
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 0,
  })),
  Logo: styled(Logo)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  })),
  Submit: styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(8),
  })),
  ForgotPassword: styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(5),
    fontSize: '70%',
  })),
};

type Focus = 'domain' | 'username' | 'password';

interface Props {
  className?: string;
  domain?: string;
  username?: string;
  password?: string;
  includeDomain?: boolean;
  authenticating?: boolean;
  logo?: boolean;
  // focus?: Focus;
  onLogin: (email: string, password: string, domain?: string) => void;
  onForgotPassword: () => void;
}

interface State {
  focus: Focus | null;
  domain?: string;
  domainError?: string;
  username?: string;
  usernameError?: string;
  password?: string;
  passwordError?: string;
  forgotPassword?: boolean;
}

type AllProps = Props & WrappedComponentProps;

// interface PropsWithStyles extends WithStyles<typeof styles>;

class LoginForm extends React.Component<AllProps, State> {
  readonly state: State = {
    focus: 'username',
  };
  domainRef = React.createRef<HTMLInputElement>();
  usernameRef = React.createRef<HTMLInputElement>();
  passwordRef = React.createRef<HTMLInputElement>();

  componentDidMount(): void {
    this.setState({
      domain: this.props.domain,
      username: this.props.username,
      password: this.props.password,
    });
  }

  componentDidUpdate(prevProps: AllProps): void {
    if (this.props.domain !== prevProps.domain && this.props.domain !== this.state.domain) {
      this.setState({ domain: this.props.domain });
    }
    logger.debug(this.state);
  }

  onLogin = () => {
    const { intl, onLogin: login, includeDomain } = this.props;
    const { username, password, domain } = this.state;
    const localized = strings(intl);

    let focus: Focus | null = null;

    if (includeDomain) {
      if (!domain) {
        focus = 'domain';
        this.setState({
          domainError: localized.validation.requiredField(),
        });
      } else {
        this.setState({ domainError: undefined });
      }
    }
    if (!username) {
      if (!focus) {
        focus = 'username';
      }
      this.setState({
        usernameError: localized.validation.requiredField(),
      });
    } else if (!EmailValidator.validate(username)) {
      if (!focus) {
        focus = 'username';
      }
      this.setState({
        usernameError: localized.validation.invalidEmail(),
      });
    } else {
      this.setState({ usernameError: undefined });
    }
    if (!password) {
      if (!focus) {
        focus = 'password';
      }
      this.setState({
        passwordError: localized.validation.requiredField(),
      });
    } else {
      this.setState({ passwordError: undefined });
    }
    if (
      username &&
      password &&
      (!includeDomain || domain) &&
      !this.state.domainError &&
      !this.state.usernameError &&
      !this.state.passwordError
    ) {
      login(username, password, domain);
    } else {
      if (includeDomain && focus === 'domain') {
        if (this.domainRef.current) {
          this.domainRef.current.focus();
        }
      } else if (focus === 'username') {
        if (this.usernameRef.current) {
          this.usernameRef.current.focus();
        }
      } else if (focus === 'password') {
        if (this.passwordRef.current) {
          this.passwordRef.current.focus();
        }
      }
    }
  };

  onForgotPassword = () => {
    this.props.onForgotPassword();
  };

  onFocusDomain = () => {
    // place holder
  };

  onBlurDomain = () => {
    // this.setState({ focus: null });
  };

  onChangeDomain = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      domain: event.target.value,
      domainError: undefined,
    });
  };

  onFocusUsername = () => {
    // place holder
  };

  onBlurUsername = () => {
    // this.setState({ focus: null });
  };

  onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      username: event.target.value,
      usernameError: undefined,
    });
  };

  onFocusPassword = () => {
    // place holder
  };

  onBlurPassword = () => {
    // this.setState({ focus: null });
  };

  onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: event.target.value,
      passwordError: undefined,
    });
  };

  onKeyPressPassword = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.charCode === 13) {
      event.preventDefault();
      this.onLogin();
    }
  };

  render() {
    const { intl, includeDomain, authenticating, logo } = this.props;
    const { usernameError, passwordError, domainError } = this.state;
    // const { focusPassword, focusUsername } = this.state;
    // const iconPasswordClasses = focusPassword && !passwordError ? classes.iconFocus : classes.icon;
    // const iconUsernameClasses = focusUsername && !usernameError ? classes.iconFocus : classes.icon;
    const localized = strings(intl);

    const domainField = includeDomain ? (
      <TextField
        sx={{ mt: logo ? 0 : 6, mb: logo ? 0 : 2 }}
        value={this.state.domain}
        inputRef={this.domainRef}
        label={localized.login.domain()}
        id="domain"
        name="domain"
        autoComplete="domain"
        // focused={focus === 'domain'}
        autoFocus={includeDomain}
        onFocus={this.onFocusDomain}
        // onBlur={this.onBlurDomain}
        onChange={this.onChangeDomain}
        helperText={domainError}
        fullWidth
        required
      />
    ) : null;

    return (
      <React.Fragment>
        <CssBaseline />
        <Styled.Main>
          <Styled.Paper elevation={2}>
            {authenticating ? (
              <LinearProgress sx={{ width: '100%', mb: 2, mt: 5 }} />
            ) : (
              <>
                {logo && <Styled.Logo width={200} ignoreTenant={isHybrid()} />}
                {domainField}
                <TextField
                  inputRef={this.usernameRef}
                  sx={includeDomain ? { mt: 3 } : {}}
                  label={localized.login.email()}
                  id="email"
                  name="email"
                  autoComplete="email"
                  // focused={focus === 'username'}
                  autoFocus={!includeDomain}
                  onFocus={this.onFocusUsername}
                  // onBlur={this.onBlurUsername}
                  onChange={this.onChangeUsername}
                  helperText={usernameError}
                  value={this.state.username}
                  fullWidth
                  required
                />
                <TextField
                  inputRef={this.passwordRef}
                  sx={{ mt: 3 }}
                  label={localized.login.password()}
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  // focused={focus === 'password'}
                  type={'password'}
                  onFocus={this.onFocusPassword}
                  // onBlur={this.onBlurPassword}
                  onChange={this.onChangePassword}
                  onKeyPress={this.onKeyPressPassword}
                  helperText={passwordError}
                  value={this.state.password}
                  fullWidth
                  required
                />
                <Styled.Submit
                  type="submit"
                  fullWidth={true}
                  variant="contained"
                  onClick={this.onLogin}
                >
                  {localized.login.signIn()}
                </Styled.Submit>
                <Styled.ForgotPassword
                  size="small"
                  autoCapitalize="false"
                  onClick={this.onForgotPassword}
                >
                  {localized.login.forgotPassword()}
                </Styled.ForgotPassword>
              </>
            )}
          </Styled.Paper>
        </Styled.Main>
      </React.Fragment>
    );
  }
}

export default injectIntl(LoginForm);
