"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep Client API
 * This document is a specification for the API that must be implemented by AiSleep client applications (i.e. AttentiveConnect) so that AiSleep servers can push data to clients. This is a specifications for the callbacks.  This specification is used to generate the code that is used by AC to integrate AiSleep data.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes). The below status codes are used in this interface:    - 200 OK - Standard response for successful HTTP requests.   - 201 Created - The request has been fulfilled, resulting in the creation of a new resource   - 202 Accepted - The request has been accepted for processing, but the processing has not been completed.   - 400 Bad Request - The server cannot or will not process the request due to an apparent client         error (e.g., malformed request syntax, size too large, invalid request message framing,         or deceptive request routing)   - 404 Not Found - The requested resource could not be found   - 500 Internal Server Error
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DailyLogDataToJSON = exports.DailyLogDataFromJSONTyped = exports.DailyLogDataFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function DailyLogDataFromJSON(json) {
    return DailyLogDataFromJSONTyped(json, false);
}
exports.DailyLogDataFromJSON = DailyLogDataFromJSON;
function DailyLogDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dayReportYmd': json['dayReportYmd'],
        'outBedTimes': json['outBedTimes'],
        'respiratoryFrequencyDisorders': json['respiratoryFrequencyDisorders'],
        'sleepEfficiency': json['sleepEfficiency'],
        'sleepQuality': json['sleepQuality'],
        'sleepRhythmPhase': !(0, runtime_1.exists)(json, 'sleepRhythmPhase') ? undefined : json['sleepRhythmPhase'],
        'sleepTotalTime': json['sleepTotalTime'],
        'sleeplatency': json['sleeplatency'],
        'slop1': !(0, runtime_1.exists)(json, 'slop1') ? undefined : json['slop1'],
        'snoringFrequencyTotal': json['snoringFrequencyTotal'],
        'startt': json['startt'],
        'turnOverTimes': json['turnOverTimes'],
        'detail': (json['detail'].map(_1.DailyLogDataDetailFromJSON)),
    };
}
exports.DailyLogDataFromJSONTyped = DailyLogDataFromJSONTyped;
function DailyLogDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dayReportYmd': value.dayReportYmd,
        'outBedTimes': value.outBedTimes,
        'respiratoryFrequencyDisorders': value.respiratoryFrequencyDisorders,
        'sleepEfficiency': value.sleepEfficiency,
        'sleepQuality': value.sleepQuality,
        'sleepRhythmPhase': value.sleepRhythmPhase,
        'sleepTotalTime': value.sleepTotalTime,
        'sleeplatency': value.sleeplatency,
        'slop1': value.slop1,
        'snoringFrequencyTotal': value.snoringFrequencyTotal,
        'startt': value.startt,
        'turnOverTimes': value.turnOverTimes,
        'detail': (value.detail.map(_1.DailyLogDataDetailToJSON)),
    };
}
exports.DailyLogDataToJSON = DailyLogDataToJSON;
