import { Reducer } from 'redux';

import { RuntimeActionTypes, RuntimeState } from './types';

// Type-safe initialState!
const initialState: RuntimeState = {
  domain: null,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<RuntimeState> = (state: RuntimeState = initialState, action) => {
  switch (action.type) {
    case RuntimeActionTypes.SET_RUNTIME_DOMAIN: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { ...state, domain: action.payload };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as runtimeReducer };
