import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

export interface WithRouterProps {
  location: ReturnType<typeof useLocation>;
  navigate: ReturnType<typeof useNavigate>;
}

/**
 * HOC to provide the route funtionality to the wrapped component.
 * @param WrappedComponent
 */
export function withRouter<T extends WithRouterProps = WithRouterProps>(
  WrappedComponent: React.ComponentType<T>
) {
  // omit the 'routes' prop from this HOC. We are adding it to the wrapped component.
  const ComponentWithRouter = (props: Omit<T, keyof WithRouterProps>) => {
    const location = useLocation();
    const navigate = useNavigate();

    const routerProps: WithRouterProps = { location, navigate };

    // props comes afterwards so they can override the default ones.
    return <WrappedComponent {...routerProps} {...(props as T)} />;
  };

  // displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
  ComponentWithRouter.displayName = `withRouter(${displayName})`;

  return ComponentWithRouter;
}
