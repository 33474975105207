import * as data from '../../data';

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum BioBeatActionTypes {
  SET_VITALS = '@@context/SET_BIOBEAT_VITALS',
  CLEAR_VITALS = '@@context/CLEAR_BIOBEAT_VITALS',
  ENABLE_VITALS_POLLING = '@@context/ENABLE_BIOBEAT_VITALS_POLLING',
  DISABLE_VITALS_POLLING = '@@context/DISABLE_BIOBEAT_VITALS_POLLING',
  SET_VITALS_POLLING_SENSOR_IDS = '@@context/SET_BIOBEAT_VITALS_POLLING_SENSOR_IDS',
}

export interface BioBeatState {
  readonly token: string | undefined;
  readonly vitalsPollingIsEnabled: boolean;
  readonly vitals: data.vitals.BioBeatVitals[];
  readonly vitalsPollingSensors: data.vitals.BioBeatSensor[];
}
