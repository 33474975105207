"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrationTypes = void 0;
/**
 * Types of services that may be used by a care center.
 *
 * @see CareCenterData.integrationTypes
 */
var IntegrationTypes;
(function (IntegrationTypes) {
    IntegrationTypes["BUDDYCOM"] = "BUDDYCOM";
    IntegrationTypes["BONX"] = "BONX";
})(IntegrationTypes || (exports.IntegrationTypes = IntegrationTypes = {}));
exports.default = IntegrationTypes;
