import { createSvgIcon } from '@mui/material';

const Sweat = createSvgIcon(
  <g>
    <circle cx="15.5" cy="9.5" r="1.5" />
    <circle cx="8.5" cy="9.5" r="1.5" />
    <path d="M9,15.9c1,1.1,0,0,1,1.1c0.6-0.3,1.2-0.5,2-0.5s1.4,0.2,2,0.5c0.1-0.1,0.9-1,1-1.1c-0.8-0.6-1.9-0.9-3-0.9S9.9,15.3,9,15.9
		z" />
    <path d="M12,2c-1.5,0-3,0.4-4.3,1c0.4,0.6,0.7,1.2,0.9,1.8C9.6,4.3,10.8,4,12,4c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8
		c0-1.1,0.2-2.2,0.6-3.1C4,8.7,3.4,8.3,3,7.7C2.4,9,2,10.5,2,12c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10S17.5,2,12,2z" />
    <path d="M5.5,8c1.2,0,2.2-1.1,2.2-2.2c0-1.5-2.2-4.1-2.2-4.1S3.2,4.2,3.2,5.8C3.2,6.9,4.3,8,5.5,8z" />{' '}
  </g>,
  'Sweat'
);

export { Sweat };
export default Sweat;
