import * as data from '../../data';

export enum SensingWaveActionTypes {
  SET_SENSING_WAVE_VITALS = '@@context/SET_SENSING_WAVE_VITALS',
  CLEAR_SENSING_WAVE_VITALS = '@@context/CLEAR_SENSING_WAVE_VITALS',
  ENABLE_SENSING_WAVE_VITALS_POLLING = '@@context/ENABLE_SENSING_WAVE_VITALS_POLLING',
  DISABLE_SENSING_WAVE_VITALS_POLLING = '@@context/DISABLE_SENSING_WAVE_VITALS_POLLING',
}

export interface SensingWaveState {
  readonly token: string | undefined;
  readonly vitalsPollingIsEnabled: boolean;
  readonly vitals: data.vitals.SensingWaveVitals[];
  readonly vitalsPollingSensors: data.vitals.SensingWaveSensor[];
}
