import classNames from 'classnames';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { FormControl, InputLabel, SxProps, Theme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import strings from '../../languages';

interface ComponentProps {
  className?: string;
  sx?: SxProps<Theme>;
  // callback when a selection is made
  onSelect: (day: number) => void;
  // the current selection
  selected?: number;
  // the available choices
  choices: number[];
  fullWidth?: boolean;
}

type Props = ComponentProps & WrappedComponentProps;

export const DaysSelect = injectIntl((props: Props) => {
  const localized = strings(props.intl);

  const onSelection = (days: number | string) => {
    if (typeof days === 'number') {
      props.onSelect(days);
    }
  };

  const choices = props.choices.map((day: number) => (
    <MenuItem value={day} key={day}>
      {localized.report.numberOfDays(day)}
    </MenuItem>
  ));

  return (
    <FormControl
      className={classNames(props.className)}
      fullWidth={props.fullWidth}
      sx={{ ...props.sx }}
    >
      <InputLabel id="days-select-label">{localized.report.days()}</InputLabel>
      <Select
        labelId="days-select-label"
        label={localized.report.days()}
        fullWidth
        value={props.selected}
        onChange={(event) => onSelection(event.target.value)}
        inputProps={{
          name: 'days',
          id: 'select-days',
        }}
      >
        {choices}
      </Select>
    </FormControl>
  );
});
