import { createSvgIcon } from '@mui/material';

const RespiratoryRateAlert = createSvgIcon(
  <path d="M17,6c-1.5,0-2.5,2-3.1,4.3l1.8,1.2c0.4-1.8,0.9-3.2,1.4-3.5C18,8.3,21,12.2,21,18c0,0.8-0.2,1-1,1h-3c-1.1,0-2-0.9-2-2
        c0-0.6,0-1.1,0.1-1.7l3.4,2.2l1.1-1.7l-1.9-1.3l1.3-1.1l-1.3-1.5l-1.7,1.5L13,11.5V3h-2v8.5l-2.9,1.9l-1.7-1.5l-1.3,1.5l1.3,1.1
        l-1.9,1.3l1.1,1.7l3.4-2.2C9,15.9,9,16.4,9,17c0,1.1-0.9,2-2,2H4c-0.8,0-1-0.2-1-1c0-5.8,3-9.7,3.9-10c0.4,0.3,1,1.8,1.4,3.5
        l1.8-1.2C9.5,8,8.5,6,7,6c-2.6,0-6,5.8-6,12c0,1.9,1.1,3,3,3h3c2.2,0,4-1.8,4-4c0-0.4-0.1-1.6-0.2-3l1.2-0.8l1.2,0.8
        c-0.2,1.4-0.2,2.5-0.2,3c0,2.2,1.8,4,4,4h3c1.9,0,3-1.1,3-3C23,11.8,19.6,6,17,6z" />,
  'RespiratoryRateAlert'
);

export { RespiratoryRateAlert };
export default RespiratoryRateAlert;
