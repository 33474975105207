import { Semaphore } from '../data/Semaphore';
import { getLogger } from '../logger';

const logger = getLogger('hybrid/HybridFCM');

// Interface to the cordova badge plugin
interface HybridFCMPlugin {
  clearNotifications: void;
  deleteToken: void;
  getBadge: number;
  getToken: string;
  // callback: (payload: PushPayload) => void
  // errorCallback?: (error: string) => void
  onBackgroundMessage: (callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid) => void;
  onMessage: (callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid) => void;
  onTokenRefresh: (callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid) => void;
  // options.forceShow boolean
  // When value is true incoming notification is displayed even when app is in foreground.
  requestPermission: (options: object) => void;
  setBadge: (badgeValue: number) => void;
  subscribe: (topic: string) => void;
  unsubscribe: (topic: string) => void;
}

declare type FunctionReturnVoid = () => void;

export class HybridFCM {
  private static _mounted = false;
  private static _instance: HybridFCM | undefined;
  private static _semaphore = new Semaphore(1, 'HybridFCM');
  private _plugin: HybridFCMPlugin | undefined = undefined;
  constructor(plugin: HybridFCMPlugin) {
    this._plugin = plugin;
  }
  static get instance() {
    return HybridFCM._instance;
  }
  static async mount(): Promise<HybridFCM | undefined> {
    await HybridFCM._semaphore.acquire();
    try {
      if (!HybridFCM._instance && !HybridFCM._mounted) {
        const _plugin = (window as any).cordova['cordova-plugin-firebase-messaging'] as
          | HybridFCMPlugin
          | undefined;
        if (!_plugin) {
          logger.notice(`'fcm' plugin: not installed`);
        } else {
          HybridFCM._instance = new HybridFCM(_plugin);
          logger.info(`'fcm' plugin: mounted`);
        }
      }
      return HybridFCM._instance;
    } finally {
      HybridFCM._semaphore.release();
    }
  }

  clearNotifications(): void {
    if (this._plugin) {
      return this._plugin.clearNotifications;
    }
  }
  deleteToken(): void {
    if (this._plugin) {
      return this._plugin.clearNotifications;
    }
  }
  getBadge(): number | undefined {
    if (this._plugin) {
      return this._plugin.getBadge;
    } else {
      return undefined;
    }
  }
  getToken(): string | undefined {
    if (this._plugin) {
      return this._plugin.getToken;
    } else {
      return undefined;
    }
  }
  
  onBackgroundMessage(callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid): void {
    if (this._plugin) {
      return this._plugin.onBackgroundMessage(callback, errorCallback);
    }
  }
  onMessage(callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid): void {
    if (this._plugin) {
      return this._plugin.onMessage(callback, errorCallback);
    }
  }
  onTokenRefresh(callback: FunctionReturnVoid, errorCallback?: FunctionReturnVoid): void {
    if (this._plugin) {
      return this._plugin.onTokenRefresh(callback, errorCallback);
    }
  }
  requestPermission(options: object): void {
    if (this._plugin) {
      return this._plugin.requestPermission(options);
    }
  }
  setBadge(badgeValue: number): void {
    if (this._plugin) {
      return this._plugin.setBadge(badgeValue);
    }
  }
  subscribe(topic: string): void {
    if (this._plugin) {
      return this._plugin.subscribe(topic);
    }
  }
  unsubscribe(topic: string): void {
    if (this._plugin) {
      return this._plugin.unsubscribe(topic);
    }
  }
}
