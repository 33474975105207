import * as React from 'react';

import { Box, styled } from '@mui/material';
import { Auth as auth } from 'attentive-connect-store/dist';
import env, { Environment } from 'attentive-connect-store/dist/config/Environment';
import * as models from 'attentive-connect-store/dist/models';
import UserPerson, { UserPersonTheme } from 'attentive-connect-store/dist/models/UserPerson';
import UserProfile from 'attentive-connect-store/dist/models/UserProfile';
import Database from 'attentive-connect-store/dist/services/Database';
import * as firebase from 'attentive-connect-store/dist/services/Firebase';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import AppView from './AppView';
import { UserSelectorDialog } from './components';
import PasswordResetDialog from './components/PasswordResetDialog';
import LanguageSelector from './components/LanguageSelector';
import LoginForm from './components/LoginForm';
import NewVersionNotification from './components/NewVersionNotification';
import Notification from './components/Notification';
import { Domain, loadDomain, findDomain, domainNeedsToBeSpecified } from './domain/DomainServices';
import { Hybrid } from './hybrid';
import strings from './languages';
import { getLogger } from './logger';
import { Push } from './push/Push';
import { pushAppId } from './push/push-env';
import * as redux from './redux';
import { Language } from './redux/locale';
import session from './session';
import withRoot from './views/withRoot';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from 'react-router-dom';
import { Listener } from 'attentive-connect-store/dist/services/ListenerService';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from './components/ErrorBoundary';
import OfflineDialog from './components/OfflineDialog';
import LayoutListener from './LayoutListener';
import { loadTheme } from './themes';
import createCache from '@emotion/cache';
import { AuthState } from './redux/auth';

const logger = getLogger('AppAuth', 'notice');

const cache = createCache({
  key: 'css',
  prepend: true,
});


type MatchPathProps = {
  path: string;
  Comp: React.ComponentType;
};

const routeComponents: Array<MatchPathProps> = [
  // @see AppView
  { path: '*', Comp: AppView },
];

const routeObjects: Array<RouteObject> = routeComponents.map((rc) => ({
  path: rc.path,
  element: <rc.Comp />,
}));

const browserRouter = createBrowserRouter(routeObjects);
const hashRouter = createHashRouter(routeObjects);

const AppAuthLanguageContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(2),
}));

const AppAuthLanguageSelector = styled(LanguageSelector)(() => ({
  // margin: theme.spacing(1),
}));

const AppAuthLoginContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
}));

interface ReduxProps {
  // redux
  db?: Database;
  user?: firebase.User;
  initialized?: boolean;
  userPerson?: UserPerson;
  themeName?: UserPersonTheme;
  fontSize?: number;
  language: Language;
  auth: AuthState;

  // dispatch
  signedIn: (user: firebase.User, db: Database, userProfile: UserProfile) => void;
  signedOut: () => void;
  selectPerson: typeof redux.auth.selectPerson;
  clearPerson: typeof redux.auth.clearPerson;
  setThemeName: (name: UserPersonTheme) => void;
  clearThemeName: () => void;
  setFontSize: (size: number) => void;
  clearFontSize: () => void;
  setCenter: typeof redux.context.setCenter;
  setSuspended: typeof redux.hybrid.setSuspended;
  clearCenter: typeof redux.context.clearCenter;
}

type Props = {
  children?: React.ReactElement | React.ReactElement[];
  offline: boolean;
} & WrappedComponentProps &
  ReduxProps;

interface State {
  // userPersons: UserPerson[];
  // user: firebase.User | null;
  runtimeId?: number;
  email?: string;
  password?: string;
  authError: boolean;
  authErrorMessage?: string;
  successNotification: boolean;
  successNotificationMessage?: string;
  forgotPassword: boolean;
  // authenticating: boolean;
  // authenticated: boolean;
  // config?: GlobalConfig;
  domainVersion: number;
  // domainName?: string;
}

/**
 * Handle auth state changes.
 */
class AppAuth extends React.Component<Props, State> {
  userPersons: UserPerson[] = [];
  user: firebase.User | null = null;
  authenticating = false;
  authenticated = false;
  configuringRuntime = false;
  domain?: Domain;
  unmount = false;
  unsubscribe: firebase.Unsubscribe | undefined;
  listeners: Listener[] = [];

  constructor(props: Props) {
    super(props);
    const state: State = {
      // userPersons: [],
      authError: false,
      successNotification: false,
      forgotPassword: false,
      // authenticating: false,
      // authenticated: false,
      domainVersion: 0,
      // user: null,
    };
    this.state = state;
    logger.debug(`constructor()`);
  }

  get nameSpace() {
    return 'ui.AppAuth';
  }

  traceState(msg: string, ...args: unknown[]) {
    const { authError } = this.state;
    logger.trace(
      msg,
      {
        domain: this.domain,
        configuringRuntime: this.configuringRuntime,
        authenticated: this.authenticated,
        authenticating: this.authenticating,
        authError,
      },
      ...args
    );
  }

  isSignedIn = () => {
    // const { db, user } = this.props;
    // const signedIn = db !== undefined && user !== undefined && this.authenticated;
    const signedIn = this.authenticated;
    this.traceState(`isSignedIn(): ${signedIn}`);
    return signedIn;
  };

  // listen(snapshot: firebase.DocumentSnapshot, onNext: (next: firebase.DocumentSnapshot) => void) {
  //   const { db } = this.props;
  //   if (db) {
  //     const listener = db.listeners.listen(this.nameSpace, snapshot, onNext);
  //     this.listeners.push(listener);
  //   }
  // }

  async onHybridSuspend() {
    logger.debug('[on hybrid suspend]');
    if (Push.instance) {
      // notify push instance that app is suspended
      logger.debug('[on hybrid suspend]');
      await Push.instance.onHybridSuspend();
    }
    this.props.setSuspended(true);
  }

  async onHybridResume() {
    logger.debug('[on hybrid resume]');
    if (Push.instance) {
      // notify push instance that app is in foreground
      logger.debug('[on hybrid resume]');
      await Push.instance.onHybridResume();
    }
    this.props.setSuspended(false);
  }

  async componentDidMount() {
    this.unmount = false;
    const push = await Push.mount();
    if (Hybrid.shouldMount()) {
      logger.debug('mounting hybrid interface');
      await Hybrid.mount(push, this.onHybridSuspend.bind(this), this.onHybridResume.bind(this));
    }
    const domain = await findDomain();
    logger.debug('componentDidMount()', { domain, unmount: this.unmount });
    if (domain) {
      if (
        !firebase.FirebaseRuntime.instance.initialized ||
        firebase.FirebaseRuntime.instance.domain !== domain.name
      ) {
        await this.configureRuntime(domain);
      } else {
        this.configuringRuntime = false;
      }
    } else {
      this.configuringRuntime = true;
    }
  }

  async componentDidUpdate(prevProps: Props) {
    logger.debug('componentDidUpdate(): ', { unmount: this.unmount });
    if (!this.unmount) {
      const userPerson = this.props.userPerson;
      const prevUserPerson = prevProps.userPerson;
      if (
        (userPerson && !prevUserPerson) ||
        (!userPerson && prevUserPerson) ||
        (userPerson && prevUserPerson && userPerson.id !== prevUserPerson.id)
      ) {
        logger.debug('componentDidUpdate(): person changed');
        this.updatePeople();
      }
      this.traceState('componentDidUpdate()');
    }
  }

  async componentWillUnmount() {
    logger.debug('componentWillUnmount()');
    this.unmount = true;
    await this.stopListeners();
  }

  setAuthState(authenticated: boolean, authenticating?: boolean) {
    const shouldUpdate =
      this.authenticated !== authenticated || this.authenticating !== authenticating || true;
    this.authenticated = authenticated;
    if (authenticating !== undefined) {
      this.authenticating = authenticating;
    }
    // this.setState({ initializing: false });
    if (shouldUpdate) {
      this.forceUpdate();
    }
  }

  registerAuthStateChangedHandler(runtimeId: number) {
    if (firebase.FirebaseRuntime.instance.initialized) {
      logger.debug(`registerAuthStateChangedHandler(${runtimeId})`);
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      // console.log('AAAA');
      this.unsubscribe = firebase.FirebaseRuntime.instance.auth.onAuthStateChanged(
        async (authUser: firebase.User | null) => {
          if (authUser) {
            // console.log('BBBB');
            logger.debug(`onAuthStateChanged(): authenticated ${authUser.email}`);
            const db = new Database(firebase.FirebaseRuntime.instance, authUser.uid, authUser);
            const userProfile = await db.userProfiles.myProfile();
            if (userProfile && userProfile.snapshot.exists()) {
              // console.log('CCCC');
              // signed in
              this.setAuthState(true, true);
              this.props.signedIn(authUser, db, userProfile);
              await this.loadPersons(db, userProfile, authUser);
              // console.log('DDDD');
              this.traceState(
                `onAuthStateChange(runTimeId: ${runtimeId}) - signed in ${authUser.email}`
              );
              logger.debug(`onAuthStateChanged(): listening user profile '${authUser.email}'...`);
              db.listeners.listen(this.nameSpace, userProfile.snapshot, async (nextProfile) => {
                if (firebase.FirebaseRuntime.instance.id === runtimeId) {
                  this.traceState(`onAuthStateChanged(runtimeId: ${runtimeId}) - profile changed`);
                  // console.log('EEEE');
                  const newProfile = db.userProfiles.dataWithSnapshot(nextProfile);
                  // console.log('EEEE - 1');
                  await this.loadPersons(db, newProfile, authUser);
                  // console.log('EEEE - 2');
                }
              });
              // console.log('FFFF');
              // push notifications
              if (firebase.FirebaseRuntime.instance.env && Push.instance) {
                // push notifications are suspended when setting the app id
                await Push.instance
                  .setAppId(pushAppId(firebase.FirebaseRuntime.instance.env), db)
                  .catch((e) => {
                    logger.error('Push.setAppId error', e);
                  });
              }
              this.setAuthState(true, false);
              // console.log('GGGG');
            } else {
              // console.log('HHHH');
              this.setAuthState(false, false);
              this.traceState('onAuthStateChange() - profile not found');
            }
          } else {
            // console.log('IIII');
            this.traceState('onAuthStateChanged() - authUser is null');
            if (!this.authenticating) {
              this.setAuthState(false, false);
              this.configuringRuntime = false;
            }
            this.signOut(runtimeId);
          }
        }
      );
    }
  }

  async configureRuntime(domain: Domain) {
    if (
      firebase.FirebaseRuntime.instance.initialized &&
      firebase.FirebaseRuntime.instance.domain === domain.name
    ) {
      logger.debug(`configureRuntime(): already initialized`, {
        id: firebase.FirebaseRuntime.instance.id,
        domain: firebase.FirebaseRuntime.instance.domain,
      });
      this.domain = domain;
      return;
    }
    // if (this.configuringRuntime) {
    //   return;
    // }
    this.configuringRuntime = true;
    await this.closeRuntime();
    logger.debug(`configureRuntime(): initializing runtime`);
    await firebase.FirebaseRuntime.instance.init(domain.name, new Environment(domain.config));
    this.domain = domain;
    session.setDomain(domain);
    logger.debug(`configureRuntime(): started runtime`, {
      id: firebase.FirebaseRuntime.instance.id,
      domain,
    });
    this.registerAuthStateChangedHandler(firebase.FirebaseRuntime.instance.id);
    this.configuringRuntime = false;
    this.setState({ runtimeId: firebase.FirebaseRuntime.instance.id });
  }

  async closeRuntime() {
    if (firebase.FirebaseRuntime.instance.initialized) {
      logger.debug(`closeRuntime(): closing runtime`, {
        id: firebase.FirebaseRuntime.instance.id,
        domain: firebase.FirebaseRuntime.instance.domain,
      });
      await this.stopListeners();
      await firebase.FirebaseRuntime.instance.close();
      this.props.clearCenter();
      logger.debug(`closeRuntime(): closed runtime`, {
        id: firebase.FirebaseRuntime.instance.id,
        domain: firebase.FirebaseRuntime.instance.domain,
      });
    }
  }

  stopListeners = async () => {
    if (this.props.db) {
      logger.debug('stopListeners()');
      for (const listener of this.listeners) {
        this.props.db.listeners.stop(listener);
      }
      // await this.props.db.listeners.stopAll();
      this.listeners = [];
    }
  };

  loadDomain = async (name: string): Promise<Domain | undefined> => {
    this.setAuthState(false, true);
    this.traceState(`loadDomain(${name})`);

    const { intl } = this.props;
    const localized = strings(intl);
    const domain = this.domain && this.domain.name === name ? this.domain : await loadDomain(name);

    if (domain) {
      logger.debug(`loaded domain ${name}`, domain);
      this.domain = domain;
      session.setDomain(domain);
      this.setState({
        domainVersion: (this.state.domainVersion + 1) % 1000,
      });
      this.traceState(`loadDomain(${name}): success`);
    } else if (!domain && !this.state.authError) {
      this.setAuthState(false, false);
      this.setState({
        authError: true,
        authErrorMessage: localized.login.domainNotImplemented(),
      });
      this.traceState('loadDomain(): domain not implemented');
    } else {
      this.setAuthState(false, false);
      this.setState({
        authError: true,
        authErrorMessage: localized.login.domainNotFound(),
      });
      this.traceState('loadDomain(): domain not found');
    }
    return domain;
  };

  signIn = async (email: string, password: string) => {
    // email and password are already validated in loginForm as stored in state
    const { language, intl } = this.props;
    const localized = strings(intl);
    if (this.domain && email && password) {
      this.setAuthState(false, true);
      this.traceState(`signIn(${email})`);
      auth.setLanguage(language);
      try {
        await auth.signIn(email, password).catch((e: firebase.AuthError) => {
          if (!e.code.startsWith('auth/')) {
            logger.notice('sign in error', e);
          }
          this.setAuthState(false, false);
          this.setState({
            authError: true,
            authErrorMessage: localized.auth.error(e.code),
          });
          logger.debug('signIn(): error', e.code, e.message);
        });
      } catch (e) {
        logger.error('sign in error', e);
      }
    }
  };

  signOut = (runtimeId: number) => {
    if (!this.unmount && !this.authenticating) {
      logger.debug(`signOut() - runtime: ${runtimeId}`);
      if (firebase.FirebaseRuntime.instance.id === runtimeId) {
        this.setAuthState(false, true);
        this.traceState('signOut() - signing out');
        session.cache.clear();
        this.props.clearCenter();
        this.props.clearPerson();
        this.props.signedOut();
        logger.debug('signOut() - stopping listeners');
        this.stopListeners();
        this.setAuthState(false, false);
      }
    }
    this.userPersons = [];
    // this.changeAuthState(false, false);
    this.setState({
      // email: '',
      password: '',
    });
    this.traceState('signOut() - signed out');
  };

  onLoginButtonClicked = async (email: string, password: string, domainName?: string) => {
    const localized = strings(this.props.intl);
    logger.debug(`onLoginButtonClicked('${email}', '${domainName}')`);
    let { authError } = this.state;
    const name = domainNeedsToBeSpecified()
      ? domainName
      : this.domain
        ? this.domain.name
        : undefined;
    this.setState({ email, password });
    if (!name) {
      authError = true;
      this.setState({
        authError,
        authErrorMessage: localized.login.domainNotFound(),
      });
      logger.trace('onLoginButtonClicked(): domain not found');
      return;
    } else {
      const domain = await this.loadDomain(name);
      if (!domain) {
        authError = true;
        this.setState({
          authError,
          authErrorMessage: localized.login.domainNotImplemented(),
        });
        logger.trace('onLoginButtonClicked(): domain not implemented');
        return;
      }
      if (!authError) {
        await this.configureRuntime(domain);
        await this.signIn(email, password);
      }
    }
  };

  closeErrorNotification = () => {
    this.setAuthState(false, false);
    this.setState({
      authError: false,
      authErrorMessage: undefined,
    });
    this.traceState('closeErrorNotification()');
  };

  closeSuccessNotification = () => {
    this.setAuthState(this.authenticated, false);
    this.setState({
      successNotification: false,
      successNotificationMessage: undefined,
    });
    this.traceState('closeSuccessNotification()');
  };

  onResetPassword = (email: string) => {
    const { language, intl } = this.props;
    const localized = strings(intl);
    auth.setLanguage(language);
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.setAuthState(false, false);
        this.setState({
          forgotPassword: false,
          successNotificationMessage: localized.login.passwordResetEmailed(),
          successNotification: true,
        });
        this.traceState('onResetPassword() - send reset email');
      })
      .catch((e: firebase.AuthError) => {
        this.setAuthState(false, false);
        this.setState({
          authError: true,
          authErrorMessage: localized.auth.error(e.code),
          forgotPassword: true,
        });
        this.traceState('onResetPassword() - error', e.code, e.message);
      });
  };

  onForgotPassword = () => {
    this.setState({ forgotPassword: true });
  };

  onCancelForgotPassword = () => {
    this.setState({ forgotPassword: false });
  };

  includeDomainField = (): boolean => {
    return domainNeedsToBeSpecified();
  };

  domainConnection = async (): Promise<Domain | undefined> => {
    const domain = await findDomain();
    if (domain) {
      return domain;
    }
    return undefined;
  };

  // domainName = (): string | undefined => {
  //   const _domain = this.domainConnection();
  //   if (_domain) {
  //     return _domain.name;
  //   }
  //   return undefined;
  // };

  /**
   * This is called whenever the user profile has changed.
   *
   * @param db
   * @param userProfile
   */
  loadPersons = async (db: Database, userProfile: UserProfile, authUser: firebase.User) => {
    logger.debug('loadPersons(): loading persons...');
    const userPersons = await db.userPersons.getUsers(userProfile);
    if (userPersons.length === 0) {
      // autocreate
      logger.debug('loadPersons(): initializing new person');
      const userPerson = await db.userPersons.initUser(userProfile, authUser);
      this.props.selectPerson(userPerson);
      this.userPersons = [userPerson];
    } else if (userPersons.length === 1) {
      logger.debug(`loadPersons(): selected ${userPersons[0].data.lastName}`);
      this.props.selectPerson(userPersons[0]);
      this.userPersons = userPersons;
    } else {
      logger.debug(`loadPersons(): loaded ${userPersons.length} persons`);
      this.userPersons = userPersons;
      const personId = session.getCurrentPersonId();
      if (personId) {
        for (const person of userPersons) {
          if (
            person.id === personId &&
            (!this.props.userPerson || this.props.userPerson.id !== person.id)
          ) {
            logger.debug(`loadPersons(): auto-selected (matched) person from session`, {
              first: userPersons[0].data.firstName,
              last: userPersons[0].data.lastName,
            });
            this.props.selectPerson(person);
          }
        }
      } else {
        // need to select a person...
        logger.debug('loadPersons(): person not stored in session, need to select a person');
        this.props.clearPerson();
      }
    }
  };

  updatePeople = () => {
    this.updateUserPerson();
    this.updateThemeSettings();
  };

  updateUserPerson = () => {
    const { userPerson } = this.props;
    if (userPerson) {
      logger.debug('updateUserPerson()', {
        first: userPerson.data.firstName,
        last: userPerson.data.lastName,
      });
      this.props.selectPerson(userPerson);
      session.setCurrentPersonId(userPerson.id);
    } else {
      logger.debug('updateUserPerson(): props.userPerson is not defined, clearing user person');
      this.props.clearPerson();
      session.removeCurrentPersonId();
    }
  };

  updateThemeSettings = () => {
    const { themeName, fontSize, userPerson } = this.props;

    if (userPerson && userPerson.data.theme) {
      const theme = userPerson.data.theme;
      if (themeName !== theme) {
        this.props.setThemeName(theme);
      }
    } else if (userPerson && !userPerson.data.theme) {
      const theme = UserPersonTheme.light;
      if (themeName !== theme) {
        this.props.setThemeName(theme);
      }
    }

    if (userPerson && userPerson.data.fontSize) {
      const size = userPerson.data.fontSize;
      if (fontSize !== size) {
        this.props.setFontSize(size);
      }
    }
  };

  onSelectUser = (userPerson: UserPerson) => {
    this.props.selectPerson(userPerson);
    session.setCurrentPersonId(userPerson.id);
  };

  userSelectorOpen = () => this.userPersons.length > 1 && !this.props.userPerson;

  currentTheme = () => loadTheme(this.props.themeName, this.props.fontSize);


  render() {
    return <ThemeProvider theme={this.currentTheme()}>
      <CacheProvider value={cache}>
        <CssBaseline />
        <LayoutListener />
        <React.Suspense fallback={<div></div>}>
          <ErrorBoundary>
            {!this.isSignedIn() ? this.renderLogin() : (
              <>
                <RouterProvider router={env().isHybrid() ? hashRouter : browserRouter} />
                <UserSelectorDialog
                  open={this.userSelectorOpen()}
                  users={this.userPersons}
                  onSelectionChanged={this.onSelectUser} />
                <OfflineDialog offline={this.props.offline} />
              </>
            )}
          </ErrorBoundary>
        </React.Suspense>
      </CacheProvider>
    </ThemeProvider>;

    /*
    if (!this.isSignedIn()) {
      logger.trace('render(): Login');
      return this.renderLogin();
    } else if (this.userSelectorOpen()) {
      logger.trace('render(): UserSelectorDialog');
      return (
        <UserSelectorDialog
          open={this.userSelectorOpen()}
          users={this.userPersons}
          onSelectionChanged={this.onSelectUser}
        />
      );
    } else {
      logger.trace('render(): RouterProvider');
      return (
        <>
          <RouterProvider router={env().isHybrid() ? hashRouter : browserRouter} />
          <UserSelectorDialog
            open={this.userSelectorOpen()}
            users={this.userPersons}
            onSelectionChanged={this.onSelectUser}
          />
        </>
      );
    }
    */
  }

  isLoginFormAuthenticating = () => {
    return (
      !this.state.authError &&
      (this.isSignedIn() || this.configuringRuntime || this.authenticating || this.authenticated)
    );
  };

  renderLogin() {
    if (this.state.forgotPassword) {
      logger.trace('renderLogin(): ForgotPasswordForm');
    } else {
      logger.trace('renderLogin(): LoginForm', {
        isLoginFormAuthenticating: this.isLoginFormAuthenticating(),
      });
    }
    this.traceState('renderLogin()');
    let notification;
    if (this.state.authError) {
      notification = (
        <Notification
          variant="error"
          message={this.state.authErrorMessage || ''}
          visible={this.state.authError}
          onClose={this.closeErrorNotification}
        // duration={10000}
        />
      );
    } else if (this.state.successNotification) {
      notification = (
        <Notification
          variant="success"
          message={this.state.successNotificationMessage || ''}
          visible={this.state.successNotification}
          onClose={this.closeSuccessNotification}
        // duration={10000}
        />
      );
    }

    return (
      <>
        <AppAuthLanguageContainer>
          <AppAuthLanguageSelector />
        </AppAuthLanguageContainer>
        <AppAuthLoginContainer>
          <NewVersionNotification />
          {notification}
          {this.state.forgotPassword ? (
            <PasswordResetDialog
              open={this.state.forgotPassword}
              onCancel={this.onCancelForgotPassword}
              onSubmit={this.onResetPassword}
            />
          ) : null}
          <LoginForm
            includeDomain={this.includeDomainField()}
            domain={this.domain ? this.domain.name : undefined}
            username={this.state.email}
            password={this.state.password}
            onLogin={this.onLoginButtonClicked}
            onForgotPassword={this.onForgotPassword}
            authenticating={this.isLoginFormAuthenticating()}
            logo={!this.configuringRuntime}
          />
        </AppAuthLoginContainer>
      </>
    );
  }
}

// map redux ApplicationState to component properties
const mapStateToProps = ({ auth, layout, locale, context }: redux.ApplicationState) => ({
  db: auth.database,
  center: context.center,
  user: auth.user,
  initialized: auth.initialized,
  userPerson: auth.userPerson,
  themeName: layout.themeName,
  fontSize: layout.fontSize,
  language: locale.language,
  auth: auth,
});

// map redux dispatch actions to component properties
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signedIn: (user: firebase.User, db: Database, userProfile: UserProfile) =>
    dispatch(redux.auth.signedIn(user, db, userProfile)),
  signedOut: () => {
    dispatch(redux.auth.signedOut());
    // dispatch(store.context.clearCenter());
    dispatch(redux.auth.clearPerson());
  },
  selectPerson: (userPerson: UserPerson) => dispatch(redux.auth.selectPerson(userPerson)),
  clearPerson: () => dispatch(redux.auth.clearPerson()),
  setThemeName: (name: UserPersonTheme) => dispatch(redux.layout.setThemeName(name)),
  clearThemeName: () => dispatch(redux.layout.clearThemeName()),
  setFontSize: (size: number) => dispatch(redux.layout.setFontSize(size)),
  clearFontSize: () => dispatch(redux.layout.clearFontSize()),
  setCenter: (center: models.CareCenter) => dispatch(redux.context.setCenter(center)),
  clearCenter: () => dispatch(redux.context.clearCenter()),
  setSuspended: (suspended: boolean) => dispatch(redux.hybrid.setSuspended(suspended)),
  setDomain: (domain: string | null) => dispatch(redux.runtime.setRuntimeDomain(domain)),
});

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppAuth));

export default connect(mapStateToProps, mapDispatchToProps)(withRoot(injectIntl(AppAuth)));
