export const pad = (data: string | number | null, len: number, v?: string) => {
  const val = data === null ? '' : (data as string);

  if (val.length < len) {
    return (v ? v : '.').repeat(len - val.length) + val;
  }

  return val;
};

export const padRight = (data: string | number | null, len: number, v?: string) => {
  const val = data === null ? '' : data + '';

  if (val.length < len) {
    return val + (v ? v : '.').repeat(len - val.length);
  }

  return val;
};

interface TimerToken {
  name: string;
  start: number;
}

export const newTimer = (name: string): TimerToken => {
  return {
    name,
    start: new Date().getTime(),
  };
};

export const printTime = (time: TimerToken): string => {
  return time.name + ': ' + (new Date().getTime() - time.start) + ' milliseconds';
};
