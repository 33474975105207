import { createSvgIcon } from '@mui/material';

const SPO2 = createSvgIcon(
  <path d="M12,2.2c0,0-7.1,8.1-7.1,12.9c0,4,3.2,7.1,7.1,7.1s7.1-3.2,7.1-7.1C19.1,10.2,12,2.2,12,2.2z M13,13c0.5,0,1,0.5,1,1
	s-0.5,1-1,1s-1-0.5-1-1S12.5,13,13,13z M11,11c0.5,0,1,0.5,1,1s-0.5,1-1,1s-1-0.5-1-1S10.5,11,11,11z M9,13c0.5,0,1,0.5,1,1
	s-0.5,1-1,1s-1-0.5-1-1S8.5,13,9,13z M9,19c-0.5,0-1-0.5-1-1s0.5-1,1-1s1,0.5,1,1S9.5,19,9,19z M11,17c-0.5,0-1-0.5-1-1s0.5-1,1-1
	s1,0.5,1,1S11.5,17,11,17z M13,19c-0.5,0-1-0.5-1-1s0.5-1,1-1s1,0.5,1,1S13.5,19,13,19z M15,17c-0.5,0-1-0.5-1-1s0.5-1,1-1
	s1,0.5,1,1S15.5,17,15,17z M15,13c-0.5,0-1-0.5-1-1s0.5-1,1-1s1,0.5,1,1S15.5,13,15,13z" />,
  'SPO2'
);

export { SPO2 };
export default SPO2;
