import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { styled } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const Styled = {
  Backdrop: styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  })),
  Progress: styled(CircularProgress)(({ theme }) => ({
    margin: theme.spacing(2),
  })),
  Typography: styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1),
  })),
};

interface Props {
  className?: string;
  open?: boolean;
  progress?: React.ReactNode;
  message?: string;
}

type AllProps = WrappedComponentProps & Props;

class Loading extends React.Component<AllProps> {
  render() {
    const { message, open } = this.props;
    const label = message ? (
      <Typography variant="caption" align="center" noWrap>
        {message}
      </Typography>
    ) : null;
    const progress = this.props.progress ? (
      this.props.progress
    ) : (
      <Styled.Progress color="inherit" />
    );
    return (
      <Styled.Backdrop open={open !== undefined ? open : true}>
        {progress}
        {label}
      </Styled.Backdrop>
    );
  }
}

export default injectIntl(Loading);
