"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeToJSON = exports.HomeFromJSONTyped = exports.HomeFromJSON = void 0;
function HomeFromJSON(json) {
    return HomeFromJSONTyped(json, false);
}
exports.HomeFromJSON = HomeFromJSON;
function HomeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'homename': json['homename'],
        'homeid': json['homeid'],
    };
}
exports.HomeFromJSONTyped = HomeFromJSONTyped;
function HomeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'homename': value.homename,
        'homeid': value.homeid,
    };
}
exports.HomeToJSON = HomeToJSON;
