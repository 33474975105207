/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { LayoutActionTypes, LayoutState } from './types';

const initialState: LayoutState = {
  themeName: undefined,
  fontSize: undefined,
  dashboard: undefined,
};

const reducer: Reducer<LayoutState> = (state: LayoutState = initialState, action) => {
  switch (action.type) {
    case LayoutActionTypes.SET_THEME_NAME: {
      return { ...state, themeName: action.payload };
    }
    case LayoutActionTypes.CLEAR_THEME_NAME: {
      return { ...state, themeName: undefined };
    }
    case LayoutActionTypes.SET_VIEW_COLLAPSED: {
      return { ...state, viewCollapsed: action.payload };
    }
    case LayoutActionTypes.SET_VIEW_COLLAPSED_BY: {
      return { ...state, viewCollapsedBy: action.payload };
    }
    case LayoutActionTypes.SET_FONT_SIZE: {
      return { ...state, fontSize: action.payload };
    }
    case LayoutActionTypes.CLEAR_FONT_SIZE: {
      return { ...state, fontSize: undefined };
    }
    case LayoutActionTypes.SET_DASHBOARD: {
      return { ...state, dashboard: action.payload };
    }
    case LayoutActionTypes.CLEAR_DASHBOARD: {
      return { ...state, dashboard: undefined };
    }
    default: {
      return state;
    }
  }
};

export { reducer as layoutReducer };
