import { getEnvironment } from 'attentive-connect-api-fetch/dist/api';
import { GlobalConfig, VendorId } from 'attentive-connect-store/dist/config/Environment';
import * as firebase from 'attentive-connect-store/dist/services/Firebase';
import { isHybrid } from '../hybrid';
import { getLogger } from '../logger';
import session from '../session';

// add ac field to window - we store defaults there
// on page reload will attempt to login in using these defaults
// see settings in Environment object
declare global {
  interface Window {
    ac?: GlobalConfig;
  }
}
const logger = getLogger('domain/DomainServices', 'info');
logger.debug();

export interface Domain {
  name: string;
  apiUrl: string;
  config: GlobalConfig;
}

interface Version {
  version: string;
}

export const findDomain = async (): Promise<Domain | undefined> => {
  let domain: Domain | undefined = undefined;
  if (domainNeedsToBeSpecified()) {
    domain = session.getDomain();
    logger.debug('lookupDomainConnection(): session', domain);
  } else {
    const url = new URL(window.location.href);
    const name = getDomainNameFromURL(url);
    domain = await loadDomain(name);
    logger.debug('lookupDomainConnection(): built-in', domain);
  }
  return domain;
};

export const storeDomain = (domain: Domain) => {
  session.setDomain(domain);
  if (domain.config) {
    // we need to do this so that on page reload we can auto login...
    window.ac = domain.config;
  }
};

const tryServicesURL = async (url: string | URL): Promise<boolean> => {
  try {
    const _url = new URL(url);
    const valid = await fetch(_url)
      .then(async (r) => {
        if (r.ok) {
          const json = (await r.json()) as Version;
          return json.version !== undefined;
        }
        return false;
      })
      .catch(() => {
        return false;
      });
    logger.trace(`tryServicesURL(${_url}): ${valid}`);
    return valid;
  } catch (e) {
    logger.trace(`tryServicesURL(${url}): invalid`, e);
    return false;
  }
};

const findServicesURL = async (url?: string | URL): Promise<URL | undefined> => {
  if (!url || (typeof url === 'string' && url.length === 0)) {
    return undefined;
  }
  try {
    const u = new URL(
      typeof url === 'string' ? (url.startsWith('http') ? url : 'https://' + url) : url
    );
    const path = '/api/v1';
    const hostname = u.hostname;
    let hostnameAC: string | undefined = undefined;
    if (
      !isIPAddress(u.hostname) &&
      u.hostname !== 'localhost' &&
      u.hostname.indexOf('.attentiveconnect.com') < 0
    ) {
      hostnameAC = u.hostname + '.attentiveconnect.com';
    }
    const port = u.port.length > 0 ? ':' + u.port : '';
    const urls: Array<string | URL> = [];
    urls.push('https://' + hostname + port + path, 'http://' + hostname + port + path);
    if (hostnameAC) {
      urls.push('https://' + hostnameAC + port + path, 'http://' + hostnameAC + port + path);
    }
    urls.push('https://' + hostname + port + path, 'http://' + hostname + ':3100' + path);
    if (hostnameAC) {
      urls.push('https://' + hostnameAC + port + path, 'http://' + hostnameAC + ':3100' + path);
    }
    for (const href of urls) {
      if (await tryServicesURL(href)) {
        logger.debug(`findServicesUrl(src=${url}): found url ${href}`);
        return new URL(href);
      }
    }
  } catch (e) {
    logger.debug(`findServicesUrl(src=${url}): could not find url: ${e}`);
  }
  return undefined;
};

export const findUrlFromDomainName = async (domainName: string): Promise<URL | undefined> => {
  const domain = domainName.toLocaleLowerCase();
  const url = await findServicesURL(domain);
  logger.debug(`findUrlFromDomainName(${domain})`, url);
  return url;
};

export const getDomainNameFromURL = (url = new URL(window.location.href)): string => {
  // if (url.port.length > 0 && url.port !== '80' && url.port !== '443') {
  //   return url.hostname + ':' + url.port;
  // }
  // if (!isIPAddress(url.hostname)) {
  //   return url.hostname.split('.')[0];
  // }
  return url.hostname;
};

export const isDomainConnected = async (config: GlobalConfig) => {
  if (!firebase.FirebaseRuntime.instance.initialized) {
    return false;
  }
  if (
    firebase.FirebaseRuntime.instance.initialized &&
    config.firebase &&
    firebase.FirebaseRuntime.instance.projectId === config.firebase.projectId
  ) {
    // already initialized
    return true;
  }
  return false;
};

export const loadDomain = async (name?: string): Promise<Domain | undefined> => {
  let domain: Domain | undefined;
  const domainName = name && name.length > 0 ? name : getDomainNameFromURL();
  const apiUrl = await findServicesURL(domainName);
  if (apiUrl) {
    const config = await loadDomainConfig(apiUrl);
    if (config) {
      domain = { name: domainName, apiUrl: apiUrl.href, config };
    }
  }
  logger.debug(`loadDomainConnection(${name})`, domain);
  return domain;
};

export const loadDomainConfig = async (
  api: string | URL
  // domain: Domain
  // email: string,
  // password: string
): Promise<GlobalConfig | undefined> => {
  const env = await getEnvironment(api.toString());
  const vendor: VendorId = env.vendor
    ? env.vendor.toLocaleLowerCase() === 'toppan'
      ? 'toppan'
      : 'macnica'
    : 'macnica';
  const config: GlobalConfig = {
    vendor: { id: vendor },
    pathname: isHybrid() ? './' : '/ac',
    servicesURL: api.toString(),
    firebase: env.firebase,
    googleMaps: undefined,
  };
  const DEBUGGING = true;
  if (DEBUGGING) {
    config.oneSignal = {
      enabled: true,
      acLiteAppId: '98679dba-a7e7-4531-9bca-fcbbd1dbd287',
      acAppId: '9f120b54-f75b-4ecc-af6c-00f040b4875d',
      acVoIPAppId: 'b0bb2d98-f5c6-4c87-a0df-212e7d47078d',
      webPushAppId: '6829317d-9647-485e-a74b-7b0c278b4ed2',
    };
  } else if (env.oneSignal) {
    config.oneSignal = {
      enabled: true,
      acLiteAppId: env.oneSignal.acLiteAppId,
      acAppId: env.oneSignal.acAppId,
      webPushAppId: env.oneSignal.webPushAppId,
    };
  }
  return config;
};

export const domainNeedsToBeSpecified = (): boolean => {
  let needed = false;
  const url = new URL(window.location.href);
  if (
    isHybrid() ||
    url.hostname === 'localhost' ||
    isIPAddress(url.hostname) ||
    url.hostname.indexOf('.attentiveconnect.com') < 0
  ) {
    needed = true;
  }
  logger.debug('domainNeedsToBeSpecified(): ' + needed);
  return needed;
};

export const isIPAddress = (hostname: string): boolean => {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9])$/.test(
      hostname
    )
  ) {
    return true;
  }
  return false;
};
