import { createSvgIcon } from '@mui/material';

const Bonx = createSvgIcon(
  <g transform="matrix(0.686574,0,0,0.714166,-80.7349,-85.2287)">
    <g>
      <path d="M125.918,147.3C128.518,149.237 131.698,150.238 134.939,150.139C142.591,150.139 147.832,145.799 148.908,138.867L125.918,147.3Z" />
      <path d="M149.091,137.049C149.106,136.749 149.117,136.449 149.117,136.141C149.117,127.641 143.552,122.141 134.939,122.141C126.49,122.141 121.031,127.641 121.031,136.141C120.894,139.772 122.156,143.321 124.556,146.049L149.091,137.049Z" />
    </g>
  </g>,
  'Bonx'
);

export { Bonx };
export default Bonx;
