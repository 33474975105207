import * as React from 'react';
import { Stack, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { DataBoxVariants } from './DataBoxConfig';
import DataBoxReading, { DataBoxReadingData } from './DataBoxReading';
import classNames from 'classnames';
import { Paper_DataBox } from '../../themes/Styled';

export type DataBoxValues = DataBoxReadingData;

type Props = {
  className?: string;
  variant?: DataBoxVariants;
  values?: DataBoxValues;
  header?: string;
  icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
};

const DataBox: React.FC<Props> = (props: Props) => {
  const DataBoxIcon = props.variant !== 'dashboard' && props.icon ? props.icon : null;
  const content = (
    <>
      <Stack className="AC-dataHeading">
        {props.header ? (
          <Typography className="AC-dataHeader" variant="subtitle2" noWrap>
            {props.header}
          </Typography>
        ) : null}
        {DataBoxIcon ? <DataBoxIcon /> : null}
      </Stack>
      <DataBoxReading variant={props.variant} values={props.values} />
    </>
  );

  if (props.variant === 'inner') {
    return <Stack className={classNames('AC-dataBoxGridLiner', props.className)}>{content}</Stack>;
  } else {
    return (
      <Paper_DataBox variant="outlined" className={classNames('AC-dataBox', props.className)}>
        {content}
      </Paper_DataBox>
    );
  }
};

export default DataBox;
