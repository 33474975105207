import { Action, AnyAction, combineReducers, createStore, Dispatch, Store } from 'redux';

import * as aisleep from './aisleep';
import * as sensingWave from './sensingWave';
import * as auth from './auth';
import * as bioBeat from './biobeat';
import * as context from './context';
import * as dashboard from './dashboard';
import * as device from './device';
import * as hybrid from './hybrid';
import * as layout from './layout';
import * as locale from './locale';
import ReduxDevToolsExtension from './reduxDevTools';
import * as runtime from './runtime';
import * as serviceWorker from './serviceWorker';

// import { applyMiddleware } from 'redux'
// import createSagaMiddleware from 'redux-saga'
// `react-router-redux` is deprecated, so we use `connected-react-router`.
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
// import { composeWithDevTools } from 'redux-devtools-extension'

// The top-level state object
export interface ApplicationState {
  auth: auth.AuthState;
  context: context.ContextState;
  dashboard: dashboard.DashboardState;
  device: device.DeviceState;
  locale: locale.LocaleState;
  serviceWorker: serviceWorker.ServiceWorkerState;
  bioBeat: bioBeat.BioBeatState;
  aisleep: aisleep.AisleepState;
  sensingWave: sensingWave.SensingWaveState;
  layout: layout.LayoutState;
  runtime: runtime.RuntimeState;
  hybrid: hybrid.HybridState;
}

// Additional props for connected React components. This prop is passed by default with `connect()`
export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const rootReducer = combineReducers<ApplicationState>({
  auth: auth.authReducer,
  context: context.contextReducer,
  dashboard: dashboard.dashboardReducer,
  device: device.deviceReducer,
  locale: locale.localeReducer,
  serviceWorker: serviceWorker.serviceWorkerReducer,
  bioBeat: bioBeat.bioBeatReducer,
  aisleep: aisleep.AisleepReducer,
  sensingWave: sensingWave.SensingWaveReducer,
  layout: layout.layoutReducer,
  runtime: runtime.runtimeReducer,
  hybrid: hybrid.hybridReducer,
});

let store: Store<ApplicationState, AnyAction>;

export {
  aisleep,
  sensingWave,
  auth,
  bioBeat,
  context,
  dashboard,
  device,
  layout,
  locale,
  hybrid,
  serviceWorker,
  store,
  runtime,
};

export function configureStore(): Store<ApplicationState> {
  // history: History,
  // initialState: ApplicationState
  // create the composing function for our middlewares
  // const composeEnhancers = composeWithDevTools({})
  // create the redux-saga middleware
  // const sagaMiddleware = createSagaMiddleware()

  // We'll create our store with the combined reducers/sagas, and the initial Redux state that
  // we'll be passing from our entry point.
  if (!store) {
    store = createStore(
      rootReducer,
      // initialState,
      // composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
      ReduxDevToolsExtension
    );
  }
  // Don't forget to run the root saga, and return the store object.
  // sagaMiddleware.run(rootSaga)
  return store;
}
