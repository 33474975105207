"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCamera = exports.isCameraModel = void 0;
// type guards
function isCameraModel(object) {
    const _obj = object;
    if (_obj.cameraType === "Elmo") {
        return _obj.cameraId !== undefined;
    }
    else {
        return _obj.cameraId !== undefined && _obj.currentPhotoTime >= 0;
    }
}
exports.isCameraModel = isCameraModel;
function isCamera(object) {
    const _obj = object;
    return _obj.data !== undefined && isCameraModel(_obj.data);
}
exports.isCamera = isCamera;
