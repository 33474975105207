import { createSvgIcon } from '@mui/material';

const SensingWave2Symbol = createSvgIcon(
  <g>
    <g transform="matrix(0.166836,0,0,0.166667,-76.4897,-24.1223)">
      <g transform="matrix(1,0,0,1,496.441,224.284)">
        <path d="M0,14.741C0,16.94 -0.562,18.843 -1.687,20.45C-2.813,22.057 -4.381,23.288 -6.394,24.142C-8.407,24.995 -10.716,25.423 -13.32,25.423C-14.048,25.423 -14.941,25.376 -15.997,25.283C-17.055,25.19 -18.459,24.933 -20.209,24.509C-21.96,24.087 -23.783,23.461 -25.677,22.632L-25.677,14.005C-23.901,15.105 -22.155,16.018 -20.438,16.745C-18.721,17.473 -16.881,17.837 -14.919,17.837C-13.109,17.837 -11.938,17.537 -11.405,16.936C-10.872,16.336 -10.606,15.773 -10.606,15.248C-10.606,14.301 -11.063,13.502 -11.976,12.851C-12.889,12.2 -14.217,11.527 -15.959,10.834C-17.888,10.022 -19.579,9.138 -21.034,8.182C-22.489,7.227 -23.677,6.03 -24.599,4.592C-25.521,3.154 -25.982,1.455 -25.982,-0.508C-25.982,-2.402 -25.516,-4.115 -24.586,-5.646C-23.656,-7.176 -22.235,-8.399 -20.323,-9.312C-18.412,-10.226 -16.078,-10.682 -13.32,-10.682C-11.359,-10.682 -9.544,-10.492 -7.878,-10.111C-6.212,-9.731 -4.838,-9.304 -3.755,-8.83C-2.673,-8.356 -1.928,-7.984 -1.522,-7.714L-1.522,0.532C-2.96,-0.499 -4.563,-1.421 -6.33,-2.233C-8.098,-3.045 -9.988,-3.451 -12.001,-3.451C-13.32,-3.451 -14.277,-3.197 -14.868,-2.69C-15.461,-2.182 -15.757,-1.557 -15.757,-0.813C-15.757,-0.119 -15.461,0.482 -14.868,0.989C-14.277,1.497 -13.219,2.114 -11.697,2.841C-8.991,4.11 -6.825,5.252 -5.201,6.267C-3.578,7.281 -2.305,8.453 -1.383,9.781C-0.461,11.109 0,12.762 0,14.741" />
      </g>
      <g transform="matrix(1,0,0,1,559.411,249.707)">
        <path d="M0,-35.623L-15.224,0L-21.364,0L-30.498,-20.729L-39.861,0L-46.051,0L-60.97,-35.623L-49.984,-35.623L-42.677,-16.137L-34.304,-35.623L-26.717,-35.623L-18.497,-16.137L-10.859,-35.623L0,-35.623Z" />
      </g>
      <g transform="matrix(-1,0,0,1,1410.77,-170.566)">
        <path d="M824.603,389.701L824.603,414.741L820.43,414.741L820.43,419.804L849.356,419.804L849.356,414.741L845.184,414.741L845.184,389.701L849.356,389.701L849.356,384.638L820.43,384.638L820.43,389.701L824.603,389.701ZM837.814,389.701L837.814,414.741L831.973,414.741L831.973,389.701L837.814,389.701Z" />
      </g>
    </g>
    <g transform="matrix(0.149486,0,0,0.149486,-81.6531,-17.751)">
      <g transform="matrix(0,-1,-1,0,563.898,180.257)">
        <path d="M-4.292,-4.292C-6.662,-4.292 -8.584,-2.37 -8.584,0C-8.584,2.37 -6.662,4.292 -4.292,4.292C-1.922,4.292 0,2.37 0,0C0,-2.37 -1.922,-4.292 -4.292,-4.292" />
      </g>
      <g transform="matrix(0,-1,-1,0,574.284,174.54)">
        <path d="M-3.862,-3.862C-5.996,-3.862 -7.726,-2.133 -7.726,0.001C-7.726,2.134 -5.996,3.862 -3.862,3.862C-1.729,3.862 0.001,2.134 0.001,0.001C0.001,-2.133 -1.729,-3.862 -3.862,-3.862" />
      </g>
      <g transform="matrix(0,-1,-1,0,586.317,169.419)">
        <path d="M-3.477,-3.477C-5.396,-3.477 -6.952,-1.921 -6.952,-0.001C-6.952,1.919 -5.396,3.477 -3.477,3.477C-1.557,3.477 -0.001,1.919 -0.001,-0.001C-0.001,-1.921 -1.557,-3.477 -3.477,-3.477" />
      </g>
      <g transform="matrix(0,-1,-1,0,598.518,165.736)">
        <path d="M-3.128,-3.128C-4.856,-3.128 -6.258,-1.728 -6.258,0.001C-6.258,1.728 -4.856,3.128 -3.128,3.128C-1.4,3.128 0.001,1.728 0.001,0.001C0.001,-1.728 -1.4,-3.128 -3.128,-3.128" />
      </g>
      <g transform="matrix(0,-1,-1,0,610.829,163.412)">
        <path d="M-2.816,-2.816C-4.371,-2.816 -5.632,-1.555 -5.632,-0C-5.632,1.555 -4.371,2.816 -2.816,2.816C-1.261,2.816 -0,1.555 -0,-0C-0,-1.555 -1.261,-2.816 -2.816,-2.816" />
      </g>
      <g transform="matrix(0,-1,-1,0,622.375,162.391)">
        <path d="M-2.535,-2.535C-3.935,-2.535 -5.069,-1.4 -5.069,-0.001C-5.069,1.399 -3.935,2.535 -2.535,2.535C-1.135,2.535 -0.001,1.399 -0.001,-0.001C-0.001,-1.4 -1.135,-2.535 -2.535,-2.535" />
      </g>
      <g transform="matrix(0,-1,-1,0,634.989,162.507)">
        <path d="M-2.281,-2.281C-3.541,-2.281 -4.562,-1.26 -4.562,0C-4.562,1.259 -3.541,2.281 -2.281,2.281C-1.021,2.281 0,1.259 0,0C0,-1.26 -1.021,-2.281 -2.281,-2.281" />
      </g>
      <g transform="matrix(0,-1,-1,0,647.475,163.895)">
        <path d="M-2.053,-2.053C-3.187,-2.053 -4.106,-1.134 -4.106,0C-4.106,1.133 -3.187,2.053 -2.053,2.053C-0.919,2.053 0,1.133 0,0C0,-1.134 -0.919,-2.053 -2.053,-2.053" />
      </g>
      <g transform="matrix(0,-1,-1,0,660.267,166.686)">
        <path d="M-1.848,-1.847C-2.868,-1.847 -3.695,-1.02 -3.695,0C-3.695,1.021 -2.868,1.848 -1.848,1.848C-0.828,1.848 0,1.021 0,0C0,-1.02 -0.828,-1.847 -1.848,-1.847" />
      </g>
      <g transform="matrix(0,-1,-1,0,672.018,170.584)">
        <path d="M-1.663,-1.663C-2.581,-1.663 -3.326,-0.919 -3.326,0C-3.326,0.918 -2.581,1.663 -1.663,1.663C-0.745,1.663 0,0.918 0,0C0,-0.919 -0.745,-1.663 -1.663,-1.663" />
      </g>
      <g transform="matrix(0,-1,-1,0,682.77,175.385)">
        <path d="M-1.497,-1.497C-2.322,-1.497 -2.992,-0.827 -2.992,-0C-2.992,0.827 -2.322,1.497 -1.497,1.497C-0.67,1.497 -0,0.827 -0,-0C-0,-0.827 -0.67,-1.497 -1.497,-1.497" />
      </g>
      <g transform="matrix(0,-1,-1,0,692.125,180.727)">
        <path d="M-1.273,-1.273C-1.976,-1.273 -2.546,-0.703 -2.546,0C-2.546,0.703 -1.976,1.273 -1.273,1.273C-0.57,1.273 0,0.703 0,0C0,-0.703 -0.57,-1.273 -1.273,-1.273" />
      </g>
    </g>
  </g>,
  'SensingWave2Symbol'
);

export { SensingWave2Symbol };
export default SensingWave2Symbol;
