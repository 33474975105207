/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { HybridActionTypes, HybridState } from './types';

const initialState: HybridState = {
  suspended: false,
};

const reducer: Reducer<HybridState> = (state: HybridState = initialState, action) => {
  switch (action.type) {
    case HybridActionTypes.SET_SUSPENDED: {
      return { ...state, suspended: action.payload };
    }
    default: {
      return state;
    }
  }
};

export { reducer as hybridReducer };
