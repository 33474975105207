/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import session from '../../session';
import { DashboardActionTypes, DashboardState } from './types';
import { getLogger } from '../../logger';

const logger = getLogger('redux/dashboard/reducer');

const initialState: DashboardState = {
  bedOccupancy: [],
  disableWarnings: session.dashboard.getDisableWarnings(),
  hideAlerts: session.dashboard.getHideAlerts(),
  hideNoData: session.dashboard.getHideNoData(),
  hideOk: session.dashboard.getHideOk(),
  hideWarnings: session.dashboard.getHideWarnings(),
  muteAlerts: session.dashboard.getMuteAlerts(),
  muteWarnings: session.dashboard.getMuteWarnings(),
  playVerbalNotifications: false,
  pollBedOccupancy: false,
  showAlertsList: true,
  showBleVitals: false,
  showVitals: session.dashboard.getShowVitals(),
  sortByRoom: session.dashboard.getSortByRoom(),
};

logger.debug(initialState);

const reducer: Reducer<DashboardState> = (state: DashboardState = initialState, action) => {
  switch (action.type) {
    case DashboardActionTypes.SET_SORT_BY_ROOM: {
      session.dashboard.setSortByRoom(Boolean(action.payload));
      return { ...state, sortByRoom: action.payload };
    }
    case DashboardActionTypes.SET_SHOW_ALERTS_LIST: {
      return { ...state, showAlertsList: action.payload };
    }
    case DashboardActionTypes.SET_SHOW_VITALS: {
      session.dashboard.setShowVitals(Boolean(action.payload));
      return { ...state, showVitals: action.payload };
    }
    case DashboardActionTypes.SET_SHOW_BLE_VITALS: {
      return { ...state, showBleVitals: action.payload };
    }
    case DashboardActionTypes.SET_PLAY_VERBAL_NOTIFICATIONS: {
      return { ...state, playVerbalNotifications: action.payload };
    }
    case DashboardActionTypes.SET_BED_OCCUPANCY: {
      return { ...state, bedOccupancy: action.payload };
    }
    case DashboardActionTypes.SET_POLL_BED_OCCUPANCY: {
      return { ...state, pollBedOccupancy: action.payload };
    }
    case DashboardActionTypes.SET_MUTE_ALERTS: {
      session.dashboard.setMuteAlerts(Boolean(action.payload));
      return { ...state, muteAlerts: action.payload };
    }
    case DashboardActionTypes.SET_MUTE_WARNINGS: {
      session.dashboard.setMuteWarnings(Boolean(action.payload));
      return { ...state, muteWarnings: action.payload };
    }
    case DashboardActionTypes.SET_HIDE_WARNINGS: {
      session.dashboard.setHideWarnings(Boolean(action.payload));
      return { ...state, hideWarnings: action.payload };
    }
    case DashboardActionTypes.SET_HIDE_NO_DATA: {
      session.dashboard.setHideNoData(Boolean(action.payload));
      return { ...state, hideNoData: action.payload };
    }
    case DashboardActionTypes.SET_HIDE_OK: {
      session.dashboard.setHideOk(Boolean(action.payload));
      return { ...state, hideOk: action.payload };
    }
    case DashboardActionTypes.SET_HIDE_ALERTS: {
      return { ...state, hideAlerts: action.payload };
    }
    case DashboardActionTypes.SET_DISABLE_WARNINGS: {
      session.dashboard.setDisableWarnings(Boolean(action.payload));
      return { ...state, disableWarnings: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { reducer as dashboardReducer };
