import { action } from 'typesafe-actions';

import { Width } from '../../device';
import { DeviceActionTypes } from './types';

export const setResizing = (resizing: boolean) => action(DeviceActionTypes.SET_RESIZING, resizing);
export const clearResizing = () => action(DeviceActionTypes.CLEAR_RESIZING);

export const setWidth = (width: Width) => action(DeviceActionTypes.SET_WIDTH, width);
export const clearWidth = () => action(DeviceActionTypes.CLEAR_WIDTH);
