"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSysLogLevel = exports.sysLogLevelValues = void 0;
exports.sysLogLevelValues = new Map([
    ['emerg', 0],
    ['alert', 1],
    ['crit', 2],
    ['error', 3],
    ['warning', 4],
    ['notice', 5],
    ['info', 6],
    ['debug', 7],
    ['trace', 8],
    ['console', 9],
]);
function isSysLogLevel(level) {
    return (level === 'emerg' ||
        level === 'alert' ||
        level === 'crit' ||
        level === 'error' ||
        level === 'warning' ||
        level === 'notice' ||
        level === 'info' ||
        level === 'debug' ||
        level === 'trace');
}
exports.isSysLogLevel = isSysLogLevel;
