import { Domain } from '../domain/DomainServices';
import { isHybrid } from '../hybrid';
import { getLogger } from '../logger';
import { routes } from '../routes';

const logger = getLogger('session', 'info');
logger.debug();

const KEY_DOMAIN = 'domain';
const KEY_CARE_CENTER_REF = 'care-center/ref';
const KEY_CURRENT_ROUTE = 'route/current';
const KEY_CURRENT_PERSON_ID = 'user/person/id';
const KEY_DASHBOARD_SORT_BY_ROOM = 'dashboard/sort-by-room';
const KEY_DASHBOARD_DISABLE_WARNINGS = 'dashboard/disable-warnings';
const KEY_DASHBOARD_SHOW_VITALS = 'dashboard/show-vitals';
const KEY_DASHBOARD_HIDE_OK = 'dashboard/hide-ok';
const KEY_DASHBOARD_HIDE_NO_DATA = 'dashboard/hide-no-data';
const KEY_DASHBOARD_HIDE_WARNINGS = 'dashboard/hide-warnings';
const KEY_DASHBOARD_HIDE_ALERTS = 'dashboard/hide-alerts';
const KEY_DASHBOARD_MUTE_ALERTS = 'dashboard/mute-alerts';
const KEY_DASHBOARD_MUTE_WARNINGS = 'dashboard/mute-warnings';
const KEY_LOCATION_MOST_RECENT = 'location/most-recent';

class Session {
  private static _instance: Session;
  cache = new Map<string, unknown>();
  private constructor() {
    //
  }
  public static load() {
    if (!Session._instance) {
      Session._instance = new Session();
    }
    return Session._instance;
  }

  acKey = (key: string) => 'ac/' + key;

  getItem = (key: string, _default?: string): string | null => {
    const item = window.localStorage.getItem(this.acKey(key));
    if (logger.isDebugEnabled()) {
      logger.debug(`GET ${this.acKey(key)}: ${item}`);
    }
    return item === null && _default !== undefined ? _default : item;
  };

  setItem = (key: string, val: string) => {
    if (logger.isDebugEnabled()) {
      logger.debug(`SET ${this.acKey(key)}: ${val}`);
    }
    window.localStorage.setItem(this.acKey(key), val);
  };

  removeItem = (key: string) => {
    if (logger.isDebugEnabled()) {
      logger.debug(`CLEAR ${this.acKey(key)}`);
    }
    window.localStorage.removeItem(this.acKey(key));
  };

  getTransientItem = (key: string, _default?: string): string | null => {
    const item = window.sessionStorage.getItem(this.acKey(key));
    if (logger.isDebugEnabled()) {
      logger.debug(`transient.GET ${this.acKey(key)}: ${item}`);
    }
    return item === null && _default !== undefined ? _default : item;
  };

  setTransientItem = (key: string, val: string) => {
    if (logger.isDebugEnabled()) {
      logger.debug(`transient.SET ${this.acKey(key)}: ${val}`);
    }
    window.sessionStorage.setItem(this.acKey(key), val);
  };

  removeTransientItem = (key: string) => {
    if (logger.isDebugEnabled()) {
      logger.debug(`transient.CLEAR ${this.acKey(key)}`);
    }
    window.sessionStorage.removeItem(this.acKey(key));
  };

  // domain connection
  setDomain = (val: Domain) => {
    this.setItem(KEY_DOMAIN, JSON.stringify(val));
  };
  getDomain = (): Domain | undefined => {
    const val = this.getItem(KEY_DOMAIN);
    if (val) {
      return JSON.parse(val) as Domain;
    }
    return undefined;
  };
  removeDomainConnection = () => this.removeItem(KEY_DOMAIN);

  // domain configuration
  // setDomainConfig = (config: GlobalConfig) =>
  //   this.setItem(KEY_DOMAIN_CONFIG, JSON.stringify(config));
  // getDomainConfig = (): GlobalConfig | null => {
  //   const config = this.getItem(KEY_DOMAIN_CONFIG);
  //   if (config) {
  //     return JSON.parse(config) as GlobalConfig;
  //   }
  //   return null;
  // };
  // removeDomainConfig = () => this.removeItem(KEY_DOMAIN_CONFIG);

  // care center
  setCareCenterRef = (val: string) => this.setItem(KEY_CARE_CENTER_REF, val);
  getCareCenterRef = () => this.getItem(KEY_CARE_CENTER_REF);
  removeCareCenterRef = () => this.removeItem(KEY_CARE_CENTER_REF);

  // route
  setCurrentRoute = (val: string) => this.setItem(KEY_CURRENT_ROUTE, val);
  getCurrentRoute = () => this.getItem(KEY_CURRENT_ROUTE);
  removeCurrentRoute = () => this.removeItem(KEY_CURRENT_ROUTE);

  setCurrentPersonId = (val: string) => this.setItem(KEY_CURRENT_PERSON_ID, val);
  getCurrentPersonId = () => this.getItem(KEY_CURRENT_PERSON_ID);
  removeCurrentPersonId = () => this.removeItem(KEY_CURRENT_PERSON_ID);

  // dashboard
  dashboard = {
    setSortByRoom: (val: boolean) => this.setItem(KEY_DASHBOARD_SORT_BY_ROOM, val.toString()),
    getSortByRoom: () =>
      this.getItem(KEY_DASHBOARD_SORT_BY_ROOM, true.toString()) === true.toString(),
    setDisableWarnings: (val: boolean) =>
      this.setItem(KEY_DASHBOARD_DISABLE_WARNINGS, val.toString()),
    getDisableWarnings: () =>
      this.getItem(KEY_DASHBOARD_DISABLE_WARNINGS, true.toString()) === true.toString(),
    setShowVitals: (val: boolean) => this.setItem(KEY_DASHBOARD_SHOW_VITALS, val.toString()),
    getShowVitals: () => {
      const val = this.getItem(KEY_DASHBOARD_SHOW_VITALS) === true.toString();
      logger.debug(`${KEY_DASHBOARD_SHOW_VITALS}: ${val}`);
      return val;
    },
    setHideOk: (val: boolean) => this.setItem(KEY_DASHBOARD_HIDE_OK, val.toString()),
    getHideOk: () => this.getItem(KEY_DASHBOARD_HIDE_OK) === true.toString(),
    setHideNoData: (val: boolean) => this.setItem(KEY_DASHBOARD_HIDE_NO_DATA, val.toString()),
    getHideNoData: () => this.getItem(KEY_DASHBOARD_HIDE_NO_DATA) === true.toString(),
    setHideWarnings: (val: boolean) => this.setItem(KEY_DASHBOARD_HIDE_WARNINGS, val.toString()),
    getHideWarnings: () => this.getItem(KEY_DASHBOARD_HIDE_WARNINGS) === true.toString(),
    setHideAlerts: (val: boolean) => this.setItem(KEY_DASHBOARD_HIDE_ALERTS, val.toString()),
    getHideAlerts: () => this.getItem(KEY_DASHBOARD_HIDE_ALERTS) === true.toString(),
    setMuteAlerts: (val: boolean) => this.setItem(KEY_DASHBOARD_MUTE_ALERTS, val.toString()),
    getMuteAlerts: () => this.getItem(KEY_DASHBOARD_MUTE_ALERTS) === true.toString(),
    setMuteWarnings: (val: boolean) => this.setItem(KEY_DASHBOARD_MUTE_WARNINGS, val.toString()),
    getMuteWarnings: () => this.getItem(KEY_DASHBOARD_MUTE_WARNINGS) === true.toString(),
  };
  location = {
    setMostRecentLocation: (val: string) => this.setItem(KEY_LOCATION_MOST_RECENT, val),
    getMostRecentLocation: (): string => {
      const _l = this.getItem(KEY_LOCATION_MOST_RECENT);
      if (!_l || isHybrid()) {
        return routes.dashboard;
      } else {
        let valid = false;
        Object.keys(routes).forEach((route) => {
          if (_l.indexOf(route) >= 0) {
            valid = true;
          }
        });
        return valid ? _l : routes.dashboard;
      }
    },
  };
  alerts = {
    setOneClickResolving: (alertId: string | undefined) => {
      if (alertId !== undefined) {
        this.setTransientItem(`alerts/resolving/${alertId}`, Date.now().toString());
      }
    },
    oneClickResolvingTimeMilliSec: (alertId: string | undefined): number => {
      if (alert !== undefined) {
        const val = this.getTransientItem(`alerts/resolving/${alertId}`);
        if (val !== null) {
          return Date.now() - Number(val);
        }
      }
      return -1;
    },
    clearOneClickResolving: (alertId: string | undefined, delayMilliSec = 30000) => {
      if (alertId !== undefined) {
        if (delayMilliSec > 0) {
          setTimeout(() => this.removeTransientItem(`alerts/resolving/${alertId}`), delayMilliSec);
        } else {
          this.removeTransientItem(`alerts/resolving/${alertId}`);
        }
      }
    },
  };
}

export default Session.load();
