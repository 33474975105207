import { Store as Database } from 'attentive-connect-store/dist';
import { UserPerson, UserProfile } from 'attentive-connect-store/dist/models';
import * as firebase from 'attentive-connect-store/dist/services/Firebase';

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum AuthActionTypes {
  SIGN_IN = '@@auth/SIGN_IN',
  SIGN_OUT = '@@auth/SIGN_OUT',
  SELECT_PERSON = '@@auth/SELECT_PERSON',
  CLEAR_PERSON = '@@auth/CLEAR_PERSON',
  TOKEN = '@@auth/TOKEN',
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface AuthState {
  readonly user?: firebase.User;
  readonly userProfile?: UserProfile;
  readonly userPerson?: UserPerson;
  readonly database?: Database;
  readonly initialized?: boolean;
  readonly token?: string;
  readonly tokenTime?: number;
}
