import { createSvgIcon } from '@mui/material';

const HeartRateVariability = createSvgIcon(
  <g>
    <path
      d="M9,6l3.4,7.1l2.8-4.6l3.1,2.5h3.1c0.3-0.8,0.5-1.6,0.5-2.5C22,5.4,19.6,3,16.5,3c-1.7,0-3.4,0.8-4.5,2.1
		C10.9,3.8,9.2,3,7.5,3C4.4,3,2,5.4,2,8.5c0,0.9,0.2,1.7,0.5,2.5h3L9,6z"
    />
    <path
      d="M17.5,12c-2.8,0-5.2,2.2-5.5,4.9l-3.4-7l-2.1,3H3.6c1.5,2.1,3.9,4.3,7,7l1.4,1.3l0.9-0.8c1,1.5,2.7,2.5,4.6,2.5
		c3,0,5.5-2.5,5.5-5.5S20.5,12,17.5,12z M17.5,22C15,22,13,20,13,17.5s2-4.5,4.5-4.5s4.5,2,4.5,4.5S20,22,17.5,22z"
    />
    <polygon points="19,17 18,17 18,19 16.8,19 18.5,20.8 20.2,19 19,19 	" />
    <polygon points="17,18 17,16 18.2,16 16.5,14.2 14.8,16 16,16 16,18 	" />
  </g>,
  'HeartRateVariability'
);

export { HeartRateVariability };
export default HeartRateVariability;
