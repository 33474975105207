import { getLogger } from 'attentive-connect-logger/dist';
import { OneSignalPlugin } from 'onesignal-cordova-plugin';

import { Semaphore } from '../data/Semaphore';
import { isHybrid } from './isHybrid';
import { DeviceState } from 'onesignal-cordova-plugin/dist/Subscription';

const logger = getLogger('hybrid/HybridOneSignal');

interface TagsObject<T> {
  [key: string]: T;
}

export class HybridOneSignal {
  private static _instance: HybridOneSignal | undefined;
  private static _plugin: OneSignalPlugin;
  private static _mounted = false;
  private static _semaphore = new Semaphore(1, 'HybridOneSignal');
  private _appId: string | undefined;
  private constructor(plugin: OneSignalPlugin) {
    HybridOneSignal._plugin = plugin;
  }
  static get instance() {
    return HybridOneSignal._instance;
  }
  static async mount() {
    await HybridOneSignal._semaphore.acquire();
    try {
      if (isHybrid() && !HybridOneSignal._instance && !HybridOneSignal._mounted) {
        const w = window as unknown as { plugins: { OneSignal: unknown } };
        if (w.plugins && w.plugins.OneSignal) {
          HybridOneSignal._instance = new HybridOneSignal(w.plugins.OneSignal as OneSignalPlugin);
        }
        if (!HybridOneSignal._instance) {
          logger.notice(`'window.plugins.OneSignal' plugin: not installed`);
        } else {
          logger.info(`'window.plugins.OneSignal' plugin: mounted`);
          if (logger.isDebugEnabled()) {
            logger.debug('OneSignal plugin', HybridOneSignal._plugin);
          }
        }
      }
      this._mounted = true;
      return HybridOneSignal._instance;
    } finally {
      HybridOneSignal._semaphore.release();
    }
  }
  static get mounted() {
    return HybridOneSignal._mounted;
  }
  get appId() {
    return this._appId;
  }
  get isEnabled() {
    return this._appId !== undefined && this._appId.length > 0;
  }
  get plugin() {
    return HybridOneSignal._plugin;
  }
  setAppId = (id: string) => {
    this._appId = id;
    if (id.length > 0 && this.plugin) {
      logger.debug('setAppId', id);
      this.plugin.setAppId(id);
    }
  };
  setExternalUserId = (externalUserId: string) => {
    if (this.isEnabled && this.plugin) {
      logger.debug('setExternalUserId', externalUserId);
      this.plugin.setExternalUserId(externalUserId);
    }
  };
  setEmail = (email: string) => {
    if (this.isEnabled && this.plugin) {
      logger.debug('setEmail', email);
      this.plugin.setEmail(email);
    }
  };
  setSMSNumber = (sms: string) => {
    if (this.isEnabled && this.plugin) {
      logger.debug('setSMSNumber', sms);
      this.plugin.setSMSNumber(sms);
    }
  };
  getDeviceState = async () => {
    const promise = new Promise<DeviceState | null>((resolve, reject) => {
      if (this.isEnabled && this.plugin) {
        try {
          this.plugin.getDeviceState((state: DeviceState) => {
            logger.debug('getDeviceState', state);
            resolve(state);
          });
        } catch (e) {
          reject(e);
        }
      } else {
        resolve(null);
      }
    });
    const token = await promise;
    return token;
  };
  getTags = async () => {
    const promise = new Promise<TagsObject<string | null>>((resolve, reject) => {
      if (this.isEnabled && this.plugin) {
        try {
          this.plugin.getTags((tags: unknown) => {
            logger.debug('getTags', tags);
            resolve(tags as TagsObject<string | null>);
          });
        } catch (e) {
          reject(e);
        }
      } else {
        resolve({});
      }
    });
    const tags = await promise;
    return tags;
  };
  deleteTags = async () => {
    const tags = await this.getTags();
    if (this.isEnabled && this.plugin) {
      logger.debug('deleteTags', tags);
      this.plugin.deleteTags(Object.keys(tags));
    }
    return this.getTags();
  };
  sendTags = (tags: TagsObject<string | null>) => {
    if (this.isEnabled && this.plugin) {
      logger.debug('sendTags', tags);
      this.plugin.sendTags(tags);
    }
  };
  setNotificationOpenedHandler = (handler: (data: unknown) => void) => {
    if (this.isEnabled && this.plugin) {
      this.plugin.setNotificationOpenedHandler(handler);
    }
  };
  promptForPushNotificationsWithUserResponse = async () => {
    const promise = new Promise<boolean | undefined>((resolve, reject) => {
      if (this.isEnabled && this.plugin) {
        try {
          this.plugin.promptForPushNotificationsWithUserResponse((allow) => {
            logger.debug(`promptForPushNotifications: ${allow ? 'enabled' : 'disabled'}`);
            resolve(allow);
          });
        } catch (e) {
          reject(e);
        }
      } else {
        resolve(undefined);
      }
    });
    const allow = await promise;
    return allow;
  };
}
