import { action } from 'typesafe-actions';

import { ServiceWorkerActionTypes } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

// newVersionAvailable will be set to true whenever the serviceWorker has uploaded
// a new version of the application.
// @see /index.ts

// This is called when the service worker is ready to install a new version.
export const setNewVersionAvailable = (available: boolean) =>
  action(ServiceWorkerActionTypes.NEW_VERSION_AVAILABLE, available);

// This is called when we find a new version on the server that should
// be installed by the service worker.
export const setNewVersionOnServer = (available: boolean) =>
  action(ServiceWorkerActionTypes.NEW_VERSION_ON_SERVER, available);
