import { BedOccupancy } from 'attentive-connect-store/dist/models';

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
export enum DashboardActionTypes {
  SET_SORT_BY_ROOM = '@@dashboard/SET_SORT_BY_ROOM',
  SET_SHOW_VITALS = '@@dashboard/SET_SHOW_VITALS',
  SET_SHOW_BLE_VITALS = '@@dashboard/SET_SHOW_BLE_VITALS',
  SET_SHOW_ALERTS_LIST = '@@dashboard/SET_SHOW_ALERTS_LIST',
  SET_PLAY_VERBAL_NOTIFICATIONS = '@@dashboard/SET_PLAY_VERBAL_NOTIFICATIONS',
  SET_POLL_BED_OCCUPANCY = '@@dashboard/SET_POLL_BED_OCCUPANCY',
  SET_BED_OCCUPANCY = '@@dashboard/SET_BED_OCCUPANCY',
  SET_MUTE_ALERTS = '@@dashboard/SET_MUTE_ALERTS',
  SET_MUTE_WARNINGS = '@@dashboard/SET_MUTE_WARNINGS',
  SET_HIDE_WARNINGS = '@@dashboard/SET_HIDE_WARNINGS',
  SET_HIDE_NO_DATA = '@@dashboard/SET_HIDE_NO_DATA',
  SET_HIDE_OK = '@@dashboard/SET_HIDE_OK',
  SET_HIDE_ALERTS = '@@dashboard/SET_HIDE_ALERTS',
  SET_DISABLE_WARNINGS = '@@dashboard/SET_DISABLE_WARNINGS',
}

export interface DashboardState {
  readonly sortByRoom: boolean;
  readonly showVitals: boolean;
  readonly showBleVitals: boolean;
  readonly showAlertsList: boolean;
  readonly playVerbalNotifications: boolean;
  readonly pollBedOccupancy: boolean;
  readonly bedOccupancy: BedOccupancy[];
  readonly muteAlerts: boolean;
  readonly muteWarnings: boolean;
  readonly hideWarnings: boolean;
  readonly hideNoData: boolean;
  readonly hideOk: boolean;
  readonly hideAlerts: boolean;
  readonly disableWarnings: boolean;
}
