import { createSvgIcon } from '@mui/material';

const Weight = createSvgIcon(
  <g>
    <path d="M0,0h24v24H0V0z" fill="none" />
    <g>
      <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M12,12c-1.66,0-3-1.34-3-3 s1.34-3,3-3s3,1.34,3,3S13.66,12,12,12z" />
      <rect height="1" width="1" x="10" y="8.5" />
      <rect height="1" width="1" x="11.5" y="8.5" />
      <rect height="1" width="1" x="13" y="8.5" />
    </g>
  </g>,
  'Weight'
);

export { Weight };
export default Weight;
