"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.currentUserId = exports.currentUser = exports.setLanguage = exports.sendPasswordResetEmail = exports.signOut = exports.signInWithCustomToken = exports.signIn = exports.createUserWithEmailAndPassword = void 0;
const auth = __importStar(require("firebase/auth"));
const logger_1 = require("../logger");
const Firebase_1 = require("./Firebase");
const logger = (0, logger_1.getLogger)('services/Authentication');
logger.debug();
// Sing Up
const createUserWithEmailAndPassword = (email, password) => {
    if (Firebase_1.FirebaseRuntime.instance.initialized) {
        logger.debug(`create user: ${email}`);
        return auth.createUserWithEmailAndPassword(Firebase_1.FirebaseRuntime.instance.auth, email, password);
    }
    else {
        throw new Error('cannot create user: firebase runtime is not initialized');
    }
};
exports.createUserWithEmailAndPassword = createUserWithEmailAndPassword;
// Sign In
const signIn = (email, password) => {
    if (Firebase_1.FirebaseRuntime.instance.initialized) {
        logger.debug(`sign in: ${email}`);
        return auth.signInWithEmailAndPassword(Firebase_1.FirebaseRuntime.instance.auth, email, password);
    }
    else {
        throw new Error('cannot sign in: firebase runtime is not initialized');
    }
};
exports.signIn = signIn;
// Sign in with custom token
const signInWithCustomToken = (token) => {
    if (Firebase_1.FirebaseRuntime.instance.initialized) {
        logger.debug(`sign in with custom token: ${token}`);
        return auth.signInWithCustomToken(Firebase_1.FirebaseRuntime.instance.auth, token);
    }
    else {
        throw new Error('cannot sign in with custom token: firebase runtime is not initialized');
    }
};
exports.signInWithCustomToken = signInWithCustomToken;
// Sing Out
const signOut = (db) => __awaiter(void 0, void 0, void 0, function* () {
    logger.debug('sign out');
    if (db) {
        db.listeners.stopAll();
    }
    if (Firebase_1.FirebaseRuntime.instance.initialized) {
        return Firebase_1.FirebaseRuntime.instance.auth.signOut();
    }
});
exports.signOut = signOut;
// Password Reset
const sendPasswordResetEmail = (email) => {
    if (Firebase_1.FirebaseRuntime.instance.initialized) {
        logger.debug(`send password reset email: ${email}`);
        return auth.sendPasswordResetEmail(Firebase_1.FirebaseRuntime.instance.auth, email);
    }
    else {
        throw new Error('cannot send password reset email: firebase runtime is not initialized');
    }
};
exports.sendPasswordResetEmail = sendPasswordResetEmail;
// Language
const setLanguage = (language) => {
    if (Firebase_1.FirebaseRuntime.instance.initialized) {
        logger.debug(`set language: ${language}`);
        Firebase_1.FirebaseRuntime.instance.auth.languageCode = language;
    }
    else {
        throw new Error('cannot set auth language: firebase runtime is not initialized');
    }
};
exports.setLanguage = setLanguage;
const currentUser = () => {
    if (Firebase_1.FirebaseRuntime.instance.initialized) {
        return Firebase_1.FirebaseRuntime.instance.auth.currentUser;
    }
    return undefined;
};
exports.currentUser = currentUser;
const currentUserId = () => {
    const user = (0, exports.currentUser)();
    return user ? user.uid : undefined;
};
exports.currentUserId = currentUserId;
