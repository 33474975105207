import classnames from 'classnames';
import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { SelectChangeEvent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import strings from '../languages';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface SelectListData {
  id: string;
  label: string;
}

interface Props {
  label?: string;
  allLabel?: string;
  className?: string;
  data: SelectListData[];
  selected: SelectListData[];
  multiselect?: boolean;
  onSelectionChanged: (selected: SelectListData[]) => void;
}

interface State {
  dataMap: Map<string, SelectListData>;
  selected: Set<SelectListData>;
}

type AllProps = Props & WrappedComponentProps;

const makeDataMap = (data: SelectListData[]) => {
  const map = new Map<string, SelectListData>();
  data.forEach((d) => map.set(d.id, d));
  return map;
};

const makeDataSet = (data: SelectListData[]) => {
  const map = new Set<SelectListData>();
  data.forEach((d) => map.add(d));
  return map;
};

class UserList extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      dataMap: makeDataMap(props.data),
      selected: makeDataSet(props.selected),
    };
  }

  render() {
    const { label, className, multiselect } = this.props;

    if (multiselect) {
      return (
        <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 300 }} className={classnames(className)}>
          {label ? (
            <InputLabel htmlFor="select-multiple-users" shrink={true}>
              {label}
            </InputLabel>
          ) : null}
          <Select
            multiple
            value={this.getSelectedValues()}
            onChange={this.handleSelectionChange}
            input={<Input id="select-multiple-users" />}
            displayEmpty
            renderValue={this.renderSelectionSummary}
            MenuProps={MenuProps}
          >
            {this.renderMenuItems()}
          </Select>
        </FormControl>
      );
    } else {
      return null; // TODO
    }
  }

  renderMenuItems = () => {
    const { allLabel } = this.props;
    const { selected, dataMap } = this.state;
    const localized = strings(this.props.intl);
    const menuItems: JSX.Element[] = [];

    menuItems.push(
      <MenuItem value="" key="all">
        <em>{allLabel ? allLabel : localized.label.all()}</em>
      </MenuItem>
    );

    dataMap.forEach((datum) => {
      menuItems.push(
        <MenuItem key={datum.id} value={datum.id}>
          <Checkbox checked={selected.has(datum)} />
          <ListItemText primary={datum.label} />
        </MenuItem>
      );
    });

    return menuItems;
  };

  renderSelectionSummary = () => {
    const { intl, allLabel } = this.props;
    const localized = strings(intl);
    const { selected } = this.state;

    if (selected.size === 0) {
      return <em>{allLabel ? allLabel : localized.label.all()}</em>;
    } else {
      let summary = '';
      selected.forEach((i) => {
        summary += (summary.length > 0 ? ', ' : '') + i.label;
      });
      if (summary.length > 2) {
        summary = summary.substring(2);
      }
      return summary;
    }
  };

  getSelectedValues = () => {
    const { selected } = this.state;
    const vals: string[] = [];
    selected.forEach((s) => vals.push(s.id));

    return vals;
  };

  handleSelectionChange = (event: SelectChangeEvent<string[]>) => {
    const { dataMap, selected } = this.state;
    // window.console.log("event: ", event);
    if (Array.isArray(event.target.value)) {
      event.target.value.forEach((id: string) => {
        const selection = dataMap.get(id);
        if (selection) {
          if (selected.has(selection)) {
            selected.delete(selection);
          } else {
            selected.add(selection);
          }
        }
      });
    }
    this.setState({ selected });
    // this.props.onSelectionChanged(ids);
  };
}

const Users = injectIntl(UserList);
export { Users };
