/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';
import { BioBeatState, BioBeatActionTypes } from './types';

const initialState: BioBeatState = {
  token: undefined,
  vitals: [],
  vitalsPollingIsEnabled: false,
  vitalsPollingSensors: [],
};

const reducer: Reducer<BioBeatState> = (state: BioBeatState = initialState, action) => {
  switch (action.type) {
    case BioBeatActionTypes.SET_VITALS: {
      return { ...state, vitals: action.payload };
    }
    case BioBeatActionTypes.SET_VITALS_POLLING_SENSOR_IDS: {
      return { ...state, vitalsPollingSensorIds: action.payload };
    }
    case BioBeatActionTypes.CLEAR_VITALS: {
      return { ...state, vitals: undefined };
    }
    case BioBeatActionTypes.ENABLE_VITALS_POLLING: {
      return { ...state, vitalsPollingIsEnabled: true, vitalsPollingSensors: action.payload };
    }
    case BioBeatActionTypes.DISABLE_VITALS_POLLING: {
      return { ...state, vitalsPollingIsEnabled: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as bioBeatReducer };
