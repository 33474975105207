"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collection = void 0;
var Collection;
(function (Collection) {
    Collection["alert"] = "alerts";
    Collection["alertSettings"] = "alert_settings";
    Collection["alertStats"] = "alert_stats";
    Collection["cameras"] = "cameras";
    Collection["careCenter"] = "care_centers";
    Collection["ccj_beds"] = "ccj_beds";
    Collection["ccj_locations"] = "ccj_locations";
    Collection["ccj_residents"] = "ccj_residents";
    Collection["ccj_sync"] = "ccj_sync";
    Collection["domains"] = "domains";
    Collection["region"] = "regions";
    Collection["resident"] = "residents";
    Collection["sensor"] = "sensors";
    Collection["sensorAlert"] = "sensor_alerts";
    Collection["sensorState"] = "sensor_state";
    Collection["sensorAlertSettings"] = "sensor_alert_settings";
    Collection["sensorVitals"] = "sensor_vitals";
    Collection["stats"] = "stats";
    Collection["tenant"] = "tenant";
    Collection["test"] = "test";
    Collection["userCareCenters"] = "user_care_centers";
    Collection["userPerson"] = "user_persons";
    Collection["userProfile"] = "user_profiles";
})(Collection || (exports.Collection = Collection = {}));
exports.default = Collection;
