import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Button } from '@mui/material';

import l10n from '../languages';
import { ApplicationState } from '../redux';
import { Language, setLanguage } from '../redux/locale';
import { Language as LanguageIcon } from '../icons';
import * as Icons from '../icons';
import LanguageDialog from './LanguageDialog';

interface ComponentProps {
  className?: string;
  hideLabel?: boolean;
  color?: string;
}

interface ApplicationStateProps {
  setLanguage: (language: Language) => void;
  language: Language;
}

type AllProps = ComponentProps & ApplicationStateProps & WrappedComponentProps;

interface State {
  open: boolean;
}

class LanguageSelectorComponent extends React.Component<AllProps, State> {
  readonly state: State = {
    open: false,
  };

  getLanguageName = (language: Language) => {
    const { intl } = this.props;
    const localized = l10n(intl);

    switch (language) {
      case 'zh-HANT':
        return localized.language.zh_hant();
      case 'zh-HANS':
        return localized.language.zh_hans();
      case 'en-US':
        return localized.language.english();
      case 'he':
        return localized.language.hebrew();
      case 'pt':
        return localized.language.portuguese();
      case 'ja-JP':
      default:
        return localized.language.japanese();
    }
  };

  allLanguages = (): Language[] => {
    return ['ja-JP', 'en-US', 'zh-HANT', 'zh-HANS', 'he', 'pt'];
  };

  languageSelection = (): Language[] => {
    // const { language } = this.props;
    const selection: Language[] = [];
    this.allLanguages()
      .sort()
      .forEach((l) => {
        // if (language !== l) {
        selection.push(l);
        // }
      });
    return selection;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changeLanguage = (lang: Language) => {
    this.props.setLanguage(lang);
    this.setState({ open: false });
  };

  render() {
    const { hideLabel, language, className } = this.props;
    const { open } = this.state;
    return (
      <>
        <Button
          className={className}
          // autoCapitalize="false"
          onClick={() => this.setState({ open: true })}
          endIcon={<Icons.Language />}
        >
          {hideLabel ? null : this.getLanguageName(language)}
        </Button>
        <LanguageDialog open={open} onClose={() => this.setState({ open: false })} />
      </>
    );
  }
}

const mapApplicationStateToProps = (state: ApplicationState) => ({
  language: state.locale.language,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLanguage: (language: Language) => dispatch(setLanguage(language)),
});

const w = injectIntl(LanguageSelectorComponent);
const LanguageSelector = connect(mapApplicationStateToProps, mapDispatchToProps)(w);

export { LanguageIcon, LanguageSelector };
export default LanguageSelector;
