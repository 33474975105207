import { getLogger } from '../logger';

const logger = getLogger('ui/data/Settings', 'info');

logger.debug('process.env', process.env);

/**
 * disableAlerts return true if alert settings and generation
 * by AC is disabled.
 */
export const disableAlerts = () => {
  const disabled = process.env.REACT_APP_ATTENTIVE_disableAlerts;

  return disabled && disabled === 'true';
};
