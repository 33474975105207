import { BedOccupancy } from 'attentive-connect-store/dist/models';
import { action } from 'typesafe-actions';

import { DashboardActionTypes } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

export const sortByRoom = (sort: boolean) => action(DashboardActionTypes.SET_SORT_BY_ROOM, sort);
export const showVitals = (show: boolean) => action(DashboardActionTypes.SET_SHOW_VITALS, show);
export const showBleVitals = (show: boolean) => action(DashboardActionTypes.SET_SHOW_BLE_VITALS, show);
export const showAlertsList = (show: boolean) => action(DashboardActionTypes.SET_SHOW_ALERTS_LIST, show);
export const playVerbalNotifications = (play: boolean) =>
  action(DashboardActionTypes.SET_PLAY_VERBAL_NOTIFICATIONS, play);
export const setBedOccupancy = (bedOccupancy: BedOccupancy[]) =>
  action(DashboardActionTypes.SET_BED_OCCUPANCY, bedOccupancy);
export const pollBedOccupancy = (poll: boolean) => action(DashboardActionTypes.SET_POLL_BED_OCCUPANCY, poll);
export const muteAlerts = (mute: boolean) => action(DashboardActionTypes.SET_MUTE_ALERTS, mute);
export const muteWarnings = (mute: boolean) => action(DashboardActionTypes.SET_MUTE_WARNINGS, mute);
export const hideWarnings = (hide: boolean) => action(DashboardActionTypes.SET_HIDE_WARNINGS, hide);
export const hideNoData = (hide: boolean) => action(DashboardActionTypes.SET_HIDE_NO_DATA, hide);
export const hideOk = (hide: boolean) => action(DashboardActionTypes.SET_HIDE_OK, hide);
export const hideAlerts = (hide: boolean) => action(DashboardActionTypes.SET_HIDE_ALERTS, hide);
export const disableWarnings = (disabled: boolean) => action(DashboardActionTypes.SET_DISABLE_WARNINGS, disabled);
