/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { AuthActionTypes, AuthState } from './types';

// Type-safe initialState!
const initialState: AuthState = {
  user: undefined,
  database: undefined,
  userProfile: undefined,
  userPerson: undefined,
  initialized: false,
  token: undefined,
  tokenTime: undefined,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<AuthState> = (state: AuthState = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.SIGN_IN: {
      return {
        ...state,
        user: action.payload.user,
        database: action.payload.database,
        userProfile: action.payload.userProfile,
        initialized: true,
      };
    }
    case AuthActionTypes.SIGN_OUT: {
      return {
        ...state,
        database: undefined,
        user: undefined,
        userProfile: undefined,
        userPerson: undefined,
        initialized: false,
      };
    }
    case AuthActionTypes.SELECT_PERSON: {
      return {
        ...state,
        userPerson: action.payload.userPerson,
      };
    }
    case AuthActionTypes.CLEAR_PERSON: {
      return {
        ...state,
        userPerson: undefined,
      };
    }
    case AuthActionTypes.TOKEN: {
      const tokenTime = Date.now();
      return {
        ...state,
        token: action.payload,
        tokenTime,
      };
    }
    default: {
      return state;
    }
  }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as authReducer };
