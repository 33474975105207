import { isVendorToppan } from '../Vendor';
import * as Logos from '../assets';

interface Props {
  className?: string;
  width?: number;
  height?: number;
  ignoreTenant?: boolean;
}

interface TenantProps {
  className?: string;
  width?: number;
  height?: number;
  tenant?: string;
  ignoreTenant?: boolean;
}

export const PrimaryLogo = (props: Props) => {
  const { className, width, height, ignoreTenant: noTenant } = props;

  return isVendorToppan() && !noTenant ? (
    <Logos.SensingWaveLogo width={width} height={height} className={className} />
  ) : (
    <Logos.AttentiveConnectLogo width={width} height={height} className={className} />
  );
};

export const PrimarySymbol = (props: Props) => {
  const { className, width, height } = props;

  return isVendorToppan() ? (
    <Logos.SensingWaveSymbol className={className} width={width} height={height} />
  ) : (
    <Logos.AttentiveConnectSymbol className={className} width={width} height={height} />
  );
};

//
// Logo for the tenant.
//
export const TenantLogo = (props: TenantProps) => {
  const { className, width, height, tenant, ignoreTenant } = props;

  if (!tenant || ignoreTenant) {
    return <PrimaryLogo height={height} width={width} className={className} ignoreTenant />;
  } else {
    switch (tenant.toLowerCase()) {
      case 'unimat':
        return <Logos.UnimatLogo height={height} width={width} className={className} />;
      case 'secom':
        return <Logos.SecomLogo height={height} width={width} className={className} />;
      default:
        return <PrimaryLogo height={height} width={width} className={className} ignoreTenant />;
    }
  }
};
