import { createSvgIcon } from '@mui/material';

const InBedMoving = createSvgIcon(
  <g>
    <path d="M19.016,11C21.226,11 23.016,12.79 23.016,15L23,22L21,22L21,19L3,19L3,22L1,22L1,7L3,7L3,16L9,16L9,11.5C9,10.4 9.9,9.5 11,9.5L14.5,9.5L14.5,11L19.016,11ZM8,13C8,11.89 7.11,11 6,11C4.89,11 4,11.89 4,13C4,14.11 4.89,15 6,15C7.11,15 8,14.11 8,13Z" />
    <g transform="matrix(0.75,0,0,0.75,2.99875,2)">
      <path d="M7.05,6.05L8.46,7.46C9.37,6.56 10.62,6 12,6C13.38,6 14.63,6.56 15.54,7.46L16.95,6.05C15.68,4.78 13.93,4 12,4C10.07,4 8.32,4.78 7.05,6.05ZM12,0C8.96,0 6.21,1.23 4.22,3.22L5.63,4.63C7.26,3.01 9.51,2 12,2C14.49,2 16.74,3.01 18.36,4.64L19.77,3.23C17.79,1.23 15.04,0 12,0Z" />
    </g>
  </g>,
  'InBedMoving'
);

export { InBedMoving };
export default InBedMoving;
