import { action } from 'typesafe-actions';

import * as data from '../../data';
import { SensingWaveActionTypes } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

// vitals
export const enableSensingWaveVitalsPolling = (sensors: data.vitals.SensingWaveSensor[]) =>
  action(SensingWaveActionTypes.ENABLE_SENSING_WAVE_VITALS_POLLING, sensors);
export const disableSensingWaveVitalsPolling = () =>
  action(SensingWaveActionTypes.DISABLE_SENSING_WAVE_VITALS_POLLING);
export const clearSensingWaveVitals = () =>
  action(SensingWaveActionTypes.CLEAR_SENSING_WAVE_VITALS);

// by slicing the data redux recognizes the array as a new set of data
export const setSensingWaveVitals = (vitals: data.vitals.SensingWaveVitals[]) =>
  action(SensingWaveActionTypes.SET_SENSING_WAVE_VITALS, vitals.slice());
