import { createSvgIcon } from '@mui/material';

const InBedAwake = createSvgIcon(
  <g>
    <path d="M9,11L19.016,11C21.226,11 23.016,12.79 23.016,15L23,22L21,22L21,19L3,19L3,22L1,22L1,7L3,7L3,16L9,16L9,11ZM8,13C8,11.89 7.11,11 6,11C4.89,11 4,11.89 4,13C4,14.11 4.89,15 6,15C7.11,15 8,14.11 8,13Z" />
    <g transform="matrix(1,0,0,1,1,-3)">
      <path d="M16.954,8.66L19.074,6.54L20.489,7.954L18.359,10.074L16.954,8.66ZM11,4L13,4L13,7L11,7L11,4ZM3.54,7.925L4.954,6.51L7.076,8.632L5.661,10.047L3.54,7.925Z" />
    </g>
  </g>,
  'InBedAwake'
);

export { InBedAwake };
export default InBedAwake;
