"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 *
 */
class HomeApi extends runtime.BaseAPI {
    /**
     * Register a new care center (home) in AiSleep cloud.
     */
    createHomeRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.homeRecordData === null || requestParameters.homeRecordData === undefined) {
                throw new runtime.RequiredError('homeRecordData', 'Required parameter requestParameters.homeRecordData was null or undefined when calling createHome.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/home`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.HomeRecordDataToJSON)(requestParameters.homeRecordData),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ResponseMessageFromJSON)(jsonValue));
        });
    }
    /**
     * Register a new care center (home) in AiSleep cloud.
     */
    createHome(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createHomeRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Delete a care center (home)
     */
    deleteHomeRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling deleteHome.');
            }
            if (requestParameters.username === null || requestParameters.username === undefined) {
                throw new runtime.RequiredError('username', 'Required parameter requestParameters.username was null or undefined when calling deleteHome.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/home/delhome/{id}/{username}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ResponseMessageFromJSON)(jsonValue));
        });
    }
    /**
     * Delete a care center (home)
     */
    deleteHome(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.deleteHomeRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Get AiSleep care center (home) details.
     */
    getHomeByIdRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.id === null || requestParameters.id === undefined) {
                throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getHomeById.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/home/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.HomeRecordFromJSON)(jsonValue));
        });
    }
    /**
     * Get AiSleep care center (home) details.
     */
    getHomeById(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHomeByIdRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Get list of care centers (homes) registered in AiSleep.
     */
    getHomesRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.page !== undefined) {
                queryParameters['page'] = requestParameters.page;
            }
            if (requestParameters.limit !== undefined) {
                queryParameters['limit'] = requestParameters.limit;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/home/homePage`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.HomePageFromJSON)(jsonValue));
        });
    }
    /**
     * Get list of care centers (homes) registered in AiSleep.
     */
    getHomes(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getHomesRaw(requestParameters);
            return yield response.value();
        });
    }
    /**
     * Update a care center (home).
     */
    updateHomeRaw(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.homeRecord === null || requestParameters.homeRecord === undefined) {
                throw new runtime.RequiredError('homeRecord', 'Required parameter requestParameters.homeRecord was null or undefined when calling updateHome.');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = typeof token === 'function' ? token("bearerAuth", []) : token;
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/admin/home`,
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: (0, models_1.HomeRecordToJSON)(requestParameters.homeRecord),
            });
            return new runtime.JSONApiResponse(response, (jsonValue) => (0, models_1.ResponseMessageFromJSON)(jsonValue));
        });
    }
    /**
     * Update a care center (home).
     */
    updateHome(requestParameters) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateHomeRaw(requestParameters);
            return yield response.value();
        });
    }
}
exports.HomeApi = HomeApi;
