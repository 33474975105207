"use strict";
/* TS enum provides for type-safe enum property and a code/value
 * that is used for internal representation in the database.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenderType = void 0;
var GenderType;
(function (GenderType) {
    GenderType["MALE"] = "MALE";
    GenderType["FEMALE"] = "FEMALE";
    GenderType["OTHER"] = "OTHER";
})(GenderType || (exports.GenderType = GenderType = {}));
exports.default = GenderType;
