import { Reducer } from 'redux';

import { getLogger } from '../../logger';
import { AisleepActionTypes, AisleepState } from './types';

const logger = getLogger('redux/aisleep/reducer');

const initialState: AisleepState = {
  token: undefined,
  vitals: [],
  vitalsPollingIsEnabled: false,
  vitalsPollingSensors: [],
};

const reducer: Reducer<AisleepState> = (state: AisleepState = initialState, action) => {
  switch (action.type) {
    case AisleepActionTypes.SET_AISLEEP_VITALS: {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      logger.debug('set aisleep vitals: ' + action.payload.length);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { ...state, vitals: action.payload ? action.payload : [] };
    }
    case AisleepActionTypes.CLEAR_AISLEEP_VITALS: {
      logger.debug('clear aisleep vitals');
      return { ...state, vitals: [] };
    }
    case AisleepActionTypes.ENABLE_AISLEEP_VITALS_POLLING: {
      logger.debug('enable aisleep vitals polling');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      return { ...state, vitalsPollingIsEnabled: true, vitalsPollingSensors: action.payload };
    }
    case AisleepActionTypes.DISABLE_AISLEEP_VITALS_POLLING: {
      logger.debug('disable aisleep vitals polling');
      return { ...state, vitalsPollingIsEnabled: false, vitalsPollingSensors: [] };
    }
    default: {
      return state;
    }
  }
};

export { reducer as AisleepReducer };
