import * as React from 'react';
import * as Icons from '../../icons';
import { SxProps, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import classNames from 'classnames';
import { useLocale } from '../../hooks';

export type FontSize = 'small' | 'medium' | 'large';

interface Props {
  className?: string;
  sx?: SxProps<Theme>;
  // callback when a selection is made
  onChange: (size: FontSize) => void;
  // the current selection
  size: FontSize;
}

const FontSizeSettings: React.FC<Props> = (props: Props) => {
  const localized = useLocale();

  return (
    <ToggleButtonGroup
      className={classNames(props.className)}
      sx={{ ...props.sx }}
      value={props.size}
      exclusive
      onChange={(event: unknown, size: FontSize) => props.onChange(size)}
      aria-label="font-size-settings"
    >
      <ToggleButton value="small" aria-label="small font size">
        <Icons.FontSize fontSize="small" sx={{ mx: 1 }} />
        <Typography sx={{ pr: 1 }} fontSize="small">
          {localized.theme.small()}
        </Typography>
      </ToggleButton>
      <ToggleButton value="medium" aria-label="medium font size">
        <Icons.FontSize fontSize="medium" sx={{ mx: 1 }} />
        <Typography sx={{ pr: 1 }} fontSize="small">
          {localized.theme.medium()}
        </Typography>
      </ToggleButton>
      <ToggleButton value="large" aria-label="large font size">
        <Icons.FontSize fontSize="large" sx={{ mx: 1 }} />
        <Typography sx={{ pr: 1 }} fontSize="small">
          {localized.theme.large()}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default FontSizeSettings;
