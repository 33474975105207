import { createSvgIcon } from '@mui/material';

const Calories = createSvgIcon(
  <g>
    <path d="M4.5,10h15C19.5,2,4.5,2,4.5,10z" />
    <path d="M4.5,19c0,0.5,0.5,1,1,1h13c0.6,0,1-0.5,1-1v-3h-15V19z" />
    <rect x="2.5" y="12" width="19" height="2" />
  </g>,
  'Calories'
);

export { Calories };
export default Calories;
