import { WrappedComponentProps, injectIntl } from 'react-intl';

import { Stack, SxProps, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import strings from '../../languages';
import * as Icons from '../../icons';

interface ComponentProps {
  className?: string;
  sx?: SxProps<Theme>;
  // callback when a selection is made
  onChange: (muted: boolean) => void;
  // the current selection
  muted: boolean;
  disabled?: boolean;
  disableMute?: boolean;
}

type Props = ComponentProps & WrappedComponentProps;

export const DashboardSoundSettings = injectIntl((props: Props) => {
  const l = strings(props.intl);

  return (
    <Stack spacing={0.5}>
      <ToggleButtonGroup
        sx={{ ...props.sx }}
        value={props.disabled === true ? true : props.muted}
        exclusive={props.disabled === true ? false : true}
        onChange={(event: unknown, muted: boolean) => props.onChange(muted)}
        aria-label="sound-settings"
        disabled={props.disabled}
        className="AC-wideButtons"
      >
        <ToggleButton value={false} aria-label="unmuted" disabled={props.disabled} color="info">
          <Icons.SoundOn />
        </ToggleButton>
        <ToggleButton
          value={props.disabled === true ? false : true}
          aria-label="muted"
          disabled={props.disabled}
          color="warning"
        >
          <Icons.SoundOff />
        </ToggleButton>
      </ToggleButtonGroup>
      {!props.disabled ? (
        props.muted ? (
          <Typography
            sx={{ paddingRight: 1 }}
            fontSize="small"
            color={props.disabled ? 'disabled' : 'inherit'}
          >
            {l.label.soundNotificationsOff()}
          </Typography>
        ) : (
          <Typography
            sx={{ paddingRight: 1 }}
            fontSize="small"
            color={props.disabled ? 'disabled' : 'inherit'}
          >
            {l.label.soundNotificationsOn()}
          </Typography>
        )
      ) : (
        <Typography
          sx={{ paddingRight: 1 }}
          fontSize="small"
          color={props.disabled ? 'disabled' : 'inherit'}
        >
          &nbsp;
        </Typography>
      )}
    </Stack>
  );
});
