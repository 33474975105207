import { Store as Database } from 'attentive-connect-store/dist';
import { isACError } from 'attentive-connect-store/dist/errors';
import * as models from 'attentive-connect-store/dist/models';
import CareCenter from 'attentive-connect-store/dist/models/CareCenter';
import * as BioBeatService from 'attentive-connect-store/dist/services/BioBeatService';
import * as firebase from 'attentive-connect-store/dist/services/Firebase';
import { Listener } from 'attentive-connect-store/dist/services/ListenerService';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { fetchApplicationVersionOnServer, isNewerApplicationVersion } from '../app-version';
import * as data from '../data';
import { Hybrid } from '../hybrid';
import { getLogger } from '../logger';
import * as redux from '../redux';

const logger = getLogger('services/PollingService', 'info');
const alertsLogger = getLogger('services/PollingService/alerts', 'info');
const aisleepVitalsLogger = getLogger('services/PollingService/aisleep-vitals', 'info');
const sensingWaveVitalsLogger = getLogger('services/PollingService/sensingWave-vitals', 'info');
const sensorsStateLogger = getLogger('services/PollingService/sensors-state');

// things that can be polled
type Polling =
  | 'alerts'
  | 'biobeat-vitals'
  | 'current-version'
  | 'aisleep-vitals'
  | 'sensingWave-vitals'
  | 'sensors-state' // includes tech alerts
  | 'bed-occupancy';

interface Props {
  pollInterval: number;
  polling: Polling;

  // dispatch props
  setNewVersionOnServer: typeof redux.serviceWorker.setNewVersionOnServer;
  setOpenAlerts: typeof redux.context.setOpenAlerts;
  clearOpenAlerts: typeof redux.context.clearOpenAlerts;
  setBioBeatVitals: typeof redux.bioBeat.setVitals;
  clearBioBeatVitals: typeof redux.bioBeat.clearVitals;
  setAisleepVitals: typeof redux.aisleep.setAisleepVitals;
  clearAisleepVitals: typeof redux.aisleep.clearAisleepVitals;
  setSensingWaveVitals: typeof redux.sensingWave.setSensingWaveVitals;
  clearSensingWaveVitals: typeof redux.sensingWave.clearSensingWaveVitals;
  setSensorsState: typeof redux.context.setSensorsState;
  clearSensorsState: typeof redux.context.clearSensorsState;
  setToken: typeof redux.auth.setToken;
  setBedOccupancy: typeof redux.dashboard.setBedOccupancy;
  setPollBedOccupancy: typeof redux.dashboard.pollBedOccupancy;

  // state (redux) props
  db?: Database;
  center?: CareCenter;
  token?: string;
  tokenTime?: number;
  user?: firebase.User;
  aisleepVitalsPollingEnabled: boolean;
  aisleepVitalsSensors: models.Sensor[];
  sensingWaveVitalsPollingEnabled: boolean;
  sensingWaveVitalsSensors: models.Sensor[];
  bioBeatVitalsPollingEnabled: boolean;
  bioBeatVitalsPollingSensors: data.vitals.BioBeatSensor[];
  sensorsStatePollingEnabled: boolean;
  sensorsStateSensors: data.sensors.SensorStateData[];
  pollBedOccupancy: boolean;
  listenAlerts: boolean;
}

type AllProps = Props;

class PollingService extends React.Component<AllProps> {
  private interval: NodeJS.Timeout | undefined;
  private listeningAlertStats = false;
  private listeningAlertStatsCount = 0;
  private listeningAisleepVitals = false;
  private listeningSensingWaveVitals = false;
  private listeningSensorsState = false;
  private aisleepVitals: data.vitals.AisleepVitals[] = [];
  private sensingWaveVitals: data.vitals.SensingWaveVitals[] = [];
  private sensorsState: data.sensors.SensorStateData[] = [];
  private sensorsStateHasChanged = false;
  private willUnmount = false;
  private listeners = new Map<string, Listener>();

  get nameSpace() {
    return 'ui.services.PollingService.' + this.props.polling;
  }

  async componentDidMount() {
    // logger.trace("did mount");
    this.willUnmount = false;
    logger.debug('componentDidMount()', this.props.polling);
    await this.reset();
  }

  async componentDidUpdate(prevProps: AllProps) {
    logger.debug('componentDidUpdate()', this.props.polling);
    const { center } = this.props;

    // logger.trace("did update");
    if (prevProps.center && center && prevProps.center.snapshot.id !== center.snapshot.id) {
      this.clearData();
    }
    await this.reset(prevProps);
  }

  async componentWillUnmount() {
    // logger.trace("will unmount");
    logger.debug('componentWillUnmount()', this.props.polling);
    this.willUnmount = true;
    this.stopPolling();
    if (this.props.db) {
      await this.stopListeners(this.props.db, this.props.center).catch((e) =>
        logger.notice('componentWillUnmount() could not stop listeners', e)
      );
    }
  }

  useListeners = () => {
    const { polling } = this.props;
    switch (polling) {
      case 'aisleep-vitals':
      case 'sensingWave-vitals':
      case 'sensors-state':
      case 'alerts':
        return true;
    }
    return false;
  };

  usePolling = () => true;

  reset = async (prevProps?: AllProps) => {
    logger.debug('reset()', this.props.polling);
    const { polling } = this.props;

    // logger.trace("reset");

    // first reset all listeners
    await this.resetListeners(prevProps).catch((e) =>
      logger.notice('reset() could not resetListeners()', e)
    );

    // next - reset all polling
    if (this.isPolling() && this.pollIntervalChanged(prevProps)) {
      this.stopPolling();
    }

    if (this.usePolling()) {
      // logger.trace("set polling interval: " + this.props.polling);
      this.updateToken(false);
    }

    switch (polling) {
      case 'current-version':
        if (!this.isPolling()) {
          logger.debug('start polling: ' + polling);
          this.interval = setInterval(this.pollDispatch, this.props.pollInterval);
          this.pollDispatch();
        }
        break;
      case 'biobeat-vitals':
        if (this.canPollBioBeatVitals() && !this.isPolling()) {
          logger.debug('start polling: ' + polling);
          this.interval = setInterval(this.pollDispatch, this.props.pollInterval);
          this.pollDispatch();
        }
        break;
      case 'bed-occupancy':
        if (this.canPollBedOccupancy() && !this.isPolling()) {
          logger.debug('start polling: ' + polling);
          this.interval = setInterval(this.pollDispatch, this.props.pollInterval);
          this.pollDispatch();
        }
        break;
      case 'alerts':
      case 'aisleep-vitals':
      case 'sensingWave-vitals':
      case 'sensors-state':
        // handled by listeners
        break;
    }
  };

  /**
   * Called whenever vitals change in FireBase
   */
  aisleepVitalsListener = (vitals: models.SensorVitals) => {
    const { db, center } = this.props;
    aisleepVitalsLogger.debug('aisleepVitalsListener() called', vitals.data.aisleep);
    if (!this.willUnmount && center && db) {
      const sensor = this.props.aisleepVitalsSensors.find(
        (s) => s.snapshot.ref.path === vitals.data.sensorRef
      );
      if (vitals.data.aisleep) {
        const v = this.aisleepVitals.find(
          (s) => s.sensor.snapshot.ref.path === vitals.data.sensorRef
        );
        if (v) {
          v.vitals = vitals;
          v.sensorStatus = () =>
            db.sensors.getTimedAisleepVitalsStatus(
              center,
              v.sensor.data.aisleep,
              vitals.data.aisleep
            );
          if (aisleepVitalsLogger.isDebugEnabled()) {
            aisleepVitalsLogger.debug('aisleepVitalsListener() vitals changed', {
              sensor: v.sensor.data.name,
              status: v.sensorStatus(),
              vitals: vitals.data.aisleep,
            });
          }
        } else if (sensor) {
          const status = () =>
            db.sensors.getTimedAisleepVitalsStatus(
              center,
              sensor.data.aisleep,
              vitals.data.aisleep
            );
          const newVitals = data.vitals.aisleep(sensor, vitals, status);
          this.aisleepVitals.push(newVitals);
          if (aisleepVitalsLogger.isDebugEnabled()) {
            aisleepVitalsLogger.debug('aisleepVitalsListener() registered new vitals', {
              sensor: newVitals.sensor.data.name,
              status: newVitals.sensorStatus(),
              vitals: vitals.data.aisleep,
            });
          }
        }
        aisleepVitalsLogger.debug(
          'aisleepVitalsListener() update aisleep vitals in redux: ' + this.aisleepVitals.length
        );
        this.props.setAisleepVitals(this.aisleepVitals.slice());
      } else {
        aisleepVitalsLogger.debug('aisleepVitalsListener() no aisleep data', vitals.data);
      }
    } else {
      aisleepVitalsLogger.debug('aisleepVitalsListener() ignored', {
        willUnmount: this.willUnmount,
        db: db ? 'ok' : 'undefined',
        center: center ? 'ok' : 'undefined',
      });
    }
  };

  /**
   * Called whenever vitals change in FireBase
   */
  sensingWaveVitalsListener = (vitals: models.SensorVitals) => {
    const { db, center } = this.props;
    if (!this.willUnmount && center && db) {
      if (vitals.data.sensingWave) {
        this.sensingWaveVitals.forEach((s) => {
          if (s.sensor.snapshot.ref.path === vitals.data.sensorRef) {
            s.vitals = vitals;
            s.sensorStatus = () =>
              db.sensors.getTimedSensingWaveVitalsStatus(
                center,
                s.sensor.data.sensingWave,
                vitals.data.sensingWave
              );
            if (sensingWaveVitalsLogger.isDebugEnabled()) {
              sensingWaveVitalsLogger.debug('sensingWave listener vitals changed', {
                sensor: s.sensor.data.name,
                status: s.sensorStatus(),
                vitals: vitals.data.sensingWave,
              });
            }
          }
        });
        sensingWaveVitalsLogger.debug(
          'update vitals in redux: sensor count: ' + this.sensingWaveVitals.length
        );
        this.props.setSensingWaveVitals(this.sensingWaveVitals.slice());
      }
    }
  };

  /**
   * Called whenever a sensor's state changes in Firebase.
   */
  sensorsStateListener = (sensorState: models.SensorState) => {
    const { db, center } = this.props;

    if (!this.willUnmount && center && db) {
      this.sensorsState.forEach((s) => {
        // logger.trace("checking: " + s.sensorState.data.sensorRef);
        if (s.sensorState.snapshot.id === sensorState.snapshot.id) {
          s.sensorState = sensorState;
          this.sensorsStateHasChanged = true;
        }
      });
      this.updateSensorsStateInRedux();
    }
  };

  isListening = () =>
    this.useListeners() &&
    (this.listeningAlertStats ||
      this.listeningAisleepVitals ||
      this.listeningSensingWaveVitals ||
      this.listeningSensorsState);

  stopListeners = async (db: Database, center?: CareCenter) => {
    try {
      this.stopListeningAlertStats(db, center);
    } catch (e) {
      logger.notice('stopListeners() could not stopListeningAlertStats()', e);
    }
    try {
      await this.stopListeningAisleepVitals(db);
    } catch (e) {
      logger.notice('stopListeners() could not stopListeningAisleepVitals()', e);
    }
    try {
      this.stopListeningSensingWaveVitals(db);
    } catch (e) {
      logger.notice('stopListeners() could not stopListeningSensingWaveVitals()', e);
    }
    try {
      this.stopListeningSensorsState(db);
    } catch (e) {
      logger.notice('stopListeners() could not stopListeningSensorsState()', e);
    }
  };

  centerChanged = (prevProps?: AllProps) => {
    const prev = prevProps?.center;
    const current = this.props.center;

    return prev?.snapshot.id !== current?.snapshot.id;
  };

  aisleepSensorsChanged = (prevProps?: AllProps) => {
    const prev = prevProps?.aisleepVitalsSensors;
    const current = this.props.aisleepVitalsSensors;

    if (prev) {
      if (prev.length !== current.length) {
        return true;
      }
      for (let i = 0; i < prev.length; i++) {
        const id = prev[i].snapshot.id;
        const s = current.find((s) => s.snapshot.id === id);
        if (!s) {
          return true;
        }
      }
      return false;
    }

    return this.props.aisleepVitalsSensors.length > 0;
  };

  pollIntervalChanged = (prevProps?: AllProps) => {
    const prev = prevProps?.pollInterval;
    const current = this.props.pollInterval;

    return prev !== current;
  };

  resetListeners = async (prevProps?: AllProps) => {
    logger.debug('resetListeners():', this.props.polling);
    const { polling, listenAlerts } = this.props;
    const centerChanged = this.centerChanged(prevProps);
    const aisleepSensorsChanged = this.aisleepSensorsChanged(prevProps);

    switch (polling) {
      case 'alerts':
        // alert stats
        if (centerChanged || !this.listeningAlertStats || !listenAlerts) {
          alertsLogger.debug('resetListeners(): alert_stats listener needs to be reset');
          if (prevProps?.db) {
            this.stopListeningAlertStats(prevProps.db, prevProps?.center);
          }
          this.listenAlertStats();
        }
        break;
      case 'aisleep-vitals':
        if (centerChanged || aisleepSensorsChanged) {
          logger.debug('resetListeners(): aisleep-vitals listeners need to be reset', {
            centerChanged,
            aisleepSensorsChanged,
            // listeningAisleepVitals: this.listeningAisleepVitals,
          });
          if (prevProps?.db) {
            await this.stopListeningAisleepVitals(prevProps.db);
          }
          if (this.props.aisleepVitalsSensors.length > 0) {
            await this.listenAisleepVitals();
          }
        } else {
          logger.debug('resetListeners(): aisleep-vitals listeners are already set');
        }
        break;
      case 'sensingWave-vitals':
        if (prevProps?.db) {
          this.stopListeningSensingWaveVitals(prevProps.db);
        }
        this.listenSensingWaveVitals();
        break;
      case 'sensors-state':
        // sensors state
        if (prevProps?.db) {
          this.stopListeningSensorsState(prevProps.db);
        }
        this.listenSensorsState();
        break;
    }
  };

  stopListeningAlertStats = async (db: Database, center?: CareCenter) => {
    if (center && db.initialized) {
      try {
        alertsLogger.debug('stop listening alert_stats/' + center.snapshot.id);
        const listener = this.listeners.get(center.snapshot.id);
        if (listener) {
          try {
            await db.alerts.stopListeningAlertStats(center, listener);
            this.listeners.delete(center.snapshot.id);
          } catch (e) {
            logger.notice('stopListeningAlertStats()', e);
          }
        }
      } catch (e) {
        logger.notice('stopListeningAlertStats()', e);
      }
    }
    this.listeningAlertStats = false;
    this.listeningAlertStatsCount = 0;
  };

  stopListeningAisleepVitals = async (db: Database) => {
    if (db && db.initialized) {
      aisleepVitalsLogger.debug('stopListeningAisleepVitals() current', {
        aisleepVitals: this.aisleepVitals.length,
      });
      this.aisleepVitals.forEach((v) => {
        const listener = this.listeners.get(v.vitals.snapshot.id);
        if (listener) {
          db.sensorVitals.stopListening(listener);
          this.listeners.delete(v.vitals.snapshot.id);
        }
        aisleepVitalsLogger.debug('stopListeningAisleepVitals() stop listening', {
          serialNumber: v.sensor.data.aisleep?.serialNumber,
        });
      });
      db.listeners.stopAllInNamespace(this.nameSpace);
    }
    this.aisleepVitals = [];
    this.listeningAisleepVitals = false;
  };

  stopListeningSensingWaveVitals = (db: Database) => {
    if (db && db.initialized) {
      sensingWaveVitalsLogger.debug('stop listening: sensingWave-vitals', {
        sensingWaveVitals: this.sensingWaveVitals.length,
      });
      this.sensingWaveVitals.forEach((v) => {
        const listener = this.listeners.get(v.vitals.snapshot.id);
        if (listener) {
          db.sensorVitals.stopListening(listener);
          this.listeners.delete(v.vitals.snapshot.id);
        }
        // logger.trace("stop listening", {
        //   sensingWave: v.sensor.data.sensingWave?.serialNumber,
        // });
      });
      this.sensingWaveVitals = [];
      db.listeners.stopAllInNamespace(this.nameSpace);
    }
    this.listeningSensingWaveVitals = false;
  };

  stopListeningSensorsState = (db: Database) => {
    if (db && db.initialized) {
      logger.debug('stop listening: sensors-state', { totalSensors: this.sensorsState.length });
      this.sensorsState.forEach((state) => {
        const listener = this.listeners.get(state.sensorState.snapshot.id);
        if (listener) {
          db.sensorState.stopListening(listener);
          this.listeners.delete(state.sensorState.snapshot.id);
        }
        // logger.trace("stop listening to sensor state changes", {
        //   sensor: state.sensorState.data.sensorRef,
        // });
      });
      this.sensorsState = [];
      db.listeners.stopAllInNamespace(this.nameSpace);
    }
    this.listeningSensorsState = false;
  };

  isPolling = () => this.interval !== undefined && this.usePolling();

  stopPolling = () => {
    if (this.isPolling() && this.interval) {
      logger.debug('stop polling: ' + this.props.polling);
      clearInterval(this.interval);
      this.interval = undefined;
    }
  };

  clearData = () => {
    logger.debug('clearing data: ' + this.props.polling);
    switch (this.props.polling) {
      case 'alerts':
        alertsLogger.debug('clear open alerts');
        this.props.clearOpenAlerts();
        break;
      case 'biobeat-vitals':
        this.props.clearBioBeatVitals();
        break;
      case 'aisleep-vitals':
        this.props.clearAisleepVitals();
        break;
      case 'sensingWave-vitals':
        this.props.clearSensingWaveVitals();
        break;
      case 'sensors-state':
        this.props.clearSensorsState();
        break;
      case 'bed-occupancy':
        this.props.setBedOccupancy([]);
        break;
    }
  };

  pollDispatch = () => {
    const { polling } = this.props;
    // logger.trace("dispatch", { polling });

    switch (polling) {
      case 'current-version':
        this.pollCurrentVersion();
        break;
      case 'biobeat-vitals':
        this.pollBioBeatVitals();
        break;
      case 'bed-occupancy':
        this.pollBedOccupancy();
        break;
      case 'alerts':
      case 'aisleep-vitals':
      case 'sensingWave-vitals':
      case 'sensors-state':
        // handeled by listeners
        break;
    }
  };

  pollHandleError = (e: unknown) => {
    const { user, polling } = this.props;

    if (isACError(e) && e.httpStatus && (e.httpStatus === 401 || e.httpStatus === 400) && user) {
      // unauthorized - need new token
      this.updateToken(false);
    } else {
      // 404 === no found - ignore
      logger.error(polling, { error: e });
    }
  };

  updateToken = (force: boolean) => {
    const { user, token } = this.props;

    if (user) {
      user.getIdToken(force).then((t) => {
        if (t !== token) {
          this.props.setToken(t);
        }
      });
    }
  };

  pollCurrentVersion = () => {
    fetchApplicationVersionOnServer()
      .then((v) => {
        if (isNewerApplicationVersion(v)) {
          this.props.setNewVersionOnServer(true);
        }
      })
      .catch((e) => this.pollHandleError(e));
  };

  canPollBioBeatVitals = () => {
    const { center, bioBeatVitalsPollingEnabled } = this.props;

    if (!bioBeatVitalsPollingEnabled || !center) {
      return false;
    }

    return true;
  };

  pollBioBeatVitals = () => {
    const { db, center, token, bioBeatVitalsPollingSensors } = this.props;

    if (!center || !db || !this.canPollBioBeatVitals()) {
      return;
    }

    if (bioBeatVitalsPollingSensors.length === 0) {
      this.props.setBioBeatVitals([]);
    } else if (!token) {
      this.updateToken(false);
    } else {
      Promise.all(
        bioBeatVitalsPollingSensors.map((sensor) =>
          BioBeatService.getCurrentVitals(center, sensor.Patch_ID, token)
        )
      )
        .then((results) =>
          results.map((vitals, i) => {
            const sensorStatus = db.sensors.getBioBeatVitalsStatus(vitals);
            return data.vitals.biobeat(
              bioBeatVitalsPollingSensors[i].Patch_ID,
              vitals,
              sensorStatus
            );
          })
        )
        .then((vitals) => {
          if (vitals) {
            this.props.setBioBeatVitals(vitals);
          }
        })
        .catch((e) => this.pollHandleError(e));
    }
  };

  canListenAisleepVitals = () => this.props.aisleepVitalsPollingEnabled;

  canPollAisleepVitals = () => {
    const { center, aisleepVitalsPollingEnabled } = this.props;

    if (!aisleepVitalsPollingEnabled || !center) {
      return false;
    }

    return true;
  };

  canListenSensingWaveVitals = () => this.props.sensingWaveVitalsPollingEnabled;

  canPollSensingWaveVitals = () => {
    const { center, sensingWaveVitalsPollingEnabled } = this.props;

    if (!sensingWaveVitalsPollingEnabled || !center) {
      return false;
    }

    return true;
  };

  canListenSensorsState = () => this.props.sensorsStatePollingEnabled;
  canPollSensorsState = () => {
    const { db, center, polling } = this.props;

    return polling === 'sensors-state' && this.canListenSensorsState() && center && db;
  };

  /**
   * In the case of sensor's state, we listen for changes and a callback is called whenever
   * the vitals data is updated. So there is no need to poll for the latest state.
   * The polling here is used to update the status of the state in redux.
   */
  updateSensorsStateInRedux = () => {
    const { db, center } = this.props;

    if (!center || !db || !this.canPollSensorsState()) {
      return;
    }

    if (this.sensorsStateHasChanged) {
      logger.debug('update sensor-state in redux');
      this.props.setSensorsState(this.sensorsState);
      this.sensorsStateHasChanged = false;
    }
  };

  canListenAlerts = () => this.props.center !== undefined;
  canPollAlerts = () => this.props.center !== undefined;

  updateAlertsInRedux = async () => {
    const { db, center } = this.props;
    if (db && center) {
      alertsLogger.debug('update alerts in redux');
      const alerts = await db.alerts.getOpenAlerts(center.snapshot.ref.path);
      alertsLogger.debug('update alerts in redux [get open alerts]');
      const alertsWithSensorDetail = await Promise.all(
        alerts.map((a) => db.alerts.getAlertWithSensorDetail(a))
      );
      alertsLogger.debug('update alerts in redux [get alert with sensor detail]');
      if (alerts.length > 0) {
        this.props.setOpenAlerts(alertsWithSensorDetail);
      } else {
        this.props.clearOpenAlerts();
      }
    }
  };

  listenAlertStats = () => {
    const { db, center, listenAlerts } = this.props;

    alertsLogger.debug('listenAlertStats()', { center: center?.snapshot.id, db: db?.id, listenAlerts, listeningAlertStats: this.listeningAlertStats });

    if (!center || !db || this.listeningAlertStats || !listenAlerts) {
      alertsLogger.debug('listenAlertStats(): not ready');
      return;
    }

    this.listeningAlertStats = true;
    alertsLogger.debug('start listening: alert_stats/' + center.snapshot.id);

    db.alerts
      .listenAlertStats(this.nameSpace, center, (stats) => {
        alertsLogger.debug('alert stats changed', { center: center.snapshot.id, stats });
        if (stats.openAlerts > 0) {
          alertsLogger.debug('update open alerts');
          this.updateAlertsInRedux();
          if (this.listeningAlertStatsCount !== stats.openAlerts) {
            // vibrate if alert count changes
            const hybrid = Hybrid.instance();
            if (hybrid && hybrid.vibrationPlugin) {
              hybrid.vibrationPlugin.vibrate(1000);
            }
            this.listeningAlertStatsCount = stats.openAlerts;
          }
        } else {
          alertsLogger.debug('clear open alerts');
          this.props.clearOpenAlerts();
          const hybrid = Hybrid.instance();
          if (hybrid && hybrid.vibrationPlugin) {
            hybrid.vibrationPlugin.vibrate(0);
          }
        }
      })
      .then((listener) => this.listeners.set(center.snapshot.id, listener));
  };

  listenAisleepVitals = async () => {
    const { db, center } = this.props;

    if (this.canListenAisleepVitals() && center && db) {
      aisleepVitalsLogger.debug(
        'listenAisleepVitals()',
        this.props.aisleepVitalsSensors.map((s) => s.data.aisleep?.serialNumber)
      );
      this.aisleepVitals = [];
      this.listeningAisleepVitals = true;
      const sensors = this.props.aisleepVitalsSensors.slice();
      const sensorVitals = await Promise.all(
        sensors.map((sensor) => db.sensorVitals.getCurrentVitals(sensor))
      );
      for (let i = 0; i < sensors.length; i++) {
        const sensor = sensors[i];
        // this.props.setAisleepVitals(this.aisleepVitals.slice());
        let listener = db.listeners.getListener(this.nameSpace, sensorVitals[i].snapshot);
        if (!listener) {
          aisleepVitalsLogger.debug('listenAisleepVitals(): start listening...', {
            serialNumber: sensor.data.aisleep?.serialNumber,
          });
          // const status = () =>
          //   db.sensors.getTimedAisleepVitalsStatus(
          //     center,
          //     sensor.data.aisleep,
          //     sensorVitals[i].data.aisleep
          //   );
          // const vitals = data.vitals.aisleep(sensor, sensorVitals[i], status);
          // this.aisleepVitals.push(vitals);
          listener = db.sensorVitals.listen(
            this.nameSpace,
            sensorVitals[i],
            this.aisleepVitalsListener
          );
          this.listeners.set(sensorVitals[i].snapshot.id, listener);
        } else {
          logger.notice('listenAisleepVitals() listener already exists', {
            serialNumber: sensor.data.aisleep?.serialNumber,
          });
        }
      }
    }
  };

  listenSensingWaveVitals = () => {
    const { db, center } = this.props;

    if (this.canListenSensingWaveVitals() && center && db) {
      sensingWaveVitalsLogger.debug('total sensors: ' + this.props.sensingWaveVitalsSensors.length);
      this.sensingWaveVitals = [];
      this.listeningSensingWaveVitals = true;
      this.props.sensingWaveVitalsSensors.forEach((sensor) => {
        // start listening for changes
        db.sensorVitals.getCurrentVitals(sensor).then((sensorVitals) => {
          sensingWaveVitalsLogger.debug('listening sensing wave', {
            sensor: sensor.data.name,
          });
          const status = () =>
            db.sensors.getTimedSensingWaveVitalsStatus(
              center,
              sensor.data.sensingWave,
              sensorVitals.data.sensingWave
            );
          const vitals = data.vitals.sensingWave(sensor, sensorVitals, status);
          this.sensingWaveVitals.push(vitals);
          // this.props.setSensingWaveVitals(this.sensingWaveVitals.slice());
          let listener = db.listeners.getListener(this.nameSpace, sensorVitals.snapshot);
          if (!listener) {
            listener = db.sensorVitals.listen(
              this.nameSpace,
              sensorVitals,
              this.sensingWaveVitalsListener
            );
            this.listeners.set(sensorVitals.snapshot.id, listener);
          }
        });
      });
    }
  };

  listenSensorsState = () => {
    const { db, center } = this.props;

    if (this.canListenSensorsState() && db && center) {
      logger.debug('listen: sensors-state', {
        totalSensors: this.props.sensorsStateSensors.length,
      });
      this.listeningSensorsState = true;
      this.sensorsState = [];
      this.props.sensorsStateSensors.forEach((sensor) => {
        // start listening for changes
        const listener = db.sensorState.listen(
          this.nameSpace,
          sensor.sensorState,
          this.sensorsStateListener
        );
        this.listeners.set(sensor.sensorState.snapshot.id, listener);
        sensorsStateLogger.debug('listening', {
          sensor: sensor.sensor.data.name,
        });
        this.sensorsState.push(sensor);
      });
      this.props.setSensorsState(this.sensorsState);
    }
  };

  canPollBedOccupancy = () => {
    const { center, pollBedOccupancy } = this.props;

    return center && pollBedOccupancy;
  };

  pollBedOccupancy = () => {
    const { token, db, center, setBedOccupancy } = this.props;

    if (!center || !db) {
      return;
    }

    if (!token) {
      // generate a token
      this.updateToken(false);
    } else {
      // logger.debug("polling bed occupancy");
      db.careCenters
        .getBedOccupancy(center)
        .then((occupancy) => {
          // logger.debug("bed occupancy", { bedOccupancy: occupancy });
          setBedOccupancy(occupancy);
        })
        .catch((e) => this.pollHandleError(e));
    }
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (application: redux.ApplicationState) => ({
  db: application.auth.database,
  center: application.context.center ? application.context.center : undefined,
  token: application.auth.token,
  tokenTime: application.auth.tokenTime,
  user: application.auth.user,
  aisleepVitalsPollingEnabled: application.aisleep.vitalsPollingIsEnabled,
  aisleepVitalsSensors: application.aisleep.vitalsPollingSensors,
  sensingWaveVitalsPollingEnabled: application.sensingWave.vitalsPollingIsEnabled,
  sensingWaveVitalsSensors: application.sensingWave.vitalsPollingSensors,
  bioBeatVitalsPollingEnabled: application.bioBeat.vitalsPollingIsEnabled,
  bioBeatVitalsPollingSensors: application.bioBeat.vitalsPollingSensors,
  sensorsStatePollingEnabled: application.context.sensorsStatePolling,
  sensorsStateSensors: application.context.sensorsStateSensors,
  listenAlerts: application.context.listenAlerts,
  pollBedOccupancy: application.dashboard.pollBedOccupancy,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setNewVersionOnServer: (available: boolean) =>
    dispatch(redux.serviceWorker.setNewVersionOnServer(available)),
  setOpenAlerts: (alerts: models.AlertWithSensorDetail[]) =>
    dispatch(redux.context.setOpenAlerts(alerts)),
  clearOpenAlerts: () => dispatch(redux.context.clearOpenAlerts()),
  setBioBeatVitals: (vitals: data.vitals.BioBeatVitals[]) =>
    dispatch(redux.bioBeat.setVitals(vitals)),
  clearBioBeatVitals: () => dispatch(redux.bioBeat.clearVitals()),
  setAisleepVitals: (vitals: data.vitals.AisleepVitals[]) =>
    dispatch(redux.aisleep.setAisleepVitals(vitals)),
  clearAisleepVitals: () => dispatch(redux.aisleep.clearAisleepVitals()),
  setSensingWaveVitals: (vitals: data.vitals.SensingWaveVitals[]) =>
    dispatch(redux.sensingWave.setSensingWaveVitals(vitals)),
  clearSensingWaveVitals: () => dispatch(redux.sensingWave.clearSensingWaveVitals()),
  setSensorsState: (sensorsState: data.sensors.SensorStateData[]) =>
    dispatch(redux.context.setSensorsState(sensorsState)),
  clearSensorsState: () => dispatch(redux.context.clearSensorsState()),
  setToken: (token: string) => dispatch(redux.auth.setToken(token)),
  setBedOccupancy: (bedOccupancy: models.BedOccupancy[]) =>
    dispatch(redux.dashboard.setBedOccupancy(bedOccupancy)),
  setPollBedOccupancy: (enabled: boolean) => dispatch(redux.dashboard.pollBedOccupancy(enabled)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PollingService);
