/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as React from 'react';
import * as _u from 'underscore';
import { getLogger } from '../logger';

const logger = getLogger('components/Sortable');

logger.debug();

export type SortableKey = string | number;

interface SortableProps {
  sortableKey?: SortableKey;
  children?: React.ReactNode;
}

export interface Sortable {
  props?: SortableProps;
}

export class Sort extends React.Component<SortableProps> {
  render() {
    const { children } = this.props;
    return children ? <div>{children}</div> : null;
  }
}

const getSortableKeyVal = (o: any, key = 'sortableKey', depth = 5): string => {
  if (depth >= 0) {
    if (_u.isArray(o)) {
      const props = o.filter((x) => _u.isObject(x) || _u.isArray(x));
      const vals = props.map((x) => getSortableKeyVal(x, key, depth - 1)).filter((x) => x.length > 0);
      if (vals.length > 0) {
        return vals[0];
      }
    } else if (_u.isObject(o)) {
      if (!_u.isUndefined(o[key])) {
        return o[key] as string;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      const next = _u.keys(o).map((k) => o[k]);
      return getSortableKeyVal(next, key, depth - 1);
    }
  }

  return '';
};

export const sort = (a: Sortable, b: Sortable) => {
  const strA = getSortableKeyVal(a);
  const strB = getSortableKeyVal(b);

  logger.debug('sort', { strA, strB });

  let cmp = 0;
  if (strA.match(/^[0-9]+$/) && strB.match(/^[0-9]+$/)) {
    const numA = parseFloat(strA);
    const numB = parseFloat(strB);
    cmp = numA < numB ? -1 : numA > numB ? 1 : 0;
  } else {
    cmp = strA.localeCompare(strB);
  }

  return cmp;
};
