import { Language as Languages } from '../../languages';

export type Language = Languages;

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum LocaleActionTypes {
  SET_LANGUAGE = '@@locale/SET_LANGUAGE',
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface LocaleState {
  readonly initialized: boolean;
  readonly language: Language;
}
