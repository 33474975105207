export const DRAWER_WIDTH = 300;
export const TOOLBAR_HEIGHT = 64;
export const TOOLBAR_HEIGHT_XS = 56;
export const TOOLBAR_BUTTON_WIDTH = 68;
export const CONTEXTUAL_TOOLBAR_HEIGHT = 64;
export const CONTEXTUAL_TOOLBAR_HEIGHT_XS = 56;
export const WRAPPER_PADDING_FACTOR = 1;
export const FONT_SIZE_DEFAULT = 16;
export const FONT_SIZE_SMALL = 12;
export const FONT_SIZE_LARGE = 20;
