import { isHybrid } from './hybrid';
import { getLogger } from './logger';
import _buildVersion from './version.json';

const logger = getLogger('app-version');
logger.debug(_buildVersion);

const DEBUG_SHOW_NEW_VERSION = false;

type ApplicationVersionJSON = {
  version: string;
  branch: string;
  commit: string;
  timestamp: string;
};
export class ApplicationVersion {
  private _version: string;
  private _branch: string;
  private _commit: string;
  private _timestamp: string;
  private _userVersion: string;

  constructor(version: string, branch: string, commit: string, timestamp: string) {
    this._version = version;
    this._branch = branch;
    this._commit = commit;
    this._timestamp = timestamp;
    this._userVersion = version.split('-')[0];
  }

  public get version() {
    return this._version;
  }
  public get userVersion() {
    return this._userVersion;
  }
  public get branch() {
    return this._branch;
  }
  public get commit() {
    return this._commit;
  }
  public get timestamp() {
    return this._timestamp;
  }
}

export const buildVersion = (): ApplicationVersion =>
  new ApplicationVersion(
    _buildVersion.version,
    _buildVersion.branch,
    _buildVersion.commit,
    _buildVersion.timestamp
  );

export const isNewerApplicationVersion = (version?: ApplicationVersion): boolean => {
  if (DEBUG_SHOW_NEW_VERSION) {
    return true;
  }
  if (
    version &&
    (version.version !== buildVersion().version ||
      version.timestamp.localeCompare(buildVersion().timestamp) > 0)
  ) {
    return true;
  }

  return false;
};

export const fetchApplicationVersionOnServer = async (): Promise<
  ApplicationVersion | undefined
> => {
  if (isHybrid()) {
    return buildVersion();
  } else {
    let versionUrl = '';
    if (process.env.PUBLIC_URL.endsWith('/')) {
      versionUrl = process.env.PUBLIC_URL + 'version.json';
    } else {
      versionUrl = process.env.PUBLIC_URL + '/version.json';
    }
    return fetch(versionUrl)
      .then((response) => response.json().then((json) => json as ApplicationVersionJSON))
      .then(
        (json) => new ApplicationVersion(json.version, json.branch, json.commit, json.timestamp)
      )
      .catch((e) => {
        logger.debug('Could not fetch current version: ', e);
        return undefined;
      });
  }
};

export const relaunchApplication = () => {
  logger.debug('relaunching...');
  navigator.serviceWorker.getRegistration().then((registration) => {
    if (registration) {
      logger.debug('registration found...');
      const href = window.location.href.replace(/\/about.*/, '');
      // registration.unregister().then(() => window.location.reload());
      registration.unregister().then(() => (window.location.href = href));
    } else {
      logger.debug('no registration?');
      window.location.reload();
    }
  });
};
