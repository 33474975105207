"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PushTypeToJSON = exports.PushTypeFromJSONTyped = exports.PushTypeFromJSON = exports.PushType = void 0;
/**
 * Types of data to push...(TODO)
 * * 1 - second data
 * * 2 - minute data
 * * 3 - daily log
 * * 4 - alarm
 * @export
 * @enum {string}
 */
var PushType;
(function (PushType) {
    PushType[PushType["NUMBER_1"] = 1] = "NUMBER_1";
    PushType[PushType["NUMBER_2"] = 2] = "NUMBER_2";
    PushType[PushType["NUMBER_3"] = 3] = "NUMBER_3";
    PushType[PushType["NUMBER_4"] = 4] = "NUMBER_4";
})(PushType || (exports.PushType = PushType = {}));
function PushTypeFromJSON(json) {
    return PushTypeFromJSONTyped(json, false);
}
exports.PushTypeFromJSON = PushTypeFromJSON;
function PushTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.PushTypeFromJSONTyped = PushTypeFromJSONTyped;
function PushTypeToJSON(value) {
    return value;
}
exports.PushTypeToJSON = PushTypeToJSON;
