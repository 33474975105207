import { createSvgIcon } from '@mui/material';

const Stress = createSvgIcon(
  <g>
    <path d="M9,15.9c1,1.1,0,0,1,1.1c0.6-0.3,1.3-0.5,2-0.5s1.4,0.2,2,0.5c0.1-0.1,0.9-1,1-1.1c-0.9-0.6-1.9-0.9-3-0.9S9.9,15.3,9,15.9
		z" />
    <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8
		S16.4,20,12,20z" />
    <polygon points="6.9,8.6 6.5,9.6 7.8,10.1 6.4,10.4 6.6,11.4 11.2,10.5 	" />
    <polygon points="17.5,9.6 17.1,8.6 12.8,10.5 17.4,11.4 17.6,10.4 16.2,10.1 	" />
  </g>,
  'Stress'
);

export { Stress };
export default Stress;
