"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListenerService = void 0;
const uuid_1 = require("uuid");
const firebase = __importStar(require("./Firebase"));
const logger_1 = require("../logger");
const log = (0, logger_1.getLogger)('services/ListenerService', 'info');
/**
 * Class ListenerService manages listeners for changes on document snapshots.
 */
class ListenerService {
    constructor(db) {
        this.listeners = new Map();
        this.db = db;
    }
    getNameSpaces() {
        return Array.from(this.listeners.keys());
    }
    getListeners(nameSpace) {
        let listeners = this.listeners.get(nameSpace);
        if (!listeners) {
            listeners = [];
            this.listeners.set(nameSpace, listeners);
            return listeners;
        }
        return listeners;
    }
    getListener(nameSpace, snapshot) {
        const listeners = this.getListeners(nameSpace);
        let listener;
        listeners.forEach((l) => {
            if (l.snapshot.ref.path === snapshot.ref.path) {
                listener = l;
            }
        });
        return listener;
    }
    isListening(nameSpace, snapshot) {
        return this.getListener(nameSpace, snapshot) !== undefined;
    }
    getAllListeners() {
        const all = [];
        this.getNameSpaces().forEach((nameSpace) => {
            all.push(...this.getListeners(nameSpace));
        });
        return all;
    }
    snapshotName(nameSpace, snapshot) {
        return nameSpace + '(' + snapshot.ref.path + ')';
    }
    listenerName(listener) {
        return this.snapshotName(listener.nameSpace, listener.snapshot);
    }
    listen(nameSpace, snapshot, onNext) {
        const listenerName = this.snapshotName(nameSpace, snapshot);
        log.debug(`listen() registering listener ${listenerName}`);
        if (this.isListening(nameSpace, snapshot)) {
            log.error(`listen() listener is already registered ${listenerName}`);
            throw new Error(`Listener is already registered: ${listenerName}`);
        }
        const unsubscribe = firebase.onSnapshot(snapshot.ref, (next) => onNext(next));
        const id = (0, uuid_1.v4)();
        const listener = {
            nameSpace,
            id,
            snapshot,
            unsubscribe: () => {
                unsubscribe();
                this.delete(nameSpace, id);
            },
        };
        const listeners = this.getListeners(nameSpace);
        listeners.push(listener);
        this.debugCurrentState(`listen(): listener '${listenerName}' has been registered`);
        return listener;
    }
    /**
     * Checks if a listener is registered.
     * @param l the listener
     * @returns true if registered
     */
    hasListener(l) {
        let has = false;
        this.getNameSpaces().forEach((nameSpace) => {
            this.getListeners(nameSpace).forEach((listener) => {
                if (!has) {
                    has = listener.id === l.id;
                }
            });
        });
        return has;
    }
    // returns current listener of snapshot if there is one; otherwise undefined
    currentListeners(snapshot) {
        const current = [];
        this.getNameSpaces().forEach((nameSpace) => {
            this.getListeners(nameSpace).forEach((listener) => {
                if (listener.snapshot.ref.id === snapshot.ref.id) {
                    current.push(listener);
                }
            });
        });
        return current;
    }
    /**
     *
     * @param listener If a listener is passed in then that specific listener will be stopped,
     *   otherwise all listeners linked to the snapshot will be stopped.
     */
    stopAll(snapshot) {
        log.debug('stopAll() stopping all listeners', snapshot ? { ref: snapshot === null || snapshot === void 0 ? void 0 : snapshot.ref.path } : undefined);
        this.getNameSpaces().forEach((nameSpace) => {
            this.stopAllInNamespace(nameSpace, snapshot);
        });
        this.debugCurrentState('stopAll() stopped all listeners');
    }
    stopAllInNamespace(nameSpace, snapshot) {
        const listeners = this.getListeners(nameSpace);
        log.debug(`stopAllInNamespace() stopping listeners in namespace ${nameSpace}`, {
            listeners: listeners.length,
            ref: snapshot === null || snapshot === void 0 ? void 0 : snapshot.ref.path,
        });
        listeners.forEach((listener) => {
            if (!snapshot || snapshot.ref.id === listener.snapshot.ref.id) {
                this.stop(listener);
            }
        });
        this.debugCurrentState(`stopAllInNamespace() stopped all listeners in namespace ${nameSpace}`);
    }
    stop(listener) {
        log.debug(`stop() stopping ${this.listenerName(listener)}`);
        listener.unsubscribe();
        this.delete(listener.nameSpace, listener.id);
        this.debugCurrentState(`stop() stopped ${this.listenerName(listener)}`);
    }
    delete(nameSpace, id) {
        const listeners = this.getListeners(nameSpace);
        const updatedListeners = [];
        listeners.forEach((l) => {
            if (l.id !== id) {
                updatedListeners.push(l);
            }
            else {
                log.debug(`delete() deleted ${this.listenerName(l)}`);
            }
        });
        this.listeners.set(nameSpace, updatedListeners);
    }
    debugCurrentState(prefix) {
        var _a;
        if (log.isDebugEnabled()) {
            const grouped = new Map();
            this.getAllListeners().forEach((listener) => {
                // const groupName = listener.nameSpace + '(' + listener.snapshot.ref.path.split('/')[0] + ')';
                const groupName = this.listenerName(listener);
                const group = grouped.get(groupName) || [];
                group.push(listener);
                grouped.set(groupName, group);
            });
            const listeners = {};
            for (const groupName of grouped.keys()) {
                listeners[groupName] = ((_a = grouped.get(groupName)) === null || _a === void 0 ? void 0 : _a.length) || 0;
            }
            log.debug(`${prefix || 'listeners'}`, listeners);
        }
    }
}
exports.ListenerService = ListenerService;
