"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelErrorToJSON = exports.ModelErrorFromJSONTyped = exports.ModelErrorFromJSON = void 0;
const runtime_1 = require("../runtime");
function ModelErrorFromJSON(json) {
    return ModelErrorFromJSONTyped(json, false);
}
exports.ModelErrorFromJSON = ModelErrorFromJSON;
function ModelErrorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'error': !(0, runtime_1.exists)(json, 'error') ? undefined : json['error'],
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
        'path': !(0, runtime_1.exists)(json, 'path') ? undefined : json['path'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : json['status'],
        'timestamp': !(0, runtime_1.exists)(json, 'timestamp') ? undefined : json['timestamp'],
    };
}
exports.ModelErrorFromJSONTyped = ModelErrorFromJSONTyped;
function ModelErrorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'error': value.error,
        'message': value.message,
        'path': value.path,
        'status': value.status,
        'timestamp': value.timestamp,
    };
}
exports.ModelErrorToJSON = ModelErrorToJSON;
