import { action } from 'typesafe-actions';
import { BioBeatActionTypes } from './types';
import * as data from '../../data';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

// vitals
export const enableVitalsPolling = (sensors: data.vitals.BioBeatSensor[]) =>
  action(BioBeatActionTypes.ENABLE_VITALS_POLLING, sensors);
export const disableVitalsPolling = () => action(BioBeatActionTypes.DISABLE_VITALS_POLLING);
export const clearVitals = () => action(BioBeatActionTypes.CLEAR_VITALS);
export const setVitals = (vitals: data.vitals.BioBeatVitals[]) =>
  action(BioBeatActionTypes.SET_VITALS, vitals);
