"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterDeviceInfoToJSON = exports.RegisterDeviceInfoFromJSONTyped = exports.RegisterDeviceInfoFromJSON = void 0;
const runtime_1 = require("../runtime");
function RegisterDeviceInfoFromJSON(json) {
    return RegisterDeviceInfoFromJSONTyped(json, false);
}
exports.RegisterDeviceInfoFromJSON = RegisterDeviceInfoFromJSON;
function RegisterDeviceInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'address': json['address'],
        'deviceid': json['deviceid'],
        'haseamato': !(0, runtime_1.exists)(json, 'haseamato') ? undefined : json['haseamato'],
        'hasmov': json['hasmov'],
        'homeid': json['homeid'],
        'roomname': json['roomname'],
        'username': json['username'],
    };
}
exports.RegisterDeviceInfoFromJSONTyped = RegisterDeviceInfoFromJSONTyped;
function RegisterDeviceInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'address': value.address,
        'deviceid': value.deviceid,
        'haseamato': value.haseamato,
        'hasmov': value.hasmov,
        'homeid': value.homeid,
        'roomname': value.roomname,
        'username': value.username,
    };
}
exports.RegisterDeviceInfoToJSON = RegisterDeviceInfoToJSON;
