"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
//
// Uses services implemented in AC API to communicate with Elmo
//
const logger_1 = require("../logger");
const logger = (0, logger_1.getLogger)('/services/VideoService');
/**
 * A generic error handler for calls to the Elmo API. If an error occured during the API
 * request, then throws an error (AttentiveError).
 *
 * @param response response object from an API call.
 */
const handleErrors = (response) => {
    if (!response.ok) {
        return response.json().then((r) => {
            // window.console.log(r);
            const error = {
                code: response.status,
                message: response.statusText,
            };
            throw error;
        });
    }
    return Promise.resolve(response);
};
class VideoService {
    constructor(db) {
        this.getVideoStreamByCameraId = (cameraId) => __awaiter(this, void 0, void 0, function* () {
            const api = yield this.db.api();
            const res = api.video.videoStreamGetByCameraId({
                cameraId: cameraId,
            });
            return res;
        });
        this.getVideoStreamBySerial = (serial) => __awaiter(this, void 0, void 0, function* () {
            const api = yield this.db.api();
            const res = api.video.videoStreamGetBySerial({
                serial: serial,
            });
            return res;
        });
        this.db = db;
    }
}
exports.default = VideoService;
