import { createSvgIcon } from '@mui/material';

const OutOfBedTime = createSvgIcon(
  <g transform="matrix(1,0,0,1,1,0)">
    <g transform="matrix(1,0,0,1,-4,0)">
      <path d="M13.5,5.5C14.6,5.5 15.5,4.6 15.5,3.5C15.5,2.4 14.6,1.5 13.5,1.5C12.4,1.5 11.5,2.4 11.5,3.5C11.5,4.6 12.4,5.5 13.5,5.5ZM9.8,8.9L7,23L9.1,23L10.9,15L13,17L13,23L15,23L15,15.5L12.9,13.5L13.5,10.5C14.8,12 16.8,13 19,13L19,11C17.1,11 15.5,10 14.7,8.6L13.7,7C13.3,6.4 12.7,6 12,6C11.7,6 11.5,6.1 11.2,6.1L6,8.3L6,13L8,13L8,9.6L9.8,8.9" />
    </g>
    <g transform="matrix(0.3,0,0,0.3,13.4,0.4)">
      <path d="M12,2C6.5,2 2,6.5 2,12C2,17.5 6.5,22 12,22C17.5,22 22,17.5 22,12C22,6.5 17.5,2 12,2ZM16.2,16.2L11,13L11,7L12.5,7L12.5,12.2L17,14.9L16.2,16.2Z" />
    </g>
  </g>,
  'OutOfBedTime'
);

export { OutOfBedTime };
export default OutOfBedTime;
