import { createSvgIcon } from '@mui/material';

const Relaxation = createSvgIcon(
  <g>
    <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8
		S16.4,20,12,20z" />
    <path d="M12,16c-0.7,0-1.4-0.2-2-0.5c-0.1,0.1-0.9,1-1,1.2c0.9,0.5,1.9,0.9,3,0.9s2.1-0.3,3-0.9c-1-1.1,0,0-1-1.2
		C13.4,15.8,12.7,16,12,16z" />
    <path d="M10.5,9.5C9.9,9.8,9.2,10,8.5,10s-1.4-0.2-2-0.5c-0.1,0.1-0.9,1-1,1.2c0.9,0.5,1.9,0.9,3,0.9s2.1-0.3,3-0.9
		C10.5,9.6,11.5,10.7,10.5,9.5z" />
    <path d="M17.5,9.5c-0.6,0.3-1.3,0.5-2,0.5s-1.4-0.2-2-0.5c-0.1,0.1-0.9,1-1,1.2c0.9,0.5,1.9,0.9,3,0.9s2.1-0.3,3-0.9
		C17.5,9.6,18.5,10.7,17.5,9.5z" />
  </g>,
  'Relaxation'
);

export { Relaxation };
export default Relaxation;
