import { lazy } from 'react';

export const About = lazy(() => import('./About'));
export const ActiveAlerts = lazy(() => import('./ActiveAlerts'));
export const AlertDescription = lazy(() => import('./AlertDescription'));
export const AlertDetails = lazy(() => import('./AlertDetails'));
export const AlertDetailsHeader = lazy(() => import('./AlertDetailsHeader'));
export const AlertDetailsMux = lazy(() => import('./AlertDetailsMux'));
export const AlertDetailsNotes = lazy(() => import('./AlertDetailsNotes'));
export const AlertDetailsPatientInfo = lazy(() => import('./AlertDetailsPatientInfo'));
export const AlertDetailsResolution = lazy(() => import('./AlertDetailsResolution'));
export const AlertDetailsSensorInfo = lazy(() => import('./AlertDetailsSensorInfo'));
export const AlertDuration = lazy(() => import('./AlertDuration'));
export const AlertIcon = lazy(() => import('./AlertIcon'));
export const AlertInfoIcon = lazy(() => import('./AlertInfoIcon'));
export const AlertList = lazy(() => import('./AlertList'));
export const AlertPauseButton = lazy(() => import('./AlertPauseButton'));
export const AlertRangeMeter = lazy(() => import('./AlertRangeMeter'));
export const AlertResolution = lazy(() => import('./AlertResolution'));
export const AlertResolutionSelection = lazy(() => import('./AlertResolutionSelection'));
export const AlertResolveDialog = lazy(() => import('./AlertResolveDialog'));
export const AlertSettings = lazy(() => import('./AlertSettings'));
export const AlertSettingsButton = lazy(() => import('./AlertSettingsButton'));
export const AlertSettingsDialogTitle = lazy(() => import('./AlertSettingsDialogTitle'));
export const AlertSettingsHeader = lazy(() => import('./AlertSettingsHeader'));
export const AlertSettingsIconPanel = lazy(() => import('./AlertSettingsIconPanel'));
export const AlertSettingsList = lazy(() => import('./AlertSettingsList'));
export const AlertUtils = lazy(() => import('./AlertUtils'));
export const Badge = lazy(() => import('./Badge'));
export const BioBeatVitals = lazy(() => import('./BioBeatVitals'));
export * as BioBeatVitalsBoxes from './BioBeatVitalsBoxes';
export * as BleVitalsBoxes from './BleVitalsBoxes';
export const BuddycomLanguageMenu = lazy(() => import('./BuddycomLanguageMenu'));
export const BuddycomMessageDialog = lazy(() => import('./BuddycomMessageDialog'));
export const CareCenterDetails = lazy(() => import('./CareCenterDetails'));
export const CareCenterEditForm = lazy(() => import('./CareCenterEditForm'));
export const CareCenterList = lazy(() => import('./CareCenterList'));
export const CareCenterMap = lazy(() => import('./CareCenterMap'));
export const CareCenterMapBubble = lazy(() => import('./CareCenterMapBubble'));
export const CareReportDownload = lazy(() => import('./CareReportDownload'));
export const ContextMenu = lazy(() => import('./ContextMenu'));
export const Dashboard = lazy(() => import('./Dashboard'));
export const DashboardButton = lazy(() => import('./DashboardButton'));
export const DetailsPager = lazy(() => import('./DetailsPager'));
export const DeviceDetails = lazy(() => import('./DeviceDetails'));
export const DeviceList = lazy(() => import('./DeviceList'));
export const ForgotPasswordForm = lazy(() => import('./ForgotPasswordForm'));
export const ForgotPasswordDialog = lazy(() => import('./PasswordResetDialog'));
export const GoogleMapsAPI = lazy(() => import('./GoogleMapsAPI'));
export const HiddenBreakpoints = lazy(() => import('./HiddenBreakpoints'));
export const Highlight = lazy(() => import('./Highlight'));
export const IconMenu = lazy(() => import('./IconMenu'));
export * as Icons from '../icons';
// export const InBedStatus = lazy(() => import('./InBedStatusChip'));
export const InfoPanel = lazy(() => import('./InfoPanel'));
export const InputCaption = lazy(() => import('./InputCaption'));
export const LanguageDialog = lazy(() => import('./LanguageDialog'));
export const LanguageSelector = lazy(() => import('./LanguageSelector'));
export const Legend = lazy(() => import('./Legend'));
export * as Lists from './Lists';
export const Loading = lazy(() => import('./Loading'));
export const LoginForm = lazy(() => import('./LoginForm'));
export * as Logos from './Logos';
export const NewVersionNotification = lazy(() => import('./NewVersionNotification'));
export const Notification = lazy(() => import('./Notification'));
export const NotificationContent = lazy(() => import('./NotificationContent'));
export const PhotoUpload = lazy(() => import('./PhotoUpload'));
// export const PollingService = lazy(() => import('../services/PollingService'));
export const RecordBloodPressure = lazy(() => import('./RecordBloodPressure'));
export const RecordBodyTemperature = lazy(() => import('./RecordBodyTemperature'));
export const RecordVitalsButton = lazy(() => import('./RecordVitalsButton'));
export const RecordVitalsDialog = lazy(() => import('./RecordVitalsDialog'));
export const RecordWeight = lazy(() => import('./RecordWeight'));
export const ReportAlertResolution = lazy(() => import('./ReportAlertResolution'));
export const ReportAlertResolutionSettings = lazy(() => import('./ReportAlertResolutionSettings'));
export const ResidentDetails = lazy(() => import('./ResidentDetails'));
export const ResidentDetailsHeader = lazy(() => import('./ResidentDetailsHeader'));
export const ResidentLabel = lazy(() => import('./ResidentLabel'));
export const ResidentList = lazy(() => import('./ResidentList'));
export const ResidentReport = lazy(() => import('./ResidentReport'));
export const ResidentReportDownload = lazy(() => import('./ResidentReportDownload'));
// export const ResidentReportSettingsDialog = lazy(() => import('./Dialog/DaysSelectionDialog'));
export const ResidentSelector = lazy(() => import('./ResidentSelector'));
export const SearchField = lazy(() => import('./SearchField'));
export const SelectField = lazy(() => import('./SelectField'));
export const SelectList = lazy(() => import('./SelectList'));
export * as SensorLEDs from './SensorLEDs';
export const SensorLabel = lazy(() => import('./SensorLabel'));
export const SensorUtils = lazy(() => import('./SensorUtils'));
// export const SleepStatus = lazy(() => import('./SleepStatusChip'));
export const Sliders = lazy(() => import('./Sliders'));
export * as Sortable from './Sortable';
export const SpeechToText = lazy(() => import('./SpeechToText'));
export const TextField = lazy(() => import('./TextField'));
export const TimeRangeMeter = lazy(() => import('./TimeRangeMeter'));
export const UserMenu = lazy(() => import('./UserMenu'));
export const UserProfileDetails = lazy(() => import('./UserProfileDetails'));
export const UserProfileEditForm = lazy(() => import('./UserProfileEditForm'));
export { default as UserSelectorDialog } from './UserSelectorDialog';
// export const VitalsBoxWrapper = lazy(() => import('./VitalsBoxWrapper'));
// export * as VitalsBoxes from './VitalsBoxes';
// export const VitalsReport = lazy(() => import('./VitalsReport'));
