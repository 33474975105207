"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLogger = exports.config = exports.Logger = void 0;
const attentive_connect_logger_1 = require("attentive-connect-logger");
// import * as logger from 'attentive-connect-logger';
var attentive_connect_logger_2 = require("attentive-connect-logger");
Object.defineProperty(exports, "Logger", { enumerable: true, get: function () { return attentive_connect_logger_2.Logger; } });
Object.defineProperty(exports, "config", { enumerable: true, get: function () { return attentive_connect_logger_2.config; } });
const getLogger = (name, level) => (0, attentive_connect_logger_1.getLogger)('store' + (name.startsWith('/') ? name : '/' + name), level);
exports.getLogger = getLogger;
