import { createSvgIcon } from '@mui/material';

const InBedSleeping = createSvgIcon(
  <g>
    <path d="M9,11L19.016,11C21.226,11 23.016,12.79 23.016,15L23,22L21,22L21,19L3,19L3,22L1,22L1,7L3,7L3,16L9,16L9,11ZM8,13C8,11.89 7.11,11 6,11C4.89,11 4,11.89 4,13C4,14.11 4.89,15 6,15C7.11,15 8,14.11 8,13Z" />
    <g transform="matrix(0.5,0,0,0.5,2.5,1.5)">
      <path d="M9,11L12.63,11L9,15.2L9,17L15,17L15,15L11.37,15L15,10.8L15,9L9,9L9,11Z" />
    </g>
    <g transform="matrix(0.5,0,0,0.5,6.5,-0.5)">
      <path d="M9,11L12.63,11L9,15.2L9,17L15,17L15,15L11.37,15L15,10.8L15,9L9,9L9,11Z" />
    </g>
    <g transform="matrix(0.5,0,0,0.5,10.5,-2.5)">
      <path d="M9,11L12.63,11L9,15.2L9,17L15,17L15,15L11.37,15L15,10.8L15,9L9,9L9,11Z" />
    </g>
  </g>,
  'InBedSleeping'
);

export { InBedSleeping };
export default InBedSleeping;
