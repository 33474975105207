import * as data from '../data';
import { useLocale } from '../hooks';
import * as Icons from '../icons';
import * as Logos from '../assets';
import DataBox from './DataBox';

// const DEFAULT_ICON_HEIGHT = 30;
const DASHBOARD_ICON_HEIGHT = 12;

type variants = 'alert' | 'dashboard';
interface Props {
  className?: string;
  vitals?: data.vitals.BioBeatVitals;
  variant?: variants;
}

export const BP = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[
        vitals && vitals.vitals ? vitals.vitals.sbp || vitals.vitals.dbp : undefined,
        localized.biobeat.bpUnit(),
      ]}
      header={localized.biobeat.bp()}
      icon={Icons.BloodPressure}
    />
  );
};

export const BR = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[vitals && vitals.vitals ? vitals.vitals.rr : undefined, localized.biobeat.brUnit()]}
      header={localized.biobeat.br()}
      icon={Icons.RespiratoryRateAlert}
    />
  );
};

export const BodyTemp = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[
        vitals && vitals.vitals ? vitals.vitals.temp : undefined,
        localized.biobeat.bodyTempUnit(),
      ]}
      header={localized.biobeat.bodyTemp()}
      icon={Icons.BodyTemperature}
    />
  );
};

export const Calories = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[
        vitals && vitals.vitals ? vitals.vitals.calories : undefined,
        localized.biobeat.caloriesUnit(),
      ]}
      header={localized.biobeat.calories()}
      icon={Icons.Calories}
    />
  );
};

export const CO = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[vitals && vitals.vitals ? vitals.vitals.co : undefined, localized.biobeat.coUnit()]}
      header={localized.biobeat.co()}
      icon={Icons.CardiacOutput}
    />
  );
};

export const CI = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[vitals && vitals.vitals ? vitals.vitals.ci : undefined, localized.biobeat.ciUnit()]}
      header={localized.biobeat.ci()}
      icon={Icons.CardiacIndex}
    />
  );
};

export const HR = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[vitals && vitals.vitals ? vitals.vitals.hr : undefined, localized.biobeat.hrUnit()]}
      header={localized.biobeat.hr()}
      icon={Icons.HeartRateAlert}
    />
  );
};

export const HRV = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[
        vitals && vitals.vitals ? vitals.vitals.hrv : undefined,
        localized.biobeat.hrvUnit(),
      ]}
      header={localized.biobeat.hrv()}
      icon={Icons.HeartRateVariability}
    />
  );
};

export const PP = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[vitals && vitals.vitals ? vitals.vitals.pp : undefined, localized.biobeat.ppUnit()]}
      header={localized.biobeat.pp()}
      icon={Icons.PulsePressure}
    />
  );
};

export const Movement = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[
        vitals && vitals.vitals ? vitals.vitals.movement : undefined,
        localized.biobeat.movementUnit(),
      ]}
      header={localized.biobeat.movement()}
      icon={Icons.Movement}
    />
  );
};

export const SPO2 = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[
        vitals && vitals.vitals ? vitals.vitals.spo2 : undefined,
        localized.biobeat.spo2Unit(),
      ]}
      header={localized.biobeat.spo2()}
      icon={Icons.SPO2}
    />
  );
};

export const SV = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[vitals && vitals.vitals ? vitals.vitals.sv : undefined, localized.biobeat.svUnit()]}
      header={localized.biobeat.sv()}
      icon={Icons.StrokeVolume}
    />
  );
};

export const SVR = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[
        vitals && vitals.vitals ? vitals.vitals.svr : undefined,
        localized.biobeat.svrUnit(),
      ]}
      header={localized.biobeat.svr()}
      icon={Icons.SVR}
    />
  );
};

export const Sweat = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();

  return (
    <DataBox
      className={className}
      variant={variant}
      values={[
        vitals && vitals.vitals ? vitals.vitals.sweat : undefined,
        localized.biobeat.sweatUnit(),
      ]}
      header={localized.biobeat.sweat()}
      icon={Icons.Sweat}
    />
  );
};

export const Empty = (props: Props) => {
  const { className, variant } = props;

  return <DataBox className={className} variant={variant} />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getDashboardIcon = (props: Props) => {
  return <Logos.BioBeatSymbol height={DASHBOARD_ICON_HEIGHT} />;
};
