// Search parameters supported by AC

import { Collection } from 'attentive-connect-store/dist/models';

const LOCATION_PARAM = 'l';
const ALERT_PARAM = 'a';

export interface SearchParams {
  // location id in firebase; encoded as 'l=' in url
  careCenterRef: string | null;
  // alert id in firebase; encoded as 'a=' in url
  alertRef: string | null;
}

export const getSearchParams = (url?: URL): SearchParams => {
  const options: SearchParams = {
    // l=
    careCenterRef: null,
    // a=
    alertRef: null,
  };

  const _url = url ? url : new URL(window.location.href);

  // LOCATION
  const locationParam = _url.searchParams.get(LOCATION_PARAM);
  if (locationParam) {
    if (locationParam.indexOf(Collection.careCenter) >= 0) {
      options.careCenterRef = locationParam;
    } else {
      options.careCenterRef = Collection.careCenter + '/' + locationParam;
    }
  }

  // ALERT
  const alertParam = _url.searchParams.get(ALERT_PARAM);
  if (alertParam) {
    if (alertParam.indexOf(Collection.alert) >= 0) {
      options.alertRef = alertParam;
    } else {
      options.alertRef = Collection.alert + '/' + alertParam;
    }
  }

  return options;
};
