import * as React from 'react';
import * as Icons from '../../icons';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SxProps,
  Theme,
} from '@mui/material';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import strings from '../../languages';
import { CancelButton, SubmitButton } from '../buttons';
import { DaysSelect } from '../selectLists';
import Dialog from './Dialog';

interface ComponentProps {
  className?: string;
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  open: boolean;
  onClose: () => void;
  onSubmit: (day: number) => void;
  days: number;
  daysOptions: number[];
}

type Props = ComponentProps & WrappedComponentProps;

export const DaysSelectionDialog = injectIntl((props: Props) => {
  const localized = strings(props.intl);
  const [days, changeDays] = React.useState(props.days);

  React.useEffect(() => changeDays(props.days), [props.days]);

  return (
    <Dialog
      sx={{ ...props.sx }}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="days-selection-dialog-title"
    >
      <DialogTitle id="days-selection-dialog-title">
        <Icons.DateRange />
        <span>{localized.report.days()}</span>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText />
        <DaysSelect
          sx={{ mt: 1 }}
          fullWidth
          selected={days}
          choices={props.daysOptions}
          onSelect={changeDays}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={props.onClose} />
        <SubmitButton onClick={() => props.onSubmit(days)} />
      </DialogActions>
    </Dialog>
  );
});
