"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseMessageToJSON = exports.ResponseMessageFromJSONTyped = exports.ResponseMessageFromJSON = void 0;
const runtime_1 = require("../runtime");
function ResponseMessageFromJSON(json) {
    return ResponseMessageFromJSONTyped(json, false);
}
exports.ResponseMessageFromJSON = ResponseMessageFromJSON;
function ResponseMessageFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'success': json['success'],
        'resultCode': json['resultCode'],
        'resultMsg': !(0, runtime_1.exists)(json, 'resultMsg') ? undefined : json['resultMsg'],
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : json['data'],
        'result': !(0, runtime_1.exists)(json, 'result') ? undefined : json['result'],
    };
}
exports.ResponseMessageFromJSONTyped = ResponseMessageFromJSONTyped;
function ResponseMessageToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'success': value.success,
        'resultCode': value.resultCode,
        'resultMsg': value.resultMsg,
        'data': value.data,
        'result': value.result,
    };
}
exports.ResponseMessageToJSON = ResponseMessageToJSON;
