"use strict";
/* TS enum provides for type-safe enum property and a code/value
 * that is used for internal representation in the database.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SensorType = void 0;
var SensorType;
(function (SensorType) {
    SensorType["AISLEEP"] = "AISLEEP";
    SensorType["BIOBEAT"] = "BIOBEAT";
    SensorType["SENSING_WAVE"] = "SENSING_WAVE";
    SensorType["BLE"] = "BLE";
    SensorType["CAMERA"] = "CAMERA";
    // deprecated
    SensorType["BLE_BLOOD_PRESSURE"] = "BLE_BLOOD_PRESSURE";
})(SensorType || (exports.SensorType = SensorType = {}));
exports.default = SensorType;
