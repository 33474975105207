/**
 * This is a utility component that stores screen size info in redux.
 * It's intended to be used by components that want to know the current screen size
 * or if the screen is being resized.
 */

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import * as device from './device';
import { getLogger } from './logger';
import * as ac from './redux';
import { useWidth } from './hooks/useWidth';

const logger = getLogger('LayoutListener');

logger.debug();


type ApplicationContextProps = {
  width: device.Width | undefined;
  resizing: boolean | undefined;
  setWidth: (width: device.Width) => void;
  clearWidth: () => void;
  setResizing: (resizing: boolean) => void;
  clearResizing: () => void;
};

type Props = ApplicationContextProps;

const LayoutListener = (props: Props): JSX.Element | null => {
  const [resizeTimeout, setResizeTimeout] = useState<NodeJS.Timeout | null>(null);
  const [resizing, setResizing] = useState(false);
  const width = useWidth();

  useEffect(() => {
    const resizeHandler = () => {
      if (!resizing) {
        setResizing(true);
        if (resizeTimeout) {
          window.clearTimeout(resizeTimeout);
          setResizeTimeout(null);
        }
        setResizeTimeout(
          setTimeout(() => {
            setResizing(false);
          }, 600)
        );
      }
    };

    logger.debug('add resize event listener');
    window.addEventListener('resize', resizeHandler);

    return () => {
      if (resizeTimeout) {
        window.clearTimeout(resizeTimeout);
      }
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    logger.debug({ width });
    if (width) {
      props.setWidth(width);
    }
  }, [width]);

  useEffect(() => {
    logger.debug({ resizing });
    props.setResizing(resizing);
  }, [resizing]);

  logger.debug({ resizing, width });

  return null;
  // return <div className={classes.root}>{`width: ${width}`}</div>;
};

const mapApplicationStateToProps = ({ device }: ac.ApplicationState) => ({
  width: device.width,
  resizing: device.resizing,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setResizing: (resizing: boolean) => dispatch(ac.device.setResizing(resizing)),
  clearResizing: () => dispatch(ac.device.clearResizing()),
  setWidth: (width: device.Width) => dispatch(ac.device.setWidth(width)),
  clearWidth: () => dispatch(ac.device.clearWidth()),
});

export default connect(mapApplicationStateToProps, mapDispatchToProps)(LayoutListener);
