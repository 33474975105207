import { createSvgIcon } from '@mui/material';

const Buddycom = createSvgIcon(
  <g transform="matrix(1.0581,0,0,1.0581,-0.730105,-0.671407)">
    <g transform="matrix(0.4125,0,0,0.375,-1.0125,-0.375)">
      <path d="M14.4,18.6C12.4,18.6 10.7,20.2 10.7,22.3L10.7,50.7C10.7,52.7 12.3,54.4 14.4,54.4L41.7,54.4C42.7,54.4 43.6,54 44.3,53.3C45,52.6 45.4,51.7 45.4,50.7C45.4,48.7 43.8,47 41.7,47L18.1,47L18.1,22.2C18,20.2 16.4,18.6 14.4,18.6Z" />
    </g>
    <g transform="matrix(0.4125,0,0,0.375,-0.975,-0.0375)">
      <path d="M11.6,52.3C12.3,53.1 13.3,53.6 14.4,53.6L41.7,53.6C42.7,53.6 43.6,53.2 44.3,52.5C45,51.8 45.4,50.9 45.4,49.9C45.4,47.9 43.8,46.2 41.7,46.2L18.1,46.2" />
    </g>
    <g transform="matrix(0.4125,0,0,0.375,-0.975,-0.525)">
      <path d="M27.6,22L27.6,28.4C31.2,27.4 35,29.3 36.2,32.9C36.7,34.2 36.7,35.6 36.4,36.9L42.7,36.9C42.9,34.9 42.8,32.9 42.1,30.9C39.9,24.6 33.7,21.1 27.6,22Z" />
    </g>
    <g transform="matrix(0.4125,0,0,0.375,-0.975,-0.675)">
      <path d="M27.5,10L27.5,16.3C36.2,15.4 44.7,20.6 47.7,29.2C48.6,31.8 48.9,34.5 48.7,37.1L55,37.1C55.2,33.8 54.8,30.4 53.6,27.1C49.7,15.9 38.8,9.2 27.5,10Z" />
    </g>
  </g>,
  'Buddycom'
);

export { Buddycom };
export default Buddycom;
