"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * AiSleep API
 * API implemented by AiSleep servers. Can be used by AC to configure sensors.  ## Status  `In progress` - this is in design phase.  ## Response Codes  API calls will generate responses with status codes as defined by the [HTTP Status Codes](https://en.wikipedia.org/wiki/List_of_HTTP_status_codes).
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeviceSettingsToJSON = exports.DeviceSettingsFromJSONTyped = exports.DeviceSettingsFromJSON = void 0;
const runtime_1 = require("../runtime");
function DeviceSettingsFromJSON(json) {
    return DeviceSettingsFromJSONTyped(json, false);
}
exports.DeviceSettingsFromJSON = DeviceSettingsFromJSON;
function DeviceSettingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hrflg': !(0, runtime_1.exists)(json, 'hrflg') ? undefined : json['hrflg'],
        'hrmax': !(0, runtime_1.exists)(json, 'hrmax') ? undefined : json['hrmax'],
        'hrmin': !(0, runtime_1.exists)(json, 'hrmin') ? undefined : json['hrmin'],
        'hrover': !(0, runtime_1.exists)(json, 'hrover') ? undefined : json['hrover'],
        'reflg': !(0, runtime_1.exists)(json, 'reflg') ? undefined : json['reflg'],
        'remax': !(0, runtime_1.exists)(json, 'remax') ? undefined : json['remax'],
        'remin': !(0, runtime_1.exists)(json, 'remin') ? undefined : json['remin'],
        'reover': !(0, runtime_1.exists)(json, 'reover') ? undefined : json['reover'],
        'movflg': !(0, runtime_1.exists)(json, 'movflg') ? undefined : json['movflg'],
        'movmin': !(0, runtime_1.exists)(json, 'movmin') ? undefined : json['movmin'],
        'movover': !(0, runtime_1.exists)(json, 'movover') ? undefined : json['movover'],
        'outbedflg': !(0, runtime_1.exists)(json, 'outbedflg') ? undefined : json['outbedflg'],
        'outbedtime': !(0, runtime_1.exists)(json, 'outbedtime') ? undefined : json['outbedtime'],
        'situpflg': !(0, runtime_1.exists)(json, 'situpflg') ? undefined : json['situpflg'],
        'situptime': !(0, runtime_1.exists)(json, 'situptime') ? undefined : json['situptime'],
        'rewflg': !(0, runtime_1.exists)(json, 'rewflg') ? undefined : json['rewflg'],
        'rewmin': !(0, runtime_1.exists)(json, 'rewmin') ? undefined : json['rewmin'],
        'rewover': !(0, runtime_1.exists)(json, 'rewover') ? undefined : json['rewover'],
    };
}
exports.DeviceSettingsFromJSONTyped = DeviceSettingsFromJSONTyped;
function DeviceSettingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hrflg': value.hrflg,
        'hrmax': value.hrmax,
        'hrmin': value.hrmin,
        'hrover': value.hrover,
        'reflg': value.reflg,
        'remax': value.remax,
        'remin': value.remin,
        'reover': value.reover,
        'movflg': value.movflg,
        'movmin': value.movmin,
        'movover': value.movover,
        'outbedflg': value.outbedflg,
        'outbedtime': value.outbedtime,
        'situpflg': value.situpflg,
        'situptime': value.situptime,
        'rewflg': value.rewflg,
        'rewmin': value.rewmin,
        'rewover': value.rewover,
    };
}
exports.DeviceSettingsToJSON = DeviceSettingsToJSON;
