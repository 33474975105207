import {
  WrappedComponentProps as ReactIntlWrappedComponentProps,
  injectIntl,
  IntlProvider,
} from 'react-intl';

import * as HighchartsIntl from './highcharts';
import { en_US, he, ja_JP, pt, zh_HANS, zh_HANT } from './messages';
import strings, { StringsIntl } from './strings';
import { Language as Languages } from './types';

export { injectIntl, IntlProvider };

export type Language = Languages;
export type WrappedComponentProps = ReactIntlWrappedComponentProps;
export { HighchartsIntl };

export const messages = {
  'ja-JP': ja_JP,
  'en-US': en_US,
  'zh-HANS': zh_HANS,
  'zh-HANT': zh_HANT,
  he,
  pt,
};

export const browserLanguage = (): Language => language(navigator.language);

export const language = (locale: string): Language => {
  const lang = locale.toLowerCase();
  let l: Language;

  if (lang.startsWith('he')) {
    l = 'he';
  } else if (lang.startsWith('en')) {
    l = 'en-US';
  } else if (lang.startsWith('pt')) {
    l = 'pt';
  } else if (lang.startsWith('zh-hans') || lang.startsWith('zh-cn') || lang.startsWith('zh-sg')) {
    l = 'zh-HANS';
  } else if (lang.startsWith('zh-hant') || lang.startsWith('zh-tw') || lang.startsWith('zh-hk')) {
    l = 'zh-HANT';
  } else {
    l = 'ja-JP';
  }

  return l;
};

export const isJapanese = (locale: string): boolean => language(locale).startsWith('ja');

export { StringsIntl };
export default strings;
