import { Store as Database } from 'attentive-connect-store/dist';
import { UserPerson, UserProfile } from 'attentive-connect-store/dist/models';
import * as firebase from 'attentive-connect-store/dist/services/Firebase';
import { action } from 'typesafe-actions';

import { Push } from '../../push/Push';
import { AuthActionTypes } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

export const signedIn = (user: firebase.User, database: Database, userProfile?: UserProfile) =>
  action(AuthActionTypes.SIGN_IN, { user, database, userProfile });
export const signedOut = () => {
  if (Push.instance) {
    Push.instance.filterCareCenters([]);
  }
  return action(AuthActionTypes.SIGN_OUT);
};
export const setToken = (token: string) => action(AuthActionTypes.TOKEN, token);
export const selectPerson = (userPerson: UserPerson) => {
  return action(AuthActionTypes.SELECT_PERSON, { userPerson });
};
export const clearPerson = () => {
  return action(AuthActionTypes.CLEAR_PERSON, { userPerson: null });
};
