import { createSvgIcon } from '@mui/material';

const HeartRateAlert = createSvgIcon(
  <g>
    <path
      d="M5.5,11l3.5-5l3.4,7.1l2.8-4.6l3.1,2.5h3.1c0.3-0.8,0.5-1.6,0.5-2.5C22,5.4,19.6,3,16.5,3c-1.7,0-3.4,0.8-4.5,2.1
            C10.9,3.8,9.2,3,7.5,3C4.4,3,2,5.4,2,8.5c0,0.9,0.2,1.7,0.5,2.5H5.5z"
    />
    <path d="M17.6,13l-1.9-1.5l-3.6,5.8L8.6,10l-2.1,3H3.6c1.5,2.1,3.9,4.3,7,7l1.4,1.3l1.4-1.3c3-2.7,5.4-4.9,6.9-7H17.6z" />
  </g>,
  'HeartRateAlert'
);

export { HeartRateAlert };
export default HeartRateAlert;
