"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CareSystemType = void 0;
var CareSystemType;
(function (CareSystemType) {
    CareSystemType["NONE"] = "none";
    CareSystemType["WISEMAN"] = "wiseman";
    CareSystemType["CAREKARTE"] = "careKarte";
})(CareSystemType || (exports.CareSystemType = CareSystemType = {}));
exports.default = CareSystemType;
