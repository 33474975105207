import { isHybrid } from '../hybrid';
import { getLogger } from '../logger';
import { Push } from '../push/Push';
import { loadSound, SoundLanguage, Sounds } from './Sounds';

const logger = getLogger('sounds/SoundsStateEngine', 'info');
logger.debug();

type SoundState = 'playing' | 'ended' | 'stopped' | 'na';
// AC-1014 - disable alert resolved sounds
const DISABLE_ALERTS_RESOLVED_SOUND = true;

/**
 * This class is a wrapper around the Sounds module.
 * It ensures that sounds do not play over each other.
 */
class SoundStateEngine {
  private static _instance: SoundStateEngine;
  private alertsState: SoundState = 'stopped';
  private awakeState: SoundState = 'stopped';
  private alertsResolvedState: SoundState = 'stopped';
  // private newAlertsState: 'stopped' | 'playing' = 'stopped';
  private alertLoopId = 0;
  private alertCount = 0;
  private alertsLoopSound: Sounds | null = null;
  private alertsResolvedSound: Sounds | null = null;
  private language: SoundLanguage = 'en';
  private awakeLoopId = 0;
  private awakeCount = 0;
  private awakeSound: Sounds | null = null;
  static instance() {
    if (!SoundStateEngine._instance) {
      SoundStateEngine._instance = new SoundStateEngine();
    }
    return SoundStateEngine._instance;
  }
  setAlertCount(language: SoundLanguage, count: number) {
    logger.debug(
      `setAlertCount: ${language} count: ${this.alertCount} -> ${count} (currently ${this.alertsState})`
    );
    if (
      this.alertsState !== 'stopped' &&
      ((language === this.language && count === this.alertCount) ||
        (count >= this.alertCount && this.alertCount > 2))
    ) {
      logger.debug(`setAlertCount: no change`);
    } else if (this.isPushActive() || count < 0) {
      logger.debug(`setAlertCount: stopping alert notifications`);
      // we don't play notifications when push is active or when count is negative
      this.stopAlertsAudio();
      this.loopAwake(this.language, this.awakeCount);
    } else if (count === 0 && this.alertCount > 0) {
      logger.debug(`setAlertCount: stop alert notifications, then play alerts resolved`);
      this.stopAlertsAudio();
      // stop active alerts
      if (this.alertCount > 0 && count <= 0) {
        // AC-1018 - play alerts resolved sound
        if (!DISABLE_ALERTS_RESOLVED_SOUND) {
          this.play(
            this.onPlayAlertsResolved.bind(this),
            this.onStopAlertsResolved.bind(this),
            this.onEndAlertsResolved.bind(this)
          );
        }
      }
    } else if (count > 0) {
      logger.debug(`setAlertCount: start loop`);
      if (this.alertsResolvedSound) {
        this.alertsResolvedSound.audio.stop();
      }
      this.loopAlerts(language, count);
    }
    this.language = language;
    this.alertCount = count;
  }
  loopAlerts(language: SoundLanguage, count: number) {
    if (count > 0) {
      this.stopAlertsAudio();
      this.stopAwakeAudio();
      const sound = this.loadAlertsSound(language, count);
      this.alertLoopId = (this.alertLoopId + 1) % 1000;
      this.alertsLoopSound = sound;
      this.alertCount = count;
      this.language = language;
      this.loop(
        this.alertLoopId,
        sound,
        this.onPlayAlerts.bind(this),
        this.onStopAlerts.bind(this),
        this.onLoopAlerts.bind(this),
        5000
      );
    }
  }
  onPlayAlerts(loopId: number, sound: Sounds): SoundState {
    if (this.alertLoopId !== loopId) {
      return 'na';
    } else if (this.alertCount > 0) {
      logger.debug(`onPlayAlerts: ${this.alertsState} -> playing`);
      this.alertsLoopSound = sound;
      this.alertsState = 'playing';
    }
    return this.alertsState;
  }
  onStopAlerts(loopId: number): SoundState {
    if (this.alertLoopId !== loopId) {
      return 'na';
    } else if (this.alertsState !== 'stopped') {
      logger.debug(`onStopAlerts: ${this.alertsState} -> stopped`);
      this.alertsState = 'stopped';
    }
    return this.alertsState;
  }
  onLoopAlerts(loopId: number): SoundState {
    if (this.alertsLoopSound && this.alertsState !== 'stopped' && this.alertLoopId === loopId) {
      logger.debug(`onLoopAlerts(true): ${this.alertsState} -> ended`);
      this.alertsState = 'ended';
      return this.alertsState;
    } else {
      return 'na';
    }
  }
  stopAlertsAudio(sound?: Sounds) {
    logger.debug(`stopLertsAudio: ${this.alertsState}`);
    if (sound) {
      sound.audio.stop();
    } else if (this.alertsLoopSound) {
      this.alertsLoopSound.audio.stop();
    }
  }
  onPlayAlertsResolved() {
    logger.debug(`onPlayAlertsResolved: ${this.alertsResolvedState}`);
    this.alertsResolvedSound = this.loadAlertsResolvedSound(this.language);
    this.alertsResolvedSound.audio.volume(0.2);
    if (this.alertsResolvedState !== 'playing') {
      this.alertsResolvedState = 'playing';
      return this.alertsResolvedSound;
    } else {
      this.loopAwake(this.language, this.awakeCount);
      return null;
    }
  }
  onStopAlertsResolved() {
    logger.debug(`onStopAlertsResolved: ${this.alertsResolvedState}`);
    if (this.alertsResolvedState !== 'stopped') {
      this.alertsResolvedState = 'stopped';
      if (this.alertsResolvedSound) {
        this.alertsResolvedSound.audio.stop();
      }
    }
  }
  onEndAlertsResolved(): boolean {
    logger.debug(`onEndAlertsResolved: ${this.alertsResolvedState}`);
    if (this.alertsResolvedState !== 'ended') {
      this.alertsResolvedState = 'ended';
      this.loopAwake(this.language, this.awakeCount);
    }
    return false;
  }
  setAwakeCount(language: SoundLanguage, count: number) {
    logger.debug(
      `setAwakeCount: ${language} count: ${this.awakeCount} -> ${count} (currently ${this.awakeState})`
    );
    if (
      this.awakeState !== 'stopped' &&
      ((language === this.language && count === this.awakeCount) ||
        (count >= this.awakeCount && this.awakeCount > 2))
    ) {
      logger.debug(`setAwakeCount: no changes required (continue)`);
    } else if (this.isPushActive() || count <= 0 || this.alertsState !== 'stopped') {
      // we don't play notifications when push is active
      // nor when alerts are active
      logger.debug(`setAwakeCount: stopping awake notifications`);
      this.stopAwakeAudio();
    } else if (count > 0) {
      if (count !== this.awakeCount) {
        if (count > this.awakeCount && this.awakeCount < 2) {
          logger.debug(`setAwakeCount: resetting awake notifications`);
          this.stopAwakeAudio();
        } else if (count < this.awakeCount && this.awakeCount < 3) {
          logger.debug(`setAwakeCount: resetting awake notifications`);
          this.stopAwakeAudio();
        }
      }
      logger.debug(`setAwakeCount: starting awake notification loop`);
      this.loopAwake(language, count);
    }
    this.awakeCount = count;
    this.language = language;
  }
  loopAwake(language: SoundLanguage, count: number) {
    if (count > 0 && (this.awakeState === 'stopped' || this.awakeCount !== count)) {
      this.awakeCount = count;
      this.awakeSound = this.loadAwakeSound(language, count);
      this.awakeLoopId = (this.awakeLoopId + 1) % 1000;
      this.language = language;
      logger.debug(`loopAwake: ${this.awakeLoopId}`);
      this.loop(
        this.awakeLoopId,
        this.awakeSound,
        this.onPlayAwake.bind(this),
        this.onStopAwake.bind(this),
        this.onEndAwake.bind(this),
        8000
      );
    }
  }
  onPlayAwake(loopId: number, sound: Sounds): SoundState {
    if (this.awakeLoopId !== loopId) {
      logger.debug(`onPlayAwake(${loopId}): ${this.awakeState} -> na`);
      return 'na';
    } else if (
      this.awakeCount > 0 &&
      this.alertsState === 'stopped' &&
      this.alertsResolvedState !== 'playing'
    ) {
      logger.debug(`onPlayAwake(${loopId}): ${this.awakeState} -> playing`);
      this.awakeState = 'playing';
      this.awakeSound = sound;
    } else {
      this.awakeState = 'stopped';
    }
    return this.awakeState;
  }
  onStopAwake(loopId: number): SoundState {
    if (loopId !== this.awakeLoopId) {
      logger.debug(`onStopAwake(${loopId}): ${this.awakeState} -> na`);
      return 'na';
    } else {
      logger.debug(`onStopAwake(${loopId}): ${this.awakeState} -> stopped`);
      this.awakeState = 'stopped';
    }
    return this.awakeState;
  }
  stopAwakeAudio(sound?: Sounds) {
    logger.debug(`stopAwakeAudio(${this.awakeLoopId}): ${this.awakeState}`);
    if (sound) {
      sound.audio.stop();
    } else if (this.awakeSound) {
      this.awakeSound.audio.stop();
    }
  }
  onEndAwake(loopId: number): SoundState {
    if (this.awakeLoopId !== loopId) {
      logger.debug(`onEndAwake(${loopId}): ${this.awakeState} -> na`);
      return 'na';
    } else if (this.awakeState === 'stopped' || this.alertsState !== 'stopped') {
      logger.debug(`onEndAwake(${loopId}): ${this.awakeState} -> stopped`);
      this.awakeState = 'stopped';
    } else {
      logger.debug(`onEndAwake(${loopId}): ${this.awakeState} -> ended`);
      this.awakeState = 'ended';
    }
    return this.awakeState;
  }
  play(onPlay: () => Sounds | null, onStop: () => void, onEnd: () => void) {
    const sound = onPlay();
    if (sound !== null) {
      sound.audio.once('end', onEnd).once('stop', onStop);
      sound.audio.play();
    }
  }
  loop = (
    loopId: number,
    sound: Sounds,
    onPlay: (loopId: number, sound: Sounds) => SoundState,
    onStop: (loopId: number) => SoundState,
    onEnd: (loopId: number) => SoundState,
    interval: number
  ) => {
    if (!this.isPushActive() && onPlay(loopId, sound) === 'playing') {
      sound.audio
        .once('end', () => {
          if (onEnd(loopId) === 'ended') {
            setTimeout(() => this.loop(loopId, sound, onPlay, onStop, onEnd, interval), interval);
          }
        })
        .once('stop', () => onStop(loopId))
        .once('play', () => onPlay(loopId, sound))
        .play();
    } else {
      sound.audio.stop();
    }
  };
  loadAlertsSound(language: SoundLanguage, count: number) {
    switch (count) {
      case 1:
        return loadSound('oneActiveAlert', language);
      case 2:
        return loadSound('twoActiveAlerts', language);
      default:
        return loadSound('severalActiveAlerts', language);
    }
  }
  loadAwakeSound(language: SoundLanguage, count: number) {
    switch (count) {
      case 1:
        return loadSound('onePersonAwake', language);
      case 2:
        return loadSound('twoPeopleAwake', language);
      default:
        return loadSound('severalPeopleAwake', language);
    }
  }
  loadAlertsResolvedSound(language: SoundLanguage) {
    return loadSound('alertsResolved', language);
  }
  loadNewAlertsSound(language: SoundLanguage) {
    return loadSound('newAlerts', language);
  }
  isPushActive = () => isHybrid() && Push.instance && Push.instance.isHybridSuspended();
  getState() {
    return {
      alertsState: this.alertsState,
      alertsResolvedState: this.alertsResolvedState,
      awakeState: this.awakeState,
    };
  }
}

export default SoundStateEngine.instance();
