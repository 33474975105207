import * as React from 'react';
import { Tooltip } from '@mui/material';
import { Vitals } from 'attentive-connect-api-fetch/dist';
import { useLocale } from '../hooks';
import * as Icons from '../icons';
import DataBox from './DataBox';
import { VitalsVariants } from './vitals/VitalsConfig';

const SHOW_ICONS = false;

interface Props {
  className?: string;
  vitals?: Vitals;
  variant?: VitalsVariants;
}

export const Diastolic = (props: Props): React.JSX.Element => {
  const { vitals, className, variant } = props;
  const localized = useLocale();
  let tooltip = '';

  if (vitals && vitals.t) {
    const d = new Date(vitals.t);
    tooltip = localized.time.date(d) + ' ' + localized.time.time(d);
  }

  return (
    <Tooltip title={tooltip} placement="top" arrow disableFocusListener>
      <DataBox
        className={className}
        variant={variant}
        header={localized.ble.diastolic()}
        values={[vitals ? vitals.dia : undefined, localized.biobeat.bpUnit()]}
        icon={SHOW_ICONS ? Icons.BloodPressure : undefined}
      />
    </Tooltip>
  );
};

export const Systolic = (props: Props): React.JSX.Element => {
  const { vitals, className, variant } = props;
  const localized = useLocale();
  let tooltip = '';

  if (vitals && vitals.t) {
    const d = new Date(vitals.t);
    tooltip = localized.time.date(d) + ' ' + localized.time.time(d);
  }

  return (
    <Tooltip title={tooltip} placement="top" arrow disableFocusListener>
      <DataBox
        className={className}
        variant={variant}
        header={localized.ble.systolic()}
        values={[vitals ? vitals.sys : undefined, localized.biobeat.bpUnit()]}
        icon={SHOW_ICONS ? Icons.BloodPressure : undefined}
      />
    </Tooltip>
  );
};

export const BodyTemp = (props: Props): React.JSX.Element => {
  const { vitals, className, variant } = props;
  const localized = useLocale();
  let tooltip = '';

  if (vitals && vitals.t) {
    const d = new Date(vitals.t);
    tooltip = localized.time.date(d) + ' ' + localized.time.time(d);
  }

  return (
    <Tooltip title={tooltip} placement="top" arrow disableFocusListener>
      <DataBox
        className={className}
        variant={variant}
        header={localized.ble.bodyTemp()}
        values={[vitals ? vitals.bodyTemp : undefined, localized.biobeat.bodyTempUnit()]}
        icon={SHOW_ICONS ? Icons.BodyTemperature : undefined}
      />
    </Tooltip>
  );
};

export const HeartRate = (props: Props) => {
  const { vitals, className, variant } = props;
  const localized = useLocale();
  let tooltip = '';

  if (vitals && vitals.t) {
    const d = new Date(vitals.t);
    tooltip = localized.time.date(d) + ' ' + localized.time.time(d);
  }

  return (
    <Tooltip title={tooltip} placement="top" arrow disableFocusListener>
      <DataBox
        className={className}
        variant={variant}
        header={localized.ble.hr()}
        values={[vitals ? vitals.hr : undefined, localized.biobeat.hrUnit()]}
        icon={SHOW_ICONS ? Icons.HeartRateAlert : undefined}
      />
    </Tooltip>
  );
};

export const Empty = (props: Props): React.JSX.Element => {
  const { className, variant } = props;
  return <DataBox className={className} variant={variant} />;
};
